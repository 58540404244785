import React, { Component } from "react";
import PropTypes from "prop-types";
import { translate, FieldTitle, addField } from "react-admin";

import {
  getCurrentUserRoles,
  getRcContext,
  getRoleIdsByNames,
  getRoleNamesByIds,
  getRoleNameById,
  getUserRoles,
  getAllRoles,
  getRoleIdByName,
  getRecordFormValues,
  getRoleById,
  getRoleByName
} from "../../redux/selectors";
import {
  getAllRoles as loadAllRoles,
  setRecordFormValue
} from "../../redux/actions";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { Field } from "redux-form";
import config from "../../config";
import { difference, intersection, union } from "lodash";
import { Chip } from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import { Labeled } from "react-admin";

const dataSourceConfig = { text: "text", value: "value" };

const styles = {
  chip: {
    margin: 4,
    float: "left"
  }
};

export class renderRoleEdit extends Component {
  constructor(props) {
    super(props);
    props.loadRoles();
    this.state = {
      values: this.getChoicesForValues(
        props.input.value || [],
        props.choices || []
      )
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.choices !== prevProps.choices ||
      this.props.input.value !== prevProps.input.value ||
      this.props.getAllRoles !== prevProps.getAllRoles
    ) {
      this.setState({
        values: this.getChoicesForValues(
          this.props.input.value || [],
          this.props.choices || []
        )
      });
    }
  }

  handleBlur = () => {
    const extracted = this.extractIds(this.state.values);
    this.props.onBlur(extracted);
    this.props.input.onBlur(extracted);
  };

  handleFocus = () => {
    const extracted = this.extractIds(this.state.values);
    this.props.onFocus(extracted);
    this.props.input.onFocus(extracted);
  };

  handleAdd = newValue => {
    const values = [...this.state.values, newValue];
    this.setState({ values });
    this.handleChange(values);
  };

  handleDelete = newValue => {
    if (
      !this.props.getRoleIds(config.ROLES.NOTEDITABLE.All).includes(newValue)
    ) {
      const values = this.state.values.filter(v => v.value !== newValue);
      this.setState({ values });
      this.handleChange(values);
    }
  };

  handleChange = eventOrValue => {
    const extracted = this.extractIds(eventOrValue);
    this.props.onChange(extracted);
    this.props.input.onChange(extracted);
  };

  handleChangeOwn = eventOrValue => {
    let extracted = this.extractIds(eventOrValue);
    const userRoles = this.props.getRoleNames(this.props.record.roles);
    const context = this.getRoleContext(userRoles);
    if (context) {
      extracted = union(extracted, this.props.getRoleIds(context));
    }
    this.props.onChange(extracted);
    this.props.input.onChange(extracted);
    this.props.changeFormValue("roles", extracted);
  };

  extractIds = eventOrValue => {
    const value =
      eventOrValue.target && eventOrValue.target.value
        ? eventOrValue.target.value
        : eventOrValue;
    if (Array.isArray(value)) {
      return value.map(o => o.value);
    }
    return [value];
  };

  getChoicesForValues = (values, choices = []) => {
    const { optionValue, optionText } = this.props;
    if (!values || !Array.isArray(values)) {
      throw Error("Value of RoleEdit should be an array");
    }
    //FIXME dont display contextRoles
    //values = difference(values, this.props.getRoleIds(config.ROLES.NOTEDITABLE.All))
    choices = values.map(
      value =>
        choices.find(c => c[optionValue] === value) ||
        !this.props.getRoleName(value) || {
          //FIXME if users are not coming from the list screen, text for the roles isnt loaded yet..
          [optionValue]: value,
          [optionText]: this.props.getRole(value).label
        }
    );
    choices = difference(choices, [true]);
    return choices.map(this.formatChoice);
  };

  formatChoices = choices => choices.map(this.formatChoice);

  formatChoice = choice => {
    const { optionText, optionValue, translateChoice, translate } = this.props;
    const choiceText =
      typeof optionText === "function"
        ? optionText(choice)
        : choice[optionText];
    return {
      value: choice[optionValue],
      text: translateChoice
        ? translate(choiceText, { _: choiceText })
        : choiceText
    };
  };

  getRoleContext = roles => intersection(config.ROLES.CONTEXTROLES, roles);

  getDataSource = () => {
    let data = [];
    const roleNames = this.props.getRoleNames(Object.keys(this.props.allRoles));
    const userRoles = this.props.getRoleNames(this.props.record.roles);
    const currentUserRoles = this.getCurrentUserRoles();
    const context = this.getRoleContext(userRoles);
    let choices = difference(roleNames, config.ROLES.NOTEDITABLE.All);
    if (!currentUserRoles) return data;
    Object.keys(config.ROLES.NOTEDITABLE).forEach(key => {
      if (currentUserRoles.includes(key)) {
        choices = difference(choices, config.ROLES.NOTEDITABLE[key]);
      }
    });
    let roles = [];
    const roleObject = config.ROLES.CONTEXT[context];
    //every user should have a rolecontext, just if one doesnt have it
    if (roleObject) {
      Object.keys(roleObject).forEach(role => {
        roles = union(roles, roleObject[role]);
      });
      choices = intersection(choices, roles);
    }
    choices.forEach(choice => {
      const role = this.props.getRoleByName(choice);
      data.push({
        value: role.id,
        text: role.label
      });
    });
    return data;
  };

  getCurrentUserRoles = () => {
    let currentUserRoles = [];
    this.props.currentUserRoles.forEach(role =>
      currentUserRoles.push(role.name)
    );
    return currentUserRoles;
  };

  render() {
    const {
      elStyle,
      input,
      isRequired,
      label,
      options,
      meta: { touched, error },
      resource,
      source,
      setFilter,
      disabled
    } = this.props;
    let allowEdit = true;

    const userRoles = this.props.record.roles || [];
    const userContext = this.getRoleContext(userRoles)[0];
    const currentUserRoles = this.getCurrentUserRoles();
    currentUserRoles.forEach(role => {
      if (
        config.ROLES.EDIT[role] &&
        config.ROLES.EDIT[role].includes(userContext)
      )
        allowEdit = false;
    });

    if (allowEdit || disabled) {
      if (userRoles && userRoles.length >= 1) {
        return (
          <Labeled label="mra.roles.selectRolesNoPermission">
            <div>
              {userRoles.map(role => (
                <Chip key={role.id} style={styles.chip} label={role.label} />
              ))}
            </div>
          </Labeled>
        );
      } else {
        return null;
      }
    } else {
      return (
        <ChipInput
          {...input}
          value={this.state.values}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onClick={this.handleFocus}
          onAdd={this.handleAdd}
          onDelete={this.handleDelete}
          onUpdateInput={setFilter}
          label={
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          }
          errorText={touched && error}
          style={elStyle}
          dataSource={this.getDataSource()}
          dataSourceConfig={dataSourceConfig}
          openOnFocus
          {...options}
        />
      );
    }
  }
}

renderRoleEdit.propTypes = {
  loadRoles: PropTypes.func,
  getAllRoles: PropTypes.func,
  getRoleNames: PropTypes.func,
  getRoleByName: PropTypes.func,
  record: PropTypes.object,
  allRoles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  currentUserRoles: PropTypes.array,
  getRoleName: PropTypes.func,
  getRole: PropTypes.func,
  getRoleIds: PropTypes.func,
  changeFormValue: PropTypes.func,
  elStyle: PropTypes.object,
  choices: PropTypes.arrayOf(PropTypes.object),
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  setFilter: PropTypes.func,
  options: PropTypes.object,
  optionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    .isRequired,
  optionValue: PropTypes.string.isRequired,
  resource: PropTypes.string,
  source: PropTypes.string,
  translate: PropTypes.func.isRequired,
  translateChoice: PropTypes.bool.isRequired,
  disabled: PropTypes.bool
};

renderRoleEdit.defaultProps = {
  choices: [],
  onBlur: () => true,
  onChange: () => true,
  onFocus: () => true,
  options: {},
  optionText: "name",
  optionValue: "id",
  translateChoice: true,
  disabled: false
};

const ConnectedrenderRoleEdit = compose(
  connect(
    state => {
      return {
        //FIXME not all of these are needed any more
        getRoleIds: names => getRoleIdsByNames(names, state),
        getRoleId: name => getRoleIdByName(name, state),
        getRole: id => getRoleById(id, state),
        getRoleByName: name => getRoleByName(name, state),
        getRoleNames: ids => getRoleNamesByIds(ids, state),
        getRoleName: id => getRoleNameById(id, state),
        getUserRoles: user => getUserRoles(user)(state),
        getFormValue: name => getRecordFormValues(name)(state),
        currentUserRoles: getCurrentUserRoles(state),
        allRoles: getAllRoles(state),
        rcContext: getRcContext(state)
      };
    },
    {
      loadRoles: loadAllRoles,
      changeFormValue: setRecordFormValue
    }
  ),
  translate,
  addField
)(renderRoleEdit);

const RoleEdit = translate(({ translate, ...props }) => (
  <Field
    label={translate(props.label)}
    name={props.source}
    component={ConnectedrenderRoleEdit}
    {...props}
  />
));

RoleEdit.propTypes = {
  source: PropTypes.string,
  label: PropTypes.string
};

export default RoleEdit;
