import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  InputLabel,
  FormControl,
  FormHelperText,
  MenuItem,
  Select
} from "@material-ui/core";
import { translate, addField } from "react-admin";
import compose from "recompose/compose";
import config from "../../config";
import {
  getRoleIdsByNames,
  getRoleNamesByIds,
  getRcContext,
  getCurrentUserRUContext,
  getCurrentUserRoles
} from "../../redux/selectors";
import { connect } from "react-redux";
import { isEqual, difference } from "lodash";
import { getAllRoles, setRecordFormValue } from "../../redux/actions";

/**
 * Input Component to select UserRoles based on the current context. The component maps
 * the selected role to the actual roles.
 * choices with their mappings are defined in config.ROLES
 * mappings: (for details see MRA-432)
 *  * Add role selector with values (and role mappings):
 *  * Headquarter Administrator (Member of HQ, Administator)
 *  * Headquarter Supervisor (Member of HQ, Supervisor)
 *  * Headquarter Report User (Member of HQ, Report User)
 *  * Reading Unit Supervisor (Member of RU, Supervisor)
 *  * Reading Unit Field Agent (Member of RU, Field agent)
 *  * Reading Unit Report User (Member of RU, Report User)
 *
 * The Component passes its props to child Components.
 * props:
 *   * source: should always be an array of roles
 *   * optionValue: should be name (default: name)
 *   * optionText: should be label (default: label)
 *       however: u can display other values of the current choice if necessary
 *   * label: translation key
 *
 * fetched choices will be of the format:
 *   * {context :String, name :String, roles :Array, label :String}
 *   * context: HQ, RU, RC (based on the rccontext)
 *   * roles: reference ids of assigned roles
 *   * label: example: "mra.roles.HQ_Administrator"
 *
 * The object passed as `options` props is passed to the material-ui <SelectField> component
 */
export class RenderRoleInput extends Component {
  /*
   * Using state to bypass a redux-form comparison but which prevents re-rendering
   * @see https://github.com/erikras/redux-form/issues/2456
   */
  constructor(props) {
    super(props);
    props.loadRoles();
    this.state = {
      value: this.getChoiceByRoleIds(this.props.input.value)
    };
  }

  renderMenuItem = choice => {
    const { translate, optionText, optionValue, translateChoice } = this.props;
    const choiceName = React.isValidElement(optionText) // eslint-disable-line no-nested-ternary
      ? React.cloneElement(optionText, { record: choice })
      : typeof optionText === "function"
      ? optionText(choice)
      : choice[optionText];
    return (
      <MenuItem
        key={`${choice.context}_${choice[optionValue]}`}
        value={choice[optionValue]}
      >
        {translateChoice
          ? translate(choiceName, { _: choiceName })
          : choiceName}
      </MenuItem>
    );
  };

  getContexts() {
    if (this.props.rcContext === config.RC_CONTEXT_ALL) {
      if (this.props.ruContext) return ["RUM"];
      else return ["HQM", "RUM"];
    } else {
      return ["RCM", "RUM"];
    }
  }

  currentUserCanCreate = roles => {
    const roleNames = this.props.currentUserRoles;
    if (
      difference(roleNames, config.ROLES.CONTEXT.HQM.SU).length === 0 &&
      difference(roles, config.ROLES.CONTEXT.HQM.AD).length === 0
    )
      return false;
    else return true;
  };

  getChoices = () => {
    const contexts = this.getContexts();
    let choices = [];
    contexts.forEach(context => {
      const relations = config.ROLES.CONTEXT[context];
      Object.keys(relations).forEach(name => {
        if (relations[name] && this.currentUserCanCreate(relations[name])) {
          choices.push({
            context: context,
            name: `${context}_${name}`,
            roles: relations[name],
            label: `mra.roles.${context}_${name}`
          });
        }
      });
    });
    return choices;
  };

  handleChange = event => {
    const value = event.target.value;
    const choice = this.getChoices().filter(choice => choice.name === value)[0];
    const roles = this.props.getRoleIds(choice.roles);
    this.props.input.onChange(roles);
    //set the rc automatically if a RC User us created from a RC_CONTEXT
    if (choice.context === config.ROLES.NAMES.RC) {
      this.props.changeFormValue("centers", [this.props.rcContext]);
    }
    // set the rc automatically if a RU Field Agent is created from a RC_CONTEXT
    if (
      choice.context === config.ROLES.NAMES.RU &&
      this.props.rcContext !== config.RC_CONTEXT_ALL &&
      choice.roles.includes(config.ROLES.NAMES.FA)
    ) {
      this.props.changeFormValue("centers", [this.props.rcContext]);
    }
    // unset the RC for any User which is Member of HQ
    if (choice.context === config.ROLES.NAMES.HQ) {
      this.props.changeFormValue("centers", null);
    }
    // unset the RU for any User which isn't Member of RU
    if (choice.context !== config.ROLES.NAMES.RU) {
      this.props.changeFormValue("unit_id", null);
    }
    // automatically set the RU if the User is in a RU Context
    if (choice.context === config.ROLES.NAMES.RU && this.props.ruContext) {
      this.props.changeFormValue("unit_id", this.props.ruContext);
    }
    this.setState({ value });
  };

  getChoiceByRoleIds(ids) {
    const roles = this.props.getRoleNames(ids);
    const choice = this.props.choices.find(choice =>
      isEqual(choice.roles, roles)
    );
    return choice;
  }

  render() {
    const {
      allowEmpty,
      elStyle,
      isRequired,
      label,
      meta: { touched, error },
      options,
      translate,
      className
    } = this.props;
    const choices = this.getChoices();
    const safeValue = this.state.value == null ? "" : this.state.value;
    return (
      <FormControl error={touched && error != null} className={className}>
        <InputLabel required={isRequired}>{translate(label)}</InputLabel>
        <Select
          autoWidth
          value={safeValue}
          onChange={this.handleChange}
          style={elStyle}
          {...options}
        >
          {allowEmpty && <MenuItem value={null} />}
          {choices.map(this.renderMenuItem)}
        </Select>
        {touched && error != null && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
}

RenderRoleInput.propTypes = {
  translate: PropTypes.func,
  allowEmpty: PropTypes.bool.isRequired,
  rcContext: PropTypes.string,
  className: PropTypes.string,
  ruContext: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getRoleIds: PropTypes.func,
  changeFormValue: PropTypes.func,
  getRoleNames: PropTypes.func,
  choices: PropTypes.arrayOf(PropTypes.object),
  elStyle: PropTypes.object,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  loadRoles: PropTypes.func,
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element
  ]).isRequired,
  optionValue: PropTypes.string.isRequired,
  resource: PropTypes.string,
  source: PropTypes.string,
  translateChoice: PropTypes.bool.isRequired,
  currentUserRoles: PropTypes.arrayOf(PropTypes.string)
};

RenderRoleInput.defaultProps = {
  allowEmpty: false,
  choices: [],
  options: {},
  optionText: "label",
  optionValue: "name",
  translateChoice: true,
  translate: PropTypes.func
};

const ConnectedRenderRoleInput = compose(
  connect(
    state => {
      return {
        getRoleIds: names => getRoleIdsByNames(names, state),
        getRoleNames: ids => getRoleNamesByIds(ids, state),
        rcContext: getRcContext(state),
        ruContext: getCurrentUserRUContext(state),
        currentUserRoles: getCurrentUserRoles(state).map(r => r.name)
      };
    },
    {
      loadRoles: getAllRoles,
      changeFormValue: setRecordFormValue
    }
  ),
  translate
)(RenderRoleInput);

export default addField(ConnectedRenderRoleInput);
