import React from "react";
import PropTypes from "prop-types";
import { CardActions, Button } from "@material-ui/core";
import { CreateButton, RefreshButton, translate } from "react-admin";
import { getActiveCycle } from "../../redux/selectors";
import { connect } from "react-redux";
import AnomalyPrintButton from "./AnomalyPrintButton";

const cardActionStyle = {
  zIndex: 2,
  display: "inline-block",
  float: "right",
  width: "60%"
};

const actionStyle = {
  float: "right"
};

const CurrentActiveCycle = props => {
  return (
    <Button disabled /*labelStyle={cycleStyle}*/>
      {props.label}
    </Button>
  );
};
CurrentActiveCycle.propTypes = {
  label: PropTypes.string
};

const ConnectedCurrentActiveCycle = connect((state, props) => {
  return {
    label: `${props.translate(
      "mra.itinerary_overview.current_active_cycle"
    )}: ${getActiveCycle(state)}`
  };
})(CurrentActiveCycle);

const ItineraryListActions = props => {
  const {
    resource,
    filters,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    showFilter,
    refresh,
    translate
  } = props;
  return (
    <CardActions style={cardActionStyle}>
      <ConnectedCurrentActiveCycle translate={translate} />
      <div style={actionStyle}>
        <AnomalyPrintButton />
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button"
          })}
        {hasCreate && <CreateButton basePath={basePath} />}
        <RefreshButton refresh={refresh} />
      </div>
    </CardActions>
  );
};

ItineraryListActions.propTypes = {
  basePath: PropTypes.string,
  data: PropTypes.object,
  resource: PropTypes.string,
  translate: PropTypes.func,
  filters: PropTypes.element,
  displayedFilters: PropTypes.object,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  showFilter: PropTypes.func,
  refresh: PropTypes.func
};

export default translate(ItineraryListActions);
