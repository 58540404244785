import React, { Children, cloneElement } from "react";
import P from "prop-types";
import cn from "classnames";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { DatagridLoading, translate } from "react-admin";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

const header = ["Itinerary", "MRA", "CMS", "Comparison Report"];

const FirstHeader = withStyles(theme => ({
  head: {
    borderTop: "1px solid rgb(224, 224, 224)",
    height: "24px"
  },
  rowCell: {
    borderRight: "1px solid rgb(224, 224, 224)",
    fontWeight: 700,
    textAlign: "center",
    "&:last-child": {
      borderRight: "unset"
    },
    "&:nth-of-type(2n)": {
      color: "#ffffff",
      backgroundColor: theme.palette.primary.main
    },
    "&:nth-of-type(2n+1)": {
      backgroundColor: fade(theme.palette.primary.main, 0.12)
    }
  }
}))(({ classes }) => (
  <TableHead>
    <TableRow classes={{ head: classes.head }}>
      {header.map((title, index) => (
        <TableCell
          key={index}
          className={classes.rowCell}
          padding="none"
          colSpan={index === 0 ? 4 : 2}
        >
          {title}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
));

function Datagrid(props) {
  const {
    basePath,
    children,
    classes,
    className,
    currentSort,
    data,
    hover,
    ids,
    loadedOnce,
    resource,
    rowStyle,
    setSort,
    total,
    translate
  } = props;

  if (loadedOnce === false) {
    return (
      <DatagridLoading
        classes={classes}
        className={className}
        nbChildren={Children.count(children)}
      />
    );
  }
  if (ids.length === 0 || total === 0) {
    return null;
  }

  return (
    <Table>
      <FirstHeader />
      <TableHead>
        <TableRow classes={{ head: classes.head }}>
          {Children.map(children, field => (
            <TableCell
              key={field.props.source}
              padding="none"
              className={classes.rowCell}
            >
              <TableSortLabel
                active={currentSort.field === field.props.source}
                direction={currentSort.order === "ASC" ? "asc" : "desc"}
                data-sort={field.props.source}
                classes={{ icon: classes.icon, active: classes.active }}
                onClick={e => {
                  e.stopPropagation();
                  setSort(e.currentTarget.dataset.sort);
                }}
              >
                {translate(
                  `resources.${resource}.fields.${field.props.source}`
                )}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {ids.map((id, rowIndex) => (
          <TableRow
            key={id}
            hover={hover}
            style={rowStyle ? rowStyle(data[id], rowIndex) : null}
          >
            {Children.map(children, field => (
              <TableCell
                key={`${id}-${field.props.source}`}
                padding="none"
                className={cn(classes.rowCell, {
                  [classes.ok]: data[id][field.props.source] === true,
                  [classes.nok]: data[id][field.props.source] === false
                })}
              >
                {cloneElement(field, {
                  record: data[id],
                  basePath: field.props.basePath || basePath,
                  resource
                })}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

Datagrid.propTypes = {
  basePath: P.string,
  children: P.node.isRequired,
  classes: P.object,
  className: P.string,
  currentSort: P.shape({
    field: P.string,
    order: P.string
  }),
  data: P.object.isRequired,
  expand: P.node,
  hover: P.bool,
  ids: P.arrayOf(P.oneOfType([P.number, P.string])).isRequired,
  isLoading: P.bool,
  onSelect: P.func,
  onToggleItem: P.func,
  resource: P.string,
  rowClick: P.oneOfType([P.string, P.func]),
  rowStyle: P.func,
  setSort: P.func,
  total: P.number,
  version: P.number,
  translate: P.func,
  loadedOnce: P.bool
};

export default compose(
  translate,
  withStyles(theme => ({
    head: {
      borderTop: "1px solid rgb(224, 224, 224)",
      height: "24px"
    },
    rowCell: {
      borderRight: "1px solid rgb(224, 224, 224)",
      textAlign: "center",
      "&:last-child": {
        borderRight: "unset"
      }
    },
    ok: {
      backgroundColor: fade(theme.palette.primary.main, 0.12)
    },
    nok: {
      backgroundColor: fade(theme.palette.error.main, 0.12)
    },
    icon: {
      display: "none"
    },
    active: {
      "& $icon": {
        display: "inline"
      }
    }
  }))
)(Datagrid);
