import React from "react";
import { ConfiguredReferenceField } from "./ConfiguredReferenceField";
import config from "../../config";

export const ClientActivityField = ({ ...props }) => (
  <ConfiguredReferenceField
    reference={config.CLIENT_ACTIVITY_RESOURCE}
    {...props}
  />
);
