import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Typography } from "@material-ui/core";

export const AvgConsumptionField = ({
  className,
  record = {},
  avg_source,
  cur_source
}) => {
  const avg = get(record, avg_source);
  const currency = get(record, cur_source);
  return (
    <Typography component="span" body1="body1" className={className}>
      {avg} {currency}
    </Typography>
  );
};

AvgConsumptionField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  className: PropTypes.string,
  avg_source: PropTypes.string.isRequired,
  cur_source: PropTypes.string.isRequired
};
