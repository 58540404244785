import React from "react";
import { translate, List, TextField, Filter, BooleanField } from "react-admin";
import { connect } from "react-redux";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import omit from "lodash/omit";
import P from "prop-types";
import LockOpen from "@material-ui/icons/LockOpen";
import { Link } from "react-router-dom";
import {
  RestrictedEditButton,
  RestrictedListActions,
  RestrictedFlatButton,
  checkPermission
} from "../restricted-components";
import config from "../../config";
import { TranslatedTextField } from "../fields";
import { Datagrid } from "../base";
import { getAnomalyType } from "../utils";

const CustomButton = props => {
  const { id, deleted_at } = props.record;
  if (deleted_at) {
    return (
      <RestrictedFlatButton
        color="primary"
        component={Link}
        to={config.ANOMALIES_ACTIVATE_PATH.replace(":id", id)}
        style={{ minWidth: 0, overflow: "inherit" }}
        requiredPermissions={[config.PERMISSIONS.ANOMALY_UPDATE]}
      >
        <LockOpen />
      </RestrictedFlatButton>
    );
  } else {
    return (
      <RestrictedEditButton
        requiredPermissions={[config.PERMISSIONS.ANOMALY_READ]}
        {...props}
      />
    );
  }
};

CustomButton.propTypes = {
  record: P.object
};

const Title = ({ translate, search }) => {
  return <span>{translate(`mra.anomaly.${getAnomalyType(search)}s`)}</span>;
};

Title.propTypes = {
  translate: P.func,
  search: P.string
};

/**
 * component for listing mra devices
 */
const AnomalyList = props => (
  <List
    {...props}
    title={<Title translate={props.translate} search={props.location.search} />}
    perPage={config.PAGINATION}
    actions={
      <RestrictedListActions
        requiredPermissionsCreateButton={[
          config.PERMISSIONS.HQ_MEMBER,
          config.PERMISSIONS.ANOMALY_CREATE
        ]}
      />
    }
    filters={<Filter />}
  >
    <Datagrid>
      <TextField source="type" />
      <TextField source="code" />
      <BooleanField source="is_active" />
      <BooleanField source="is_default" />
      <BooleanField source="is_skip_reading" />
      <TranslatedTextField source="description" sortable={false} />
      <CustomButton />
    </Datagrid>
  </List>
);

AnomalyList.propTypes = {
  translate: P.func,
  location: P.object,
  bulkActions: P.bool
};

export default compose(
  translate,
  connect(state => {
    if (
      !checkPermission(state, {
        requiredPermissions: [
          config.PERMISSIONS.HQ_MEMBER,
          config.PERMISSIONS.ANOMALY_UPDATE,
          config.PERMISSIONS.ANOMALY_DELETE
        ]
      })
    ) {
      return {
        bulkActions: false
      };
    }
    return {};
  }),
  mapProps(props => omit(props, ["dispatch"]))
)(AnomalyList);
