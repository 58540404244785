import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import config from "../../config";

export const addRcFilter = WrappedComponent =>
  compose(
    connect(
      state => ({
        rcFilter: state.mra.rcContext
      }),
      null
    ),
    withProps(props => {
      const rcFilter = props.rcFilter
        ? props.rcFilter === config.RC_CONTEXT_ALL ? "" : props.rcFilter
        : "";
      return {
        filter:
          props.resource === config.READINGCENTER_RESOURCE
            ? { id: rcFilter }
            : { reading_center_id: rcFilter, reading_centers: rcFilter }
      };
    })
  )(WrappedComponent);
