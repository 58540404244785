import React from "react";
import { ListButton } from "react-admin";
import { CardActions, withStyles } from "@material-ui/core";

const Actions = withStyles({
  cardActionStyle: { justifyContent: "flex-end" }
})(({ classes, basePath, children }) => (
  <CardActions className={classes.cardActionStyle}>
    <ListButton basePath={basePath} />
    {children}
  </CardActions>
));

export default Actions;

export { default as ListActions } from "./ListActions";
