import React from "react";
import P from "prop-types";
import { translate } from "react-admin";
import { WarningText } from "../../layout";

export const Warning = translate(({ translate }) => (
  <WarningText text={translate("mra.itinerary_assign.warn_reset")} />
));

Warning.propTypes = {
  translate: P.func
};
