import React from "react";
import { checkPermission } from "./index";
import { connect } from "react-redux";
import {
  ChipField,
  SingleFieldList,
  ReferenceArrayField,
  Labeled,
  ReferenceArrayInput
} from "react-admin";

/** This HOC returns 3 can return 3 different Components:
  * 1. ReferenceArrayInput
  * 2. ReferenceArrayField
  * 3. NUll
  * added Props:
  * 1. requiredPermissions={["thisisaPermissionKey"]
  * 2. readonlyIfNoPermission={true|false}
       //if true a readonly component will be displayed if a permissin is missing
       //if false the component will be hidden if a permission is missing
  * 3. addField={true} //this is ncessary for the aor components
  * 4. optionText="name"
       //this will be mapped as the source prop for the child of the readonly component
*/

export const replaceReferenceArrayInput = Component => props => {
  if (!props.disabled) {
    return <Component {...props} />;
  } else {
    const { readonlyIfNoPermission, optionText } = props;
    if (readonlyIfNoPermission) {
      return (
        <Labeled {...props}>
          <ReferenceArrayField {...props}>
            <SingleFieldList>
              <ChipField source={optionText} />
            </SingleFieldList>
          </ReferenceArrayField>
        </Labeled>
      );
    } else {
      return null;
    }
  }
};
export const HOCReferenceArrayInput = replaceReferenceArrayInput(
  ReferenceArrayInput
);

export const RestrictedReferenceArrayInput = connect((state, props) => {
  const permission = !checkPermission(state, props);
  const { optionText } = props;
  return {
    disabled: permission,
    optionText: permission ? optionText : null
  };
})(HOCReferenceArrayInput);

export default RestrictedReferenceArrayInput;
