import {
  put,
  call,
  takeEvery,
  takeLatest,
  take,
  fork,
  select,
  all
} from "redux-saga/effects";
import { push } from "react-router-redux";
import { changeLocale, changeListParams } from "react-admin";
import {
  CRUD_UPDATE_SUCCESS,
  crudUpdate,
  USER_LOGIN_SUCCESS,
  USER_CHECK,
  showNotification,
  userLogout,
  USER_LOGOUT,
  CRUD_DELETE_SUCCESS
} from "react-admin";
import {
  getCurrentUser,
  jwtReceived,
  getReadingCenters,
  getReadingUnits,
  switchRcContext,
  getPermissions,
  JWT_RECEIVED,
  GET_CURRENTUSER_SUCCESS,
  CHECK_FOR_TOKEN,
  getSettings,
  SWITCH_RCCONTEXT,
  GET_USER_INFO_SUCCESS,
  getQualityRules,
  GET_READINGCENTERS_SUCCESS,
  getAllRoles
  // addDisabledRcToToken
} from "../redux/actions";
import jwtDecode from "jwt-decode";
import config from "../config";
import { setAuthToken, getAuthToken } from "../lib/tokenStorage";
import pull from "lodash/pull";
import {
  getRcContext,
  checkPermissionByName,
  getCurrentUserId,
  getCurrentUser as getCurrentUserSelector,
  getJwtValues
} from "./selectors";

// check if there is a token in local storage and load it
// also preload all required data
export function* maybeLoadToken() {
  const token = yield call(getAuthToken);
  if (token) yield put(jwtReceived(token));
  yield all([put(getReadingCenters()), put(getSettings()), put(getAllRoles())]);
}

const userUpdate = id => action =>
  action.type === CRUD_UPDATE_SUCCESS &&
  action.meta.resource === config.USER_RESOURCE &&
  action.requestPayload.id === id;

export const requestCurrentUser = userId =>
  function* performRequestCurrentUser() {
    yield put(getCurrentUser(userId));
  };

// when a user logs in successfully (JWT is retrieved), get his data
export function* requestCurrentUserAndListenForChanges(action) {
  const userId = jwtDecode(action.payload.jwt).userid;
  yield all([
    call(setAuthToken, action.payload.jwt),
    put(getCurrentUser(userId))
  ]);

  // request user data (and by extension apply settings) every time the current users profile is updated
  yield takeLatest(userUpdate(`${userId}`), requestCurrentUser(userId));
}

const redirectToReset = (url, message, type) => {
  return function*() {
    yield put(showNotification(message, type));
    yield put(push(url));
  };
};

const redirectToDashboard = (url, message, type) => {
  return function*() {
    yield put(showNotification(message, type));
    yield put(push(url));
  };
};

const redirectAll = (url, redirectFunction, message, type = "info") => {
  return function*() {
    yield takeEvery(USER_CHECK, redirectFunction(url, message, type));
  };
};

function* getRolesAndPermissions() {
  yield put(getPermissions());
}

const passwordUpdateSuccess = action =>
  action.type === CRUD_UPDATE_SUCCESS &&
  action.meta.resource === config.USER_RESOURCE &&
  action.requestPayload.data.password &&
  action.requestPayload.data.password.length > 0;

export const checkDisabledRCs = newDisabledRC =>
  function* performCheckDisabledRCs() {
    if (newDisabledRC) {
      // yield put(addDisabledRcToToken(newDisabledRC));
    }

    const decoded = yield select(getJwtValues);
    if (decoded && decoded.enabled_rcs && decoded.disabled_rcs) {
      yield put(showNotification("mra.notifications.rcs_disabled", "warning"));
    }

    const hasGlobalContext = yield select(
      checkPermissionByName(config.PERMISSIONS.ACESSGLOBALCONTEXT)
    );

    if (
      decoded &&
      !decoded.enabled_rcs &&
      decoded.disabled_rcs &&
      !hasGlobalContext
    ) {
      const redirectURL = "/";
      yield put(push(redirectURL));
      const message =
        decoded.disabled_rcs.length > 1
          ? "mra.notifications.no_active_rc_all_disabled"
          : "mra.notifications.no_active_rc_one_disabled";
      yield put(showNotification(message, "warning"));
      const redirectTask = yield fork(
        redirectAll(redirectURL, redirectToDashboard, message, "warning")
      );
      yield take(USER_LOGOUT);
      redirectTask.cancel();
    }
  };

export const getRCDelete = action =>
  action.type === CRUD_DELETE_SUCCESS &&
  action.meta &&
  action.meta.resource &&
  action.meta.resource === config.READINGCENTER_RESOURCE;

export function* handleDeletedRC(action) {
  const {
    requestPayload: { id }
  } = action;
  yield fork(checkDisabledRCs(id));
  const currentContext = yield select(getRcContext);
  const currentUser = yield select(getCurrentUserSelector);
  let centers = currentUser.centers;
  if (id === currentContext) {
    if (centers) {
      pull(centers, id);
      if (centers.length >= 1) yield put(switchRcContext(centers[0]));
    } else {
      yield put(switchRcContext(config.RC_CONTEXT_ALL));
    }
  }
}

//will be fired on data changes of the current user or the action GET_CURRENTUSER_SUCCESS
export function* applyUserSettings(action) {
  const { is_reset_requested, language, id, centers } = action.payload.data;
  // when the current user was retrieved successfully, get the language and dispatch a locale change action
  const userLocale = language || config.DEFAULT_LOCALE;
  //load rcs
  yield put(getReadingCenters());
  //wait until rcs are loaded
  yield take(action => action.type === GET_READINGCENTERS_SUCCESS);

  yield put(changeLocale(userLocale));
  // also set the readingcenter context
  const rcContext = yield select(getRcContext);
  /**
   * dont reset the rc context if
   * 1. the user is on the global context (admin, supervisor)
   * 2. the user is on the context which is assigned to the user (there can be user with multiple contexts)
   * otherwise it will be always reset if UserProfile is saved... see #MRA-385)
   */
  if (
    rcContext !== config.RC_CONTEXT_ALL &&
    centers &&
    !centers.includes(rcContext)
  ) {
    if (centers && centers[0]) yield put(switchRcContext(centers[0]));
  }
  //save if user had permission AccessGlobalContext for #defaultGlobalContext
  const hadGlobal = yield select(
    checkPermissionByName(config.PERMISSIONS.ACESSGLOBALCONTEXT)
  );

  // load all the roles and their permissions
  yield fork(getRolesAndPermissions);

  //wait for users info to load
  yield take(action => action.type === GET_USER_INFO_SUCCESS);
  /** #defaultGlobalContext
   * if user has AccessGlobalContext Permission and didnt have it before switchRcContext to Global
   * default rc context is Global #MRA-365
   * if a user with AccessGlobalContext Permission logs in rccontextswitch to Global
   */
  const loginPermission = yield select(
    checkPermissionByName(config.PERMISSIONS.BOM_ALLOW_LOGIN)
  );

  if (!loginPermission) {
    yield logout();
  }

  const hasGlobal = yield select(
    checkPermissionByName(config.PERMISSIONS.ACESSGLOBALCONTEXT)
  );
  if (!hadGlobal) {
    if (hasGlobal) yield put(switchRcContext(config.RC_CONTEXT_ALL));
  }
  /*if the rcContext is not set or the user doesnt have permissions for the global context: reset the context*/
  if (!rcContext || (!hasGlobal && rcContext === config.RC_CONTEXT_ALL)) {
    if (centers && centers[0]) {
      yield put(switchRcContext(centers[0]));
    } else {
      yield put(switchRcContext(config.RC_CONTEXT_ALL));
    }
  }

  // MRA-1616: picture viewer hasn't access to fetch reading_units and quality_rules
  // and logined out after try get it.

  const isPVUser = (yield select(s => s.mra.userRoles) || []).some(
    r => r.name === "PV"
  );
  if (!isPVUser) {
    yield all([put(getReadingUnits()), put(getQualityRules())]);
  }

  if (is_reset_requested) {
    // if a password reset was requested for this user, redirect to ResetPassword
    const resetUrl = `/${config.USER_RESOURCE}/${id}/reset`;
    yield put(push(resetUrl));
    // wait for a password update, then set reset_requested to false
    // as long as the password has not been reset, redirect to reset page
    const redirectTask = yield fork(
      redirectAll(resetUrl, redirectToReset, "mra.reset.msg")
    );
    const updateAction = yield take(passwordUpdateSuccess);
    redirectTask.cancel();
    yield put(
      crudUpdate(
        config.USER_RESOURCE,
        id,
        { is_reset_requested: false },
        updateAction.payload.data,
        `/${config.USER_RESOURCE}/${id}`,
        true
      )
    );
    yield* logout();
  }

  /**
   * We have to verify if the user is assigned to a deactivated Rc here
   * If he is assigned only to this deactivated RC, the user should be restricted
   * to the Dashboard.
   * If the user is assigned to multiple RCs and not all of them are deactivated
   * he should see a proper Notification.
   * The deactivated RCs of the user will be available in the JWT MAA-841
   */
  yield fork(checkDisabledRCs(null));
}

const getResetListParams = resource => {
  const params = {
    sort: null,
    order: null,
    page: 1,
    perPage: null,
    filter: {},
    total: 0
  };
  return put(changeListParams(resource, params));
};

export function* processContextSwitch() {
  const resetResources = [
    config.USER_RESOURCE,
    config.ITINERARY_RESOURCE,
    config.DISTRIBUTION_ITINERARY_RESOURCE,
    config.REGISTERED_METERS_RESOURCE,
    config.READINGCENTER_RESOURCE,
    config.DISTRIBUTION_ITINERARY_RESOURCE,
    config.DEVICE_RESOURCE,
    config.READINGUNIT_RESOURCE
  ];
  for (let i = 0; i < resetResources.length; i++) {
    yield getResetListParams(resetResources[i]);
  }
  yield put(showNotification("mra.notifications.context_switch_success"));
  if (process.env.NODE_ENV !== "production") {
    return;
  }
  yield put(push("/"));
}

/**
 * #MRA-418 If a user resets his own Passwords he gets logged out automatically.
 */

function* logout() {
  yield put(push("/login"));
  yield put(userLogout());
}

export function* passwordResetTriggerLogout(action, id) {
  if (id === action.requestPayload.id) {
    yield* logout();
  }
}

export function* passwordResetCheckUser(action) {
  const currentUserID = yield select(getCurrentUserId);
  yield passwordResetTriggerLogout(action, currentUserID);
}

// trigger sagas for every corresponding action
export default function* authSaga() {
  yield all([
    takeLatest([USER_LOGIN_SUCCESS, CHECK_FOR_TOKEN], maybeLoadToken),
    takeLatest(JWT_RECEIVED, requestCurrentUserAndListenForChanges),
    takeLatest(GET_CURRENTUSER_SUCCESS, applyUserSettings),
    takeLatest(SWITCH_RCCONTEXT, processContextSwitch),
    takeLatest(passwordUpdateSuccess, passwordResetCheckUser),
    takeEvery(getRCDelete, handleDeletedRC)
  ]);
}
