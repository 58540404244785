import React from "react";
import P from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { addField, translate } from "ra-core";
import { formValueSelector } from "redux-form";

import {
  AutocompleteInput,
  Edit,
  ReferenceInput,
  SelectInput,
  TextField
} from "ra-ui-materialui";

import { getRoleIdByName } from "../../../redux/selectors";
import config from "../../../config";
import { hideableField } from "../../fields";

import { AssignmentForm } from "./AssignmentForm";
import { AssignmentToolbar } from "./AssignmentToolbar";
import { ResetWarning } from "./ResetWarning";
import { Actions } from "./AssigmentActions";
import { getFilter } from "../../layout";
import {
  assignmentWithMethodChange,
  getAllRoles,
  updateItinerary
} from "../../../redux/actions";
import mapProps from "recompose/mapProps";
import omit from "lodash/omit";
import { makeUserFilter } from "../../utils";

const selector = formValueSelector("record-form");
const CaptureMethodInput = hideableField(
  state => selector(state, "method") === "paper"
)(ReferenceInput);

const ConnectedCaptureMethodInput = connect((state, props) => ({
  filter: {
    ...props.filter,
    "@role_id": [getRoleIdByName(config.ROLES.NAMES.AG, state)]
  }
}))(CaptureMethodInput);

const ConnectedFieldAgentInput = compose(
  connect((state, props) => ({
    filter: {
      ...props.filter,
      "@role_id": [getRoleIdByName(config.ROLES.NAMES.FA, state)]
    }
  })),
  addField
)(ReferenceInput);

const validate = (values, { translate }) => {
  const errors = {};
  if (!values.method) {
    errors.method = [translate("mra.itinerary_assign.method_not_null")];
  }
  if (!values.reader_id) {
    errors.reader_id = [translate("mra.itinerary_assign.reader_not_null")];
  }
  if (values.method === config.METHOD_TYPES[1].id && !values.capturer_id) {
    errors.capturer_id = [translate("mra.itinerary_assign.capturer_not_null")];
  }
  return errors;
};

export class Assignment extends React.Component {
  constructor(props) {
    super(props);
    /**
     * preload all roles
     * this is necessary to be able to filter them by name
     * see also #MRA-583
     */
    if (this.props.extended) {
      this.props.loadRoles();
    }
  }

  render() {
    const {
      // eslint-disable-next-line no-unused-vars
      loadRoles,
      methodChange,
      translate,
      performConfirm,
      extended,
      EditTitle,
      validate,
      ...props
    } = this.props;
    const { id } = props.match.params;
    const userFilter = makeUserFilter(props.filter);
    const methods = config.METHOD_TYPES.map(method => ({
      ...method,
      id: method.id.toLowerCase()
    }));
    return (
      <Edit
        {...props}
        id={id}
        title={<EditTitle translate={translate} />}
        actions={<Actions translate={translate} history={props.history} />}
      >
        <AssignmentForm
          validate={validate}
          toolbar={
            <AssignmentToolbar hasDelete={false} onConfirm={performConfirm} />
          }
        >
          <TextField source="remote_id" />
          {extended ? <SelectInput source="method" choices={methods} /> : null}
          <ConnectedFieldAgentInput
            allowEmpty
            source="agent_id"
            reference={config.USER_RESOURCE}
            filter={userFilter}
            format={v => (v ? String(v) : v)}
            parse={v => (v ? Number(v) : v)}
            filterToQuery={searchText => ({ username: searchText })}
          >
            <AutocompleteInput
              options={{ name: "username" }}
              optionText="username"
            />
          </ConnectedFieldAgentInput>
          {extended ? (
            <ConnectedCaptureMethodInput
              source="capturer_id"
              reference={config.USER_RESOURCE}
              filter={userFilter}
              filterToQuery={searchText => ({ username: searchText })}
              allowEmpty
              format={v => (v ? String(v) : "")}
            >
              <AutocompleteInput
                options={{ name: "user" }}
                optionText="username"
              />
            </ConnectedCaptureMethodInput>
          ) : null}
          <ResetWarning
            methodChange={methodChange}
            style={{ width: "100%", paddingLeft: "0" }}
          />
        </AssignmentForm>
      </Edit>
    );
  }
}

Assignment.propTypes = {
  translate: P.func,
  filter: P.object,
  match: P.object,
  history: P.object,
  loadRoles: P.func,
  methodChange: P.func,
  classes: P.object,
  performConfirm: P.func,
  resource: P.string,
  EditTitle: P.node,
  validate: P.func,
  extended: P.bool
};

Assignment.defaultProps = {
  validate: validate,
  extended: true
};

export default compose(
  connect(
    state => ({
      filter: getFilter(state)
    }),
    (
      dispatch,
      {
        resource,
        match: {
          params: { id }
        }
      }
    ) => {
      return {
        performConfirm: () => {
          dispatch(
            updateItinerary(id, { is_confirmation_required: false }, resource)
          );
        },
        loadRoles: () => dispatch(getAllRoles()),
        methodChange: (old_method, new_method) =>
          dispatch(assignmentWithMethodChange(id, old_method, new_method))
      };
    }
  ),
  translate,
  mapProps(props => omit(props, ["dispatch", "staticContext", "authClient"]))
)(Assignment);
