/* eslint-disable no-unused-vars */
import React from "react";
import P from "prop-types";
import { Loading } from "react-admin";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import difference from "lodash/difference";
import config from "../../config";
import {
  getUserPermissions,
  getCurrentUserRoles,
  checkPermissionByName
} from "../../redux/selectors";
import { TopItineraries, ReconReport, SimpleDashboard } from ".";

const reports = [
  { id: 1, resource: config.IMPORT_RECON_RESOURCE },
  { id: 2, resource: config.EXPORT_RECON_RESOURCE }
];

function Dashboard(props) {
  const { userRoles, permissionsLoaded, hasDashboardPermission } = props;
  if (!permissionsLoaded || !userRoles.length) return <Loading />;
  if (userRoles.includes("PV")) {
    return <Redirect to={config.RMS_RESOURCE} />;
  }
  if (!hasDashboardPermission) return <SimpleDashboard />;

  const isItSupport = !["RCM", "IT"]
    .map(role => userRoles.includes(role))
    .includes(false);
  const isAgent = !["RCM", "AG"]
    .map(role => userRoles.includes(role))
    .includes(false);
  const isSupervisor = userRoles.includes("SU") || userRoles.includes("AD");
  const HQReportUser = difference(userRoles, ["HQM", "RU"]).length === 0;
  const canSeeReconReport =
    isItSupport || isAgent || isSupervisor || HQReportUser;
  return (
    <div>
      <div style={{ display: "flex" }}>
        {canSeeReconReport &&
          reports.map(r => (
            <ReconReport key={r.id} id={r.id} resource={r.resource} />
          ))}
      </div>
      <div style={{ margin: 10 }}>
        <TopItineraries id={0} resource={config.ITINERARY_RESOURCE} />
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  userRoles: P.array,
  permissionsLoaded: P.bool,
  hasDashboardPermission: P.bool
};

export default connect(state => ({
  userRoles: getCurrentUserRoles(state).map(r => r.name),
  permissionsLoaded: !isEmpty(getUserPermissions(state)),
  hasDashboardPermission: checkPermissionByName(
    config.PERMISSIONS.NAV_DASHBOARD
  )(state)
}))(Dashboard);
