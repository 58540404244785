import React from "react";
import P from "prop-types";
import {
  List,
  TextField,
  Filter,
  TextInput,
  ReferenceField,
  NumberField,
  SelectInput,
  ShowButton,
  ReferenceInput,
  BooleanInput,
  AutocompleteInput
} from "react-admin";
import { connect } from "react-redux";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import omit from "lodash/omit";
import config from "../../config";
import { Datagrid, ListActions } from "../base";
import { getFilter } from "../layout";
import { BooleanField, TypeTranslationField, DateTimeField } from "../fields";
/**
 * filters for list component
 */
const ItineraryFilter = ({ types, ...props }) => (
  <Filter {...props}>
    <TextInput source="remote_id" />
    <TextInput source="cycle" />
    <TextInput source="cycle_year" />
    <ReferenceInput
      source="center_id"
      reference={config.READINGCENTER_RESOURCE}
      filterToQuery={searchText => ({ remote_id: searchText })}
      allowEmpty
    >
      <AutocompleteInput
        options={{ name: "reading center" }}
        optionValue="id"
        optionText="remote_id"
      />
    </ReferenceInput>
    <ReferenceInput
      source="agent_id"
      reference={config.USER_RESOURCE}
      filterToQuery={searchText => ({ username: searchText })}
      allowEmpty
    >
      <AutocompleteInput
        options={{ name: "reader" }}
        optionValue="id"
        optionText="username"
      />
    </ReferenceInput>
    <SelectInput source="status" choices={Object.values(types)} />
    <BooleanInput source="is_completion_confirmation_required" />
  </Filter>
);

ItineraryFilter.propTypes = {
  types: P.object
};

/**
 * component for listing itineraries
 */
export const ItineraryList = ({ types, ...props }) => (
  <List
    {...props}
    filters={<ItineraryFilter types={types} />}
    actions={<ListActions />}
    sort={{ field: "scheduled_at", order: "DESC" }}
    bulkActions={false}
    perPage={config.PAGINATION}
  >
    <Datagrid>
      <TextField source="route" />
      <TextField source="remote_id" />
      <TextField source="cycle" />
      <TextField source="cycle_year" />
      <TextField source="description" />
      <TypeTranslationField source="status" types={types} />
      <DateTimeField source="scheduled_at" />
      <NumberField source="task_count" />
      <BooleanField source="is_completion_confirmation_required" />
      <NumberField source="result_count" />
      <DateTimeField source="created_at" />
      <ReferenceField
        source="agent_id"
        reference={config.USER_RESOURCE}
        linkType={false}
        allowEmpty
      >
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField
        source="center_id"
        reference={config.READINGCENTER_RESOURCE}
        linkType={false}
        allowEmpty
      >
        <TextField source="remote_id" />
      </ReferenceField>
      <ShowButton label="" />
    </Datagrid>
  </List>
);

ItineraryList.propTypes = {
  types: P.object,
  resource: P.string
};

export default compose(
  connect(state => ({
    filter: getFilter(state)
  })),
  mapProps(props => omit(props, ["dispatch"]))
)(ItineraryList);
