import React from "react";
import P from "prop-types";
import config from "../../config";
import { Assignment } from "../base/assigment";

const EditTitle = ({ translate, record }) => {
  return (
    <span>
      {translate("mra.itinerary_assign.title")}
      {record ? ` - "${record.remote_id}"` : ""}
    </span>
  );
};

EditTitle.propTypes = {
  translate: P.func,
  record: P.object
};

export default props => (
  <Assignment
    extended
    EditTitle={EditTitle}
    basePath={config.ITINERARIES_ASSIGN_PATH}
    resource={config.ITINERARY_RESOURCE}
    {...props}
  />
);
