import { put, call, takeEvery, all } from "redux-saga/effects";
import {
  refreshView,
  CRUD_UPDATE_SUCCESS,
  CRUD_CREATE_SUCCESS
} from "react-admin";
import config from "../config";
import { mapValues } from "lodash";

// m:n relations have extra endpoints, we need to make extra calls to set/delete them

function maybeObjToId(obj) {
  if (obj != null && typeof obj === "object") {
    return obj.id;
  }
  return obj;
}

export const updateRelations = httpClient => {
  return function* handleRelations(action) {
    const { resource } = action.meta;
    const { data, previousData } = action.requestPayload;
    // for CREATE actions, use the new id
    const entityId = data.id ? data.id : action.payload.data.id;
    const createCalls = [],
      deleteCalls = [];
    if (config.RELATIONS[resource]) {
      const urlsByField = config.RELATIONS[resource].urls;
      mapValues(urlsByField, (url, field) => {
        const newRelationIds = (data[field] ? data[field] : []).map(
          maybeObjToId
        );
        const oldRelationIds = (previousData && previousData[field]
          ? previousData[field]
          : []
        ).map(maybeObjToId);
        const deleteRelationIds = oldRelationIds.filter(
          id => !newRelationIds.includes(id)
        );
        const createRelationIds = newRelationIds.filter(
          id => !oldRelationIds.includes(id)
        );
        const deleteRelationUrls = deleteRelationIds.map(
          id => `${config.API_BASE_URL}/${resource}/${entityId}/${url}/${id}`
        );
        const createRelationUrls = createRelationIds.map(
          id => `${config.API_BASE_URL}/${resource}/${entityId}/${url}/${id}`
        );
        deleteCalls.push(
          ...deleteRelationUrls.map(u =>
            call(httpClient, u, { method: "DELETE" })
          )
        );
        createCalls.push(
          ...createRelationUrls.map(u =>
            call(httpClient, u, { method: "POST" })
          )
        );
      });
    }
    yield all(deleteCalls);
    yield all(createCalls);
    yield put(refreshView());
  };
};

// trigger relation set for every successful update
export default httpClient =>
  function* relationSaga() {
    yield all([
      takeEvery(
        [CRUD_UPDATE_SUCCESS, CRUD_CREATE_SUCCESS],
        updateRelations(httpClient)
      )
    ]);
  };
