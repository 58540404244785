import React from "react";
import { default as MapButton } from "./MapButton";
import PropTypes from "prop-types";
import config from "../../../config";

export const LabeledMapButton = props => <MapButton {...props} />;

LabeledMapButton.propTypes = {
  label: PropTypes.string,
  resource: PropTypes.string,
  latField: PropTypes.string.isRequired,
  lngField: PropTypes.string.isRequired,
  fieldsToDisplay: PropTypes.array
};

LabeledMapButton.defaultProps = {
  label: "mra.general.map",
  latField: "supply_point.lat",
  lngField: "supply_point.lng",
  fieldsToDisplay: config.MAP_FIELDS
};
