import { fetchUtils } from "react-admin";
import config from "../config";
import { getAuthToken } from "./tokenStorage";

export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = getAuthToken();
  if (!token) return Promise.reject(Error("no_token"));
  options.headers.set("Authorization", `Bearer ${token}`);
  if (url.indexOf(config.API_BASE_URL) !== -1) {
    options.headers.set("X-MRA-SOURCE", `BOM v.${config.version}`);
  }
  return fetchUtils.fetchJson(url, options);
};
