import React from "react";
import P from "prop-types";
import {
  List,
  TextField,
  Filter,
  TextInput,
  ReferenceField,
  NumberField,
  SelectInput,
  ShowButton,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  translate
} from "react-admin";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import { Checkbox } from "@material-ui/core";
import SwapVert from "@material-ui/icons/SwapVert";
import Keyboard from "@material-ui/icons/Keyboard";
import ControlReadingIcon from "@material-ui/icons/Beenhere";
import AnomalyCorrectionIcon from "@material-ui/icons/LooksTwo";
import ItineraryListActions from "./ItineraryListActions";
import config from "../../config";
import { getFilter } from "../layout";
import { BooleanField, TypeTranslationField, DateTimeField } from "../fields";
import { Datagrid } from "../base";
import {
  checkPermission,
  RestrictedFlatButton
} from "../restricted-components";
import { clearReadings, clearSelectedField } from "../../redux/actions";
import { getSelectedFields, getRcContext } from "../../redux/selectors";
import { hide } from "../fields/hideableField";
import PrintButton from "./PrintButton";
import { PostBulkActionButtons } from "./ItineraryBulkActions";
import { itineraryIsComplete } from "../utils";

const {
  ITINERARY_STATUS_TYPES,
  RC_CONTEXT_ALL,
  NOPERMISSION,
  ITINERARY_RESOURCE,
  METHOD_TYPES,
  READINGCENTER_RESOURCE,
  USER_RESOURCE,
  PAGINATION
} = config;

const {
  UNASSIGNED,
  PAPER_READING_READY,
  PAPER_READING_DATA_BEING_CAPTURED
} = ITINERARY_STATUS_TYPES;

// hide if exported
const hideIfExported = hide(
  (state, { record }) => record && !record.exported_at
);

const HideablePrintButton = hideIfExported(PrintButton);

const canGenerate = record =>
  itineraryIsComplete(record) && record.task_count > 0;

const canGenerateControl = record =>
  !(record.child_flags & config.CHILD_FLAGS.CONTROL_READING) &&
  canGenerate(record);

const canGenerateCorrection = record =>
  !(record.child_flags & config.CHILD_FLAGS.ANOMALY_CORRECTION) &&
  canGenerate(record);

//label={translate("mra.itinerary_overview.resequence_button")}
const RestrictedResequenceButton = ({ record, resource }) => (
  <ConnectedRestrictedFlatButton
    color="primary"
    component={Link}
    to={`${resource}/${record.id}/resequence`}
    requiredPermissions={[config.PERMISSIONS.READING_ITINERARY_RESEQUENCE]}
    style={{ minWidth: 0, overflow: "inherit" }}
  >
    <SwapVert />
  </ConnectedRestrictedFlatButton>
);

RestrictedResequenceButton.propTypes = {
  record: P.object,
  resource: P.string
};

// hide if not UNASSIGNED
export const ResequenceButton = hide(
  (state, { record }) => record && record.status === UNASSIGNED.id
)(RestrictedResequenceButton);

//label={translate("mra.itinerary_overview.capture_button")}
const CaptureButton = ({ record, resource }) =>
  record.status === PAPER_READING_READY.id ||
  record.status === PAPER_READING_DATA_BEING_CAPTURED.id ? (
    <ConnectedRestrictedFlatButton
      color="primary"
      component={Link}
      to={`${resource}/${record.id}/capture`}
      requiredPermissions={[config.PERMISSIONS.READING_ITINERARY_DATA_CAPTURE]}
      style={{ minWidth: 0, overflow: "inherit" }}
    >
      <Keyboard />
    </ConnectedRestrictedFlatButton>
  ) : null;

CaptureButton.propTypes = {
  record: P.object,
  resource: P.string
};
/**
 * nobody has permissions to edit on global rc context MRA-367
 * result: buttons are not available if current rccontext is global
 */
const ConnectedRestrictedFlatButton = connect((state, props) => {
  const permission =
    getRcContext(state) === RC_CONTEXT_ALL
      ? [NOPERMISSION]
      : props.requiredPermissions;
  return {
    requiredPermissions: permission
  };
})(RestrictedFlatButton);

const GenerateControlItineraryButton = ({ record }) =>
  canGenerateControl(record) && (
    <RestrictedFlatButton
      color="primary"
      component={Link}
      requiredPermissions={[config.PERMISSIONS.ITINERARY_CREATE]}
      to={`/${ITINERARY_RESOURCE}/${record.id}/generate_control_reading`}
      style={{ minWidth: 0, overflow: "inherit" }}
    >
      <ControlReadingIcon />
    </RestrictedFlatButton>
  );
const GenerateCorrectionItineraryButton = ({ record }) =>
  canGenerateCorrection(record) && (
    <RestrictedFlatButton
      color="primary"
      component={Link}
      requiredPermissions={[config.PERMISSIONS.ITINERARY_CREATE]}
      to={`/${ITINERARY_RESOURCE}/${record.id}/generate_anomaly_correction`}
      style={{ minWidth: 0, overflow: "inherit" }}
    >
      <AnomalyCorrectionIcon />
    </RestrictedFlatButton>
  );

/**
 * filters for list component
 */
const ItineraryFilter = props => (
  <Filter {...props}>
    <TextInput source="remote_id" />
    <TextInput source="cycle" />
    <TextInput source="cycle_year" />
    <SelectInput source="method" choices={METHOD_TYPES} />
    <ReferenceInput
      source="center_id"
      reference={READINGCENTER_RESOURCE}
      filterToQuery={searchText => ({ remote_id: searchText })}
      allowEmpty
    >
      <AutocompleteInput
        options={{ name: "reading center" }}
        optionValue="id"
        optionText="remote_id"
      />
    </ReferenceInput>
    <ReferenceInput
      source="agent_id"
      reference={USER_RESOURCE}
      filterToQuery={searchText => ({ username: searchText })}
      allowEmpty
    >
      <AutocompleteInput
        options={{ name: "reader" }}
        optionValue="id"
        optionText="username"
      />
    </ReferenceInput>
    <ReferenceInput
      source="capturer_id"
      reference={USER_RESOURCE}
      filterToQuery={searchText => ({ username: searchText })}
      allowEmpty
    >
      <AutocompleteInput
        options={{ name: "capturer" }}
        optionValue="id"
        optionText="username"
      />
    </ReferenceInput>
    <SelectInput
      source="status"
      choices={Object.values(ITINERARY_STATUS_TYPES)}
    />
    <BooleanInput source="is_exported" />
    <BooleanInput source="is_completion_confirmation_required" />
  </Filter>
);

const styles = {
  checkbox: {
    marginBottom: 0
  }
};

/**
 * CheckBoxes for Selecting
 */
const CheckBox = ({ record, update }) => (
  <Checkbox
    style={styles.checkbox}
    onCheck={event => update(record.id, event.target.value)}
  />
);

CheckBox.propTypes = {
  record: P.object,
  update: P.func
};

const ConnectedItineraryListActions = compose(
  connect(state => ({
    selectedIds: getSelectedFields(state)
  })),
  translate
)(ItineraryListActions);

/**
 * component for listing itineraries
 */

export class ItineraryList extends React.Component {
  constructor(props) {
    super(props);
    props.init();
    props.clearReadings();
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { bulkActions, init, clearReadings, ...props } = this.props;
    return (
      <List
        {...props}
        actions={<ConnectedItineraryListActions />}
        filters={<ItineraryFilter />}
        bulkActionButtons={bulkActions ? <PostBulkActionButtons /> : false}
        filter={props.filter}
        sort={{ field: "scheduled_at", order: "DESC" }}
        perPage={PAGINATION}
      >
        <Datagrid>
          <TextField source="route" />
          <TextField source="remote_id" />
          <TextField source="cycle" />
          <TextField source="cycle_year" />
          <TextField source="description" />
          <TypeTranslationField
            source="status"
            types={ITINERARY_STATUS_TYPES}
          />
          <TextField source="method" />
          <DateTimeField source="scheduled_at" />
          <NumberField source="task_count" />
          <NumberField source="result_count" />
          <DateTimeField source="updated_at" />
          <ReferenceField
            source="agent_id"
            reference={USER_RESOURCE}
            linkType={false}
            allowEmpty
          >
            <TextField source="username" />
          </ReferenceField>
          <ReferenceField
            source="capturer_id"
            reference={USER_RESOURCE}
            linkType={false}
            allowEmpty
          >
            <TextField source="username" />
          </ReferenceField>
          <ReferenceField
            source="center_id"
            reference={READINGCENTER_RESOURCE}
            linkType={false}
            allowEmpty
          >
            <TextField source="remote_id" />
          </ReferenceField>
          <BooleanField source="is_exported" />
          <BooleanField source="is_in_quality" />
          <BooleanField source="is_force_exported" />
          <BooleanField source="is_completion_confirmation_required" />
          <ShowButton style={{ minWidth: 0 }} label="" />
          <GenerateCorrectionItineraryButton />
          <GenerateControlItineraryButton />
          <ResequenceButton />
          <CaptureButton />
          <HideablePrintButton />
        </Datagrid>
      </List>
    );
  }
}

ItineraryList.propTypes = {
  init: P.func,
  bulkActions: P.bool,
  clearReadings: P.func
};

export const ConnectedItineraryList = connect(
  state => {
    return {
      filter: getFilter(state),
      bulkActions: checkPermission(state, {
        requiredPermissions: [
          config.PERMISSIONS.READING_ITINERARY_RETURN_FOR_ESTIMATION
        ]
      })
    };
  },
  {
    init: clearSelectedField,
    clearReadings
  }
)(ItineraryList);

export default ConnectedItineraryList;
