import React from "react";
import P from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core";
import { translate, TextField, ReferenceField } from "react-admin";
import { Card as BaseCard } from "../base";
import config from "../../config";
import { getCurrentLang, getItineraryByID } from "../../redux/selectors";
import { getCardHeader } from "../utils";
import {
  AnomalyField,
  ClientActivityField,
  CustomerNameField,
  MultiField,
  ResourceTextField,
  ReferenceManyField,
  PrecisionModeField,
  GpsCoordinateField
} from "../fields";
import Images from "../layout/Images";
import PictureControl from "../fields/PictureControl";

const styles = theme => ({
  whiteSpace: {
    ...theme.typography.body1,
    whiteSpace: "pre"
  },
  [config.ANOMALY_CORRECTION_TASK_RESOURCE]: {
    backgroundColor: "#e040fb",
    color: "#fff"
  },
  [config.CONTROL_READING_TASK_RESOURCE]: {
    backgroundColor: "#d32f2f",
    color: "#fff"
  },
  [config.DISTRIBUTION_TASK_RESOURCE]: {
    backgroundColor: "#df7401",
    color: "#fff"
  }
});

const Card = props => {
  const { record, classes, itineraryResource, ...rest } = props;
  const { title, subheader } = getCardHeader(props);
  const images = record.latest_result ? record.latest_result.images : [];
  return (
    <BaseCard
      title={title}
      subheader={subheader}
      images={images}
      record={record}
      classes={{ header: classes[props.resource || props.resourceToFetch] }}
      {...rest}
    >
      <MultiField
        addLabel
        label={`resources.${config.DISTRIBUTION_ITINERARY_RESOURCE}.fields.itinerary_cycle`}
      >
        <TextField
          addLabel
          label={`resources.${config.DISTRIBUTION_ITINERARY_RESOURCE}.fields.itinerary_id`}
          source={record.metadata ? "metadata.itinerary_eid" : "remote_id"}
        />
        {record.metadata ? (
          <TextField
            addLabel
            label={`resources.${config.DISTRIBUTION_ITINERARY_RESOURCE}.fields.cycle`}
            source={"metadata.cycle"}
          />
        ) : (
          <ResourceTextField
            addLabel
            label={`resources.${config.DISTRIBUTION_ITINERARY_RESOURCE}.fields.cycle`}
            selector={getItineraryByID(itineraryResource, record.itinerary_id)}
            source={record.metadata ? "metadata.cycle" : "cycle"}
          />
        )}
      </MultiField>
      <TextField
        addLabel
        label={`resources.${config.DISTRIBUTION_TASK_RESOURCE}.fields.reading_value`}
        source={
          record.metadata ? "metadata.reading_value" : "latest_result.value"
        }
      />
      <CustomerNameField
        addLabel
        label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.customer_name`}
        source={
          record.metadata
            ? "metadata.customer_name"
            : "supply_point.customer_name"
        }
      />
      <ClientActivityField
        addLabel
        label="mra.client_activity.name"
        sourceId={[
          record.metadata
            ? "metadata.client_activity_id"
            : "latest_result.client_activity_id"
        ]}
      />
      <Images
        addLabel
        label={`resources.${config.DISTRIBUTION_ITINERARY_RESOURCE}.fields.images`}
        source={record.metadata ? "id" : "latest_result.images"}
        type="count"
      />
      <PictureControl addLabel label="mra.anomaly.picture_control" />
      {/* collapsed props */}
      <TextField
        addLabel
        collapsed
        label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.premise_identifier`}
        source="supply_point.premise_identifier"
      />
      <AnomalyField
        addLabel
        collapsed
        label="mra.anomaly.reading"
        source="latest_result.anomaly_id"
      />
      <AnomalyField
        addLabel
        collapsed
        label="mra.anomaly.picture"
        source="latest_result.picture_anomaly_id"
      />
      <AnomalyField
        addLabel
        collapsed
        label="mra.anomaly.occupancy"
        source="latest_result.occupancy_anomaly_id"
      />
      <TextField
        addLabel
        collapsed
        label={`resources.${config.METER_RESOURCE}.fields.manufacturer`}
        source="meter.manufacturer"
      />
      <TextField
        addLabel
        collapsed
        label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.access`}
        source="supply_point.access"
      />
      <TextField
        addLabel
        collapsed
        label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.access_premise`}
        source="supply_point.access_premise"
      />
      <TextField
        addLabel
        collapsed
        label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.access_meter`}
        source="supply_point.access_meter"
      />
      <TextField
        addLabel
        collapsed
        label={`resources.${config.DISTRIBUTION_TASK_RESOURCE}.fields.input_type`}
        source="latest_result.input_type"
      />
      <TextField
        addLabel
        collapsed
        label={`resources.${config.DISTRIBUTION_TASK_RESOURCE}.fields.comment`}
        source="latest_result.comment"
      />
      {record.metadata ? (
        <ReferenceField
          addLabel
          collapsed
          linkType={false}
          label={`resources.${config.DISTRIBUTION_TASK_RESOURCE}.fields.reading_center_id`}
          source="metadata.rc_id"
          reference={config.READINGCENTER_RESOURCE}
        >
          <TextField source="name" />
        </ReferenceField>
      ) : (
        <ReferenceManyField
          addLabel
          collapsed
          linkType={false}
          label={`resources.${config.DISTRIBUTION_TASK_RESOURCE}.fields.reading_center_id`}
          source="itinerary.center_id"
          reference={config.READINGCENTER_RESOURCE}
        >
          <TextField source="remote_id" />
          <span className={classes.whiteSpace}> - </span>
          <TextField source="name" />
        </ReferenceManyField>
      )}
      <GpsCoordinateField
        addLabel
        collapsed
        label={`resources.${config.READING_RESOURCE}.fields.gps_coordinates`}
        lng_source="latest_result.lng_actual"
        lat_source="latest_result.lat_actual"
      />
      <PrecisionModeField
        addLabel
        collapsed
        className={classes.inlineField}
        label={`resources.${config.DISTRIBUTION_TASK_RESOURCE}.fields.gps_precision_mode`}
        source="latest_result.gps_precision_mode"
      />
    </BaseCard>
  );
};

Card.propTypes = {
  record: P.object,
  basePath: P.string,
  resource: P.string,
  resourceToFetch: P.string,
  dataFrom: P.string,
  classes: P.object,
  lang: P.object,
  itineraryResource: P.string
};

export default compose(
  connect(state => ({
    lang: getCurrentLang(state)
  })),
  withStyles(styles),
  translate
)(Card);
