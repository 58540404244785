import React, { useEffect } from "react";
import P from "prop-types";
import {
  List,
  TextField,
  Filter,
  TextInput,
  ReferenceField,
  NumberField,
  SelectInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { initialize } from "redux-form";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import omit from "lodash/omit";
import Assign from "@material-ui/icons/Assignment";
import { getFilter } from "../layout";
import { TypeTranslationField, DateTimeField, BooleanField } from "../fields";
import { Datagrid } from "../base";
import { RestrictedFlatButton } from "../restricted-components";
import config from "../../config";
import { getRcContext, getActiveCycle } from "../../redux/selectors";
import { itineraryIsComplete, itineraryIsExported } from "../utils";

export const mapStateToPropsAssignButton = (state, props) => {
  const { data } = props;
  const permission =
    getRcContext(state) === config.RC_CONTEXT_ALL ||
    itineraryIsComplete(data) ||
    itineraryIsExported(data)
      ? config.NOPERMISSION
      : props.requiredPermissions;
  return {
    requiredPermissions: permission
  };
};

/**
 * nobody has permissions to edit on global rc context MRA-367
 * result: buttons are not available if current rccontext is global
 * hide buttons if complete or exported
 */
const ConnectedRestrictedFlatButton = connect(mapStateToPropsAssignButton)(
  RestrictedFlatButton
);

const RestrictedAssignButton = props => (
  <ConnectedRestrictedFlatButton
    color="primary"
    component={Link}
    to={`${props.resource}/${props.record.id}/assign`}
    requiredPermissions={[config.PERMISSIONS.READING_ITINERARY_DATA_ASSIGNMENT]}
    style={{ minWidth: 0, overflow: "inherit" }}
    data={props.record}
  >
    <Assign />
  </ConnectedRestrictedFlatButton>
);

RestrictedAssignButton.propTypes = {
  resource: P.string,
  record: P.object
};

/**
 * filters for list component
 */
// FIXME: not working - defaultValue={config.ITINERARY_STATUS_TYPES.UNASSIGNED.id} alwaysOn
const ItineraryFilter = props => {
  const { context, setFilters } = props;
  useEffect(() => {
    if (context === "form") {
      setFilters({});
    }
  }, [setFilters, context]);
  return (
    <Filter {...props} perPage={config.PAGINATION}>
      <TextInput source="remote_id" />
      <SelectInput source="method" choices={config.METHOD_TYPES} />
      <BooleanInput source="is_printed" parse={v => (v ? 1 : 0)} />
      <ReferenceInput
        source="center_id"
        reference={config.READINGCENTER_RESOURCE}
        filterToQuery={searchText => ({ remote_id: searchText })}
        allowEmpty
      >
        <AutocompleteInput
          options={{ name: "reading center" }}
          optionValue="id"
          optionText="remote_id"
        />
      </ReferenceInput>
      <SelectInput
        source="status"
        choices={Object.values(config.ITINERARY_STATUS_TYPES)}
      />
      <BooleanInput source="is_confirmation_required" />
    </Filter>
  );
};

ItineraryFilter.propTypes = {
  setFilters: P.func,
  context: P.oneOf(["form", "button"])
};

/**
 * component for listing itineraries
 */
export class ItineraryAssignList extends React.Component {
  componentDidMount() {
    const { initializeClear } = this.props;
    initializeClear("filterForm", null, false); //reset all filters
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { initializeClear, active_cycle, ...props } = this.props;
    return (
      <List
        {...props}
        basePath={config.ITINERARY_RESOURCE}
        hasEdit={false}
        hasList={false}
        hasCreate={false}
        hasShow={false}
        perPage={config.PAGINATION}
        resource={config.ITINERARY_RESOURCE}
        filters={<ItineraryFilter />}
        bulkActions={false}
        filter={
          active_cycle
            ? {
                ...props.filter,
                cycle: active_cycle
              }
            : props.filter
        }
        sort={{ field: "scheduled_at", order: "DESC" }}
      >
        <Datagrid>
          <TextField source="route" />
          <TextField source="remote_id" />
          <TextField source="cycle" />
          <TextField source="description" />
          <TypeTranslationField
            source="status"
            types={config.ITINERARY_STATUS_TYPES}
          />
          <TextField source="method" />
          <DateTimeField source="scheduled_at" />
          <NumberField source="task_count" />
          <ReferenceField
            source="agent_id"
            reference={config.USER_RESOURCE}
            linkType={false}
            allowEmpty
          >
            <TextField source="username" />
          </ReferenceField>
          <ReferenceField
            source="capturer_id"
            reference={config.USER_RESOURCE}
            linkType={false}
            allowEmpty
          >
            <TextField source="username" />
          </ReferenceField>
          <ReferenceField
            source="center_id"
            reference={config.READINGCENTER_RESOURCE}
            linkType={false}
            allowEmpty
          >
            <TextField source="remote_id" />
          </ReferenceField>
          <BooleanField source="is_confirmation_required" />
          <RestrictedAssignButton />
        </Datagrid>
      </List>
    );
  }
}

ItineraryAssignList.propTypes = {
  filter: P.object,
  active_cycle: P.string,
  initializeClear: P.func
};

const ConnectedItineraryAssignList = compose(
  connect(
    state => {
      return {
        active_cycle: getActiveCycle(state),
        filter: getFilter(state)
      };
    },
    {
      initializeClear: initialize //for resetting Filters on componentDidMount
    }
  ),
  mapProps(props => omit(props, ["dispatch", "staticContext", "authClient"]))
)(ItineraryAssignList);

export default ConnectedItineraryAssignList;
