import { stringify } from "query-string";
import { isNil, get } from "lodash";
import moment from "moment";
import config from "../../config";

const {
  ITINERARY_RESOURCE,
  DISTRIBUTION_TASK_RESOURCE,
  CONTROL_READING_TASK_RESOURCE,
  ANOMALY_CORRECTION_TASK_RESOURCE,
  ITINERARY_STATUS_TYPES: {
    PAPER_READING_FULLY_ENTERED,
    FULLY_RECEIVED,
    COMPLETED
  }
} = config;

export const getBasePath = location =>
  location.pathname
    .split("/")
    .slice(0, -1)
    .join("/");

export const safeValue = value => (isNil(value) ? "" : value);

export const getAnomalyRedirection = (basePath, id, { type: anomalyType }) =>
  `/${config.ANOMALIES_RESOURCE}?${stringify({
    filter: JSON.stringify({
      type: anomalyType
    }),
    order: "ASC",
    page: 1,
    perPage: config.PAGINATION,
    sort: "id"
  })}`;

export const getAnomalyType = s => {
  try {
    const search = new URLSearchParams(s);
    const { type } = JSON.parse(search.get("filter"));
    return type ? type.toLowerCase() : "";
  } catch (e) {
    return "";
  }
};

export const itineraryIsComplete = record =>
  record &&
  (record.status === PAPER_READING_FULLY_ENTERED.id ||
    record.status === FULLY_RECEIVED.id ||
    record.status === COMPLETED.id);
export const itineraryIsExported = record => record && record.exported_at;
export const itineraryIsCompleteOrExported = data =>
  data && (itineraryIsComplete(data) || itineraryIsExported(data));
export const itineraryIsCompleteAndNotExported = data =>
  data && itineraryIsComplete(data) && !itineraryIsExported(data);

export function getCardHeader(props) {
  const { record, resource, resourceToFetch, translate, lang } = props;
  const date =
    get(record, "latest_result.updated_at") ||
    get(record, "latest_result.date_visited_at") ||
    get(record, "metadata.visited_at") ||
    get(record, "metadata.registration_date");
  return {
    title: `MTR: ${get(record, "meter.meter_identifier", "") ||
      get(record, "metadata.meter_identifier", "")} - SP: ${get(
      record,
      "supply_point.remote_id",
      ""
    ) || get(record, "metadata.supply_point_eid", "")}`,
    subheader: `${translate(
      "resources." + (resource || resourceToFetch) + ".type_name"
    )} - ${date ? moment(date).format(lang.dateTimeFormat) : ""}`
  };
}

export function isPaperReading(resource, method) {
  return resource === ITINERARY_RESOURCE && method === "paper";
}

export function getItineraryAnomalyLabel(resource) {
  switch (resource) {
    case DISTRIBUTION_TASK_RESOURCE:
      return "mra.anomaly.distribution";
    case CONTROL_READING_TASK_RESOURCE:
      return "mra.anomaly.control_reading";
    case ANOMALY_CORRECTION_TASK_RESOURCE:
      return "mra.anomaly.correction";
    default:
      return "";
  }
}

export function makeUserFilter({ center_id, unit_id } = {}) {
  const result = {};
  if (center_id) {
    result["@center_id"] = center_id;
  }
  if (unit_id) {
    result["@unit_id"] = unit_id;
  }
  return result;
}
