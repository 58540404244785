/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import { findDOMNode } from "react-dom";
import P from "prop-types";
import Menu from "@material-ui/core/Menu";
import { withStyles, createStyles } from "@material-ui/core/styles";
import ContentFilter from "@material-ui/icons/FilterList";
import classnames from "classnames";
import compose from "recompose/compose";
import { translate, Button } from "react-admin";
import get from "lodash/get";

import FilterButtonMenuItem from "./FilterButtonMenuItem";

const styles = createStyles({
  root: { display: "inline-block" }
});

function FilterButton(props) {
  const {
    classes = {},
    className,
    resource,
    showFilter,
    filters,
    displayedFilters,
    filterValues,
    translate,
    ...rest
  } = props;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const hiddenFilters = filters.filter(
    filterElement =>
      !filterElement.props.alwaysOn &&
      !displayedFilters[filterElement.props.source] &&
      get(filterValues, filterElement.props.source) === undefined
  );

  function handleShow({ source, defaultValue }) {
    showFilter(source, defaultValue);
    setOpen(false);
  }

  function handleRequestClose() {
    setOpen(false);
  }

  function handleClickButton(e) {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
    setOpen(true);
  }

  return (
    hiddenFilters.length > 0 && (
      <div className={classnames(classes.root, className)} {...rest}>
        <Button
          className="add-filter"
          label="ra.action.add_filter"
          onClick={handleClickButton}
        >
          <ContentFilter />
        </Button>
        <Menu open={open} anchorEl={anchorEl} onClose={handleRequestClose}>
          {hiddenFilters.map(filterElement => (
            <FilterButtonMenuItem
              key={filterElement.props.source}
              filter={filterElement.props}
              resource={resource}
              onShow={handleShow}
            />
          ))}
        </Menu>
      </div>
    )
  );
}

FilterButton.propTypes = {
  resource: P.string.isRequired,
  filters: P.arrayOf(P.node).isRequired,
  displayedFilters: P.object.isRequired,
  filterValues: P.object.isRequired,
  showFilter: P.func.isRequired,
  translate: P.func.isRequired,
  classes: P.object,
  className: P.string
};

export default compose(
  translate,
  withStyles(styles)
)(FilterButton);
