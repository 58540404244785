import React from "react";
import PropTypes from "prop-types";
import { Toolbar as MuiToolbar } from "@material-ui/core";
import { Responsive } from "react-admin";

const ToolbarTitle = props => <div {...props} />;
const ToolbarGroup = props => <div {...props} />;

const styles = {
  mobileToolbar: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: "white",
    justifyContent: "flex-end",
    zIndex: 2
  }
};

/* #DisabledToolbar#
 * Displays a Toolbar with a title.
 * a bit like aor [Toolbar](https://github.com/marmelab/admin-on-rest/blob/master/src/mui/form/Toolbar.js)
 * prop toolbarTitle: String which will be displayed in the toolbar.
 * prop displayEmpty: Display the toolbar even if toolbarTitle is null
 * (default: return null if toolbarTitle is null)
*/

const DisabledToolbar = ({ toolbarTitle, displayEmpty }) => {
  if (!toolbarTitle && !displayEmpty) return null;
  return (
    <Responsive
      small={
        <MuiToolbar style={styles.mobileToolbar} noGutter>
          <ToolbarGroup>
            <ToolbarTitle>
              {toolbarTitle}
            </ToolbarTitle>
          </ToolbarGroup>
        </MuiToolbar>
      }
      medium={
        <MuiToolbar>
          <ToolbarGroup>
            <ToolbarTitle>
              {toolbarTitle}
            </ToolbarTitle>
          </ToolbarGroup>
        </MuiToolbar>
      }
    />
  );
};

DisabledToolbar.propTypes = {
  toolbarTitle: PropTypes.string,
  displayEmpty: PropTypes.bool
};

export default DisabledToolbar;
