import React from "react";
import {
  Edit,
  TextInput,
  BooleanInput,
  translate,
  addField,
  required
} from "react-admin";
import { formValueSelector } from "redux-form";
import PropTypes from "prop-types";
import {
  RestrictedCustomForm,
  RestrictedEditActions
} from "../restricted-components";
import { connect } from "react-redux";
import { compose, branch } from "recompose";
import config from "../../config";

const selector = formValueSelector("record-form");
export const mapStateToProps = state => ({
  display: selector(state, "is_active")
});

const BooleanDisplay = Component =>
  function DisabledBoolean(props) {
    return <Component options={{ disabled: true }} {...props} />;
  };

const IsDefaultBooleanInput = compose(
  addField,
  branch(props => props.record.is_default, BooleanDisplay)
)(BooleanInput);

const ConnectedRestrictedEditActions = connect((state, props) => {
  //Delte button isn't displayed for the default client activity
  if (props.data && props.data.is_default) {
    return {
      requiredPermissionsDeleteButton: [config.NOPERMISSION]
    };
  } else {
    return {
      requiredPermissionsDeleteButton: [
        config.PERMISSIONS.CLIENT_ACTIVITY_DELETE
      ]
    };
  }
})(RestrictedEditActions);

/**
 * component for editing client activity
 */
export const ClientActivityEdit = ({ translate, ...props }) => {
  return (
    <Edit
      actions={
        <ConnectedRestrictedEditActions
          requiredPermissionsDeleteButton={[
            config.PERMISSIONS.CLIENT_ACTIVITY_UPDATE,
            config.PERMISSIONS.HQ_MEMBER
          ]}
        />
      }
      {...props}
    >
      <RestrictedCustomForm
        requiredPermissions={[
          config.PERMISSIONS.CLIENT_ACTIVITY_UPDATE,
          config.PERMISSIONS.HQ_MEMBER
        ]}
        disabledToolbarTitle={translate("mra.general.readonly_details")}
      >
        <TextInput
          source="code"
          validate={required("mra.validation.required")}
        />
        <TextInput source="label_lang_one" />
        <TextInput source="label_lang_two" />
        <BooleanInput source="is_active" />
        <IsDefaultBooleanInput source="is_default" defaultValue={0} />
      </RestrictedCustomForm>
    </Edit>
  );
};

ClientActivityEdit.propTypes = {
  translate: PropTypes.func
};

export default translate(ClientActivityEdit);
