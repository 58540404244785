import React from "react";
import {
  Create,
  TextInput,
  BooleanInput,
  SelectInput,
  required
} from "react-admin";
import { SimpleForm } from "../base";
import config from "../../config";

/**
 * component for creating reading centers
 */
export const AnomalyCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <SelectInput
          source="type"
          choices={Object.values(config.ANOMALY_TYPES)}
          validate={required("mra.anomaly.type")}
        />
        <TextInput source="code" validate={required("mra.anomaly.code")} />
        <BooleanInput
          source="is_active"
          defaultValue={true}
          style={{ display: "none" }}
        />
        <BooleanInput source="is_default" defaultValue={false} />
        <BooleanInput source="is_skip_reading" defaultValue={false} />
        <TextInput source="label_lang_one" />
        <TextInput source="label_lang_two" />
      </SimpleForm>
    </Create>
  );
};

export default AnomalyCreate;
