import { DateInput } from "react-admin-date-inputs";
import { connect } from "react-redux";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import { getCurrentLang } from "../../redux/selectors";

export default connect((state, props) => {
  const lang = getCurrentLang(state);
  const { dateFormat } = lang;
  return {
    ...props,
    providerOptions: { utils: MomentUtils },
    options: { format: dateFormat }
  };
})(DateInput);
