import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Table,
  TableBody,
  TableHead as TableHeader,
  TableCell,
  TableRow
} from "@material-ui/core";
import FalseIcon from "@material-ui/icons/Clear";
import TrueIcon from "@material-ui/icons/Done";
import { translate } from "react-admin";
import compose from "recompose/compose";
import { getQualityRuns } from "../../redux/actions";
import {
  getQualityRunsByItineraryId,
  getQualityRuleByQualityRun
} from "../../redux/selectors";
import sortBy from "lodash/sortBy";
import filter from "lodash/filter";
import get from "lodash/get";
import config from "../../config";

class QualityCheckTable extends React.Component {
  constructor(props) {
    super(props);
    const { getQualityRuns, record } = this.props;
    if (record && getQualityRuns) getQualityRuns(record.id);
  }

  render() {
    const { qualityRuns, record, displayAll = false, translate } = this.props;
    let dateRuns = sortBy(
      qualityRuns.map(r => ({ ...r, run_at: new Date(r.run_at) })),
      "run_at"
    ).reverse();
    /**
     * #MRA-769 Dipslay only the last run of the quality checks
     */
    if (!displayAll) {
      let rule_codes = [];
      dateRuns = filter(dateRuns, run => {
        if (rule_codes.includes(run.rule_code)) return false;
        rule_codes.push(run.rule_code);
        return true;
      });
    }
    return (
      <div>
        {dateRuns && dateRuns.length > 0
          ? [
              <div key={1}>
                <span>
                  {translate("mra.quality_runs.overall")}:{" "}
                  {record.is_in_quality
                    ? translate("mra.quality_runs.check_passed")
                    : translate("mra.quality_runs.check_failed")}
                </span>
                <span style={{ marginLeft: "10px" }}>
                  {translate("mra.quality_runs.last_run")}:{" "}
                  {dateRuns[0].run_at.toLocaleString()}
                </span>
              </div>,
              <Table
                key={2}
                style={{
                  width: "100%",
                  marginTop: "5px",
                  marginBottom: "5px"
                }}
              >
                <TableHeader>
                  <TableRow>
                    <TableCell>
                      {translate(
                        `resources.${config.QUALITY_RULES_RESOURCE}.fields.short_description`
                      )}
                    </TableCell>
                    <TableCell>
                      {translate("mra.reading_center.threshold")}
                    </TableCell>
                    <TableCell>
                      {translate("mra.quality_runs.actual_value")}
                    </TableCell>
                    <TableCell>
                      {translate("mra.quality_runs.passed")}
                    </TableCell>
                    <TableCell>
                      {translate("mra.quality_runs.run_at")}
                    </TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {dateRuns.map(
                    (
                      {
                        short_description,
                        threshold,
                        actual_value,
                        passed,
                        run_at
                      },
                      index
                    ) => (
                      <TableRow key={index}>
                        <TableCell>{short_description}</TableCell>
                        <TableCell>{threshold}</TableCell>
                        <TableCell>{actual_value}</TableCell>
                        <TableCell>
                          {passed ? <TrueIcon /> : <FalseIcon />}
                        </TableCell>
                        <TableCell>{run_at.toLocaleString()}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            ]
          : translate("mra.quality_runs.no_checks")}
      </div>
    );
  }
}

QualityCheckTable.propTypes = {
  getQualityRuns: PropTypes.func,
  record: PropTypes.object,
  qualityRuns: PropTypes.array,
  displayAll: PropTypes.bool,
  translate: PropTypes.func
};

const ConnectedQualityCheckTable = compose(
  connect(
    (state, props) => {
      let qualityRuns = getQualityRunsByItineraryId(props.record.id)(state);
      qualityRuns.forEach(run => {
        run.description = get(
          getQualityRuleByQualityRun(run)(state),
          "description",
          ""
        );
        run.short_description = run.description.substring(
          0,
          run.description.indexOf("-")
        );
      });
      return {
        qualityRuns
      };
    },
    {
      getQualityRuns
    }
  ),
  translate
)(QualityCheckTable);

export default ConnectedQualityCheckTable;
