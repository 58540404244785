import { connect } from "react-redux";
import { compose, branch, renderNothing, mapProps } from "recompose";
import omit from "lodash/omit";
import { addField } from "react-admin";

export const hide = predicate =>
  compose(
    connect((state, props) => ({
      display: predicate(state, props)
    })),
    branch(props => !props.display, renderNothing)
  );

const hideableField = predicate =>
  compose(
    hide(predicate),
    mapProps(props => omit(props, ["display"])),
    addField
  );

export default hideableField;
