import React, { useState, cloneElement } from "react";
import P from "prop-types";
import {
  ListController,
  ListToolbar,
  BulkActionsToolbar,
  Pagination,
  Title,
  BulkDeleteButton,
  getListControllerProps
} from "react-admin";
import { Card, withStyles, createStyles } from "@material-ui/core";
import classnames from "classnames";
import { SwitchLayoutButton, Actions } from "./actions";
import config from "../../../config";

const { GRID_VIEW, LIST_VIEW } = config;

export const styles = createStyles({
  cardActions: {
    padding: 10
  },
  noShadow: {
    boxShadow: "none"
  },
  root: {
    display: "flex"
  },
  card: {
    position: "relative",
    flex: "1 1 auto"
  },
  actions: {
    zIndex: 2,
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignSelf: "flex-start"
  },
  noResults: { padding: 20 }
});

const DefaultBulkActionButtons = props => <BulkDeleteButton {...props} />;

function SwitchList(props) {
  const [viewMode, setViewMode] = useState(LIST_VIEW);
  function toggleView() {
    setViewMode(v => (v === LIST_VIEW ? GRID_VIEW : LIST_VIEW));
  }

  const {
    classes,
    gridComponent,
    tableComponent,
    bulkActionButtons,
    bulkActions,
    actions,
    exporter,
    filters,
    version,
    pagination,
    aside,
    title,
    defaultTitle,
    className,
    ...rest
  } = props;
  const controllerProps = getListControllerProps(rest);

  return (
    <div className={classnames("list-page", classes.root, className)}>
      {(title || defaultTitle) && (
        <Title title={title} defaultTitle={defaultTitle} />
      )}
      <Card className={classes.card}>
        {bulkActions !== false &&
          bulkActionButtons !== false &&
          bulkActionButtons && (
            <BulkActionsToolbar {...controllerProps}>
              {bulkActionButtons}
            </BulkActionsToolbar>
          )}
        {(filters || actions) && (
          <ListToolbar
            filters={filters}
            {...controllerProps}
            actions={actions}
            bulkActions={cloneElement(bulkActions, {
              viewMode,
              toggleView
            })}
            exporter={exporter}
          />
        )}
        <div key={version}>
          {cloneElement(
            viewMode === GRID_VIEW ? gridComponent : tableComponent,
            {
              ...controllerProps,
              hasBulkActions:
                bulkActions !== false && bulkActionButtons !== false
            }
          )}
          {pagination && cloneElement(pagination, controllerProps)}
        </div>
      </Card>
      {aside && cloneElement(aside, controllerProps)}
    </div>
  );
}

SwitchList.propTypes = {
  actions: P.element,
  aside: P.node,
  bulkActions: P.oneOfType([P.bool, P.element]),
  bulkActionButtons: P.oneOfType([P.bool, P.element]),
  classes: P.object,
  className: P.string,
  defaultTitle: P.string,
  exporter: P.oneOfType([P.func, P.bool]),
  filters: P.element,
  gridComponent: P.oneOfType([P.node, P.element, P.func]),
  pagination: P.oneOfType([P.bool, P.element]),
  tableComponent: P.oneOfType([P.node, P.element, P.func]),
  title: P.any,
  version: P.number,
  card: P.element
};

SwitchList.defaultProps = {
  bulkActions: <SwitchLayoutButton />,
  actions: <Actions />,
  bulkActionButtons: <DefaultBulkActionButtons />,
  pagination: <Pagination />
};

const StyledSwitchList = withStyles(styles)(SwitchList);

const ControlledList = props => (
  <ListController {...props}>
    {controllerProps => <StyledSwitchList {...props} {...controllerProps} />}
  </ListController>
);

ControlledList.defaultProps = {
  debounce: 1e3
};

export default ControlledList;
