import React from "react";
import {
  Edit,
  ReferenceInput,
  AutocompleteInput,
  TextField,
  NumberField,
  translate
} from "react-admin";
import { SimpleForm } from "../base";
import config from "../../config";
import { DateTimeField } from "../fields";
import PropTypes from "prop-types";

const EditTitle = translate(({ translate, ...props }) => (
  <span>
    {translate(`resources.${config.ITINERARY_RESOURCE}.name`, 1)} -{" "}
    {props.record ? `"${props.record.remote_id}"` : ""}
  </span>
));

EditTitle.contextTypes = {
  props: PropTypes.object
};

/**
 * component for editing reading centers
 */
const ItineraryEdit = props => (
  <Edit title={<EditTitle />} {...props}>
    <SimpleForm>
      <TextField source="route" />
      <TextField source="remote_id" />
      <TextField source="cycle" />
      <TextField source="description" />
      <TextField source="status" />
      <TextField source="method" />
      <DateTimeField source="scheduled_at" addLabel />
      <NumberField source="result_count" style={{ textAlign: "left" }} />
      <ReferenceInput
        source="agent_id"
        reference={config.USER_RESOURCE}
        filterToQuery={searchText => ({ username: searchText })}
        allowEmpty
      >
        <AutocompleteInput options={{ name: "reader" }} optionText="username" />
      </ReferenceInput>
      <ReferenceInput
        source="capturer_id"
        reference={config.USER_RESOURCE}
        filterToQuery={searchText => ({ username: searchText })}
        allowEmpty
      >
        <AutocompleteInput options={{ name: "user" }} optionText="username" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default ItineraryEdit;
