import { connect } from "react-redux";
import { ReferenceInput } from "react-admin";
import { getRoleIdByName } from "../../redux/selectors";
import config from "../../config";

export const FieldAgentInput = connect((state, props) => ({
  filter: {
    ...props.filter,
    "@role_id": [getRoleIdByName(config.ROLES.NAMES.FA, state)]
  }
}))(ReferenceInput);
