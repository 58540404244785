import { Component } from "react";
import P from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import { crudGetManyAccumulate as crudGetManyAccumulateAction } from "react-admin";
const linkToRecord = (basePath, id, linkType = "edit") => {
  const link = `${basePath}/${encodeURIComponent(id)}`;

  if (linkType === "show") {
    return `${link}/show`;
  }

  return link;
};

export class UnconnectedReferenceFieldController extends Component {
  static propTypes = {
    allowEmpty: P.bool,
    basePath: P.string,
    children: P.element,
    linkType: P.string,
    record: P.object,
    reference: P.string,
    referenceRecord: P.object,
    resource: P.string,
    source: P.string,
    crudGetManyAccumulate: P.func
  };

  static defaultProps = {
    allowEmpty: false,
    linkType: "edit",
    referenceRecord: null,
    record: { id: "" }
  };

  componentDidMount() {
    this.fetchReference(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.record.id !== nextProps.record.id) {
      this.fetchReference(nextProps);
    }
  }

  fetchReference(props) {
    const source = get(props.record, props.source);
    if (source !== null && source !== 0 && typeof source !== "undefined") {
      this.props.crudGetManyAccumulate(props.reference, [source]);
    }
  }

  render() {
    const {
      allowEmpty,
      basePath,
      children,
      linkType,
      record,
      reference,
      referenceRecord,
      resource,
      source
    } = this.props;
    const rootPath = basePath.replace(resource, reference);
    const resourceLinkPath = !linkType
      ? false
      : linkToRecord(rootPath, get(record, source), linkType);

    return children({
      isLoading: !referenceRecord && !allowEmpty,
      referenceRecord,
      resourceLinkPath
    });
  }
}

const mapStateToProps = (state, props) => ({
  referenceRecord:
    state.admin.resources[props.reference] &&
    state.admin.resources[props.reference].data[get(props.record, props.source)]
});

const ReferenceFieldController = connect(
  mapStateToProps,
  {
    crudGetManyAccumulate: crudGetManyAccumulateAction
  }
)(UnconnectedReferenceFieldController);

export default ReferenceFieldController;
