import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as SelectField
} from "@material-ui/core";
import { translate } from "react-admin";
import {
  getRcContext,
  getCurrentUserRUContext,
  getRecordFormValues,
  getRCsofRU
} from "../../redux/selectors";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { isEqual } from "lodash";
import {
  getAllRoles,
  setRecordFormValue,
  getReadingUnits,
  getReadingCenters
} from "../../redux/actions";
import config from "../../config";

export class renderRCSelectInput extends Component {
  /*
   * Using state to bypass a redux-form comparison but which prevents re-rendering
   * @see https://github.com/erikras/redux-form/issues/2456
   */
  constructor(props) {
    super(props);
    this.props.loadRoles();
    this.props.loadRCs();
    this.props.loadRUs();
    this.state = {
      value: this.props.input.value && this.props.input.value[0]
    };
  }

  renderMenuItem = choice => {
    const { optionText, optionValue, translate, translateChoice } = this.props;
    const choiceName = React.isValidElement(optionText) // eslint-disable-line no-nested-ternary
      ? React.cloneElement(optionText, { record: choice })
      : typeof optionText === "function"
      ? optionText(choice)
      : choice[optionText];
    return (
      <MenuItem
        key={`${choice.context}_${choice[optionValue]}`}
        value={choice[optionValue]}
      >
        {translateChoice
          ? translate(choiceName, { _: choiceName })
          : choiceName}
      </MenuItem>
    );
  };

  getChoices = () => {
    let choices = [];
    const ru = this.props.formValue("unit_id");
    const rcs = this.props.RCs(String(ru));
    if (!rcs) return [];
    Object.keys(rcs).forEach(id => {
      choices.push({ id: rcs[id].id, name: `${rcs[id].name}` });
    });
    return choices;
  };

  handleChange = ({ target: { value } }) => {
    this.props.input.onChange([value]);
    this.setState({ value });
  };

  /** not needed for now.
   */
  getChoice(rcId) {
    const choices = this.getChoices();
    const choice = choices.find(choice => isEqual(choice.id, rcId));
    return choice;
  }

  render() {
    const {
      allowEmpty,
      elStyle,
      isRequired,
      label,
      meta: { touched, error },
      className,
      options,
      ruId,
      rcContext,
      translate
    } = this.props;
    const choices = this.getChoices();
    const safeValue = this.state.value == null ? "" : this.state.value;
    if (
      ruId &&
      //dont display the component if the User is locked in a RC Context (Context will be set automatically)
      rcContext === config.RC_CONTEXT_ALL
    ) {
      if (!choices || isEqual(choices, [])) {
        return (
          <FormControl className={className}>
            <InputLabel required={isRequired}>{translate(label)}</InputLabel>
            <SelectField
              value={safeValue}
              onChange={this.handleChange}
              autoWidth
              style={elStyle}
              errorText={touched && error}
              disabled={true}
              {...options}
            />
          </FormControl>
        );
      }
      return (
        <FormControl className={className}>
          <InputLabel required={isRequired}>{translate(label)}</InputLabel>
          <SelectField
            autoWidth
            value={safeValue}
            onChange={this.handleChange}
            errorText={touched && error}
            {...options}
          >
            {allowEmpty && <MenuItem value={null} />}
            {choices.map(this.renderMenuItem)}
          </SelectField>
        </FormControl>
      );
    } else {
      return null;
    }
  }
}

renderRCSelectInput.propTypes = {
  ruId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rcContext: PropTypes.string,
  formValue: PropTypes.func,
  RCs: PropTypes.func,
  loadRoles: PropTypes.func,
  loadRCs: PropTypes.func,
  loadRUs: PropTypes.func,
  allowEmpty: PropTypes.bool.isRequired,
  choices: PropTypes.arrayOf(PropTypes.object),
  elStyle: PropTypes.object,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  className: PropTypes.string,
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element
  ]).isRequired,
  optionValue: PropTypes.string.isRequired,
  resource: PropTypes.string,
  source: PropTypes.string,
  translate: PropTypes.func.isRequired,
  translateChoice: PropTypes.bool.isRequired
};

renderRCSelectInput.defaultProps = {
  allowEmpty: false,
  choices: [],
  options: {},
  optionText: "name",
  optionValue: "id",
  translateChoice: true
};

const ConnectedrenderRCSelectInput = connect(
  state => {
    return {
      rcContext: getRcContext(state),
      ruId: getRecordFormValues("unit_id")(state),
      ruContext: getCurrentUserRUContext(state),
      formValue: value => getRecordFormValues(value)(state),
      RCs: ruId => getRCsofRU(ruId)(state)
    };
  },
  {
    loadRoles: getAllRoles,
    loadRUs: getReadingUnits,
    loadRCs: getReadingCenters,
    changeFormValue: setRecordFormValue
  }
)(translate(renderRCSelectInput));

const RCSelectInput = translate(({ translate, ...props }) => (
  <Field
    label={translate(props.label)}
    name={props.source}
    component={ConnectedrenderRCSelectInput}
    {...props}
  />
));

RCSelectInput.propTypes = {
  source: PropTypes.string,
  label: PropTypes.string
};

export default RCSelectInput;
