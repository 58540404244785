import React, { Fragment } from "react";
import P from "prop-types";
import { Button } from "@material-ui/core";
import { translate } from "react-admin";
import { Link } from "react-router-dom";
import { RestrictedFlatButton } from "../restricted-components";
import config from "../../config";

const ActionButtons = translate(props => {
  const { resource, basePath, selectedIds, translate } = props;
  const base =
    resource && !basePath.includes(resource)
      ? `${basePath}/${resource}/${selectedIds}`
      : `${basePath}/${selectedIds}`;
  return (
    <Fragment>
      <RestrictedFlatButton
        component={Link}
        to={`${base}/generate_control_reading`}
        requiredPermissions={[config.PERMISSIONS.ITINERARY_CREATE]}
      >
        {translate("mra.itinerary_overview.generate_control_reading")}
      </RestrictedFlatButton>
      <RestrictedFlatButton
        component={Link}
        to={`${base}/generate_anomaly_correction`}
        requiredPermissions={[config.PERMISSIONS.ITINERARY_CREATE]}
      >
        {translate("mra.itinerary_overview.generate_anomaly_correction")}
      </RestrictedFlatButton>
      <RestrictedFlatButton
        component={Link}
        to={`${base}/force_export`}
        requiredPermissions={[
          config.PERMISSIONS.READING_ITINERARY_FORCE_EXPORT
        ]}
      >
        {translate("mra.itinerary_overview.forceexport")}
      </RestrictedFlatButton>
      <Button component={Link} to={`${base}/return`}>
        {translate("mra.itinerary_overview.return_selected")}
      </Button>
    </Fragment>
  );
});

ActionButtons.propTypes = {
  resource: P.string,
  basePath: P.string,
  selectedIds: P.array
};

export const PostBulkActionButtons = ActionButtons;
