import React, { useState, useEffect } from "react";
import P from "prop-types";
import Lightbox from "react-image-lightbox";
import { connect } from "react-redux";
import { closeLightBox, downloadImage } from "../../redux/actions";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import "react-image-lightbox/style.css";
import { mraImages } from "../../redux/selectors";

function getImage(images, image) {
  return image && image.id ? get(images, `${image.id}.url`, "") : image;
}

function LightBox({ lightbox, mraImages, closeLightBox, downloadImage }) {
  const { images = [], index: initialIndex = 0 } = lightbox || {};
  const [index, setIndex] = useState(initialIndex);
  const nextIdx = (index + 1) % images.length;
  const prevIdx = (index + images.length - 1) % images.length;
  useEffect(() => {
    if (index !== initialIndex) {
      setIndex(Math.min(index, images.length));
    }
    images.forEach(image => {
      if (image && image.id && !mraImages[image.id]) {
        downloadImage(image);
      }
    });
  }, [images, mraImages, index, initialIndex, downloadImage]);
  return (
    !isEmpty(images) && (
      <Lightbox
        mainSrc={getImage(mraImages, images[index])}
        nextSrc={
          images.length > 1 ? getImage(mraImages, images[nextIdx]) : null
        }
        prevSrc={
          images.length > 1 ? getImage(mraImages, images[prevIdx]) : null
        }
        onCloseRequest={closeLightBox}
        onMovePrevRequest={() => {
          setIndex(prevIdx);
        }}
        onMoveNextRequest={() => {
          setIndex(nextIdx);
        }}
        reactModalStyle={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
            zIndex: 12000
          }
        }}
      />
    )
  );
}

LightBox.propTypes = {
  lightbox: P.object,
  mraImages: P.object,
  downloadImage: P.func,
  closeLightBox: P.func
};

LightBox.defaulProps = {
  lightbox: {}
};

export default connect(
  state => ({
    lightbox: state.mra.lightbox,
    mraImages: mraImages(state)
  }),
  {
    closeLightBox,
    downloadImage
  }
)(LightBox);
