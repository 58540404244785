import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  CardActions,
  Toolbar,
  Button,
  TextField,
  withStyles
} from "@material-ui/core";
import ActionCheck from "@material-ui/icons/CheckCircle";
import AlertError from "@material-ui/icons/ErrorOutline";
import { Title, translate, required } from "react-admin";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { confirmDevice } from "../../redux/actions";

const ToolbarGroup = props => <div {...props} />;

const styles = {
  actions: { zIndex: 2, display: "inline-block", float: "right" },
  appCode: { minWidth: 240 },
  toolbar: { clear: "both" },
  button: { margin: "10px 24px", position: "relative" }
};

class DeviceConfirmation extends Component {
  constructor() {
    super();
    this.state = { onb_code: "" };
  }

  handleSubmit = event => {
    event.preventDefault();
    this.props.confirm(this.props.match.params.id, this.state.onb_code);
  };

  goBack = () => this.props.history.goBack();

  handleChange = event => {
    this.setState({ onb_code: event.target.value });
  };

  render() {
    const { translate, classes } = this.props;

    return (
      <div>
        <Card>
          <CardActions className={classes.actions} />
          <Title title={translate("mra.devices.confirm_title")} />
          <form onSubmit={this.handleSubmit}>
            <CardContent>
              {translate("mra.devices.confirm_text")}
              <br />
              <TextField
                required
                className={classes.appCode}
                onChange={this.handleChange}
                validate={required("mra.validation.required")}
                type="text"
                label={translate("resources.device.fields.onb_code")}
              />
            </CardContent>
            <Toolbar className={classes.toolbar}>
              <ToolbarGroup>
                <Button
                  type="submit"
                  variant="raised"
                  color="primary"
                  className={classes.button}
                >
                  <ActionCheck />
                  {translate("mra.confirm.ok")}
                </Button>
                <Button
                  variant="raised"
                  onClick={this.goBack}
                  className={classes.button}
                >
                  <AlertError />
                  {translate("ra.action.cancel")}
                </Button>
              </ToolbarGroup>
            </Toolbar>
          </form>
        </Card>
      </div>
    );
  }
}

DeviceConfirmation.propTypes = {
  history: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  match: PropTypes.object.isRequired,
  translate: PropTypes.func,
  classes: PropTypes.object,
  confirm: PropTypes.func
};

export default compose(
  connect(
    null,
    { confirm: confirmDevice }
  ),
  translate,
  withStyles(styles)
)(DeviceConfirmation);
