import { connect } from "react-redux";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import omit from "lodash/omit";
import { SelectInput } from "react-admin";
import { getCurrentLang } from "../../redux/selectors";

export const TranslatedSelectInput = compose(
  connect(state => ({
    optionText: getCurrentLang(state).label
  })),
  mapProps(props => omit(props, ["filterData", "dispatch"]))
)(SelectInput);
