import React from "react";
import PropTypes from "prop-types";
import { Edit, TextInput, BooleanInput } from "react-admin";
import { hideableField } from "../fields";
import { Actions } from "../base";
import { formValueSelector } from "redux-form";
import { RestrictedCustomForm } from "../restricted-components";
import config from "../../config";

/* Validation*/

const validateTime = values => {
  const errors = {};
  const re = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

  if (values.is_access && !re.test(values.start)) {
    errors.start = ["The start time MUST be in a 24H format hh:mm"];
  }
  if (values.is_access && !re.test(values.end)) {
    errors.end = ["The end time MUST be in a 24H format hh:mm"];
  }
  return errors;
};

const EditTitle = ({ record }) => {
  return <span>Work Day - {record ? `"${record.day}"` : ""}</span>;
};

EditTitle.propTypes = {
  record: PropTypes.object
};

const selector = formValueSelector("record-form");
const BlockedInput = hideableField(state => selector(state, "is_access"))(
  TextInput
);

/**
 * component for editing work hours of the days
 */
const WorkHoursEdit = props => {
  return (
    <Edit {...props} title={<EditTitle />} actions={<Actions />}>
      <RestrictedCustomForm
        validate={validateTime}
        requiredPermissions={[config.PERMISSIONS.WORK_HOUR_UPDATE]}
        // disabledToolbarTitle={translate("mra.general.readonly_details")}
      >
        <BooleanInput source="is_access" />
        <BlockedInput source="start" />
        <BlockedInput source="end" />
      </RestrictedCustomForm>
    </Edit>
  );
};

export default WorkHoursEdit;
