import React from "react";
import {
  TextField,
  Filter,
  ReferenceField,
  Button,
  TextInput,
  ChipField,
  translate
} from "react-admin";
import P from "prop-types";
import { connect } from "react-redux";
import DownloadIcon from "@material-ui/icons/GetApp";
import { withStyles, Grid } from "@material-ui/core";
import compose from "recompose/compose";
import config from "../../config";
import {
  RestrictedEditButton,
  RestrictedListActions
} from "../restricted-components";
import { Datagrid, SwitchList } from "../base";
import { Images, getFilter } from "../layout";
import { DateTimeField } from "../fields";
import reports from "../../config/reports";
import { getRcContext } from "../../redux/selectors";
import MeterCard from "./MeterCard";
import { ReferenceInput, FieldAgentInput, AutocompleteInput } from "../inputs";
import { makeUserFilter } from "../utils";
import { Link } from "react-router-dom";
import { stringify } from "query-string";

const styles = {
  meterField: {
    width: 256
  }
};

const FakeExportDataButton = props => (
  <Button label="ra.action.export" {...props}>
    <DownloadIcon />
  </Button>
);

/**
 * filters for list component
 */
export const RegisteredMetersFilter = ({
  filter,
  dispatch, // eslint-disable-line no-unused-vars
  classes,
  showRcFilter,
  ...props
}) => (
  <Filter {...props}>
    <FieldAgentInput
      allowEmpty
      source="agent_id"
      filter={makeUserFilter(filter)}
      reference={config.USER_RESOURCE}
      filterToQuery={searchText => ({ username: searchText })}
    >
      <AutocompleteInput optionText="username" />
    </FieldAgentInput>
    {showRcFilter ? (
      <ReferenceInput
        allowEmpty
        source="center_id"
        reference={config.READINGCENTER_RESOURCE}
        filterToQuery={searchText => ({ remote_id: searchText })}
      >
        <AutocompleteInput optionText="remote_id" />
      </ReferenceInput>
    ) : null}
    <ReferenceInput
      allowEmpty
      source="itinerary_remote_id"
      reference={config.ITINERARY_RESOURCE}
      filter={filter}
      filterToQuery={searchText => ({ remote_id: searchText })}
    >
      <AutocompleteInput optionValue="remote_id" optionText="remote_id" />
    </ReferenceInput>
    <TextInput className={classes.meterField} source="meter_ref" alwaysOn />
    <TextInput source="cycle" />
  </Filter>
);
// see MRA-1018 and MRA-1020 we cannot use this filter for now
/*
    <ReferenceInput
      source="reading_center_id"
      reference={config.READINGCENTER_RESOURCE}
      filterToQuery={searchText => ({ name: searchText })}
      allowEmpty
      label={translate(
            `resources.${config.READINGCENTER_RESOURCE}.name`
            , 1)}
    >
      <AutocompleteInput
        options={{ name: "reading center" }}
        optionValue="id"
        optionText="remote_id"
      />
    </ReferenceInput>
*/

RegisteredMetersFilter.propTypes = {
  dispatch: P.func,
  classes: P.object,
  filterValues: P.object,
  filter: P.object,
  showRcFilter: P.bool
};

const ConnectedRegisteredMetersFilter = compose(
  connect(state => ({
    showRcFilter: config.RC_CONTEXT_ALL === getRcContext(state)
  })),
  withStyles(styles)
)(RegisteredMetersFilter);

export const RegisteredMetersList = props => {
  return (
    <Datagrid {...props}>
      <TextField source="meter_ref" />
      <ReferenceField
        source="agent_id"
        reference={config.USER_RESOURCE}
        linkType={false}
        allowEmpty
      >
        <TextField source="username" />
      </ReferenceField>
      <TextField
        allowEmpty
        label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.itinerary_id`}
        source="itinerary.remote_id"
      />
      <TextField source="dials" />
      <ReferenceField
        linkType
        allowEmpty
        sortable={false}
        source="center_id"
        reference={config.READINGCENTER_RESOURCE}
      >
        <ChipField source="remote_id" />
      </ReferenceField>
      <TextField source="value" />
      <TextField source="location_description" />
      <DateTimeField source="registration_date" />
      <Images source="images" type="button" sortable={false} />
      <RestrictedEditButton
        requiredPermissions={[config.PERMISSIONS.REGISTERED_METER_READ]}
      />
    </Datagrid>
  );
};

RegisteredMetersList.propTypes = {
  dispatch: P.func
};

const RegisteredMetersGrid = ({ ids, data, basePath, resource, classes }) => (
  <Grid
    container
    spacing={8}
    justify="center"
    className={classes.switchListContainer}
  >
    {ids.map(id => (
      <Grid item key={id}>
        <MeterCard resource={resource} record={data[id]} basePath={basePath} />
      </Grid>
    ))}
  </Grid>
);

RegisteredMetersGrid.propTypes = {
  ids: P.array,
  resource: P.string,
  data: P.object,
  basePath: P.string,
  classes: P.object
};

const StyledRegisteredMetersList = withStyles({
  switchListContainer: {
    paddingLeft: 24,
    paddingRight: 24
  }
})(RegisteredMetersGrid);

const RegisteredMetersSwitchList = ({
  dispatch, // eslint-disable-line no-unused-vars
  card,
  record,
  ...props
}) => {
  return (
    <SwitchList
      {...props}
      actions={
        <RestrictedListActions
          disableInGlobalContext
          exportButton={
            <FakeExportDataButton
              component={Link}
              to={`/${config.REPORTS_RESOURCE}?${stringify({
                report: reports.new_meters.name
              })}`}
            />
          }
          requiredPermissionsCreateButton={[
            config.PERMISSIONS.REGISTERED_METER_CREATE
          ]}
        />
      }
      resource={config.REGISTERED_METERS_RESOURCE}
      perPage={config.PAGINATION}
      filters={<ConnectedRegisteredMetersFilter filter={props.filter} />}
      gridComponent={<StyledRegisteredMetersList Card={card} record={record} />}
      tableComponent={<RegisteredMetersList />}
    />
  );
};
RegisteredMetersSwitchList.propTypes = {
  card: P.element,
  record: P.object,
  filter: P.object,
  dispatch: P.func
};

RegisteredMetersList.propTypes = {
  filter: P.object,
  translate: P.func
};

const ConnectedRegisteredMetersList = compose(
  connect(state => ({
    filter: getFilter(state)
  })),
  translate
)(RegisteredMetersSwitchList);

export default ConnectedRegisteredMetersList;
