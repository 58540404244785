import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  List,
  TextField,
  Filter,
  TextInput,
  ReferenceField
} from "react-admin";
import config from "../../config";
import {
  RestrictedEditButton,
  RestrictedListActions
} from "../restricted-components";

import { getFilter } from "../layout";
import { Datagrid } from "../base";
/**
 * filters for list component
 */
const ReadingUnitFilter = props => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
);

/**
 * component for listing reading centers
 * omit dispatch prop
 */
// eslint-disable-next-line no-unused-vars
export const ReadingUnitList = ({ dispatch, ...props }) => (
  <List
    {...props}
    actions={
      <RestrictedListActions
        requiredPermissionsCreateButton={[
          config.PERMISSIONS.READING_UNIT_CREATE
        ]}
        denyForCreateButton={[config.PERMISSIONS.RC_MEMBER]}
      />
    }
    filters={<ReadingUnitFilter />}
    sort={{ field: "name", order: "DESC" }}
    perPage={config.PAGINATION}
    bulkActions={false}
  >
    <Datagrid>
      <TextField source="name" />
      <TextField source="ref_number" />
      <TextField source="type" />
      <TextField source="address" />
      <ReferenceField
        source="region_id"
        reference={config.REGION_RESOURCE}
        linkType={false}
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <RestrictedEditButton
        requiredPermissions={[config.PERMISSIONS.READING_UNIT_READ]}
        style={{ minWidth: 0, overflow: "inherit" }}
      />
    </Datagrid>
  </List>
);
ReadingUnitList.propTypes = {
  dispatch: PropTypes.func
};
const ConnectedReadingUnitList = connect(
  state => {
    let filter = getFilter(state);
    //as this is the reading unit list we need to filter by ru with id instead of reading_unit_id
    if (filter.unit_id) {
      filter.id = filter.unit_id;
    }
    if (filter.center_id) {
      filter["@center_id"] = filter.center_id;
    }
    delete filter.center_id;
    delete filter.unit_id;
    delete filter["@units"];
    return { filter };
  },
  null
)(ReadingUnitList);

export default ConnectedReadingUnitList;
