/* eslint-disable no-unused-vars */
import React, { cloneElement } from "react";
import P from "prop-types";
import CardActions from "@material-ui/core/CardActions";
import NavigationRefresh from "@material-ui/icons/Refresh";
import { Button, sanitizeListRestProps } from "react-admin";

export function Actions(props) {
  const {
    className,
    resource,
    filters,
    displayedFilters,
    filterValues,
    showFilter,
    bulkActions,
    permanentFilter,
    refresh,
    ...rest
  } = props;
  return (
    <CardActions
      className={className}
      disableActionSpacing
      {...sanitizeListRestProps(rest)}
    >
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button"
        })}
      <Button
        label={"ra.action.refresh"}
        onClick={e => {
          e.preventDefault();
          refresh();
        }}
      >
        <NavigationRefresh />
      </Button>
    </CardActions>
  );
}
Actions.propTypes = {
  className: P.string,
  resource: P.string,
  filters: P.element,
  displayedFilters: P.object,
  filterValues: P.object,
  basePath: P.string,
  showFilter: P.func,
  bulkActions: P.element,
  permanentFilter: P.object,
  refresh: P.func
};
