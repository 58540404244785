import React from "react";
import PropTypes from "prop-types";
import { RefField } from ".";

export const ReferenceManyField = ({
  allowEmpty,
  record,
  source,
  resource,
  reference,
  referenceRecord,
  basePath,
  linkType,
  children
}) => (
  <div style={{ display: "flex", flexWrap: "wrap" }}>
    {React.Children.map(children, field =>
      field && field.props && field.props.source ? (
        <RefField
          key={field.props.source}
          allowEmpty={allowEmpty}
          basePath={basePath}
          record={record}
          linkType={linkType}
          source={source}
          resource={resource}
          reference={reference}
          referenceRecord={referenceRecord}
        >
          {field}
        </RefField>
      ) : (
        field
      )
    )}
  </div>
);

ReferenceManyField.propTypes = {
  allowEmpty: PropTypes.bool,
  basePath: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  record: PropTypes.object,
  reference: PropTypes.string,
  referenceRecord: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string,
  linkType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default ReferenceManyField;
