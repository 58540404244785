import React, { Children } from "react";
import P from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { sanitizeListRestProps } from "react-admin";
import FilterForm from "./FilterForm";
import FilterButton from "./FilterButton";

const styles = {
  button: {},
  form: {}
};

export function Filter(props) {
  const {
    classes = {},
    context,
    resource,
    children,
    showFilter,
    hideFilter,
    displayedFilters,
    filterValues,
    setFilters,
    ...rest
  } = props;
  return context === "button" ? (
    <FilterButton
      className={classes.button}
      resource={resource}
      filters={Children.toArray(children)}
      showFilter={showFilter}
      displayedFilters={displayedFilters}
      filterValues={filterValues}
      {...sanitizeListRestProps(rest)}
    />
  ) : (
    <FilterForm
      className={classes.form}
      resource={resource}
      filters={Children.toArray(children)}
      hideFilter={hideFilter}
      displayedFilters={displayedFilters}
      initialValues={filterValues}
      setFilters={setFilters}
      {...sanitizeListRestProps(rest)}
    />
  );
}

Filter.propTypes = {
  children: P.node,
  classes: P.object,
  context: P.oneOf(["form", "button"]),
  displayedFilters: P.object,
  filterValues: P.object,
  hideFilter: P.func,
  setFilters: P.func,
  showFilter: P.func,
  resource: P.string.isRequired
};

export default withStyles(styles)(Filter);
