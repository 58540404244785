import { useState, useEffect } from "react";
import { httpClient, toNewResource } from "../../lib/";
import config from "../../config";

export function useItineraryStats(id, resource) {
  const [stats, setStats] = useState({});
  useEffect(() => {
    httpClient(
      `${config.API_BASE_URL}/${toNewResource(resource).resource}/${id}/stat`
    ).then(({ json }) => {
      setStats(json);
    });
  }, [id, resource]);
  return stats;
}
