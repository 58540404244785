import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCurrentUserId } from "../../redux/selectors";
import { Edit, TextInput, SelectInput, DisabledInput } from "react-admin";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import omit from "lodash/omit";
import { SimpleForm } from "../base";
import config from "../../config";
import UserEditActions from "./UserEditActions";
import RoleEdit from "./RoleEdit";

// validate form: ensure password was confirmed
const createValidation = values => {
  const errors = {};
  if (
    values.password &&
    values.confirmPassword &&
    values.password !== values.confirmPassword
  ) {
    errors.confirmPassword = ["The entered passwords must match."];
  }
  return errors;
};

/**
 * component for editing current user profile
 */
export const UserProfile = ({ currentUserId, ...props }) =>
  currentUserId != null
    ? <Edit
        {...props}
        id={currentUserId}
        basePath={config.USER_RESOURCE}
        title="mra.profile.title"
        resource={config.USER_RESOURCE}
        actions={<UserEditActions resource={config.USER_RESOURCE} profile />}
      >
        <SimpleForm validate={createValidation}>
          <DisabledInput source="username" />
          <DisabledInput source="first_name" />
          <DisabledInput source="middle_name" />
          <DisabledInput source="last_name" />
          <TextInput source="phone" />
          <TextInput source="mail" type="email" />
          <SelectInput
            source="language"
            choices={Object.values(config.LOCALE_TYPES)}
          />
          <RoleEdit source="roles" disabled label="mra.roles.selectRoles" />
        </SimpleForm>
      </Edit>
    : null;

UserProfile.propTypes = {
  currentUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export const mapStateToProps = state => ({
  currentUserId: getCurrentUserId(state) ? `${getCurrentUserId(state)}` : null
});

export default compose(
  connect(mapStateToProps, null),
  mapProps(props => omit(props, ["dispatch", "staticContext", "authClient"]))
)(UserProfile);
