import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    backgroundColor: theme.palette.error.main,
    color: "white"
  }
});

const WarningText = ({ text, classes }) =>
  <div className={classes.container}>
    <div
      style={{ height: "auto", verticalAlign: "center", marginRight: "10px" }}
    >
      <WarningIcon style={{ color: "white" }} />
    </div>
    <div>
      {text}
    </div>
  </div>;

WarningText.propTypes = {
  text: PropTypes.string,
  classes: PropTypes.object
};

export default withStyles(styles)(WarningText);
