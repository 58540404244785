import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCurrentLang } from "../../redux/selectors";
import get from "lodash/get";

const TranslatedTextField = ({
  className,
  record = {},
  language,
  type = "label"
}) =>
  <span className={className}>
    {get(record, language[type])}
  </span>;

TranslatedTextField.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
  label: PropTypes.string,
  language: PropTypes.object,
  type: PropTypes.string,
  addLabel: PropTypes.bool
};

TranslatedTextField.defaultProps = {
  addLabel: true
};

const ConnectedTranslatedTextField = connect(state => ({
  language: getCurrentLang(state)
}))(TranslatedTextField);

export default ConnectedTranslatedTextField;
