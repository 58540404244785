import { useState, useEffect, useCallback } from "react";
import { translate, crudGetList } from "react-admin";
import { compose } from "recompose";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { setPage, setPerPage, setSort, setFilters } from "../../redux/actions";
import config from "../../config";

const initState = {
  page: 1,
  perPage: config.PAGINATION,
  sort: null,
  order: null,
  filters: {}
};

function List(props) {
  const {
    id,
    data,
    ids,
    basePath,
    children,
    isLoading,
    loadedOnce,
    resource,
    translate,
    total,
    crudGetList,
    dashboard: { page, perPage, sort: sortField, order: sortOrder, filters },
    defaultFilter,
    filter: permanentFilter,
    sort: defaultSort = {},
    setPage,
    setPerPage,
    setSort,
    setFilters: origSetFilter
  } = props;
  const [version, setViewVersion] = useState(0);
  const refresh = () => setViewVersion(version + 1);
  const setFilters = useCallback(debounce(origSetFilter.bind(null, id), 500), [
    origSetFilter,
    id
  ]);
  const [displayedFilters, setDisplayedFilters] = useState({});
  const showFilter = filterName => {
    setDisplayedFilters({ ...displayedFilters, [filterName]: true });
  };

  const hideFilter = filterName => {
    setDisplayedFilters({ ...displayedFilters, [filterName]: false });
    setFilters({ ...filters, [filterName]: undefined });
  };
  const field = sortField || defaultSort.field;
  const order = sortOrder || defaultSort.order;
  useEffect(() => {
    const pagination = {
      page: parseInt(page, 10),
      perPage: parseInt(perPage, 10)
    };
    const sort = { field, order };
    const filter = {
      ...defaultFilter,
      ...filters,
      ...permanentFilter
    };
    crudGetList(resource, pagination, sort, filter);
  }, [
    crudGetList,
    resource,
    page,
    perPage,
    field,
    order,
    defaultFilter,
    filters,
    permanentFilter,
    version
  ]);

  return children({
    id,
    basePath,
    displayedFilters,
    filterValues: { ...defaultFilter, ...filters },
    showFilter,
    hideFilter,
    currentSort: { field, order },
    data,
    ids,
    isLoading,
    loadedOnce,
    page,
    perPage,
    resource,
    translate,
    total,
    version,
    refresh,
    setPage: setPage.bind(null, id),
    setPerPage: setPerPage.bind(null, id),
    setSort: setSort.bind(null, id),
    setFilters
  });
}
const mapState = (state, props) => {
  const resourceState = state.admin.resources[props.resource];
  return {
    data: resourceState.data,
    ids: resourceState.list.ids,
    total: resourceState.list.total,
    loadedOnce: resourceState.list.loadedOnce,
    dashboard: state.mra.dashboard[props.id] || initState
  };
};
export const ListController = compose(
  connect(
    mapState,
    { crudGetList, setPage, setPerPage, setSort, setFilters }
  ),
  translate
)(List);
