import React from "react";
import P from "prop-types";
import { CardActions, Button, Typography } from "@material-ui/core";

const cardActionStyle = {
  zIndex: 2,
  display: "flex",
  justifyContent: "flex-end"
};

export const Actions = ({ history, translate, data }) => {
  const title = data
    ? `mra.assign_itinerary.${
        data.is_confirmation_required ? "confirm_req_title" : "assign_req_title"
      }`
    : null;
  return (
    <div>
      {data ? (
        <Typography align="center" variant="h6">
          {translate(title)}
        </Typography>
      ) : null}
      <CardActions style={cardActionStyle}>
        <Button color="primary" onClick={() => history.goBack()}>
          {translate("mra.general.back")}
        </Button>
      </CardActions>
    </div>
  );
};

Actions.propTypes = {
  data: P.object,
  history: P.object,
  translate: P.func
};
