import { TextField } from "react-admin";
import withProps from "recompose/withProps";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core";

export const OkNotokField = compose(
  withStyles(theme => ({
    ok: {
      color: theme.palette.primary.main
    },
    nok: {
      color: theme.palette.error.main
    }
  })),
  withProps(({ record, source, classes }) => ({
    className: record[source] ? classes.ok : classes.nok,
    record: {
      ...record,
      [source]: record[source] ? "OK" : "NOT OK"
    }
  }))
)(TextField);
