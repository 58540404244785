import React from "react";
import P from "prop-types";
import { TextField } from "react-admin";

export const CustomerNameField = props => {
  if (!props.record) return null;
  let extraProps = null;
  const { supply_point } = props.record;
  if (supply_point) {
    const { first_name, middle_name, last_name } = supply_point;
    extraProps = {
      record: {
        ...props.record,
        supply_point: {
          ...supply_point,
          customer_name: `${first_name}${middle_name || ""} ${last_name}`
        }
      }
    };
  }
  return <TextField {...props} {...extraProps} />;
};

CustomerNameField.propTypes = {
  record: P.object
};
