import React from "react";
import PropTypes from "prop-types";
import {
  Edit,
  TextInput,
  BooleanInput,
  ReferenceInput,
  LongTextInput,
  DisabledInput,
  ReferenceArrayInput,
  AutocompleteInput,
  SelectArrayInput,
  translate,
  required,
  addField
} from "react-admin";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import omit from "lodash/omit";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { getFilter } from "../layout";
import { hideableField } from "../fields";
import config from "../../config";
import {
  RestrictedCustomForm,
  RestrictedEditActions
} from "../restricted-components";

import { getRoleIdByName } from "../../redux/selectors";
import { makeUserFilter } from "../utils";

const selector = formValueSelector("record-form");
const BlockedCommentInput = hideableField(
  state => selector(state, "is_blocked")
  // eslint-disable-next-line no-unused-vars
)(({ display, ...props }) => <LongTextInput {...props} />);

const EditTitle = props => {
  return (
    <span>
      {props.translate(`resources.${config.DEVICE_RESOURCE}.name`, 2)} -{" "}
      {props.record ? `"${props.record.device_identifier}"` : ""}
    </span>
  );
};

EditTitle.propTypes = {
  translate: PropTypes.func,
  record: PropTypes.object
};

const ConnectedFieldAgentInput = compose(
  connect((state, props) => ({
    filter: {
      ...props.filter,
      "@role_id": [getRoleIdByName(config.ROLES.NAMES.FA, state)]
    }
  })),
  addField
)(ReferenceArrayInput);

function CustomForm(props) {
  return (
    <RestrictedCustomForm
      {...props}
      record={{
        ...props.record,
        users: props.record.users.map(u => String(u.id))
      }}
    />
  );
}

CustomForm.propTypes = {
  record: PropTypes.object
};

/**
 * component for editing device
 */
export const DeviceEdit = ({ filter, translate, ...props }) => {
  const req = required("mra.validation.required");
  return (
    <Edit
      title={<EditTitle translate={translate} />}
      actions={
        <RestrictedEditActions
          requiredPermissionsDeleteButton={[config.PERMISSIONS.DEVICE_DELETE]}
        />
      }
      {...props}
    >
      <CustomForm
        requiredPermissions={[config.PERMISSIONS.DEVICE_UPDATE]}
        disabledToolbarTitle={translate("mra.general.readonly_details")}
      >
        <DisabledInput
          source="device_identifier"
          validate={[req]}
          label={`resources.${config.DEVICE_RESOURCE}.fields.device_identifier`}
        />
        <TextInput
          source="device_serial"
          validate={[req]}
          label={`resources.${config.DEVICE_RESOURCE}.fields.device_serial`}
        />
        <TextInput
          source="model"
          validate={[req]}
          label={`resources.${config.DEVICE_RESOURCE}.fields.model`}
        />
        <TextInput
          source="manufacturer"
          validate={[req]}
          label={`resources.${config.DEVICE_RESOURCE}.fields.manufacturer`}
        />
        <TextInput
          source="app_version"
          validate={[req]}
          label={`resources.${config.DEVICE_RESOURCE}.fields.app_version`}
        />
        <TextInput
          source="android_version"
          validate={[req]}
          label={`resources.${config.DEVICE_RESOURCE}.fields.android_version`}
        />
        <ReferenceInput
          source="center_id"
          reference={config.READINGCENTER_RESOURCE}
          filterToQuery={searchText => ({ remote_id: searchText })}
          filter={filter.center_id ? { id: filter.center_id } : null}
          format={v => (v ? String(v) : v)}
          parse={v => (v ? Number(v) : v)}
          allowEmpty
        >
          <AutocompleteInput
            options={{ name: "reading center" }}
            optionValue="id"
            optionText="remote_id"
          />
        </ReferenceInput>
        <ConnectedFieldAgentInput
          source="users"
          reference={config.USER_RESOURCE}
          filterToQuery={searchText => ({ username: searchText })}
          filter={makeUserFilter(filter)}
          allowEmpty
        >
          <SelectArrayInput optionText="username" />
        </ConnectedFieldAgentInput>
        <TextInput
          source="onb_status"
          validate={[req]}
          label={`resources.${config.DEVICE_RESOURCE}.fields.onb_status`}
        />
        <BooleanInput source="is_blocked" />
        <BlockedCommentInput source="blocked_reason" />
      </CustomForm>
    </Edit>
  );
};

DeviceEdit.propTypes = {
  translate: PropTypes.func,
  filter: PropTypes.object
};

export const ConnectedDeviceEdit = compose(
  connect(
    state => ({
      filter: getFilter(state)
    }),
    null
  ),
  translate,
  mapProps(props => omit(props, ["dispatch"]))
)(DeviceEdit);

export default ConnectedDeviceEdit;
