import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import LockOpen from "@material-ui/icons/LockOpen";
import { connect } from "react-redux";
import {
  List,
  TextField,
  Filter,
  TextInput,
  ReferenceField
} from "react-admin";
import config from "../../config";
import {
  RestrictedFlatButton,
  RestrictedListActions,
  RestrictedEditButton
} from "../restricted-components";
import { getFilter } from "../layout";
import { Datagrid } from "../base";

//label={context.translate("aor.action.activate")}
//label={context.translate("aor.action.edit")}
const CustomButton = props => {
  if (props.record.deleted_at) {
    return (
      <RestrictedFlatButton
        color="primary"
        component={Link}
        to={`/${config.READINGCENTER_RESOURCE}/${props.record.id}/activate`}
        style={{ minWidth: 0, overflow: "inherit" }}
        requiredPermissions={[config.PERMISSIONS.READING_CENTER_DELETE]}
      >
        <LockOpen />
      </RestrictedFlatButton>
    );
  } else {
    return (
      <RestrictedEditButton
        requiredPermissions={[config.PERMISSIONS.READING_CENTER_READ]}
        {...props}
      />
    );
  }
};

CustomButton.contextTypes = {
  translate: PropTypes.func
};

CustomButton.propTypes = {
  record: PropTypes.object
};

/**
 * filters for list component
 */
const ReadingCenterFilter = props => (
  <Filter
    {...props}
    filterValues={props.filterValues ? props.filterValues : {}}
  >
    <TextInput source="name" alwaysOn />
  </Filter>
);

ReadingCenterFilter.propTypes = {
  filterValues: PropTypes.object
};

/**
 * component for listing reading centers
 */
// eslint-disable-next-line no-unused-vars
export const ReadingCenterList = ({ dispatch, ...props }) => (
  <List
    {...props}
    actions={
      <RestrictedListActions
        requiredPermissionsCreateButton={[
          config.PERMISSIONS.READING_CENTER_CREATE
        ]}
      />
    }
    filters={<ReadingCenterFilter />}
    sort={{ field: "name", order: "DESC" }}
    perPage={config.PAGINATION}
  >
    <Datagrid>
      <TextField source="name" />
      <TextField source="remote_id" />
      <TextField source="location" />
      <ReferenceField
        source="region_id"
        reference={config.REGION_RESOURCE}
        linkType={false}
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <CustomButton
        requiredPermissions={[config.PERMISSIONS.READING_CENTER_READ]}
      />
    </Datagrid>
  </List>
);
ReadingCenterList.propTypes = {
  dispatch: PropTypes.func
};

export default connect(
  state => {
    const filter = getFilter(state);
    if (filter.center_id) {
      filter.id = filter.center_id;
      delete filter.center_id;
      delete filter["@center"];
    }
    return {
      filter
    };
  },
  null
)(ReadingCenterList);
