import React from "react";
import PropTypes from "prop-types";
import {
  Show,
  TextField,
  BooleanField,
  translate,
  SimpleShowLayout
} from "react-admin";
import { withStyles } from "@material-ui/core";
import config from "../../config";
import Images from "../layout/Images";
import ReadingTaskShowActions from "./ReadingTaskShowActions";
import {
  AnomalyField,
  ClientActivityField,
  CustomerNameField,
  DateTimeField,
  PrecisionModeField
} from "../fields";
import ConnectionModeField from "../fields/ConnectionModeField";
import SubTitle from "../base/sub-title";

const styles = {
  layoutGridWrapper: {
    display: "flex",
    flexWrap: "wrap"
  },
  inlineField: {
    display: "inline-flex",
    flexBasis: "calc(25% - 40px)",
    marginRight: 40
  },
  override: {
    width: "auto",
    height: "auto"
  }
};

const EditTitle = translate(({ translate, ...props }) => {
  const { record } = props;
  return (
    <span>
      {translate(`resources.${config.READINGTASK_RESOURCE}.name`, 1)} -{" "}
      {props.record && props.record.meter
        ? ` ${translate(
            `resources.${config.METER_RESOURCE}.fields.meter_identifier`
          )}: ${record.meter.meter_identifier}`
        : " "}
      {props.record && props.record.supply_point
        ? ` ${translate(
            `resources.${config.SUPPLYPOINT_RESOURCE}.fields.remote_id`
          )}: ${record.supply_point.remote_id}`
        : " "}
      {props.record && props.record.supply_point
        ? ` ${translate(
            `resources.${config.SUPPLYPOINT_RESOURCE}.fields.premise_identifier`
          )}: ${record.supply_point.premise_identifier}`
        : " "}
    </span>
  );
});

EditTitle.propTypes = {
  record: PropTypes.object
};

/**
 * component for displaying readingtask details
 */
const ReadingTaskDetails = ({ classes, ...props }) => (
  <Show
    {...props}
    actions={<ReadingTaskShowActions history={props.history} />}
    title={<EditTitle />}
  >
    <SimpleShowLayout className={classes.layoutGridWrapper}>
      <TextField
        className={classes.inlineField}
        label={`resources.${config.METER_RESOURCE}.fields.meter_identifier`}
        source="meter.meter_identifier"
      />
      <TextField
        className={classes.inlineField}
        label={`resources.${config.METER_RESOURCE}.fields.remote_id`}
        source="meter.remote_id"
      />
      <TextField
        className={classes.inlineField}
        label={`resources.${config.METER_RESOURCE}.fields.manufacturer`}
        source="meter.manufacturer"
      />
      <TextField
        className={classes.inlineField}
        label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.remote_id`}
        source="supply_point.remote_id"
      />
      <TextField
        className={classes.inlineField}
        label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.premise_identifier`}
        source="supply_point.premise_identifier"
      />
      <TextField
        className={classes.inlineField}
        label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.access`}
        source="supply_point.access"
      />
      <TextField
        className={classes.inlineField}
        label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.access_premise`}
        source="supply_point.access_premise"
      />
      <TextField
        className={classes.inlineField}
        label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.access_meter`}
        source="supply_point.access_meter"
      />
      <CustomerNameField
        addLabel
        className={classes.inlineField}
        label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.customer_name`}
        source="supply_point.customer_name"
      />
      <TextField
        className={classes.inlineField}
        label={`resources.${config.READING_RESOURCE}.fields.reading_value`}
        source="latest_result.value"
      />
      <DateTimeField
        className={classes.inlineField}
        label={`resources.${config.READING_RESOURCE}.fields.date_visited`}
        source="latest_result.date_visited_at"
        addLabel
      />
      <TextField
        className={classes.inlineField}
        label={`resources.${config.READING_RESOURCE}.fields.input_type`}
        source="latest_result.input_type"
      />
      <BooleanField
        className={classes.inlineField}
        label={`resources.${config.READING_RESOURCE}.fields.is_out_of_vicinity`}
        source="latest_result.is_out_of_vicinity"
      />
      <BooleanField
        className={classes.inlineField}
        label={`resources.${config.READING_RESOURCE}.fields.is_out_of_range`}
        source="latest_result.is_out_of_range"
      />
      <AnomalyField
        addLabel
        className={classes.inlineField}
        label="mra.anomaly.reading"
        source="latest_result.anomaly_id"
      />
      <AnomalyField
        addLabel
        linkType={false}
        className={classes.inlineField}
        label="mra.anomaly.picture"
        source="latest_result.picture_anomaly_id"
      />
      <AnomalyField
        addLabel
        className={classes.inlineField}
        label="mra.anomaly.occupancy"
        source="latest_result.occupancy_anomaly_id"
      />
      <ClientActivityField
        addLabel
        className={classes.inlineField}
        label="mra.client_activity.name"
        source="latest_result.client_activity_id"
      />
      <ConnectionModeField
        addLabel
        className={classes.inlineField}
        label={`resources.${config.READINGTASK_RESOURCE}.fields.conn_mode`}
        source="latest_result.conn_mode"
      />
      <TextField
        addLabel
        className={classes.inlineField}
        label={`resources.${config.READINGTASK_RESOURCE}.fields.net_type`}
        source="latest_result.net_type"
      />
      <PrecisionModeField
        addLabel
        className={classes.inlineField}
        label={`resources.${config.READINGTASK_RESOURCE}.fields.gps_precision_mode`}
        source="latest_result.gps_precision_mode"
      />
      <TextField
        className={classes.inlineField}
        label={`resources.${config.READINGTASK_RESOURCE}.fields.comment`}
        source="latest_result.comment"
      />
      <SubTitle source="full-width" title="mra.itinerary_overview.images" />
      <Images
        source="latest_result.images"
        className={classes.override}
        type="grid"
      />
    </SimpleShowLayout>
  </Show>
);

ReadingTaskDetails.propTypes = {
  history: PropTypes.object,
  translate: PropTypes.func,
  classes: PropTypes.object
};

export default withStyles(styles)(ReadingTaskDetails);
