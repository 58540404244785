import {
  call,
  takeEvery,
  put,
  select,
  all,
  race,
  // MRA-874 dont call reset for now
  // eslint-disable-next-line
  take
} from "redux-saga/effects";
import NotFound from "../assets/image-not-found.jpg";

import moment from "moment";
import {
  RESET_ITINERARY,
  // MRA-874 dont call reset for now
  // eslint-disable-next-line
  // MRA-874 dont call reset for now
  // eslint-disable-next-line
  resetItinerarySuccess,
  CONFIRM_DEVICE,
  REOPEN_ITINERARY,
  REOPEN_DISTRIBUTION_ITINERARY,
  GENERATE_ITINERARY,
  updateItinerary,
  SAVE_NEW_SEQUENCE,
  CREATE_READINGS,
  createReading,
  COMPLETE_CAPTURE,
  PRINT_ROUTESHEET_COMPLETE,
  getReadingTasks,
  PUSH_UPLOAD_FILES,
  getAllReadingTasks,
  getAllDistributionTasks,
  FORCE_QC,
  CONFIRM_ITINERARY,
  getQualityRuns,
  getItinerary,
  QUALIFY_IMAGE,
  DOWNLOAD_IMAGE_ATTEMPT,
  startDownload,
  downloadImageSuccess,
  startUndoable,
  stopUndoable,
  BATCH_OPERATION,
  batchOperation,
  ASSIGN_ITINERARY,
  UNASSIGN_ITINERARY
} from "./actions";
import config from "../config";
import {
  crudGetOne,
  refreshView,
  showNotification,
  CRUD_UPDATE_SUCCESS,
  CRUD_GET_ONE_SUCCESS,
  UNDOABLE,
  UNDO,
  COMPLETE
  // CRUD_GET_LIST_SUCCESS,
  // setSidebarVisibility
} from "react-admin";
import { replace, goBack } from "react-router-redux";
import map from "lodash/map";
import filter from "lodash/filter";
import isNil from "lodash/isNil";
import { downloadImage } from "./utils";
import {
  getValueFromRecord,
  getImageServerUrl,
  getSettingValue,
  getUploadFiles,
  mraImages,
  getRecord
} from "./selectors";

const updateReadingCenterSuccess = action =>
  action.type === CRUD_UPDATE_SUCCESS &&
  action.meta &&
  action.meta.resource &&
  action.meta.resource === config.READINGCENTER_RESOURCE;

// const routerLocationChange = action =>
//   action.type === "@@router/LOCATION_CHANGE";

const getItinerarySuccess = action =>
  action.type === CRUD_GET_ONE_SUCCESS &&
  action.meta &&
  action.meta.resource &&
  action.meta.resource === config.ITINERARY_RESOURCE;

const getDistributionItinerarySuccess = action =>
  action.type === CRUD_GET_ONE_SUCCESS &&
  action.meta &&
  action.meta.resource &&
  action.meta.resource === config.DISTRIBUTION_ITINERARY_RESOURCE;

// const getListSuccess = action => action.type === CRUD_GET_LIST_SUCCESS;

function* loadReadingTasks(action) {
  const {
    payload: {
      data: { id }
    }
  } = action;
  yield put(
    getAllReadingTasks(
      { page: "1", perPage: config.PAGINATION },
      { field: "sequence_orig", order: "ASC" },
      { itinerary_id: id }
    )
  );
}

function* loadDistributionTasks(action) {
  const {
    payload: {
      data: { id }
    }
  } = action;
  yield put(
    getAllDistributionTasks(
      { page: "1", perPage: config.PAGINATION },
      { field: "sequence_orig", order: "ASC" },
      { itinerary_id: id }
    )
  );
}

const performForceQC = httpClient => {
  return function* callForceQC(action) {
    const { id } = action.payload;
    try {
      yield call(
        httpClient,
        `${config.API_BASE_URL}${config.FORCE_QC_URL.replace(":id", id)}`,
        { method: "PATCH" }
      );
      yield put(getQualityRuns(id));
      //We have to fetch the itinerary again to get the current is_in_quality flag of the itinerary
      yield put(getItinerary(id));
      yield put(showNotification("mra.notifications.force_qc"));
    } catch (error) {
      yield put(showNotification("mra.notifications.force_qc_error"));
    }
  };
};

const confirmItinerary = httpClient => {
  return function*(action) {
    const { id } = action.payload;
    try {
      yield call(
        httpClient,
        `${config.API_BASE_URL}${config.ITINERARY_CONFIRM.replace(":id", id)}`,
        { method: "PATCH" }
      );
    } catch (error) {
      yield put(showNotification(error.body.error, "warning"));
    }
  };
};

const performReset = httpClient => {
  return function* callReset(action) {
    const { id } = action.payload;
    try {
      yield call(
        httpClient,
        `${config.API_BASE_URL}${config.ITINERARY_RESET_URL.replace(
          ":id",
          id
        )}`,
        { method: "PATCH" }
      );
      yield put(resetItinerarySuccess(id));
    } catch (error) {
      yield put(showNotification("mra.notifications.api_error"));
    }
  };
};

const performPrintRoutesheet = httpClient => {
  return function* callReset(action) {
    const { id } = action.payload;
    try {
      yield call(
        httpClient,
        `${config.API_BASE_URL}${config.ITINERARY_UPDATE_URL.replace(
          ":id",
          id
        )}`,
        {
          method: "PATCH",
          body: JSON.stringify({ is_printed: true })
        }
      );
    } catch (error) {
      yield put(showNotification("mra.notifications.api_error"));
    }
  };
};

const performSetThresholds = httpClient => {
  return function* callSetThreshold(action) {
    const { id } = action.payload.data;
    const thresholds = yield select(state => state.mra.thresholds[id]);
    const effects = map(thresholds, (threshold, qId) => {
      try {
        return call(
          httpClient,
          `${
            config.API_BASE_URL
          }${config.READING_CENTER_QUALITY_RULE_PATH.replace(":id", id).replace(
            ":qId",
            qId
          )}`,
          {
            method: "PATCH",
            body: JSON.stringify({ threshold: Number(threshold) })
          }
        );
      } catch (error) {
        return put(showNotification("mra.notifications.api_error"));
      }
    });
    yield effects;
  };
};

const performConfirm = httpClient => {
  return function* callConfirm(action) {
    const { id, onb_code } = action.payload;
    try {
      yield call(
        httpClient,
        `${config.API_BASE_URL}${config.CONFIRM_DEVICE_URL.replace(":id", id)}`,
        { method: "PATCH", body: JSON.stringify({ onb_code }) }
      );
    } catch (error) {
      yield put(showNotification(error.message));
    }
    yield put(replace(`/${config.DEVICE_RESOURCE}`));
  };
};

const performResequence = httpClient => {
  return function* callResequence(action) {
    const { id, newSequence } = action.payload;
    const sequence = map(newSequence, (seq, task_id) => ({
      task_id: Number(task_id),
      seq: Number(seq)
    }));
    try {
      yield call(
        httpClient,
        `${config.API_BASE_URL}${config.RESEQUENCE_ITINERARY_URL.replace(
          ":id",
          id
        )}`,
        { method: "POST", body: JSON.stringify({ items: sequence }) }
      );
    } catch (error) {
      yield put(showNotification("mra.notifications.api_error"));
    }
    yield put(replace(`/${config.ITINERARY_RESOURCE}`));
  };
};

function performBatchOperation(httpClient) {
  return function*({ payload: { type, ids, optionsMap = {} } }) {
    const formattedIds = typeof ids === "string" ? ids.split(",") : ids;
    try {
      yield call(httpClient, `${config.API_BASE_URL}/batch`, {
        method: "POST",
        body: JSON.stringify(
          formattedIds.map(id => ({
            name: type,
            id: Number(id),
            payload: optionsMap[id]
          }))
        )
      });
      yield put(refreshView());
    } catch (e) {
      let notificationMessage = "mra.notifications.generation_error";
      switch (type) {
        case "force_export":
          notificationMessage = "mra.notifications.force_qc_error";
          break;
        case "return_for_estimation":
          notificationMessage = "mra.notifications.return_for_estimation_error";
          break;
        default:
          notificationMessage = "mra.notifications.generation_error";
          break;
      }
      yield put(showNotification(notificationMessage, "warning"));
    }
  };
}

function* performCreateReadings(action) {
  const {
    payload: {
      readings,
      itinerary: { id, status, reader_id }
    }
  } = action;
  const readingEffects = filter(
    map(readings, (data, task_id) =>
      (data.value || data.has_no_value) && data.date_visited_at
        ? put(
            createReading({
              ...data,
              date_visited_at: moment(data.date_visited_at).format(),
              itinerary_id: id,
              task_id,
              value: parseFloat(data.value),
              method: config.METHOD_TYPES[1].id,
              input_type: config.INPUT_TYPES[0].id,
              has_no_value: false,
              status: "SUCCESS",
              agent_id: reader_id
            })
          )
        : null
    ),
    data => !!data
  );

  if (readingEffects.length > 0) {
    yield all(readingEffects);
    if (status === config.ITINERARY_STATUS_TYPES.PAPER_READING_READY.id) {
      yield put(
        updateItinerary(id, {
          status:
            config.ITINERARY_STATUS_TYPES.PAPER_READING_DATA_BEING_CAPTURED.id
        })
      );
    }
    yield put(replace(`/${config.ITINERARY_RESOURCE}`));
    /**
     * update the ReadingTasks which got new values
     * without the update the old values would still be shown in the datacapture screen
     * even tough the new values are saved...
     * without this a reload would be required.
     */
    const readingTaskIds = Object.keys(readings);
    yield put(getReadingTasks(readingTaskIds));
  }
}

const performReopen = httpClient => {
  return function* reopen(action) {
    const {
      payload: { id }
    } = action;
    try {
      yield call(
        httpClient,
        `${config.API_BASE_URL}${config.REOPEN_ITINERARY_URL.replace(
          ":id",
          id
        )}`,
        { method: "PATCH" }
      );
    } catch (error) {
      yield put(showNotification(`${error.message}.reading`, "warning"));
    }
    yield put(goBack());
  };
};

const performReopenDistribution = httpClient => {
  return function* reopenDistribution(action) {
    const {
      payload: { id }
    } = action;
    try {
      yield call(
        httpClient,
        `${
          config.API_BASE_URL
        }${config.REOPEN_DISTRIBUTION_ITINERARY_URL.replace(":id", id)}`,
        { method: "POST" }
      );
    } catch (error) {
      yield put(showNotification(`${error.message}.distribution`, "warning"));
    }
    yield put(replace(`/${config.DISTRIBUTION_ITINERARY_RESOURCE}`));
  };
};

function* performCompleteCapture(action) {
  const {
    payload: { id }
  } = action;
  const status = config.ITINERARY_STATUS_TYPES.PAPER_READING_FULLY_ENTERED.id;
  yield put(updateItinerary(id, { status }));
  yield put(replace(`/${config.ITINERARY_RESOURCE}`));
}

// function* performLocationChange() {
//   //yield put(resetForm());

//   /**
//     * Toggle sidebar if something is clicked.
//     * only do this in the production version as it is annoying during development
//     */
//   if (process.env.NODE_ENV !== "development") {
//     yield put(setSidebarVisibility(false));
//   }
// }

const performUploadFiles = httpClient => {
  return function* uploadFile(action) {
    const { id, resource, source } = action.payload;
    const fileObj = yield select(getUploadFiles(resource, id, source));
    if (fileObj) {
      if (fileObj.type === "routesheet") {
        for (const key in fileObj.files) {
          const file = fileObj.files[key];
          const data = new FormData();
          data.append("file", file.rawFile);
          let options = {
            method: "PATCH",
            body: data
          };
          try {
            yield call(
              httpClient,
              `${config.API_BASE_URL}/${config.ITINERARY}/${id}/uploadScan`,
              options
            );
            yield put(showNotification("mra.upload.routesheet.success"));
          } catch (error) {
            yield put(showNotification("mra.upload.routesheet.fail"));
          }
        }
      } else {
        yield put(showNotification("mra.upload.unknownUploadType"));
      }
    } else {
      yield put(showNotification("mra.upload.invalidFile"));
    }
    yield put(replace(`${config.DATA_CAPTURE_PATH.replace(":id", id)}`));
  };
};

const performImageQaulify = httpClient => {
  return function* qualifyImage(action) {
    const { images, payload, record, resource, basePath } = action.payload;
    const { id, metadata, rmsImageId } = record;
    const isRMS = metadata && metadata.image_id;
    const imageIds = isRMS ? [metadata.image_id] : images.map(i => i.id);
    const errors = [];
    for (const imageId of imageIds) {
      const url = `${config.API_BASE_URL}${config.QUALIFY_URL.replace(
        ":id",
        imageId
      )}`;
      try {
        yield call(httpClient, url, {
          method: "PATCH",
          body: JSON.stringify(payload)
        });
      } catch (exc) {
        errors.push(exc);
      }
    }
    if (errors.length) {
      yield put(showNotification("mra.notifications.access_denied"));
    }
    const getResource = isRMS ? config.RMS_RESOURCE : resource;
    const resourceId = isRMS ? rmsImageId : id;
    yield put(crudGetOne(getResource, resourceId, basePath));
  };
};

function getImagePathname(image) {
  const path =
    image.metadata && image.metadata.path ? image.metadata.path : image.path;
  const replaced = path ? path.replace(/^upload\/?/, "") : path;
  return replaced ? `${replaced}/${image.filename}` : image.filename;
}

function* requestImage(action) {
  const {
    payload: { image }
  } = action;
  try {
    if (!image || !image.id || !image.filename) return;
    const images = yield select(mraImages);
    if (!isNil(images[image.id])) return;

    let imageUrl;
    if (image.metadata) {
      const RMS_URL = yield select(getSettingValue("RMS_URL"));
      imageUrl = new URL(
        `api/v1/resource/${image.rmsImageId || image.id}/file`,
        RMS_URL
      );
    } else {
      let imageServerUrl;
      if (!isNil(image.target)) {
        imageServerUrl = yield select(getSettingValue(image.target));
      }
      if (isNil(imageServerUrl)) {
        imageServerUrl = yield select(getImageServerUrl);
      }
      imageUrl = new URL(getImagePathname(image), imageServerUrl);
    }
    const username = yield select(
      getSettingValue(config.IMAGE_SERVER_USERNAME)
    );
    const password = yield select(
      getSettingValue(config.IMAGE_SERVER_PASSWORD)
    );
    const auth =
      process.env.NODE_ENV === "production"
        ? { username, password }
        : { username: "foo", password: "bar" };
    yield put(startDownload(image));
    const imageBlob = yield call(downloadImage, imageUrl, auth);
    if (imageBlob) {
      yield put(
        downloadImageSuccess(image, imageBlob, URL.createObjectURL(imageBlob))
      );
    }
  } catch (e) {
    yield put(downloadImageSuccess(image, null, NotFound));
  }
}
function* performItineraryGeneration({ payload: { kind, ids, agent_id } }) {
  const { ITINERARY_RESOURCE } = config;
  const isAnomalyCorrection = kind === "anomaly_correction";
  const batchPayload = {};
  if (typeof ids === "string") {
    batchPayload[ids] = { kind, agent_id: Number(agent_id) || 0 };
  } else if (Array.isArray(ids)) {
    if (isAnomalyCorrection) {
      for (let id of ids) {
        batchPayload[id] = {
          kind,
          agent_id:
            Number(
              yield select(
                getValueFromRecord(ITINERARY_RESOURCE, id, "agent_id")
              )
            ) || 0
        };
      }
    } else {
      for (let id of ids) {
        batchPayload[id] = { kind, agent_id: 0 };
      }
    }
  }
  yield put(
    batchOperation("generate_itinerary", batchPayload, {
      redirectTo: `/${ITINERARY_RESOURCE}`
    })(ids)
  );
}

function* handleUndo({ payload: { action } }) {
  yield put(startUndoable());
  const { complete } = yield race({
    undo: take(UNDO),
    complete: take(COMPLETE)
  });
  if (complete) {
    yield take([`${action.type}_SUCCESS`, `${action.type}_FAILURE`]);
  }
  yield put(stopUndoable());
}

function performAssignment(httpClient) {
  return function*(action) {
    const {
      id,
      resource,
      method,
      agent_id,
      capturer_id,
      basePath
    } = action.payload;
    const itinerary = yield select(getRecord(resource, id));

    const getUrl = (itinerary, method, agent_id, capturer_id) => {
      const currentMethod = itinerary.method.trim();
      const canBeUpdate =
        itinerary.status !== config.ITINERARY_STATUS_TYPES.UNASSIGNED;
      switch (true) {
        case canBeUpdate &&
          currentMethod !== method &&
          (agent_id !== itinerary.agent_id ||
            itinerary.capturer_id !== capturer_id):
          return config.ASSIGN_URL.replace(":new_method", method)
            .replace(
              ":capturer_id",
              method === config.ASSIGN_METHODS.APP ? 0 : capturer_id
            )
            .replace(":agent_id", agent_id);
        case canBeUpdate && currentMethod !== method:
          // just swap method
          return config.METHOD_ASSIGN.replace(":new_method", method);
        case method === config.ASSIGN_METHODS.PAPER:
          return config.PAPER_ASSIGN.replace(
            ":capturer_id",
            capturer_id
          ).replace(":agent_id", agent_id);
        case method === config.ASSIGN_METHODS.APP:
          return config.AGENT_ASSIGN.replace(":agent_id", agent_id);
        default:
          throw new Error("wrong assigment method");
      }
    };

    try {
      let url = getUrl(itinerary, method, agent_id, capturer_id).replace(
        ":id",
        id
      );
      yield call(httpClient, `${config.API_BASE_URL}${url}`, {
        method: "PATCH"
      });
      yield put(replace(basePath));
    } catch (e) {
      yield put(showNotification(e.message, "warning"));
    }
  };
}

function performUnassignment(httpClient) {
  return function*(action) {
    const {
      payload: { id, options: { redirectTo } = {} }
    } = action;
    try {
      yield call(
        httpClient,
        `${config.API_BASE_URL}${config.UNASSIGN_URL.replace(":id", id)}`,
        { method: "PATCH" }
      );
      yield put(replace(redirectTo));
    } catch (error) {
      yield put(showNotification(`${error.message}.distribution`, "warning"));
    }
  };
}

export default httpClient =>
  function* apiSaga() {
    yield all([
      takeEvery(RESET_ITINERARY, performReset(httpClient)),
      takeEvery(CONFIRM_DEVICE, performConfirm(httpClient)),
      takeEvery(REOPEN_ITINERARY, performReopen(httpClient)),
      takeEvery(BATCH_OPERATION, performBatchOperation(httpClient)),
      takeEvery(
        REOPEN_DISTRIBUTION_ITINERARY,
        performReopenDistribution(httpClient)
      ),
      takeEvery(SAVE_NEW_SEQUENCE, performResequence(httpClient)),
      takeEvery(CREATE_READINGS, performCreateReadings),
      takeEvery(COMPLETE_CAPTURE, performCompleteCapture),
      takeEvery(updateReadingCenterSuccess, performSetThresholds(httpClient)),
      // takeEvery(routerLocationChange, performLocationChange),
      takeEvery(PRINT_ROUTESHEET_COMPLETE, performPrintRoutesheet(httpClient)),
      takeEvery(PUSH_UPLOAD_FILES, performUploadFiles(httpClient)),
      takeEvery(FORCE_QC, performForceQC(httpClient)),
      takeEvery(getItinerarySuccess, loadReadingTasks),
      takeEvery(CONFIRM_ITINERARY, confirmItinerary(httpClient)),
      takeEvery(getDistributionItinerarySuccess, loadDistributionTasks),
      takeEvery(QUALIFY_IMAGE, performImageQaulify(httpClient)),
      takeEvery(DOWNLOAD_IMAGE_ATTEMPT, requestImage),
      takeEvery(GENERATE_ITINERARY, performItineraryGeneration),
      takeEvery(UNDOABLE, handleUndo),
      takeEvery(ASSIGN_ITINERARY, performAssignment(httpClient)),
      takeEvery(UNASSIGN_ITINERARY, performUnassignment(httpClient))
    ]);
  };
