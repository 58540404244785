import React, { Children, cloneElement } from "react";
import { Pagination, ListToolbar } from "react-admin";
import { withStyles, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { ListController } from "./ListController";
import { Actions } from "./Actions";

const styles = createStyles({
  root: {
    display: "flex"
  },
  card: {
    position: "relative",
    flex: "1 1 auto"
  },
  header: {
    paddingBottom: 0
  },
  actions: {
    zIndex: 2,
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap"
  },
  noResults: { padding: 20 },
  toolbar: {
    minHeight: "48px"
  }
});

const ListView = withStyles(styles)(
  ({
    title,
    translate,
    children,
    classes,
    defaultFilter, // eslint-disable-line no-unused-vars
    filters,
    actions,
    refresh,
    ...props
  }) => {
    const { root, card, header } = classes;
    return (
      <div className={root}>
        <Card className={card}>
          <CardHeader
            className={header}
            title={title || translate(`resources.${props.resource}.name`, 1)}
          />
          {(filters || actions) && (
            <ListToolbar
              filters={filters}
              actions={actions}
              refresh={refresh}
              {...props}
            />
          )}
          <div key={props.version}>
            {cloneElement(Children.only(children), props)}
            <Pagination {...props} />
          </div>
        </Card>
      </div>
    );
  }
);

export const List = props => (
  <ListController {...props}>
    {controllerProps => <ListView {...props} {...controllerProps} />}
  </ListController>
);

List.defaultProps = {
  actions: <Actions />
};
