import React from "react";
import P from "prop-types";
import { CardActions, withStyles } from "@material-ui/core";
import { ListButton, translate } from "react-admin";
import { Link } from "react-router-dom";
import { stringify } from "query-string";
import { connect } from "react-redux";
import isNil from "lodash/isNil";
import some from "lodash/some";
import config from "../../config";
import { forceQC } from "../../redux/actions";
import {
  compose,
  branch,
  renderNothing,
  defaultProps,
  setDisplayName
} from "recompose";
import { RestrictedFlatButton } from "../restricted-components";
import {
  itineraryIsCompleteOrExported,
  itineraryIsCompleteAndNotExported
} from "../utils";
import { LabeledMapButton } from "../base/buttons";

const { CHILD_FLAGS } = config;

const styles = {
  cardAction: {
    zIndex: 2,
    display: "flex"
  },
  actionGroup: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end"
  },
  info: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#00bcd4",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "1.4em",
    textTransform: "uppercase",
    color: "#fff",
    padding: "10px 0"
  }
};

const HideableFlatButton = compose(
  setDisplayName("HideableFlatButton"),
  defaultProps({ display: true }),
  branch(props => !props.display, renderNothing)
)(RestrictedFlatButton);

export const ItineraryShowActions = props => {
  const {
    basePath,
    data,
    resource,
    showReadingButton,
    forceQC,
    translate,
    classes
  } = props;

  const {
    id,
    child_flags,
    completion_confirmed_at,
    status,
    is_completion_confirmation_required,
    is_force_exported,
    exported_at,
    task_count
  } = data || {};
  const isGenerationDisabled =
    [
      config.ITINERARY_STATUS_TYPES.UNASSIGNED.id,
      config.ITINERARY_STATUS_TYPES.READY.id
    ].indexOf(status) !== -1;
  const isGenerationControlReadingDisabled = some(
    [
      isGenerationDisabled,
      Boolean(child_flags & CHILD_FLAGS.CONTROL_READING),
      !task_count
    ],
    Boolean
  );
  const isGenerationAnomalyCorrectionDisabled = some(
    [
      isGenerationDisabled,
      Boolean(child_flags & CHILD_FLAGS.ANOMALY_CORRECTION),
      !task_count
    ],
    Boolean
  );
  const isCompleteConfirmationDisabled =
    is_force_exported ||
    status === config.ITINERARY_STATUS_TYPES.RETURN_FOR_ESTIMATION.id;
  return (
    <div>
      <CardActions className={classes.cardAction}>
        {!completion_confirmed_at ? (
          <RestrictedFlatButton
            color="primary"
            component={Link}
            style={{ overflow: "inherit" }}
            requiredPermissions={[
              config.PERMISSIONS.READING_ITINERARY_RETURN_FOR_ESTIMATION
            ]}
            name="return_for_estimation"
            to={
              resource && !basePath.includes(resource)
                ? `${basePath}/${resource}/${id}/return`
                : `${basePath}/${id}/return`
            }
          >
            {translate("mra.itinerary_overview.return")}
          </RestrictedFlatButton>
        ) : null}
        {is_completion_confirmation_required ? (
          <RestrictedFlatButton
            color="primary"
            component={Link}
            to={`/${resource}/${id}/completion_confirm`}
            style={{ minWidth: 0, overflow: "inherit" }}
            disabled={isCompleteConfirmationDisabled}
            requiredPermissions={[config.PERMISSIONS.DEVICE_UPDATE]}
          >
            {translate("mra.itinerary_overview.confirm_as_complete")}
          </RestrictedFlatButton>
        ) : null}
        <div className={classes.actionGroup}>
          {!completion_confirmed_at ? (
            <div>
              <HideableFlatButton
                color="primary"
                component={Link}
                style={{ overflow: "inherit" }}
                disabled={isGenerationControlReadingDisabled}
                requiredPermissions={[config.PERMISSIONS.ITINERARY_CREATE]}
                to={`/${config.ITINERARY_RESOURCE}/${id}/generate_control_reading`}
              >
                {translate("mra.itinerary_overview.generate_control_reading")}
              </HideableFlatButton>
              <HideableFlatButton
                color="primary"
                component={Link}
                style={{ overflow: "inherit" }}
                disabled={isGenerationAnomalyCorrectionDisabled}
                requiredPermissions={[config.PERMISSIONS.ITINERARY_CREATE]}
                to={`/${config.ITINERARY_RESOURCE}/${id}/generate_anomaly_correction`}
              >
                {translate(
                  "mra.itinerary_overview.generate_anomaly_correction"
                )}
              </HideableFlatButton>
              <HideableFlatButton
                color="primary"
                component={Link}
                style={{ overflow: "inherit" }}
                display={showReadingButton}
                to={`/${config.READINGTASK_RESOURCE}?${stringify({
                  filter: JSON.stringify({ itinerary_id: id }),
                  order: "ASC",
                  page: 1,
                  perPage: config.PAGINATION,
                  sort: "id"
                })}`}
              >
                {translate("mra.itinerary_overview.readings")}
              </HideableFlatButton>
              <HideableFlatButton
                color="primary"
                component={Link}
                style={{ overflow: "inherit" }}
                disableInGlobalContext={true}
                requiredPermissions={[
                  config.PERMISSIONS.REGISTERED_METER_CREATE
                ]}
                name="register_new_meter"
                display={!itineraryIsCompleteOrExported(data)}
                to={`/${config.REGISTERED_METERS_RESOURCE}/create?itinerary=${id}`}
              >
                {translate("mra.itinerary_overview.newmeter")}
              </HideableFlatButton>
              <RestrictedFlatButton
                color="primary"
                onClick={() => forceQC(id)}
                style={{ overflow: "inherit" }}
                requiredPermissions={[
                  config.PERMISSIONS.READING_ITINERARY_FORCE_QC
                ]}
                name="force_qc"
              >
                {translate("mra.itinerary_overview.force_qc")}
              </RestrictedFlatButton>
              <RestrictedFlatButton
                color="primary"
                style={{ overflow: "inherit" }}
                component={Link}
                disabled={!isNil(exported_at)}
                requiredPermissions={[
                  config.PERMISSIONS.READING_ITINERARY_FORCE_EXPORT
                ]}
                name="force_export"
                to={
                  resource && !basePath.includes(resource)
                    ? `${basePath}/${resource}/${id}/force_export`
                    : `${basePath}/${id}/force_export`
                }
              >
                {translate("mra.itinerary_overview.forceexport")}
              </RestrictedFlatButton>
              <HideableFlatButton
                color="primary"
                component={Link}
                style={{ overflow: "inherit" }}
                requiredPermissions={[
                  config.PERMISSIONS.READING_ITINERARY_REOPEN
                ]}
                name="reopen"
                display={itineraryIsCompleteAndNotExported(data)}
                to={
                  resource && !basePath.includes(resource)
                    ? `${basePath}/${resource}/${id}/reopen`
                    : `${basePath}/${id}/reopen`
                }
              >
                {translate("mra.itinerary_overview.reopen")}
              </HideableFlatButton>
            </div>
          ) : null}
          <LabeledMapButton
            record={data}
            filtrationKey="itinerary_id"
            mapResource={config.LOCATION}
          />
          <ListButton basePath={basePath} />
        </div>
      </CardActions>
      {completion_confirmed_at ? (
        <div className={classes.info}>{translate("mra.confirm.info")} </div>
      ) : null}
    </div>
  );
};

ItineraryShowActions.propTypes = {
  basePath: P.string,
  data: P.object,
  resource: P.string,
  translate: P.func,
  showReadingButton: P.bool,
  forceQC: P.func,
  classes: P.object
};

ItineraryShowActions.defaultProps = {
  showReadingButton: false
};

export default compose(
  connect(
    null,
    { forceQC }
  ),
  translate,
  withStyles(styles)
)(ItineraryShowActions);
