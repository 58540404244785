export default {
  itinerary: {
    title: "mra.reports.itinerary.title",
    name: "itinerary",
    shortid: "R002ReadingItinerary",
    permission: "nav_reports_r1002_reading_itinerary"
  },
  supply_points: {
    title: "mra.reports.supply_points.title",
    name: "supply_points",
    shortid: "R001SupplyPoints",
    permission: "nav_reports_r1001_supply_point"
  },
  distribution_gps_comparison: {
    title: "mra.reports.distribution_gps_comparison.title",
    name: "distribution_gps_comparison",
    shortid: "R003DistGpsDiff",
    permission: "nav_reports_r1003_dist_gps_diff"
  },
  reading_gps_comparison: {
    title: "mra.reports.reading_gps_comparison.title",
    name: "reading_gps_comparison",
    shortid: "R005ReadingGPSDiff",
    permission: "nav_reports_r1005_reading_gps_diff"
  },
  device: {
    title: "mra.reports.device.title",
    name: "device",
    shortid: "R006DeviceList",
    permission: "nav_reports_r1006_device_list"
  },
  user_connection: {
    title: "mra.reports.user_connection.title",
    name: "user_connection",
    shortid: "R007UserLoginOut",
    permission: "nav_reports_r1007_user_connect"
  },
  new_meters: {
    title: "mra.reports.new_meters.title",
    name: "new_meters",
    shortid: "R008NewMeters",
    permission: "nav_reports_r1008_new_meters"
  },
  picture_control_rc: {
    title: "mra.reports.picture_control_rc.title",
    name: "picture_control_rc",
    shortid: "R009PictureControlRC",
    permission: "nav_reports_r1009_picture_control_rc"
  },
  picture_control_ru: {
    title: "mra.reports.picture_control_ru.title",
    name: "picture_control_ru",
    shortid: "R010PictureControlRU",
    permission: "nav_reports_r10010_picture_control_ru"
  },
  anomaly_correction: {
    title: "mra.reports.anomaly_correction.title",
    name: "anomaly_correction",
    shortid: "R011AnomalyCorrection",
    permission: "nav_reports_r10011_anomaly_correction"
  },
  control_reading: {
    title: "mra.reports.control_reading.title",
    name: "control_reading",
    shortid: "R012ControlReading",
    permission: "nav_reports_r10012_control_reading"
  },
  import_recon: {
    title: "mra.reports.import_recon.title",
    name: "import_recon",
    shortid: "R013ImportRecon",
    permission: "nav_reports_r10013_import_recon"
  },
  zero_consumption: {
    title: "mra.reports.zero_consumption.title",
    name: "zero_consumption",
    shortid: "R010ZeroConsumption",
    permission: "nav_reports_r1010_zero_consumption"
  }
};
