/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import {
  Edit,
  TextInput,
  BooleanInput,
  LongTextInput,
  SelectInput,
  AutocompleteInput,
  ReferenceArrayField,
  SingleFieldList,
  DisabledInput,
  SelectArrayInput,
  translate,
  required
} from "react-admin";
import compose from "recompose/compose";
import { formValueSelector } from "redux-form";
import { ToolTipChipField } from "../layout";
import config from "../../config";
import UserEditActions, {
  checkPermissionsAndAdminUser,
  checkNotEditSelf
} from "./UserEditActions";
import { hideableField } from "../fields";
import { CustomForm } from "../restricted-components/RestrictedCustomForm";
import {
  getCurrentUserRUContext,
  getCurrentUserRU,
  getRCsofRU,
  getRecordFormValues,
  getRcContext,
  checkPermissionByName
} from "../../redux/selectors";
import {
  ConnectedReadingUnitDisplay,
  HideableRCSelectInput,
  ReadingUnitInput,
  ReadingCenterInput,
  checkRoleContext
} from "./specialRURCComponents";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";

const selector = formValueSelector("record-form");
const RestrictedBooleanInput = hideableField(checkNotEditSelf)(BooleanInput);
const BlockedCommentInput = hideableField(
  (state, props) =>
    selector(state, "is_blocked") && checkNotEditSelf(state, props)
)(LongTextInput);

export const RestrictedCustomForm = connect((state, props) => {
  return {
    disabled: !checkPermissionsAndAdminUser(state, {
      ...props,
      data: props.record
    })
  };
})(CustomForm);

const EditTitle = translate(({ translate, record }) => (
  <span>
    {translate(`resources.${config.USER_RESOURCE}.name`, 2)} -{" "}
    {record ? `"${record.username}"` : ""}
  </span>
));

EditTitle.propTypes = {
  record: PropTypes.object
};

/**
 * component for editing users
 */
export class UserEdit extends React.Component {
  // validate form
  createValidation = values => {
    const { checkRoleContext, RCsofRU, ruId, translate } = this.props;
    const errors = {};
    if (
      checkRoleContext(config.ROLES.NAMES.RU) &&
      !(values.reading_unit_id && values.reading_unit_id.trim().length)
    ) {
      errors.reading_unit_id = [translate("mra.reset.reading_unit_id")];
    }
    if (
      checkRoleContext(config.ROLES.NAMES.RU) &&
      checkRoleContext(config.ROLES.NAMES.FA)
    ) {
      if (!(values.reading_centers && values.reading_centers.length)) {
        errors.reading_centers = [translate("mra.reset.reading_centers")];
        errors.roles = [translate("mra.reset.rolesNoRU")];
        errors.reading_unit_id = [translate("mra.reset.rolesNoRU")];
      } else {
        const rcs = RCsofRU(ruId);
        if (rcs) {
          const rc = Object.values(rcs).find(rc =>
            isEqual(rc.id, values.reading_centers[0])
          );
          if (!rc) {
            errors.reading_centers = [
              translate("mra.reset.reading_centers_valid")
            ];
          }
        }
      }
    }
    if (
      checkRoleContext(config.ROLES.NAMES.RC) &&
      !(values.reading_centers && values.reading_centers.length)
    ) {
      errors.reading_centers = [translate("mra.reset.reading_centers")];
    }
    return errors;
  };

  componentDidUpdate() {
    const { ru, ruContext, crudGetOne, basePath } = this.props;
    if (ruContext && !ru) {
      crudGetOne(config.READINGUNIT_RESOURCE, ruContext, basePath);
    }
  }

  render() {
    const {
      checkRoleContext,
      disableAdminInput,
      ruContext,
      rcContext,
      editNames,
      ru,
      translate,
      RCsofRU,
      ruId,
      dispatch,
      crudGetOne,
      ...props
    } = this.props;
    return (
      <Edit {...props} title={<EditTitle />} actions={<UserEditActions />}>
        <RestrictedCustomForm
          validate={this.createValidation}
          requiredPermissions={[config.PERMISSIONS.USER_UPDATE]}
          disabledToolbarTitle={translate("mra.general.readonly_details")}
        >
          {editNames
            ? [
                <TextInput
                  source="username"
                  key="1"
                  validate={required("mra.reset.username")}
                />,
                <TextInput
                  source="first_name"
                  key="2"
                  validate={required("mra.reset.first_name")}
                />,
                <TextInput source="middle_name" key="3" />,
                <TextInput
                  source="last_name"
                  key="4"
                  validate={required("mra.reset.last_name")}
                />
              ]
            : [
                <DisabledInput
                  source="username"
                  key="1"
                  validate={required("mra.reset.username")}
                />,
                <DisabledInput
                  source="first_name"
                  key="2"
                  validate={required("mra.reset.first_name")}
                />,
                <DisabledInput source="middle_name" key="3" />,
                <DisabledInput
                  source="last_name"
                  key="4"
                  validate={required("mra.reset.last_name")}
                />
              ]}
          <TextInput source="phone" />
          <TextInput source="email" type="email" />
          <SelectInput
            source="language"
            validate={required("mra.reset.language")}
            choices={Object.values(config.LOCALE_TYPES)}
          />
          <TextInput source="user_reference" />
          <RestrictedBooleanInput source="is_reset_requested" />

          {/*
            <ReferenceArrayInput
            source="roles"
            reference={config.ROLE_RESOURCE}
            filterToQuery={searchText => ({ name: searchText })}
            allowEmpty
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
          */}

          {/* <RoleEdit source="roles" label="mra.roles.selectRoles" /> */}
          {/**
           * It there is a rcContext, the RC cant be changed!
           * RC Supervisors shouldnt be able to change  RCs
           */
          rcContext && rcContext !== config.RC_CONTEXT_ALL ? (
            <ReferenceArrayField
              reference={config.READINGCENTER_RESOURCE}
              target="id"
              label={`resources.${config.USER_RESOURCE}.fields.reading_center`}
              source="centers"
              sortable={false}
              linkType={false}
            >
              <SingleFieldList>
                <ToolTipChipField
                  source="remote_id"
                  toolTipText="#{remote_id} - #{name}"
                  toolTipEval
                />
              </SingleFieldList>
            </ReferenceArrayField>
          ) : (
            <ReadingCenterInput
              source="centers"
              perPage={100}
              label={`resources.${config.USER_RESOURCE}.fields.reading_center`}
              reference={config.READINGCENTER_RESOURCE}
              filterToQuery={searchText => ({ name: searchText })}
              allowEmpty
            >
              <SelectArrayInput
                optionText={rc => `${rc.remote_id} - ${rc.name}`}
              />
            </ReadingCenterInput>
          )}
          {/**
           * If there is a ruContext, the RU cant be changed!
           * users are locked in there contexts
           */
          ruContext ? (
            <ConnectedReadingUnitDisplay />
          ) : (
            <ReadingUnitInput
              allowEmpty
              source="unit_id"
              reference={config.READINGUNIT_RESOURCE}
              filterToQuery={name => ({ name })}
            >
              <AutocompleteInput
                options={{ name: "reading unit" }}
                optionValue="id"
                optionText="name"
              />
            </ReadingUnitInput>
          )}
          <RestrictedBooleanInput source="is_blocked" />
          <BlockedCommentInput source="blocked_reason" />
          <HideableRCSelectInput
            source="centers"
            label={`resources.${config.USER_RESOURCE}.fields.reading_center`}
          />
        </RestrictedCustomForm>
      </Edit>
    );
  }
}

UserEdit.propTypes = {
  checkRoleContext: PropTypes.func,
  RCsofRU: PropTypes.func,
  editNames: PropTypes.bool,
  ruId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rcContext: PropTypes.string,
  disableAdminInput: PropTypes.bool,
  crudGetOne: PropTypes.func,
  basePath: PropTypes.string,
  ruContext: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ru: PropTypes.object,
  translate: PropTypes.func,
  dispatch: PropTypes.func
};

const ConnectedUserEdit = compose(
  connect((state, props) => {
    return {
      ruContext: getCurrentUserRUContext(state),
      rcContext: getRcContext(state),
      ru: getCurrentUserRU(state),
      checkRoleContext: role => checkRoleContext(role)(state),
      RCsofRU: ruId => getRCsofRU(ruId)(state),
      ruId: getRecordFormValues("reading_unit_id")(state),
      disableAdminInput: !checkPermissionsAndAdminUser(state, props),
      editNames: checkPermissionByName(config.PERMISSIONS.USER_UPDATE_NAMES)(
        state
      )
    };
  }),
  translate
)(UserEdit);

export default ConnectedUserEdit;
