import React from "react";
import P from "prop-types";
import {
  ShowButton,
  ReferenceInput,
  SelectArrayInput,
  AutocompleteInput,
  TextInput,
  ReferenceField,
  TextField,
  translate
} from "react-admin";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import omit from "lodash/omit";
import isNil from "lodash/isNil";
import { Datagrid } from "../base";
import config from "../../config";
import { List, Filter } from ".";
import { FieldWithUnits, TypeTranslationField } from "../fields";
import { makeFilter, getRCContextId } from "../../redux/selectors";

const styles = {
  selectField: {
    minWidth: "160px"
  }
};

function TopItinerariesFilter({ classes, ...props }) {
  return (
    <Filter {...props}>
      <ReferenceInput
        label="mra.picture_filter.agent_id"
        source="agent_id"
        filterToQuery={username => ({ username })}
        reference={config.USER_RESOURCE}
      >
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <TextInput source="remote_id" />
      <ReferenceInput
        source="center_id"
        reference={config.READINGCENTER_RESOURCE}
        filterToQuery={searchText => ({ remote_id: searchText })}
        allowEmpty
      >
        <AutocompleteInput
          options={{ name: "reading center" }}
          optionValue="id"
          optionText="remote_id"
        />
      </ReferenceInput>
      <SelectArrayInput
        className={classes.selectField}
        source="status"
        choices={[
          config.ITINERARY_STATUS_TYPES.UNASSIGNED,
          config.ITINERARY_STATUS_TYPES.READY,
          config.ITINERARY_STATUS_TYPES.IN_PROGRESS
        ]}
      />
    </Filter>
  );
}

TopItinerariesFilter.propTypes = {
  classes: P.object
};

const Title = translate(({ translate }) => (
  <span>{translate(`resources.${config.TOP_ITINERARY_RESOURCE}.name`)}</span>
));

function TopItineraries({ classes, ...props }) {
  return (
    <List
      {...props}
      title={<Title />}
      filters={<TopItinerariesFilter classes={classes} />}
      defaultFilter={{
        status: [
          config.ITINERARY_STATUS_TYPES.UNASSIGNED.id,
          config.ITINERARY_STATUS_TYPES.READY.id,
          config.ITINERARY_STATUS_TYPES.IN_PROGRESS.id
        ]
      }}
      sort={{ field: "c_money_netto", order: "DESC" }}
      basePath={`/${props.resource}`}
    >
      <Datagrid>
        <TextField source="cycle_year" />
        <TextField source="cycle" />
        <ReferenceField
          source="center_id"
          reference={config.READINGCENTER_RESOURCE}
          linkType={false}
          allowEmpty
        >
          <TextField source="remote_id" />
        </ReferenceField>
        <TextField source="remote_id" />
        <TypeTranslationField
          source="status"
          types={config.ITINERARY_STATUS_TYPES}
        />
        <FieldWithUnits
          source="c_money_brutto"
          units="c_money_brutto_currency"
        />
        <FieldWithUnits source="c_money_netto" units="c_money_netto_currency" />
        <FieldWithUnits source="c_units" units="c_unit_type" />
        <TextField source="n_supply_points" />
        <TextField source="method" />
        <ReferenceField
          source="agent_id"
          reference={config.USER_RESOURCE}
          linkType={false}
          allowEmpty
        >
          <TextField source="username" />
        </ReferenceField>
        <ShowButton label="" />
      </Datagrid>
    </List>
  );
}

TopItineraries.propTypes = {
  resource: P.string,
  classes: P.object
};

function makeMapStateToProps() {
  const filterSelector = makeFilter({
    center_id: getRCContextId
  });

  return state => {
    const filter = filterSelector(state);
    if (isNil(filter.center_id)) {
      delete filter.center_id;
    }
    return {
      filter
    };
  };
}

const ConnectedTopItineraries = compose(
  connect(makeMapStateToProps),
  mapProps(props => omit(props, ["dispatch"])),
  withStyles(styles)
)(TopItineraries);

export { ConnectedTopItineraries as TopItineraries };
