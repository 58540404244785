import React from "react";
import P from "prop-types";
import { Link } from "react-router-dom";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core";
import ConfirmIcon from "@material-ui/icons/CheckCircle";
import UnassignIcon from "@material-ui/icons/PersonAddDisabled";
import AssignementIcon from "@material-ui/icons/PersonAdd";
import { translate, SaveButton, DeleteButton } from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import config from "../../../config";
import { RestrictedFlatButton } from "../../restricted-components";

const {
  ITINERARY_STATUS_TYPES: { PAPER_READING_READY, READY }
} = config;

const readyStatuses = [PAPER_READING_READY.id, READY.id];

const styles = {
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1
  },
  group: {
    "& > button": {
      marginRight: "8px"
    }
  },
  confirm: {
    fontSize: "18px",
    marginRight: "8px"
  }
};

function isReady(status) {
  return readyStatuses.includes(status);
}

function ToolBar(props) {
  const {
    basePath,
    classes,
    handleSubmitWithRedirect,
    invalid,
    record,
    redirect,
    resource,
    saving,
    submitOnEnter,
    translate,
    undoable,
    hasDelete,
    onConfirm
  } = props;
  const { status, is_confirmation_required: confirmReq } = record;
  return (
    <Toolbar role="toolbar">
      <div className={classes.toolbar}>
        <div className={classes.group}>
          {confirmReq ? (
            <RestrictedFlatButton variant="raised" onClick={onConfirm}>
              <ConfirmIcon className={classes.confirm} />
              {translate("mra.confirm.ok")}
            </RestrictedFlatButton>
          ) : null}
          {isReady(status) ? (
            <RestrictedFlatButton
              variant="raised"
              component={Link}
              to={`/${resource}/${record.id}/unassign`}
            >
              <UnassignIcon className={classes.confirm} />
              {translate("mra.unassign")}
            </RestrictedFlatButton>
          ) : (
            <SaveButton
              handleSubmitWithRedirect={handleSubmitWithRedirect}
              invalid={invalid}
              redirect={redirect}
              saving={saving}
              submitOnEnter={submitOnEnter}
              label="mra.assign"
              icon={<AssignementIcon className={classes.confirm} />}
            />
          )}
        </div>
        {hasDelete && (
          <DeleteButton
            basePath={basePath}
            record={record}
            resource={resource}
            undoable={undoable}
          />
        )}
      </div>
    </Toolbar>
  );
}

ToolBar.propTypes = {
  basePath: P.string,
  classes: P.object,
  handleSubmitWithRedirect: P.func,
  invalid: P.bool,
  onConfirm: P.func,
  onUnassign: P.func,
  record: P.object,
  redirect: P.oneOfType([P.string, P.bool, P.func]),
  hasDelete: P.bool,
  resource: P.string,
  saving: P.bool,
  submitOnEnter: P.bool,
  translate: P.func,
  undoable: P.bool
};

ToolBar.defaultProps = {
  hasDelete: true,
  onConfirm: () => {}
};

export const AssignmentToolbar = compose(
  withStyles(styles),
  translate
)(ToolBar);
