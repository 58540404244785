import React from "react";
import {
  Edit,
  ReferenceField,
  TextField,
  Toolbar,
  translate,
  required
} from "react-admin";
import P from "prop-types";
import { withStyles } from "@material-ui/core";
import { SimpleForm, Actions } from "../base";
import config from "../../config";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { getCurrentLang } from "../../redux/selectors";
import get from "lodash/get";
import { SettingInput } from "../inputs";
import { DescriptionField, DateTimeField } from "../fields";

const EditTitle = ({ record, language, translate }) =>
  <span>
    {translate(`resources.${config.SETTINGS_RESOURCE}.name`, 2)} -{" "}
    {record ? `"${get(record, language.label)}"` : ""}
  </span>;

const ConnectedEditTitle = compose(
  connect(state => ({
    language: getCurrentLang(state)
  })),
  translate
)(EditTitle);

EditTitle.propTypes = {
  translate: P.func,
  record: P.object,
  language: P.object
};

export const SettingEdit = ({ classes, ...props }) => {
  return (
    <Edit {...props} title={<ConnectedEditTitle />} actions={<Actions />}>
      <SimpleForm toolbar={<Toolbar />} className={classes.form}>
        <TextField addLabel source="name" />
        <DescriptionField
          addLabel
          fullWidth
          source="description"
          translationKey="settings_descriptions"
        />
        <ReferenceField
          addLabel={true}
          source="updated_by_id"
          reference={config.USER_RESOURCE}
          linkType={false}
          allowEmpty={true}
          className={classes.field}
        >
          <TextField source="username" />
        </ReferenceField>
        <DateTimeField source="updated_at" addLabel />
        <SettingInput
          source="value"
          parse={v => (v !== null ? String(v) : null)}
          validate={required("mra.settings.value")}
        />
      </SimpleForm>
    </Edit>
  );
};

SettingEdit.propTypes = {
  classes: P.object,
  translate: P.func
};

export default withStyles({
  form: {
    "& > div > .ra-input": {
      display: "flex"
    }
  }
})(SettingEdit);
