import React from "react";
import PropTypes from "prop-types";
import AnomalyCorrectionIcon from "@material-ui/icons/LooksTwo";
import ControlIcon from "@material-ui/icons/Beenhere";
import DistributionIcon from "@material-ui/icons/MonetizationOn";
import AssignList from "./AssignList";
import Assignment from "./Assignment";
import Card from "./Card";
import DetailsList from "./DetailsList";
import ItineraryDetails from "./ItineraryDetails";
import ItineraryList from "./ItineraryList";
import config from "../../config";
import { RestrictedBulkDeleteButton } from "../restricted-components/RestrictedButtons";

export { default as TaskDetails } from "./TaskDetails";

const {
  ANOMALY_CORRECTION_ITINERARY_RESOURCE,
  CONTROL_READING_ITINERARY_RESOURCE,
  DISTRIBUTION_ITINERARY_RESOURCE,
  ITINERARY_STATUS_TYPES,
  ANOMALY_CORRECTION_ITINERARY_ASSIGN_PATH,
  CONTROL_READING_ITINERARY_ASSIGN_PATH,
  DISTRIBUTION_ITINERARIES_ASSIGN_PATH,
  ANOMALY_CORRECTION_TASK_RESOURCE,
  CONTROL_READING_TASK_RESOURCE,
  DISTRIBUTION_TASK_RESOURCE
} = config;

export const AnomalyCorrectionAssignList = props => (
  <AssignList
    {...props}
    resource={ANOMALY_CORRECTION_ITINERARY_RESOURCE}
    types={ITINERARY_STATUS_TYPES}
    assignPath={"anomaly_correction_assign"}
  />
);
export const AnomalyCorrectionAssignment = props => (
  <Assignment
    {...props}
    basePath={ANOMALY_CORRECTION_ITINERARY_ASSIGN_PATH}
    resource={ANOMALY_CORRECTION_ITINERARY_RESOURCE}
  />
);
export const AnomalyCorrectionCard = props => (
  <Card
    {...props}
    avatar={<AnomalyCorrectionIcon />}
    itineraryResource={ANOMALY_CORRECTION_ITINERARY_RESOURCE}
  />
);
export const AnomalyCorrectionDetailsList = props => (
  <DetailsList
    {...props}
    card={AnomalyCorrectionCard}
    bulkActionButtons={false}
    resource={ANOMALY_CORRECTION_TASK_RESOURCE}
  />
);

export const AnomalyCorrectionItineraryDetails = props => (
  <ItineraryDetails
    {...props}
    types={ITINERARY_STATUS_TYPES}
    resource={ANOMALY_CORRECTION_ITINERARY_RESOURCE}
    DetailsList={AnomalyCorrectionDetailsList}
  />
);
export const AnomalyCorrectionItineraryList = props => (
  <ItineraryList {...props} types={ITINERARY_STATUS_TYPES} />
);

export const ControlReadingAssignList = props => (
  <AssignList
    {...props}
    resource={CONTROL_READING_ITINERARY_RESOURCE}
    types={ITINERARY_STATUS_TYPES}
    assignPath={"control_reading_assign"}
  />
);
export const ControlReadingAssignment = props => (
  <Assignment
    {...props}
    basePath={CONTROL_READING_ITINERARY_ASSIGN_PATH}
    resource={CONTROL_READING_ITINERARY_RESOURCE}
  />
);
export const ControlReadingCard = props => (
  <Card
    {...props}
    avatar={<ControlIcon />}
    itineraryResource={CONTROL_READING_ITINERARY_RESOURCE}
  />
);

const ControlReadingBulkActionButtons = props => (
  <RestrictedBulkDeleteButton
    {...props}
    resource={config.TASK}
    requiredPermissions={[config.PERMISSIONS.DELETE_CONTROL_ITINERARY_TASK]}
  />
);
export const ControlReadingDetailsList = props => {
  const hasBulkActionButtons =
    props.record &&
    props.record.status &&
    [
      config.ITINERARY_STATUS_TYPES.UNASSIGNED.id,
      config.ITINERARY_STATUS_TYPES.READY.id,
      config.ITINERARY_STATUS_TYPES.PAPER_READING_READY.id
    ].indexOf(props.record.status.toUpperCase()) !== -1;
  return (
    <DetailsList
      {...props}
      bulkActionButtons={
        hasBulkActionButtons && <ControlReadingBulkActionButtons />
      }
      card={ControlReadingCard}
      resource={CONTROL_READING_TASK_RESOURCE}
    />
  );
};

ControlReadingDetailsList.propTypes = {
  record: PropTypes.object
};

export const ControlReadingItineraryDetails = props => (
  <ItineraryDetails
    {...props}
    types={ITINERARY_STATUS_TYPES}
    resource={CONTROL_READING_ITINERARY_RESOURCE}
    DetailsList={ControlReadingDetailsList}
  />
);
export const ControlReadingItineraryList = props => (
  <ItineraryList {...props} types={ITINERARY_STATUS_TYPES} />
);

export const DistributionAssignList = props => (
  <AssignList
    {...props}
    resource={DISTRIBUTION_ITINERARY_RESOURCE}
    types={ITINERARY_STATUS_TYPES}
    assignPath={"distribution_assign"}
  />
);
export const DistributionAssignment = props => (
  <Assignment
    {...props}
    basePath={DISTRIBUTION_ITINERARIES_ASSIGN_PATH}
    resource={DISTRIBUTION_ITINERARY_RESOURCE}
  />
);
export const DistributionCard = props => (
  <Card
    {...props}
    avatar={<DistributionIcon />}
    itineraryResource={DISTRIBUTION_ITINERARY_RESOURCE}
  />
);
export const DistributionDetailsList = props => (
  <DetailsList
    {...props}
    card={DistributionCard}
    bulkActionButtons={false}
    resource={DISTRIBUTION_TASK_RESOURCE}
  />
);
export const DistributionItineraryDetails = props => (
  <ItineraryDetails
    {...props}
    types={ITINERARY_STATUS_TYPES}
    resource={DISTRIBUTION_ITINERARY_RESOURCE}
    DetailsList={DistributionDetailsList}
  />
);
export const DistributionItineraryList = props => (
  <ItineraryList {...props} types={ITINERARY_STATUS_TYPES} />
);
