/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import { checkPermission } from "../restricted-components";
import { SimpleForm } from "../base";
import DisabledSimpleForm from "../layout/DisabledSimpleForm";

/*
* Returns a CustomForm with the prop disabled if the user doesn't have any of the
*  required Permissions. CustomForm will return
* * a SimpleForm (standard aor) if prop disabled is not set (disabled =false)
* * a DisabledSimpleForm (modiefed aor SimpleForm) if prop disabled is set (disabled=true)
*/

export const replaceForm = Form => props => {
  if (!props.disabled) {
    const {
      requiredPermissions,
      disabledToolbarTitle,
      denyFor,
      dispatch,
      ...rest
    } = props;
    return <Form {...rest} />;
  } else {
    return <DisabledSimpleForm {...props} />;
  }
};
export const CustomForm = replaceForm(SimpleForm);

export const RestrictedCustomForm = connect((state, props) => ({
  disabled: !checkPermission(state, props)
}))(CustomForm);

export default RestrictedCustomForm;
