import React, { cloneElement } from "react";
import PropTypes from "prop-types";
import onlyUpdateForKeys from "recompose/onlyUpdateForKeys";
import { CreateButton, ExportButton, CardActions } from "react-admin";
import { sanitizeListRestProps } from "../../fields";

function Actions(props) {
  const {
    bulkActions,
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter,
    filterValues,
    permanentFilter,
    hasCreate,
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    total,
    ...rest
  } = props;
  return (
    <CardActions className={className} {...sanitizeListRestProps(rest)}>
      {bulkActions &&
        cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems
        })}
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button"
        })}
      {hasCreate && <CreateButton basePath={basePath} />}
      {exporter !== false && (
        <ExportButton
          maxResults={500}
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={{ ...filterValues, ...permanentFilter }}
          exporter={exporter}
        />
      )}
    </CardActions>
  );
}

Actions.propTypes = {
  bulkActions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  basePath: PropTypes.string,
  className: PropTypes.string,
  currentSort: PropTypes.object,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  permanentFilter: PropTypes.object,
  hasCreate: PropTypes.bool,
  resource: PropTypes.string,
  onUnselectItems: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  showFilter: PropTypes.func,
  total: PropTypes.number.isRequired
};

Actions.defaultProps = {
  selectedIds: []
};

export default onlyUpdateForKeys([
  "resource",
  "filters",
  "displayedFilters",
  "filterValues",
  "selectedIds"
])(Actions);
