/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import {
  TextField,
  List,
  translate,
  crudGetOne,
  crudGetAll
} from "react-admin";
import config from "../../config";
import {
  TextField as MuiTextField,
  Typography,
  Button as FlatButton,
  Select,
  MenuItem,
  CardActions,
  FormHelperText,
  FormControl,
  withStyles
} from "@material-ui/core";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import filter from "lodash/filter";
import find from "lodash/find";
import omit from "lodash/omit";
import get from "lodash/get";
import isNil from "lodash/isNil";
import { DateInput } from "../inputs";
import { RestrictedFlatButton } from "../restricted-components";
import {
  setReadingValue,
  createReadings,
  clearReadings,
  setPrefillSelectedDate,
  clearPrefillSelectedDate
} from "../../redux/actions";
import {
  getReadingValueMRA,
  getAllRecords,
  getRecord,
  getDefaultSelectedId,
  getPrefillSelectedDate,
  getItineraryByID,
  getSkipReadingFlag,
  getAllReadingTasksForItinerary,
  getReadingsMRA,
  getParams
} from "../../redux/selectors";
import { Datagrid } from "../base";
import AutocompleteInput from "./AutocompleteInput";

const cardActionStyle = {
  zIndex: 2,
  display: "inline-block",
  float: "right",
  width: "60%"
};

const actionStyle = {
  float: "right"
};

const readingsValid = (readings, data, defaultClientActivityId) => {
  const values = Object.entries(readings).filter(([_, { value }]) => !!value);

  if (values.length === 0) {
    return false;
  }

  return values.reduce((valid, [id, { value, client_activity_id }]) => {
    return (
      valid &&
      String(value).length <= data[id].meter.dials &&
      client_activity_id !== defaultClientActivityId
    );
  }, true);
};

const ItineraryCode = ({ label }) => <FlatButton disabled>{label}</FlatButton>;

ItineraryCode.propTypes = {
  label: PropTypes.string
};

const ConnectedItineraryCode = connect((state, props) => {
  const { id } = props;
  const itinerary = getItineraryByID(config.ITINERARY_RESOURCE, id)(state);
  return {
    label: `${props.translate("mra.itinerary_overview.itinerary_code")}: ${
      itinerary ? itinerary.remote_id : props.translate("mra.general.loading")
    }`
  };
})(ItineraryCode);

const CaptureConfirmButton = connect((state, props) => {
  const { id } = props;
  const tasks = getAllReadingTasksForItinerary(id)(state);
  const readings = getReadingsMRA(state);
  const defaultClientActivityId = getDefaultSelectedId(
    config.CLIENT_ACTIVITY_RESOURCE,
    null
  )(state);
  const hasUnfinishedTask = !!find(tasks, task => {
    const reading = readings[task.id];
    return (
      !reading ||
      !reading.date_visited ||
      (!reading.reading_value && !reading.has_no_value) ||
      !reading.client_activity_id ||
      reading.client_activity_id === defaultClientActivityId ||
      !reading.reading_anomaly_id ||
      !reading.picture_anomaly_id
    );
  });
  return {
    disabled: hasUnfinishedTask,
    component: Link,
    to: hasUnfinishedTask
      ? `/${config.ITINERARY_RESOURCE}/${id}/capture`
      : `/${config.ITINERARY_RESOURCE}/${id}/capture_confirm`
  };
})(RestrictedFlatButton);

const CaptureActions = ({
  save,
  data,
  readings,
  id,
  itinerary,
  history,
  defaultClientActivityId,
  translate
}) => (
  <CardActions style={cardActionStyle}>
    <ConnectedItineraryCode translate={translate} id={id} />
    <div style={actionStyle}>
      <FlatButton color="primary" onClick={() => history.goBack()}>
        {translate("mra.general.back")}
      </FlatButton>
      <FlatButton
        color="primary"
        style={{ overflow: "inherit" }}
        onClick={() => save(readings, itinerary)}
        disabled={!readingsValid(readings, data, defaultClientActivityId)}
      >
        {translate("ra.action.save")}
      </FlatButton>
      <CaptureConfirmButton
        color="primary"
        style={{ overflow: "inherit" }}
        onClick={() => save(readings, itinerary)}
        id={id}
        requiredPermissions={[
          config.PERMISSIONS.READING_ITINERARY_MARK_COMPLETE
        ]}
      >
        {translate("mra.itinerary_overview.confirm_capture_button")}
      </CaptureConfirmButton>
      <FlatButton
        color="primary"
        style={{ overflow: "inherit" }}
        component={Link}
        to={`${config.ROUTESHEET_UPLOAD_PATH.replace(":id", id)}`}
      >
        {translate("mra.itinerary_overview.image_upload_button")}
      </FlatButton>
    </div>
  </CardActions>
);

CaptureActions.propTypes = {
  save: PropTypes.func,
  data: PropTypes.object,
  readings: PropTypes.object,
  id: PropTypes.string,
  itinerary: PropTypes.object,
  history: PropTypes.object,
  defaultClientActivityId: PropTypes.string,
  translate: PropTypes.func
};

const ConnectedCaptureActions = compose(
  connect(
    (state, props) => {
      return {
        readings: getReadingsMRA(state),
        itinerary: getRecord(config.ITINERARY_RESOURCE, props.id)(state),
        defaultClientActivityId: getDefaultSelectedId(
          config.CLIENT_ACTIVITY_RESOURCE,
          null
        )(state)
      };
    },
    {
      save: createReadings
    }
  ),
  translate
)(CaptureActions);

class ReadingInput extends React.Component {
  constructor(props) {
    super(props);
    const { record, set, source, value } = props;
    const valueFromResource =
      record.latest_result && record.latest_result.value;
    if (
      this.props &&
      record &&
      set &&
      source &&
      !value &&
      !isNil(valueFromResource)
    ) {
      set(record.id, source, valueFromResource);
    }
  }

  /** Set the has_no_value flag if the reading anomaly has the is_skip_reading flag set.
   * the field has the disabled prop if is_skip_reading is set
   * resetting the value of the reading if there was one entered before the anomaly was
   * selected is not needed, the api will do that.
   */
  componentDidUpdate() {
    const {
      set,
      disabled,
      record: { id }
    } = this.props;
    if (disabled) {
      set(id, "has_no_value", true);
      set(id, "reading_value", null);
    } else {
      set(id, "has_no_value", false);
    }
  }

  render() {
    const { translate, record, set, value, source, disabled } = this.props;
    const error =
      value && value.length > record.meter.dials
        ? translate("mra.register_meter.reading_value_chars")
        : null;
    return (
      <MuiTextField
        onChange={e => set(record.id, source, e.target.value)}
        type="number"
        name={`${record.id}_capture_input`}
        disabled={disabled}
        value={value}
        error={Boolean(error)}
        helperText={error}
      />
    );
  }
}

ReadingInput.propTypes = {
  record: PropTypes.object,
  translate: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  set: PropTypes.func,
  source: PropTypes.string,
  disabled: PropTypes.bool
};

const ConnectedReadingInput = compose(
  connect(
    (state, { record, source }) => {
      const disabled = getSkipReadingFlag(record.id)(state);
      const value = getReadingValueMRA(record.id, source)(state);
      return {
        value: isNil(value) ? "" : value,
        disabled
      };
    },
    {
      set: setReadingValue
    }
  ),
  translate
)(ReadingInput);

const ReadingDateInputStyles = {
  disableMargin: {
    margin: 0,
    "& > label + div": {
      margin: 0
    }
  }
};

class ReadingDateInput extends React.Component {
  constructor(props) {
    super(props);
    const { record, set, source, value, valueFromResource } = props;
    if (props && record && set && source && !value && valueFromResource) {
      set(record.id, source, valueFromResource);
    }
  }

  componentDidUpdate() {
    if (
      this.props &&
      this.props.record &&
      this.props.set &&
      this.props.source &&
      !this.props.value &&
      this.props.defaultValue
    ) {
      const { record, source, defaultValue } = this.props;
      this.props.set(record.id, source, defaultValue);
    }
  }

  render() {
    const { record, value, set, source, prefill, classes } = this.props;
    return (
      <DateInput
        input={{
          value: value,
          onChange: newValue => {
            set(record.id, source, newValue);
            prefill(newValue);
          },
          onBlur: () => {}
        }}
        className={classes.disableMargin}
        meta={{}}
      />
    );
  }
}

ReadingDateInput.propTypes = {
  record: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  set: PropTypes.func,
  prefill: PropTypes.func,
  source: PropTypes.string,
  classes: PropTypes.object,
  valueFromResource: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const ConnectedReadingDateInput = compose(
  connect(
    (state, { record, source }) => {
      return {
        value: getReadingValueMRA(record.id, source)(state),
        valueFromResource:
          record.latest_result && record.latest_result.date_visited_at,
        defaultValue: getPrefillSelectedDate(state)
      };
    },
    {
      set: setReadingValue,
      prefill: setPrefillSelectedDate
    }
  ),
  withStyles(ReadingDateInputStyles)
)(ReadingDateInput);

export const PrevReadingValueField = ({ className, record = {} }) => {
  return (
    <Typography component="span" body1="body1" className={className}>
      {get(record, "prev_reading_value", "n/a")}
    </Typography>
  );
};

PrevReadingValueField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  className: PropTypes.string
};

/**
 * component for resequencing reading tasks
 */
class ReadingTaskList extends React.Component {
  constructor(props) {
    super(props);
    const { initPrefillSelectedDate } = props;
    initPrefillSelectedDate();
  }

  componentDidMount() {
    const {
      resource,
      match: {
        params: { id }
      }
    } = this.props;
    const basePath = `/${resource}`;
    this.props.crudGetOne(resource, id, basePath);
    this.props.crudGetAll(config.ANOMALIES_RESOURCE, null, null, 500);
    this.props.crudGetAll(config.CLIENT_ACTIVITY_RESOURCE, null, null, 500);
  }

  render() {
    const {
      translate,
      initPrefillSelectedDate,
      init,
      defaultValues,
      crudGetOne,
      crudGetAll,
      ...props
    } = this.props;
    return (
      <List
        {...props}
        resource={config.READINGTASK_RESOURCE}
        hasCreate={false}
        hasEdit={false}
        hasList={false}
        hasShow={false}
        basePath={config.READINGTASK_RESOURCE}
        perPage={config.PAGINATION}
        actions={
          <ConnectedCaptureActions
            id={props.match.params.id}
            history={props.history}
          />
        }
        title={translate("mra.itinerary_overview.capture")}
        sort={{ field: "sequence_orig", order: "ASC" }}
        filter={{ itinerary_id: props.match.params.id }}
        bulkActions={false}
      >
        <Datagrid>
          <TextField
            label={`resources.${config.METER_RESOURCE}.fields.meter_identifier`}
            source="meter.meter_identifier"
            sortable={false}
          />
          <TextField
            label={`resources.${config.METER_RESOURCE}.fields.manufacturer`}
            source="meter.manufacturer"
            sortable={false}
          />
          <TextField
            label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.remote_id`}
            source="supply_point.remote_id"
            sortable={false}
          />
          <TextField
            label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.premise_identifier`}
            source="supply_point.premise_identifier"
            sortable={false}
          />
          <PrevReadingValueField
            label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.prev_reading_value`}
            sortable={false}
          />
          <ConnectedReadingInput source="value" sortable={false} />
          <ConnectedReadingDateInput
            source="date_visited_at"
            sortable={false}
          />
          <AutocompleteInput
            source="client_activity_id"
            reference={config.CLIENT_ACTIVITY_RESOURCE}
            search="code"
            filterData={{ is_active: true }}
            defaultValues={defaultValues}
            sortable={false}
          />
          <AutocompleteInput
            source="anomaly_id"
            reference={config.ANOMALIES_RESOURCE}
            search="code"
            filterData={{
              type: config.ANOMALY_TYPES.READING.id,
              is_active: true
            }}
            defaultValues={defaultValues}
            sortable={false}
          />
          <AutocompleteInput
            source="picture_anomaly_id"
            reference={config.ANOMALIES_RESOURCE}
            search="code"
            filterData={{
              type: config.ANOMALY_TYPES.PICTURE.id,
              is_active: true
            }}
            defaultValues={defaultValues}
            sortable={false}
          />
        </Datagrid>
      </List>
    );
  }
}

const ConnectedReadingTaskList = compose(
  connect(
    state => ({
      params: getParams(config.READINGTASK_RESOURCE)(state),
      resource: config.ITINERARY_RESOURCE,
      defaultValues: {
        picture_anomaly_id: getDefaultSelectedId(
          config.ANOMALIES_RESOURCE,
          config.ANOMALY_TYPES.PICTURE.id
        )(state),
        anomaly_id: getDefaultSelectedId(
          config.ANOMALIES_RESOURCE,
          config.ANOMALY_TYPES.READING.id
        )(state),
        client_activity_id: getDefaultSelectedId(
          config.CLIENT_ACTIVITY_RESOURCE,
          null
        )(state)
      }
    }),
    {
      init: clearReadings,
      initPrefillSelectedDate: clearPrefillSelectedDate,
      crudGetOne: crudGetOne,
      crudGetAll: crudGetAll
    }
  ),
  translate,
  mapProps(props => omit(props, ["dispatch", "staticContext", "authClient"]))
)(ReadingTaskList);

ReadingTaskList.propTypes = {
  translate: PropTypes.func,
  defaultValues: PropTypes.object,
  init: PropTypes.func,
  initPrefillSelectedDate: PropTypes.func,
  loadAllReadingTasks: PropTypes.func,
  resource: PropTypes.string.isRequired,
  crudGetOne: PropTypes.func,
  crudGetAll: PropTypes.func,
  match: PropTypes.object,
  setListParams: PropTypes.func,
  params: PropTypes.object
};

export default ConnectedReadingTaskList;
