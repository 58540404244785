import React from "react";
import {
  SaveButton,
  SimpleForm as AdminSimpleForm,
  Toolbar
} from "react-admin";

const SaveToolbar = props =>
  <Toolbar {...props}>
    <SaveButton submitOnEnter={true} />
  </Toolbar>;

const SimpleForm = props => <AdminSimpleForm {...props} />;

SimpleForm.defaultProps = {
  toolbar: <SaveToolbar />
};

export default SimpleForm;
