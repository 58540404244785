import PropTypes from "prop-types";
import get from "lodash/get";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import { TextField, translate } from "react-admin";
import React from "react";

function TranslationField({ translate, convertFunction, ...props }) {
  let fieldToTranslate = get(props.record, props.source);
  let record = set(
    cloneDeep(props.record),
    props.source,
    translate(convertFunction(fieldToTranslate))
  );
  return <TextField {...props} record={record} />;
}

TranslationField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  convertFunction: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default translate(TranslationField);
