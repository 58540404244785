import { connect } from "react-redux";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import { SimpleForm } from "react-admin";
import { assign } from "../../../redux/actions";

export const AssignmentForm = compose(
  connect(
    null,
    { assign }
  ),
  withProps(props => ({
    save: data => {
      const { assign, basePath, resource } = props;
      assign(
        resource,
        data.method,
        data.id,
        data.agent_id,
        data.capturer_id,
        basePath
      );
    }
  }))
)(SimpleForm);
