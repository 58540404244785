import React from "react";
import PropTypes from "prop-types";
import { List } from "react-admin";
import { Grid, withStyles } from "@material-ui/core";
import { crudGetOne } from "react-admin";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import omit from "lodash/omit";
import { connect } from "react-redux";
import ReadingMeterCard from "../reading-itineraries/ReadingMeterCard";
import MeterCard from "../registered-meters/MeterCard";
import {
  AnomalyCorrectionCard,
  ControlReadingCard,
  DistributionCard
} from "../itineraries";
import config from "../../config";
import { CardFilter } from "../base";
import { getFilter } from "../layout";

const READING_TASK = "reading";
const DISTRIBUTION_TASK = "distribution";
const REGISTERED_METER = "registered_meter";
const CONTROL = "control";
const CORRECTION = "anomaly_correction";

class CardGrid extends React.PureComponent {
  getCardComponent = ({ namespace }) => {
    switch (namespace) {
      case DISTRIBUTION_TASK:
        return DistributionCard;
      case REGISTERED_METER:
        return MeterCard;
      case CONTROL:
        return ControlReadingCard;
      case CORRECTION:
        return AnomalyCorrectionCard;
      case READING_TASK:
      default:
        return ReadingMeterCard;
    }
  };

  getCardContent = record => {
    let resource;
    switch (record.namespace) {
      case DISTRIBUTION_TASK:
        resource = config.DISTRIBUTION_TASK_RESOURCE;
        break;
      case REGISTERED_METER:
        resource = config.REGISTERED_METERS_RESOURCE;
        break;
      case CONTROL:
        resource = config.CONTROL_READING_TASK_RESOURCE;
        break;
      case CORRECTION:
        resource = config.ANOMALY_CORRECTION_TASK_RESOURCE;
        break;
      case READING_TASK:
      default:
        resource = config.READINGTASK_RESOURCE;
        break;
    }
    if ((record.metadata && record.metadata.task_id) || record.remote_id) {
      const remoteId = record.metadata.task_id || record.remote_id;
      this.props.crudGetOne(resource, remoteId, this.props.basePath);
    }
    if ([DISTRIBUTION_TASK, READING_TASK].indexOf(record.namespace) !== -1) {
      // ToDo: right now user doens't have access to itinerary we need to fix it on backend
      // this.props.crudGetOne(
      //   {
      //     [DISTRIBUTION_TASK]: config.DISTRIBUTION_ITINERARY_RESOURCE,
      //     [READING_TASK]: config.ITINERARY_RESOURCE
      //   }[record.namespace],
      //   record.metadata.itinerary_eid,
      //   this.props.basePath
      // );
    }
    // this.props.crudGetOne(, record.remote_id, this.props.basePath);
  };

  getCardResource = record => {
    return {
      [READING_TASK]: config.READINGTASK_RESOURCE,
      [DISTRIBUTION_TASK]: config.DISTRIBUTION_TASK_RESOURCE,
      [REGISTERED_METER]: config.REGISTERED_METERS_RESOURCE,
      [CONTROL]: config.CONTROL_READING_TASK_RESOURCE,
      [CORRECTION]: config.ANOMALY_CORRECTION_TASK_RESOURCE
    }[record.namespace];
  };

  render() {
    const { ids, data, basePath, classes } = this.props;
    return (
      <Grid
        container
        className={classes.switchListContainer}
        spacing={8}
        justify="center"
      >
        {ids.map(id => {
          const Card = this.getCardComponent(data[id]);
          return (
            <Grid key={id} item>
              <Card
                showType
                resourceToFetch={this.getCardResource(data[id])}
                fetchOnExpand
                record={data[id]}
                basePath={basePath}
                getContent={this.getCardContent}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

CardGrid.propTypes = {
  ids: PropTypes.array,
  data: PropTypes.object,
  basePath: PropTypes.string,
  crudGetOne: PropTypes.func,
  classes: PropTypes.object
};

const ControlledCardGrid = compose(
  connect(null, { crudGetOne }),
  withStyles({
    switchListContainer: {
      paddingLeft: 24,
      paddingRight: 24
    }
  })
)(CardGrid);

export const PictureList = compose(
  connect((state, props) => ({
    filter: getFilter(state, props)
  })),
  mapProps(props => omit(props, ["dispatch"]))
)(props =>
  <List {...props} filters={<CardFilter />}>
    <ControlledCardGrid />
  </List>
);
