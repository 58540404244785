import React from "react";
import {
  Create,
  TextInput,
  SelectInput,
  AutocompleteInput,
  translate,
  required,
  crudGetOne
} from "react-admin";
import { SimpleForm } from "../base";
import compose from "recompose/compose";
import PropTypes from "prop-types";
import config from "../../config";
import {
  getCurrentUserRUContext,
  getCurrentUserRU
} from "../../redux/selectors";
import RoleInput from "./RoleInput";
import { connect } from "react-redux";
import {
  ConnectedReadingUnitInput,
  ConnectedReadingUnitDisplay,
  HideableRCSelectInput
} from "./specialRURCComponents";

export class UserCreate extends React.Component {
  // validate form: ensure password was confirmed
  createValidation = values => {
    const errors = {};
    const { translate } = this.props;
    if (
      values.password &&
      values.confirmPassword &&
      values.password !== values.confirmPassword
    ) {
      errors.confirmPassword = [translate("mra.reset.match")];
    }
    return errors;
  };

  componentDidUpdate() {
    const { ru, ruContext, crudGetOne, basePath } = this.props;
    if (ruContext && !ru) {
      crudGetOne(config.READINGUNIT_RESOURCE, ruContext, basePath);
    }
  }

  render() {
    const { ruContext, ru, crudGetOne, ...props } = this.props;
    return (
      <Create {...props}>
        <SimpleForm validate={this.createValidation}>
          <RoleInput
            validate={required("mra.reset.roles")}
            source="roles"
            label="mra.roles.selectRole"
          />
          {/*/!***/}
          {/** If there is a ruContext, the RU cant be changed!*/}
          {/** users are locked in there contexts*/}
          {/**/}

          {ruContext ? (
            <ConnectedReadingUnitDisplay ru={ru} />
          ) : (
            <ConnectedReadingUnitInput
              allowEmpty
              source="unit_id"
              reference={config.READINGUNIT_RESOURCE}
              filterToQuery={searchText => ({ name: searchText })}
              validate={required("mra.reset.reading_unit_id")}
            >
              <AutocompleteInput optionText="name" />
            </ConnectedReadingUnitInput>
          )}
          <HideableRCSelectInput
            validate={required("mra.reset.reading_centers")}
            source="centers"
            label={`resources.${config.USER_RESOURCE}.fields.reading_centers`}
          />
          <TextInput
            isRequired
            source="username"
            validate={required("mra.reset.username")}
          />
          <TextInput
            isRequired
            source="first_name"
            validate={required("mra.reset.first_name")}
          />
          <TextInput source="middle_name" />
          <TextInput
            source="last_name"
            validate={required("mra.reset.last_name")}
          />
          <TextInput source="phone" />
          <TextInput source="email" type="email" />
          <SelectInput
            source="language"
            choices={Object.values(config.LOCALE_TYPES)}
            validate={required("mra.reset.language")}
          />
          <TextInput source="user_reference" />
          <TextInput
            source="password"
            type="password"
            validate={required("mra.reset.password")}
          />
          <TextInput
            validate={required("mra.validation.required")}
            label="mra.reset.confirm"
            source="confirmPassword"
            type="password"
            defaultValue=""
          />
        </SimpleForm>
      </Create>
    );
  }
}
UserCreate.propTypes = {
  dispatch: PropTypes.func,
  translate: PropTypes.func,
  checkRoleContext: PropTypes.func,
  basePath: PropTypes.string,
  crudGetOne: PropTypes.func,
  ruContext: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ru: PropTypes.object
};

export default compose(
  connect(
    state => ({
      ruContext: getCurrentUserRUContext(state),
      ru: getCurrentUserRU(state)
    }),
    { crudGetOne }
  ),
  translate
)(UserCreate);
