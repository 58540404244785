/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import P from "prop-types";
import { reduxForm } from "redux-form";
import classnames from "classnames";
import { withStyles, createStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import set from "lodash/set";
import get from "lodash/get";
import FilterFormInput from "./FilterFormInput";

const styles = theme =>
  createStyles({
    form: {
      marginTop: "-10px",
      paddingTop: 0,
      display: "flex",
      alignItems: "flex-end",
      flexWrap: "wrap"
    },
    body: { display: "flex", alignItems: "flex-end" },
    spacer: { width: "1em" },
    icon: {
      color: theme.palette.primary1Color || "#00bcd4",
      paddingBottom: 0
    },
    clearFix: { clear: "right" }
  });

const sanitizeRestProps = ({
  anyTouched,
  asyncValidate,
  asyncValidating,
  autofill,
  blur,
  change,
  clearAsyncError,
  clearFields,
  clearSubmit,
  clearSubmitErrors,
  destroy,
  dirty,
  dispatch,
  displayedFilters,
  filterValues,
  handleSubmit,
  hideFilter,
  initialize,
  initialized,
  initialValues,
  invalid,
  pristine,
  pure,
  reset,
  resetSection,
  save,
  setFilter,
  setFilters,
  submit,
  submitFailed,
  submitSucceeded,
  submitting,
  touch,
  triggerSubmit,
  untouch,
  valid,
  validate,
  ...props
}) => props;

export function FilterForm(props) {
  function getShownFilters() {
    const { filters, displayedFilters, initialValues } = props;

    return filters.filter(
      filterElement =>
        filterElement.props.alwaysOn ||
        displayedFilters[filterElement.props.source] ||
        get(initialValues, filterElement.props.source) !== undefined
    );
  }

  function handleHide(event) {
    props.hideFilter(event.currentTarget.dataset.key);
  }

  const { classes = {}, className, resource, ...rest } = props;

  return (
    <div
      className={classnames(className, classes.form)}
      {...sanitizeRestProps(rest)}
    >
      {getShownFilters().map(filterElement => (
        <FilterFormInput
          key={filterElement.props.source}
          filterElement={filterElement}
          handleHide={handleHide}
          classes={classes}
          resource={resource}
        />
      ))}
      <div className={classes.clearFix} />
    </div>
  );
}

FilterForm.propTypes = {
  resource: P.string.isRequired,
  filters: P.arrayOf(P.node).isRequired,
  displayedFilters: P.object.isRequired,
  hideFilter: P.func.isRequired,
  initialValues: P.object,
  classes: P.object,
  className: P.string
};

export const mergeInitialValuesWithDefaultValues = ({
  id,
  initialValues,
  filters
}) => ({
  form: `filterForm-${id}`,
  initialValues: {
    ...filters
      .filter(({ props }) => props.alwaysOn && props.defaultValue)
      .reduce(
        (acc, { props }) => set({ ...acc }, props.source, props.defaultValue),
        {}
      ),
    ...initialValues
  }
});

const enhance = compose(
  withStyles(styles),
  withProps(mergeInitialValuesWithDefaultValues),
  reduxForm({
    enableReinitialize: true,
    destroyOnUnmount: false,
    onChange: (values, dispatch, props) => {
      props && props.setFilters(values);
    }
  })
);

export default enhance(FilterForm);
