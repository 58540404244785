import React from "react";
import P from "prop-types";
import { Card, CardContent } from "@material-ui/core";
import { Title, translate } from "react-admin";
import { connect } from "react-redux";
import compose from "recompose/compose";
import {
  getCurrentUser,
  getActiveCycle,
  getJwtValues,
  getReadingCenterById,
  checkPermissionByName
} from "../../redux/selectors";
import { MessageList } from "../messages";
import config from "../../config";

function UnconnectedRenderContext(props) {
  const {
    translate,
    enabledRcsList,
    disabledRcsList,
    hasGlobalContext
  } = props;
  const multipleRcs = enabledRcsList.length + disabledRcsList.length > 1;
  const disabledRcs = disabledRcsList.length >= 1;
  if (hasGlobalContext) {
    return (
      <p>
        {translate("mra.dashboard.text_global_context")}
        <strong>{translate("mra.dashboard.global_context")}</strong>
        {disabledRcs && (
          <>
            {translate("mra.dashboard.text_rcs_diabled_message_global_context")}
            <strong>{disabledRcsList}</strong>
          </>
        )}
      </p>
    );
  }
  const key = multipleRcs ? "mra.dashboard.text_rcs" : "mra.dashboard.text_rc";
  return disabledRcs ? (
    <p>
      {translate(key)}
      <strong>{multipleRcs ? enabledRcsList : disabledRcs}</strong>
      {translate(`${key}_disabled_message`)}
      <strong>{multipleRcs ? disabledRcsList : null}</strong>
    </p>
  ) : (
    <p>
      {translate(key)}
      <strong>{enabledRcsList}</strong>
    </p>
  );
}

UnconnectedRenderContext.propTypes = {
  translate: P.func,
  enabledRcsList: P.string,
  disabledRcsList: P.string,
  hasGlobalContext: P.bool
};

function getRcList(str, state) {
  return str
    ? str
        .split(",")
        .map(id => {
          const { remote_id, name } = getReadingCenterById(id, state);
          return `${remote_id} - ${name}`;
        })
        .join(", ")
    : "";
}

const RenderContext = compose(
  connect(state => {
    const { enabled_rcs, disabled_rcs } = getJwtValues(state) || {};
    const enabledRcsList = getRcList(enabled_rcs, state);
    const disabledRcsList = getRcList(disabled_rcs, state);
    const hasGlobalContext = checkPermissionByName(
      config.PERMISSIONS.ACESSGLOBALCONTEXT
    )(state);
    return {
      enabledRcsList,
      disabledRcsList,
      hasGlobalContext
    };
  }),
  translate
)(UnconnectedRenderContext);

function SimpleDashboard(props) {
  const {
    translate,
    active_cycle,
    currentUser: { first_name, last_name }
  } = props;
  return (
    <Card>
      <Title title={translate("mra.dashboard.title")} />
      <CardContent>
        <p>
          {translate("mra.dashboard.text_hello")}
          <strong>{`${first_name} ${last_name}`}</strong>!
          {translate("mra.dashboard.text_welcome")}
        </p>
        <RenderContext />
        <p>
          {translate("mra.dashboard.text_active_cycle")}
          <strong>{active_cycle}</strong>
        </p>
      </CardContent>
      {config.FEATURE_MESSAGES && (
        <>
          <Title title={translate("mra.messages.title")} key="title" />,
          <CardContent key="text">
            <MessageList />
          </CardContent>
        </>
      )}
    </Card>
  );
}

SimpleDashboard.propTypes = {
  translate: P.func,
  active_cycle: P.string,
  currentUser: P.object
};

const ConnectedDashboard = compose(
  connect(state => ({
    currentUser: getCurrentUser(state),
    active_cycle: getActiveCycle(state)
  })),
  translate
)(SimpleDashboard);

export default ConnectedDashboard;
