import React from "react";
import P from "prop-types";
import {
  Show,
  ReferenceField,
  TextField,
  NumberField,
  SimpleShowLayout,
  translate
} from "react-admin";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core";
import { FieldGroup, commonStyles } from "../base";
import config from "../../config";
import { TypeTranslationField, DateTimeField } from "../fields";
import ItineraryActions from "./ItineraryActions";
import { useItineraryStats } from "../hooks";
import { AvgConsumptionField } from "../fields/AvgConsumptionField";

const { ITINERARY, DISTRIBUTION_ITINERARY_RESOURCE } = config;

const ConnectedShowLayout = connect((state, props) => ({
  record: {
    ...props.record,
    ...props.stats
  }
}))(SimpleShowLayout);

const ShowTitle = ({ translate, record, resource }) => {
  return (
    <span>
      {translate(`resources.${resource}.name`, 1)}
      {record ? ` - ${record.remote_id}` : ""}
    </span>
  );
};

ShowTitle.propTypes = {
  translate: P.func,
  record: P.object,
  resource: P.string
};

/**
 * component for displaying itinerary details
 */
function ItineraryDetails({ classes, DetailsList, types, ...props }) {
  const { id } = props;
  const resource =
    props.resource === DISTRIBUTION_ITINERARY_RESOURCE
      ? props.resource
      : ITINERARY;
  const stats = useItineraryStats(id, resource);
  return (
    <Show
      {...props}
      actions={<ItineraryActions />}
      title={
        <ShowTitle translate={props.translate} resource={props.resource} />
      }
    >
      <ConnectedShowLayout stats={stats}>
        <FieldGroup
          title="mra.itinerary_overview.itinerary_data"
          className={classes.fieldGroup}
        >
          <TextField source="route" />
          <TextField source="remote_id" />
          <TextField source="cycle" />
          <TextField source="cycle_year" />
          <TextField source="description" />
          <TypeTranslationField source="status" types={types} />
          <DateTimeField source="scheduled_at" addLabel />
          <AvgConsumptionField
            addLabel
            label={`resources.${config.READING_RESOURCE}.fields.avg_consumption`}
            source="avg_consumption"
            avg_source="c_money_brutto"
            cur_source="c_money_brutto_currency"
          />
          <ReferenceField
            addLabel={true}
            source="center_id"
            reference={config.READINGCENTER_RESOURCE}
            linkType={false}
            allowEmpty
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            addLabel={true}
            source="agent_id"
            reference={config.USER_RESOURCE}
            linkType={false}
            allowEmpty
          >
            <TextField source="username" />
          </ReferenceField>
          <DateTimeField addLabel source="completion_confirmed_at" />
          <ReferenceField
            addLabel
            source="completion_confirmed_by_id"
            reference={config.USER_RESOURCE}
            linkType={false}
            allowEmpty
          >
            <TextField source="username" />
          </ReferenceField>
        </FieldGroup>
        <FieldGroup
          title="mra.itinerary_overview.summary"
          className={classes.fieldGroup}
        >
          <NumberField source="task_count" style={{ textAlign: "left" }} />
          <NumberField source="result_count" style={{ textAlign: "left" }} />
          <NumberField
            source="total_anomaly_count"
            style={{ textAlign: "left" }}
          />
          <NumberField source="anomaly_count" style={{ textAlign: "left" }} />
          <NumberField
            source="picture_anomaly_count"
            style={{ textAlign: "left" }}
          />
          <DateTimeField source="updated_at" addLabel />
          <DateTimeField source="created_at" addLabel />
          <DateTimeField source="imported_at" addLabel />
        </FieldGroup>
        <FieldGroup
          title="mra.itinerary_overview.details"
          className={classes.fieldGroup}
        >
          <DetailsList {...props} />
        </FieldGroup>
      </ConnectedShowLayout>
    </Show>
  );
}

ItineraryDetails.propTypes = {
  id: P.string,
  types: P.object,
  translate: P.func,
  classes: P.object,
  DetailsList: P.func,
  resource: P.string
};

export default compose(
  withStyles({
    ...commonStyles,
    noPaper: {
      boxShadow: "none"
    },
    expanded: {
      marginBottom: "0 !important"
    },
    summaryRoot: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      marginBottom: "0 !important",
      width: "100%"
    },
    expandIcon: {
      top: 73,
      left: 250,
      right: "auto",
      "&$expanded": {
        top: 80
      }
    },
    extendedData: {
      display: "flex",
      flexDirection: "row"
    }
  }),
  translate
)(ItineraryDetails);
