import React from "react";
import {
  Edit,
  TextInput,
  ReferenceInput,
  SelectInput,
  translate,
  AutocompleteArrayInput,
  required
} from "react-admin";
import compose from "recompose/compose";
import config from "../../config";
import PropTypes from "prop-types";
import {
  RestrictedEditActions,
  RestrictedCustomForm,
  RestrictedReferenceArrayInput
} from "../restricted-components";
import { addRcFilter } from "../layout";
import { connect } from "react-redux";
import { checkPermissionByName } from "../../redux/selectors";

const EditTitle = translate(props => (
  <span>
    {props.translate(`resources.${config.READINGUNIT_RESOURCE}.name`, 2)} -{" "}
    {props.record ? `"${props.record.name}"` : ""}
  </span>
));

EditTitle.contextTypes = {
  translate: PropTypes.func
};

export const ReadingUnitEdit = ({
  // eslint-disable-next-line no-unused-vars
  dispatch,
  disableTypeEdit,
  rcFilter,
  translate,
  ...props
}) => {
  return (
    <Edit
      title={<EditTitle />}
      actions={
        <RestrictedEditActions
          hasDelete
          requiredPermissionsDeleteButton={[
            config.PERMISSIONS.READING_UNIT_DELETE
          ]}
        />
      }
      {...props}
    >
      <RestrictedCustomForm
        disabledToolbarTitle={translate("mra.general.readonly_details")}
        requiredPermissions={[config.PERMISSIONS.READING_UNIT_UPDATE]}
        denyFor={[config.PERMISSIONS.RC_MEMBER]}
      >
        <TextInput
          source="name"
          validate={required("mra.reading_unit.name")}
          label={`resources.${config.READINGUNIT_RESOURCE}.fields.name`}
        />
        <SelectInput
          source="type"
          choices={config.RU_TYPES}
          validate={required("mra.reading_unit.type")}
          label={`resources.${config.READINGUNIT_RESOURCE}.fields.type`}
          options={{ disabled: disableTypeEdit }}
        />
        <TextInput source="contact_person" />
        <TextInput source="phone" />
        <TextInput source="email" type="email" />
        <TextInput source="registration" />
        <TextInput source="tax_number" />
        <TextInput options={{ disabled: true }} source="ref_number" />
        <TextInput source="address" />
        <ReferenceInput
          source="region_id"
          reference={config.REGION_RESOURCE}
          validate={required("mra.validation.required")}
          label={`resources.${config.READINGUNIT_RESOURCE}.fields.region_id`}
        >
          <SelectInput options={{ name: "region" }} optionText="name" />
        </ReferenceInput>

        <RestrictedReferenceArrayInput
          allowEmpty
          source="centers"
          reference={config.READINGCENTER_RESOURCE}
          requiredPermissions={[
            config.PERMISSIONS.READING_UNIT_ASSIGN_READING_CENTER
          ]}
          readonlyIfNoPermission
          filterToQuery={searchText => ({ remote_id: searchText })}
          filter={
            rcFilter && rcFilter !== config.RC_CONTEXT_ALL
              ? { id: rcFilter }
              : null
          }
          validate={[required("mra.validation.required")]}
          optionText="remote_id"
        >
          {/* TODO: remove duplicates */}
          <AutocompleteArrayInput
            source="reading_center"
            optionText="remote_id"
          />
        </RestrictedReferenceArrayInput>
      </RestrictedCustomForm>
    </Edit>
  );
};

ReadingUnitEdit.propTypes = {
  rcFilter: PropTypes.string,
  dispatch: PropTypes.func,
  disableTypeEdit: PropTypes.bool,
  translate: PropTypes.func
};

export default compose(
  connect(
    state => ({
      disableTypeEdit: checkPermissionByName(config.PERMISSIONS.RU_MEMBER)(
        state
      )
    }),
    null
  ),
  translate
)(addRcFilter(ReadingUnitEdit));
