import React from "react";
import P from "prop-types";
import { DateInput } from "react-admin";

function noop() {}

export function DateRangeInput(props) {
  const { input } = props;
  const [from, to = ""] = input.value.split(" ");

  const onFromChange = value => {
    input.onChange(`${value} ${to}`);
  };
  const onToChange = value => {
    input.onChange(`${from} ${value}`);
  };
  return (
    <span>
      <DateInput
        {...props}
        label={"from"}
        input={{
          ...input,
          value: from,
          onChange: onFromChange,
          onBlur: noop
        }}
      />
      {" - "}
      <DateInput
        {...props}
        label={"to"}
        input={{
          ...input,
          value: to,
          onChange: onToChange,
          onBlur: noop
        }}
      />
    </span>
  );
}

DateRangeInput.propTypes = {
  input: P.shape({
    value: P.string,
    onChange: P.func
  })
};
