import React from "react";
import PropTypes from "prop-types";
import { TextField, List } from "react-admin";
import config from "../../config";
import { Datagrid } from "../base";
import { DateTimeField } from "../fields";

/**
 * component for displaying reading tasks
 */
const ReadingTaskList = props => (
  <List
    {...props}
    resource={config.READINGTASK_RESOURCE}
    hasCreate={false}
    perPage={config.PAGINATION}
  >
    <Datagrid>
      <TextField
        label={`resources.${config.METER_RESOURCE}.fields.meter_identifier`}
        source="meter.meter_identifier"
      />
      <TextField
        label={`resources.${config.METER_RESOURCE}.fields.manufacturer`}
        source="meter.manufacturer"
      />
      <TextField
        label={`resources.${config.READING_RESOURCE}.fields.reading_value`}
        source="latest_result.value"
      />
      <DateTimeField
        label={`resources.${config.READING_RESOURCE}.fields.date_visited`}
        source="latest_result.date_visited_at"
      />
      <TextField
        label={`resources.${config.READING_RESOURCE}.fields.input_type`}
        source="latest_result.input_type"
      />
      <TextField
        label={`resources.${config.READING_RESOURCE}.fields.is_out_of_vicinity`}
        source="latest_result.is_out_of_vicinity"
      />
      <TextField
        label={`resources.${config.READING_RESOURCE}.fields.is_out_of_range`}
        source="latest_result.is_out_of_range"
      />
    </Datagrid>
  </List>
);

ReadingTaskList.propTypes = {
  translate: PropTypes.func
};

export default ReadingTaskList;
