import { hide } from "../fields/hideableField";
import { compose, mapProps } from "recompose";
import { difference, size, omit, compact, intersection } from "lodash";
import { Button as FlatButton } from "@material-ui/core";
import {
  getCurrentUserPermissionNames,
  getRcContext
} from "../../redux/selectors";
import config from "../../config";
import {
  EditButton,
  CreateButton,
  DeleteButton,
  BulkDeleteButton
} from "react-admin";

export const checkPermission = (state, props) => {
  const permissions = compact(getCurrentUserPermissionNames(state));
  const { requiredPermissions, disableInGlobalContext, denyFor } = props;
  const rcContext = getRcContext(state);
  if (disableInGlobalContext && rcContext === config.RC_CONTEXT_ALL)
    return false;
  if (denyFor && size(intersection(denyFor, permissions)) > 0) return false;
  if (requiredPermissions) {
    return size(difference(requiredPermissions, permissions)) === 0;
  }
  return true;
};

export const RestrictedFlatButton = compose(
  hide(checkPermission),
  mapProps(props =>
    omit(props, [
      "display",
      "denyFor",
      "disableInGlobalContext",
      "dispatch",
      "requiredPermissions"
    ])
  )
)(FlatButton);

export const RestrictedEditButton = compose(
  hide(checkPermission),
  mapProps(props =>
    omit(props, [
      "display",
      "denyFor",
      "disableInGlobalContext",
      "dispatch",
      "requiredPermissions"
    ])
  )
)(EditButton);

export const RestrictedCreateButton = compose(
  hide(checkPermission),
  mapProps(props =>
    omit(props, [
      "display",
      "denyFor",
      "disableInGlobalContext",
      "dispatch",
      "requiredPermissions"
    ])
  )
)(CreateButton);

export const RestrictedDeleteButton = compose(
  hide(checkPermission),
  mapProps(props =>
    omit(props, [
      "display",
      "denyFor",
      "disableInGlobalContext",
      "dispatch",
      "requiredPermissions"
    ])
  )
)(DeleteButton);

export const RestrictedBulkDeleteButton = compose(
  hide(checkPermission),
  mapProps(props =>
    omit(props, [
      "display",
      "denyFor",
      "disableInGlobalContext",
      "dispatch",
      "requiredPermissions"
    ])
  )
)(BulkDeleteButton);
