import React from "react";
import PropTypes from "prop-types";
import { printRoutesheet, printRoutesheetComplete } from "../../redux/actions";
import ReactDOMServer from "react-dom/server";
import RouteSheet from "./RouteSheet";
import { connect } from "react-redux";
import config from "../../config";
import { RestrictedFlatButton } from "../restricted-components";
import Print from "@material-ui/icons/Print";
import {
  getAllReadingTasks,
  getAllUsers,
  getReadingCenterById
} from "../../redux/selectors";

class PrintButton extends React.Component {
  printSheet = () => {
    const { readingTasks, readingCenter, reader } = this.props;
    const routesheet = (
      <RouteSheet
        itinerary={this.props.record}
        readingTasks={readingTasks}
        readingCenter={readingCenter}
        reader={reader}
      />
    );
    const content = ReactDOMServer.renderToStaticMarkup(routesheet);
    const pri = this.iframe.contentWindow;
    pri.document.open();
    pri.document.write(content);
    pri.document.close();
    pri.focus();
    pri.print();
    this.props.handlePrintComplete(this.props.record.id);
  };

  //label={translate("mra.itinerary_assign.print_button")}
  render() {
    const { handleClick, record, loading } = this.props;
    if (loading === config.PRINT_STATE.READY) this.printSheet();
    return !(record.method === config.METHOD_TYPES[1].id) ? null : (
      <div>
        <RestrictedFlatButton
          color="primary"
          name={`print-button-${record.id}`}
          onClick={() => handleClick(record.id)}
          disabled={loading === config.PRINT_STATE.LOADING}
          requiredPermissions={[config.PERMISSIONS.READING_ITINERARY_PRINT]}
          style={{ minWidth: 0, overflow: "inherit" }}
        >
          <Print />
        </RestrictedFlatButton>
        <iframe
          ref={iframe => {
            this.iframe = iframe;
          }}
          title="print_frame"
          style={{
            height: "0px",
            width: "0px",
            position: "absolute",
            border: "0"
          }}
        />
      </div>
    );
  }
}
PrintButton.propTypes = {
  handleClick: PropTypes.func,
  record: PropTypes.object,
  loading: PropTypes.string,
  handlePrintComplete: PropTypes.func,
  readingTasks: PropTypes.array,
  readingCenter: PropTypes.object,
  reader: PropTypes.object
};

const mapStateToProps = (state, props) => ({
  loading: state.mra.routesheetLoading[props.record.id],
  readingTasks: Object.values(getAllReadingTasks(state)).filter(
    rt => rt.itinerary_id === props.record.id
  ),
  readingCenter: getReadingCenterById(props.record.reading_center_id, state),
  reader: getAllUsers(state)[props.record.reader_id]
});

export default connect(
  mapStateToProps,
  {
    handleClick: printRoutesheet,
    handlePrintComplete: printRoutesheetComplete
  }
)(PrintButton);
