import React, { Children } from "react";
import P from "prop-types";
import { Filter, TextInput, SelectInput } from "react-admin";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import config, {
  cycleChoices,
  pictureQtyChoices,
  itineraryTypeChoices
} from "../../../config";
import { getCurrentUserPermissionNames } from "../../../redux/selectors";
import { ReferenceInput, AutocompleteInput } from "../../inputs";

const permissionMap = {
  supply_point_eid: "rms_search_by_supply_point_number",
  meter_identifier: "rms_search_by_meter_number",
  agent_id: "rms_search_by_field_agent",
  client_activity_id: "rms_search_by_customer_activity",
  namespace: "rms_search_by_itinerary_type",
  itinerary_eid: "rms_search_by_itinerary_id",
  cycle: "rms_search_by_cycle",
  quality: "rms_search_by_picture_quality"
};

const FilterWithPermissions = connect(state => ({
  perms: getCurrentUserPermissionNames(state)
}))(({ children, perms, ...props }) => (
  <Filter {...props}>
    {Children.map(
      children,
      child => perms.includes(permissionMap[child.props.source]) && child
    )}
  </Filter>
));

const CardFilter = ({ classes, ...props }) => (
  <FilterWithPermissions {...props}>
    <TextInput
      alwaysOn
      className={classes.root}
      label="mra.picture_filter.supply_point_eid"
      source="supply_point_eid"
    />
    <TextInput
      alwaysOn
      className={classes.root}
      label="mra.picture_filter.meter_identifier"
      source="meter_identifier"
    />
    <ReferenceInput
      alwaysOn
      className={classes.root}
      label="mra.picture_filter.agent_id"
      source="agent_id"
      filter={{ "@role_id": "6" }}
      filterToQuery={username => ({ username })}
      reference={config.USER_RESOURCE}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <ReferenceInput
      alwaysOn
      className={classes.root}
      label="mra.picture_filter.itinerary_id"
      source="itinerary_eid"
      filterToQuery={remote_id => ({ remote_id })}
      reference={config.ITINERARY_RESOURCE}
    >
      <AutocompleteInput
        limitChoicesToValue
        optionValue="remote_id"
        optionText="remote_id"
      />
    </ReferenceInput>
    <ReferenceInput
      alwaysOn
      classes={classes}
      label="mra.picture_filter.client_activity_id"
      source="client_activity_id"
      reference={config.CLIENT_ACTIVITY_RESOURCE}
    >
      <SelectInput optionText="code" />
    </ReferenceInput>
    <SelectInput
      alwaysOn
      label="mra.picture_filter.namespace"
      source="namespace"
      choices={itineraryTypeChoices}
      className={classes.selectInput}
    />
    <SelectInput
      alwaysOn
      label="mra.picture_filter.cycle"
      source="cycle"
      choices={cycleChoices}
      className={classes.selectInput}
    />
    <TextInput
      alwaysOn
      classes={classes}
      label="mra.picture_filter.year"
      source="year"
    />
    <SelectInput
      alwaysOn
      label="mra.picture_filter.quality"
      source="quality"
      choices={pictureQtyChoices}
      className={classes.selectInput}
    />
  </FilterWithPermissions>
);

CardFilter.propTypes = {
  classes: P.object
};

export default withStyles({
  root: {
    width: "150px",
    marginBottom: 0
  },
  selectInput: { marginBottom: 0 }
})(CardFilter);
