import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { crudGetList, crudUpdate, translate } from "react-admin";
import compose from "recompose/compose";
import config from "../../config";
import { getAllMessages, getCurrentUserId } from "../../redux/selectors";
import { List, ListItem, Divider, IconButton } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import CheckIcon from "@material-ui/icons/CheckCircle";
import ReadIcon from "@material-ui/icons/Drafts";
import { flatMap } from "lodash";
import { colors } from "@material-ui/core";

const grey100 = colors.grey;
const grey400 = colors.grey;
const lightBlack = colors.lightBlue;

const Message = translate(
  ({
    message: { subject, text, read_at, created_at },
    message,
    onClick,
    style,
    translate
  }) =>
    <ListItem
      leftIcon={<InfoIcon />}
      primaryText={
        <p>
          {subject}&nbsp;&nbsp;<span style={{ color: lightBlack }}>
            {created_at}
          </span>
        </p>
      }
      secondaryText={
        <p>
          {text}
        </p>
      }
      secondaryTextLines={1}
      style={!read_at ? style : { backgroundColor: grey100, ...style }}
      disabled
      rightIconButton={
        !read_at
          ? <IconButton
              touch
              tooltip={translate("mra.messages.mark_as_read")}
              onClick={() => onClick(message)}
            >
              <CheckIcon />
            </IconButton>
          : <IconButton
              tooltip={translate("mra.messages.read_at", { read_at })}
            >
              <ReadIcon color={grey400} />{" "}
            </IconButton>
      }
    />
);

Message.propTypes = {
  translate: PropTypes.func
};

class MessageList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false
    };
  }

  componentDidUpdate({ currentUserId, getMessages, messages }) {
    if (!this.state.loading && currentUserId && messages.length === 0) {
      this.setState({ loading: true }, () => getMessages(currentUserId));
    } else if (
      messages.length !== this.props.messages.length &&
      this.state.loading
    )
      this.setState({ loading: false });
  }

  render() {
    const { messages, markAsRead, limit, translate } = this.props,
      { loading } = this.state;
    messages.sort((a, b) => new Date(a.created_at) < new Date(b.created_at));
    return loading
      ? <div>
          {translate("mra.general.loading")}...
        </div>
      : <List>
          {flatMap(messages, (m, idx) => {
            const msg = <Message message={m} key={m.id} onClick={markAsRead} />;
            if (idx > limit - 1) return [];
            else if (idx < messages.length - 1)
              return [msg, <Divider key={m.id + "_d"} />];
            return msg;
          })}
        </List>;
  }
}
MessageList.propTypes = {
  currentUserId: PropTypes.string,
  getMessages: PropTypes.func,
  messages: PropTypes.array,
  markAsRead: PropTypes.func,
  limit: PropTypes.number,
  translate: PropTypes.func
};
MessageList.defaultProps = {
  limit: config.DEFAULT_MSG_LIMIT
};

export default compose(
  connect(
    state => ({
      messages: Object.values(getAllMessages(state)),
      currentUserId: getCurrentUserId(state)
    }),
    {
      getMessages: receiver_id =>
        crudGetList(
          config.MESSAGES_RESOURCE,
          { page: 1, perPage: config.DEFAULT_MSG_LIMIT },
          { field: "created_at", order: "DESC" },
          { receiver_id }
        ),
      markAsRead: message =>
        crudUpdate(
          config.MESSAGES_RESOURCE,
          message.id,
          { read_at: new Date() },
          message,
          "/",
          ""
        )
    }
  ),
  translate
)(MessageList);
