import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-admin";
import { withStyles } from "@material-ui/core";

const TypeTranslationField = withStyles(theme => ({
  field: { fontSize: theme.typography.fontSize }
}))(({ source, classes, record = {}, types, translate }) =>
  <span className={classes.field}>
    {translate((types[record[source]] || {}).name)}
  </span>
);

TypeTranslationField.propTypes = {
  addLabel: PropTypes.bool,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  types: PropTypes.object,
  translate: PropTypes.func
};

TypeTranslationField.defaultProps = {
  addLabel: true
};

export default translate(TypeTranslationField);
