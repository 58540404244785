import { createElement } from "react";
import { number, minValue, maxValue } from "react-admin";
import { NumberInput, TextInput, SelectInput } from "react-admin";
import isArray from "lodash/isArray";
import { CHOICE, mobileSettings, serverSettings } from "../../config/settings";
import config from "../../config";

export function SettingInput(props) {
  const { record: { name }, resource, validate = [] } = props;
  const setting = (resource === config.DEVICES_SETTINGS_RESOURCE
    ? mobileSettings
    : serverSettings)[name];
  const validators = isArray(validate) ? validate : [validate];
  return setting
    ? setting.type === CHOICE
      ? createElement(SelectInput, {
          ...props,
          choices: setting.choices
        })
      : createElement(NumberInput, {
          ...props,
          validate: [
            ...validators,
            number(),
            minValue(setting.min),
            maxValue(setting.max)
          ]
        })
    : createElement(TextInput, props);
}
