import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import omit from "lodash/omit";
import {
  List,
  TextField,
  Filter,
  TextInput,
  AutocompleteInput,
  ReferenceInput
} from "react-admin";
import { Datagrid, ListActions } from "../base";
import { DateTimeField } from "../fields";
import { DateRangeInput } from "../inputs";
import { getFilter } from "../layout";
import config from "../../config";

function CalendarFilter(props) {
  return (
    <Filter {...props}>
      <TextInput source="cycle_year" />
      <TextInput source="cycle" />
      <TextInput source="itinerary_code" />
      <ReferenceInput
        source="rc_code"
        reference={config.READINGCENTER_RESOURCE}
        filterToQuery={searchText => ({ remote_id: searchText })}
        allowEmpty
      >
        <AutocompleteInput
          options={{ name: "reading center" }}
          optionValue="remote_id"
          optionText="remote_id"
        />
      </ReferenceInput>
      <DateRangeInput source="scheduled_at" />
    </Filter>
  );
}

function ReadingCalendarList(props) {
  return (
    <List
      {...props}
      actions={<ListActions />}
      filters={<CalendarFilter />}
      bulkActions={false}
    >
      <Datagrid>
        <TextField source="cycle_year" />
        <TextField source="cycle" />
        <TextField source="itinerary_code" />
        <TextField source="rc_code" />
        <TextField source="rc_name" />
        <DateTimeField source="scheduled_at" format={"DD/MM/YYYY"} />
      </Datagrid>
    </List>
  );
}

const EnhancedReadingCalendarList = compose(
  connect((state, props) => ({ filter: getFilter(state, props) })),
  mapProps(props => omit(props, ["dispatch"]))
)(ReadingCalendarList);

export { EnhancedReadingCalendarList as ReadingCalendarList };
