export {
  cycleChoices,
  pictureQtyChoices,
  itineraryTypeChoices
} from "./filterOptions";

export default {
  version: "1.0.0",
  FEATURE_MESSAGES: false,
  // API URLs
  API_BASE_URL:
    global.MRA.API_BASE_URL !== "VARAPIBASEURL"
      ? global.MRA.API_BASE_URL
      : process.env.NODE_ENV !== "production"
      ? "https://api.mra.accept.maviance.info"
      : console.log(
          "The key VARAPIBASEURL in index.html should be replaced!"
        ) && global.MRA.API_BASE_URL,
  LOGIN_URL: "/login",
  ITINERARY_RESET_URL: "/itinerary/:id/reset",
  ITINERARY_UPDATE_URL: "/itinerary/:id",
  CONFIRM_DEVICE_URL: "/device/:id/confirm",
  ITINERARY_CONFIRM: "/itinerary/:id/confirm_completion",
  RESEQUENCE_ITINERARY_URL: "/itinerary/:id/resequence",
  FORCE_QC_URL: "/itinerary/:id/force_quality_check",
  ACTIVATE_USER_URL: "/user/:id?showDeleted=true",
  ACTIVATE_READING_CENTER_URL: "/center/:id?showDeleted=true",
  ACTIVATE_DEVICE_URL: "/device/:id?showDeleted=true",
  REOPEN_ITINERARY_URL: "/itinerary/:id/reopen",
  AGENT_ASSIGN: "/itinerary/:id/assign/:agent_id",
  PAPER_ASSIGN: "/itinerary/:id/assign/:agent_id/:capturer_id",
  METHOD_ASSIGN: "/itinerary/:id/method/:new_method",
  ASSIGN_URL:
    "/itinerary/:id/method_with_assign/:new_method/:agent_id/:capturer_id",
  UNASSIGN_URL: "/itinerary/:id/unassign",
  QUALIFY_URL: "/image/:id/qualify",
  // custom route paths
  DATA_CAPTURE_PATH: "/reading_itineraries/:id/capture",
  FORCE_EXPORT_ITINERARY_PATH: "/reading_itineraries/:id/force_export",
  FORCE_QC_PATH: "/reading_itineraries/:id/force_qc",
  REOPEN_ITINERARY_PATH: "/:itinerary_resource/:id/reopen",
  RETURN_ITINERARY_PATH: "/reading_itineraries/:id/return",
  RETURN_ITINERARIES_BULK_PATH: "/reading_itineraries/:id/returnbulk",
  GENERATE_CONTROL_READING_PATH:
    "/reading_itineraries/:id/generate_control_reading",
  GENERATE_ANOMALY_CORRECTION_PATH:
    "/reading_itineraries/:id/generate_anomaly_correction",
  RESEQUENCE_ITINERARY_PATH: "/reading_itineraries/:id/resequence",
  RESEQUENCE_ITINERARY_CONFIRM_PATH:
    "/reading_itineraries/:id/resequence/confirm",
  COMPLETE_CAPTURE_PATH: "/reading_itineraries/:id/capture_confirm",
  ROUTESHEET_UPLOAD_PATH: "/reading_itineraries/:id/routesheet_upload",
  READING_CENTER_QUALITY_RULE_PATH: "/center/:id/quality_rule/:qId",
  UNASSIGN_CONFIRM_PATH: "/:type/:id/unassign",

  // App defaults
  DEFAULT_LOCALE: "en",
  PAGINATION: 25,
  DEFAULT_MSG_LIMIT: 10,

  // base paths
  DISTRIBUTION_ASSIGN_BASE_PATH: "distribution_assign",

  // Resources
  USER_RESOURCE: "user",
  READINGCENTER_RESOURCE: "center",
  READINGUNIT_RESOURCE: "unit",
  REGION_RESOURCE: "region",
  ITINERARY_RESOURCE: "reading_itineraries",
  DISTRIBUTION_ITINERARY_RESOURCE: "distribution_itineraries",
  DEVICE_RESOURCE: "device",
  READINGTASK_RESOURCE: "reading_tasks",
  DISTRIBUTION_TASK_RESOURCE: "distribution_tasks",
  DISTRIBUTION_RESOURCE: "distributions",
  READING_RESOURCE: "result",
  LOCATION: "itinerary_location",
  TASK_LOCATION: "task_location",
  METER_RESOURCE: "known_meter",
  ANOMALIES_RESOURCE: "anomaly",
  REGISTERED_METERS_RESOURCE: "registered_meter",
  SETTINGS_RESOURCE: "setting",
  DEVICES_SETTINGS_RESOURCE: "device_setting",
  WORK_HOURS_RESOURCE: "workhour",
  READING_TYPE_RESOURCE: "reading_type",
  METER_TYPE_RESOURCE: "meter_type",
  SUPPLYPOINT_RESOURCE: "supply_point",
  ROLE_RESOURCE: "role",
  PERMISSION_RESOURCE: "permission",
  USER_INFO: "userinfo",
  CLIENT_ACTIVITY_RESOURCE: "client_activity",
  QUALITY_RULES_RESOURCE: "quality_rule",
  REPORTS_RESOURCE: "mra_reports",
  IMAGE_RESOURCE: "image",
  IMAGE_RESOURCE_QUALIFY: "qualify",
  QUALITYRUN_RESOURCE: "quality_run",
  MESSAGES_RESOURCE: "messages",
  RMS_RESOURCE: "rms_picture_viewer",
  CONTROL_READING_ITINERARY_RESOURCE: "control_reading_itineraries",
  ANOMALY_CORRECTION_ITINERARY_RESOURCE: "anomaly_correction_itineraries",
  CONTROL_READING_TASK_RESOURCE: "control_reading_tasks",
  ANOMALY_CORRECTION_TASK_RESOURCE: "anomaly_correction_tasks",
  ITINERARY: "itinerary",
  SUPPLY_POINT: "supply_point",
  TASK: "task",
  TASK_RESULT: "result",
  EXPORT_RECON_RESOURCE: "export_recon",
  IMPORT_RECON_RESOURCE: "import_recon",
  TOP_ITINERARY_RESOURCE: "top_itineraries",
  RECON_RESOURCE: "api/v1/readings_information",
  READING_CALENDAR_RESOURCE: "calendar",
  // Custom route paths
  PROFILE_PATH: "/profile",
  LOGIN_PATH: "/login",
  RESET_PATH: "/:resource/:id/reset",
  ASSIGN_PATH: "/:resource/:id/assign",
  ITINERARIES_ASSIGN_PATH: "/itineraries_assign",
  DISTRIBUTION_ASSIGN_PATH: "/:resource/:id/distribution_assign",
  DISTRIBUTION_ITINERARIES_ASSIGN_PATH: "/distribution_itineraries_assign",
  CONTROL_READING_ASSIGN_PATH:
    "/control_reading_itineraries/:id/control_reading_assign",
  ANOMALY_CORRECTION_ASSIGN_PATH:
    "/anomaly_correction_itineraries/:id/anomaly_correction_assign",
  CONTROL_READING_ITINERARY_ASSIGN_PATH: "/control_reading_itinerary_assign",
  ANOMALY_CORRECTION_ITINERARY_ASSIGN_PATH:
    "/anomaly_correction_itinerary_assign",
  RESET_ITINERARY_PATH: "/:resource/:id/reset_itinerary",
  DEVICES_CONFIRM_PATH: "/:resource/:id/confirm",
  USER_ACTIVATE_PATH: "/user/:id/activate",
  COMPLETION_CONFIRM: "/:resource/:id/completion_confirm",
  DEVICE_ACTIVATE_PATH: "/device/:id/activate",
  RC_ACTIVATE_PATH: "/center/:id/activate",
  CLIENT_ACTIVITY_ACTIVATE_PATH: "/client_activity/:id/activate",
  ANOMALIES_ACTIVATE_PATH: "/anomaly/:id/activate",
  DOCS_BOM_PATH: "/docs/bom/Introduction.html",
  DOCS_APP_PATH: "/docs/app/Introduction1.html",
  READING_TYPE: "reading",
  DISTRIBUTION_TYPE: "distribution",
  CONTROL_ITINERARY_TYPE: "control",
  ANOMALY_CORRECTION_TYPE: "anomaly_correction",
  EXPORT_DIRECTION_TYPE: "OUTBOUND",
  IMPORT_DIRECTION_TYPE: "INBOUND",
  // type constants
  // TODO: translate names in components

  CHILD_FLAGS: {
    DISTRIBUTION: 1,
    ANOMALY_CORRECTION: 2,
    CONTROL_READING: 4
  },
  LOCALES: {
    ENGLISH: "en",
    FRENCH: "fr"
  },
  RU_TYPES: [
    { id: "internal", name: "mra.constants.ru_types.internal" },
    { id: "external", name: "mra.constants.ru_types.external" }
  ],
  METHOD_TYPES: [
    { id: "APP", name: "mra.constants.method_types.mobile" },
    { id: "PAPER", name: "mra.constants.method_types.paper" }
  ],
  ASSIGN_METHODS: {
    APP: "app",
    PAPER: "paper"
  },
  INPUT_TYPES: [
    { id: "MANUAL", name: "mra.constants.input_types.manual" },
    { id: "OPTICAL", name: "mra.constants.input_types.optical" }
  ],
  LOCALE_TYPES: {
    en: {
      id: "en",
      name: "English",
      label: "label_lang_one",
      description: "descr_lang_one",
      dateFormat: "DD/MM/YYYY",
      dateTimeFormat: "DD/MM/YYYY, HH:mm"
    },
    fr: {
      id: "fr",
      name: "French",
      label: "label_lang_two",
      description: "descr_lang_two",
      dateFormat: "DD/MM/YYYY",
      dateTimeFormat: "DD/MM/YYYY, HH:mm"
    }
  },
  ITINERARY_TYPES: [
    { id: "reading", name: "Reading" },
    { id: "distribution", name: "Distribution" },
    { id: "control", name: "Control" },
    { id: "anomaly_correction", name: "Correction" }
  ],
  ITINERARY_STATUS_TYPES: {
    UNASSIGNED: {
      id: "UNASSIGNED",
      name: "mra.constants.itinerary_status.unassigned"
    },
    FULLY_RECEIVED: {
      id: "FULLY_RECEIVED",
      name: "mra.constants.itinerary_status.mobile_reading_fully_received"
    },
    COMPLETED: {
      id: "COMPLETED",
      name: "mra.constants.itinerary_status.mobile_reading_completed"
    },
    IN_PROGRESS: {
      id: "IN_PROGRESS",
      name: "mra.constants.itinerary_status.mobile_reading_in_progress"
    },
    READY: {
      id: "READY",
      name: "mra.constants.itinerary_status.mobile_reading_ready"
    },
    PAPER_READING_READY: {
      id: "PAPER_READING_READY",
      name: "mra.constants.itinerary_status.paper_reading_ready"
    },
    RETURN_FOR_ESTIMATION: {
      id: "RETURN_FOR_ESTIMATION",
      name: "mra.constants.itinerary_status.return_for_estimation"
    },
    PAPER_READING_DATA_BEING_CAPTURED: {
      id: "PAPER_READING_DATA_BEING_CAPTURED",
      name: "mra.constants.itinerary_status.paper_reading_data_being_captured"
    },
    PAPER_READING_FAGENT_SELECTED: {
      id: "PAPER_READING_FAGENT_SELECTED",
      name: "mra.constants.itinerary_status.paper_reading_fagent_selected"
    },
    PAPER_READING_FULLY_ENTERED: {
      id: "PAPER_READING_FULLY_ENTERED",
      name: "mra.constants.itinerary_status.paper_reading_fully_entered"
    }
  },
  ANOMALY_TYPES: {
    PICTURE: { id: "PICTURE", name: "mra.anomaly.picture" },
    READING: { id: "READING", name: "mra.anomaly.reading" },
    CONTROL: { id: "CONTROL", name: "mra.anomaly.control" },
    CORRECTION: { id: "CORRECTION", name: "mra.anomaly.correction" },
    DISTRIBUTION: { id: "DISTRIBUTION", name: "mra.anomaly.distribution" },
    OCCUPANCY: { id: "OCCUPANCY", name: "mra.anomaly.occupancy" }
  },
  PICTURE_QUALITY: {
    NOK: { id: "NOK", name: "NOK" },
    OK: { id: "OK", name: "OK" }
  },
  DEVICE_STATUS_TYPES: {
    REGISTERED: {
      id: "REGISTERED",
      name: "mra.constants.device_status.registered"
    },
    EXPIRED: { id: "EXPIRED", name: "mra.constants.device_status.expired" },
    OK: { id: "OK", name: "mra.constants.device_status.ok" },
    LOST: { id: "LOST", name: "mra.constants.device_status.lost" }
  },
  GRID_VIEW: "GRID_VIEW",
  LIST_VIEW: "LIST_VIEW",
  DATA_SOURCE: {
    RMS: "RMS",
    MRA: "MRA"
  },
  RMS_RESOURCE_ROOT_FIELDS: [
    "namespace",
    "remote_id",
    "created_at",
    "modified_at",
    "filename",
    "remote_url"
  ],

  // internal constants
  IGNORE_ANOMALY_IDS: [3, 18, 75],
  PRINT_STATE: {
    DEFAULT: 1,
    LOADING: 2,
    READY: 3
  },
  FILTERS: {
    NULL: "NULL",
    NOTNULL: "NOTNULL"
  },
  RC_CONTEXT_ALL: "$ALL",
  NOPERMISSION: ["noPermission"], //if this is set for requiredPermissions, nobody has permissions
  ADMIN_ROLE: "Administrator",
  //SETTINGS
  MOBILE_APP_REGISTRATION_KEY: "MOBILE_APP_REGISTRATION_KEY",
  CURRENT_CYCLE: "CURRENT_CYCLE",
  REPORTING_SERVER_URL: "REPORTING_SERVER_URL",
  REPORTING_SERVER_PASSWORD: "REPORTING_SERVER_PASSWORD",
  REPORTING_SERVER_USERNAME: "REPORTING_SERVER_USERNAME",
  ACTIVE_MAP_SOURCE: "ACTIVE_MAP_SOURCE",
  MAP_SOURCE_OSM_URL: "MAP_SOURCE_OSM_URL",
  MAP_RESOURCE: "supply_point_map",
  MAP_SOURCE_ARCGIS_URL: "MAP_SOURCE_ARCGIS_URL",
  IMAGE_SERVER_URL: "IMAGE_SERVER_URL",
  IMAGE_SERVER_USERNAME: "IMAGE_SERVER_USERNAME",
  IMAGE_SERVER_PASSWORD: "IMAGE_SERVER_PASSWORD",
  MODALS: {
    MAP_MODAL: "MAP_MODAL"
  },
  MAPS: {
    ARCGIS: "ARCGIS",
    OSM: "OSM"
  },
  MAP_FIELDS: [
    {
      label: `resources.supply_point_map.fields.meter_identifier`,
      key: "meter.identifier"
    },
    {
      label: "resources.supply_point_map.fields.supply_point_number",
      key: "supply_point.remote_id"
    },
    {
      label: "resources.supply_point_map.fields.address",
      key: "address"
    },
    {
      label: "resources.supply_point_map.fields.previous_reading_date",
      key: "previous_result_taken_at"
    },
    {
      label: "resources.supply_point_map.fields.consumption_type",
      key: "consumption_type"
    },
    {
      label: "resources.result.fields.itinerary_remote_id",
      key: "itinerary_remote_id"
    },
    {
      label: "resources.supply_point_map.fields.service_number",
      key: "supply_point.service_number"
    },
    {
      label: "resources.supply_point_map.fields.full_customer_name",
      key: "supply_point.customer_name"
    }
  ],
  //PermissionNames
  PERMISSIONS: {
    ACESSGLOBALCONTEXT: "AccessGlobalContext",
    BOM_ALLOW_LOGIN: "bom_allow_login",
    DELETE_CONTROL_ITINERARY_TASK: "delete_control_itinerary_task",
    READING_ITINERARY_MARK_COMPLETE: "MraReadingItinerary_mark_complete",
    READING_ITINERARY_RESEQUENCE: "MraReadingItinerary_resequence",
    READING_ITINERARY_PRINT: "MraReadingItinerary_print",
    READING_ITINERARY_RETURN_FOR_ESTIMATION:
      "MraReadingItinerary_return_for_estimation",
    READING_ITINERARY_LIMIT_OWN_RU: "MraReadingItinerary_limit_to_own_ru",
    READING_ITINERARY_FORCE_EXPORT: "MraReadingItinerary_force_export",
    READING_ITINERARY_FORCE_QC: "MraReadingItinerary_quality_check",
    READING_ITINERARY_LIMIT_OWN_CAPTURER:
      "MraReadingItinerary_data_capture_only_own",
    READING_ITINERARY_REOPEN: "MraReadingItinerary_reopen",
    READING_ITINERARY_DATA_CAPTURE: "MraReadingItinerary_data_capture",
    READING_ITINERARY_DATA_ASSIGNMENT: "MraReadingItinerary_data_assignment",
    DISTRIBUTION_ITINERARY_LIMIT_OWN_RU:
      "MraDistributionItinerary_limit_to_own_ru",
    ITINERARY_CREATE: "Itinerary_create",
    ITINERARY_REOPEN: "Itinerary_reopen",
    ITINERARY_DATA_ASSIGNMENT: "Itinerary_data_assignment",
    REGISTERED_METER_READ: "MraRegisteredMeter_read",
    REGISTERED_METER_CREATE: "MraRegisteredMeter_create",
    REGISTERED_METER_UPDATE: "MraRegisteredMeter_update",
    REGISTERED_METER_DELETE: "MraRegisteredMeter_delete",
    USER_CREATE: "MraUser_create",
    USER_OWN_PROFILE: "MraUser_only_own_profile",
    USER_CHANGE_PASSWORD: "MraUser_change_password",
    USER_UPDATE_ADMINISTRATORS: "MraUser_update_Administrators",
    USER_UPDATE_NAMES: "MraUser_update_names",
    USER_UPDATE: "MraUser_update",
    USER_DELETE: "MraUser_delete",
    HQ_MEMBER: "MraMember_HQMember",
    RU_MEMBER: "MraMember_RUMember",
    RC_MEMBER: "MraMember_RCMember",
    ANOMALY_READ: "MraAnomaly_read",
    ANOMALY_CREATE: "MraAnomaly_create",
    ANOMALY_UPDATE: "MraAnomaly_update",
    ANOMALY_DELETE: "MraAnomaly_delete",
    READING_CENTER_CREATE: "MraReadingCenter_create",
    READING_CENTER_READ: "MraReadingCenter_read",
    READING_CENTER_UPDATE: "MraReadingCenter_update",
    READING_CENTER_DELETE: "MraReadingCenter_delete",
    READING_UNIT_CREATE: "MraReadingUnit_create",
    READING_UNIT_READ: "MraReadingUnit_read",
    READING_UNIT_UPDATE: "MraReadingUnit_update",
    READING_UNIT_DELETE: "MraReadingUnit_delete",
    READING_UNIT_ASSIGN_READING_CENTER: "MraReadingUnit_assign_reading_center",
    DEVICE_READ: "MraDevice_read",
    DEVICE_UPDATE: "MraDevice_update",
    DEVICE_CONFIRM: "MraDevice_confirm",
    DEVICE_DELETE: "MraDevice_delete",
    WORK_HOUR_READ: "MraWorkHour_read",
    WORK_HOUR_UPDATE: "MraWorkHour_update",
    CLIENT_ACTIVITY_READ: "MraClientActivity_read",
    CLIENT_ACTIVITY_CREATE: "MraClientActivity_create",
    CLIENT_ACTIVITY_UPDATE: "MraClientActivity_update",
    CLIENT_ACTIVITY_DELETE: "MraClientActivity_delete",
    SETTINGS_UPDATE: "MraSetting_update",
    EXECUTE_PICTURE_CONTROL_MY_RC: "execute_picture_control_my_rc",
    NAV_DASHBOARD: "nav_dashboard",
    NAV_REPORTS: "nav_reports",
    NAV_READING_ITINERARY_OVERVIEW: "nav_reading itinerary_overview",
    NAV_READING_ITINERARY_ASSIGN: "nav_reading itinerary_assign",
    NAV_DISTRIBUTION_ITINERARY_OVERVIEW: "nav_distribution itinerary_overview",
    NAV_DISTRIBUTION_ITINERARY_ASSIGN: "nav_distribution itinerary_assign",
    NAV_CONTROL_READING_ITINERARY_OVERVIEW:
      "nav_control_reading itinerary_overview",
    NAV_CONTROL_READING_ITINERARY_ASSIGN:
      "nav_control_reading itinerary_assign",
    NAV_ANOMALY_CORRECTION_ITINERARY_OVERVIEW:
      "nav_anomaly_correction itinerary_overview",
    NAV_ANOMALY_CORRECTION_ITINERARY_ASSIGN:
      "nav_anomaly_correction itinerary_assign",
    NAV_NEW_METERS_OVERVIEW: "nav_new meters_overview",
    NAV_NEW_METERS_REGISTER: "nav_new meters_register",
    NAV_PICTURE_VIEWER: "nav_picture_viewer",
    NAV_DEVICES_OVERVIEW: "nav_devices_overview",
    NAV_USERS_OVERVIEW: "nav_users_overview",
    NAV_USERS_ADD_USER: "nav_users_add user",
    NAV_ADMINISTRATION_READING_UNITS: "nav_administration_reading units",
    NAV_ADMINISTRATION_READING_CENTERS: "nav_administration_reading centers",
    NAV_ADMINISTRATION_READING_ANOMALY: "nav_administration_reading anomaly",
    NAV_ADMINISTRATION_PICTURE_ANOMALY: "nav_administration_picture anomaly",
    NAV_ADMINISTRATION_CONTROL_ANOMALY: "nav_administration_control anomaly",
    NAV_ADMINISTRATION_OCCUPANCY_ANOMALY:
      "nav_administration_occupancy anomaly",
    NAV_ADMINISTRATION_DISTRIBUTION_ANOMALY:
      "nav_administration_distribution anomaly",
    NAV_ADMINISTRATION_WORK_HOURS: "nav_administration_work hours",
    NAV_ADMINISTRATION_CLIENT_ACTIVITIES:
      "nav_administration_client activities",
    NAV_ADMINISTRATION_READING_CALENDAR: "nav_administration_reading calendar",
    NAV_SETTINGS_MOBILE_APP: "nav_settings_mobile app",
    NAV_SETTINGS_SERVER: "nav_settings_server"
  },

  /**
   * map real roles to combined roles for RoleInput Component
   * this is necessary for user create/edit, to give the user clear options to select
   */

  ROLES: {
    CONTEXT: {
      HQM: {
        AD: ["HQM", "AD"],
        SU: ["HQM", "SU"],
        RU: ["HQM", "RU"],
        PV: ["HQM", "PV"],
        PC: ["HQM", "PC"],
        SOA: ["HQM", "SOA"],
        RSU: ["HQM", "RSU"]
      },
      RUM: {
        SU: ["RUM", "US"],
        FA: ["RUM", "FA"],
        RU: ["RUM", "RU"]
      },
      RCM: {
        IT: ["RCM", "IT"],
        SU: ["RCM", "SU"],
        AG: ["RCM", "AG"],
        RU: ["RCM", "RU"]
      }
    },
    CONTEXTROLES: ["RUM", "RCM", "HQM"],
    NAMES: {
      RU: "RUM",
      RC: "RCM",
      HQ: "HQM",
      AD: "AD",
      FA: "FA",
      AG: "AG"
    },
    NOTEDITABLE: {
      All: ["RCM", "HQM", "RUM", "DX"],
      SU: ["AD"]
    },
    EDIT: {
      AD: ["RUM", "RCM", "HQM"],
      HQM: ["RUM", "RCM", "HQM"],
      RUM: ["RUM"],
      RCM: ["RUM", "RCM"]
    }
  },

  /*
  ROLES: {
    CONTEXT: {
      "HQ Member": {
        Administrator: ["HQ Member", "Administrator"],
        Supervisor: ["HQ Member", "Supervisor"],
        Report_User: ["HQ Member", "Report User"]
      },
      "RU Member": {
        Supervisor: ["RU Member", "Unit Supervisor"],
        Field_Agent: ["RU Member", "Field Agent"],
        Report_User: ["RU Member", "Report User"]
      },
      "RC Member": {
        Supervisor: ["RC Member", "Supervisor"],
        Agent: ["RC Member", "Agent"],
        Report_User: ["RC Member", "Report User"]
      }
    },
    CONTEXTROLES: ["RU Member", "RC Member", "HQ Member"],
    NAMES: {
      RU: "RU Member",
      RC: "RC Member",
      HQ: "HQ Member",
      Administrator: "Administrator",
      "Field Agent": "Field Agent"
    },
    NOTEDITABLE: {
      All: ["RC Member", "HQ Member", "RU Member", "Datax"],
      Supervisor: ["Administrator"]
    },
    EDIT: {
      Administrator: ["RU Member", "RC Member", "HQ Member"],
      "HQ Member": ["RU Member", "RC Member", "HQ Member"],
      "RU Member": ["RU Member"],
      "RC Member": ["RU Member", "RC Member"]
    }
  },
  */

  // resource relations
  // preloads are used to fetch relations on a resource
  // urls are used to set/delete m:n relations
  // id_fields are used to convert ids on requests
  PROTECTED_FIELDS: [
    "created_at",
    "updated_at",
    "imported_at",
    "registration_date",
    "images",
    "units",
    "quality_rule_assignments"
  ],
  RELATIONS: {
    user: {
      preloads: ["center", "role", "device"],
      urls: {
        centers: "center",
        roles: "role"
      },
      id_fields: ["unit_id"],
      partialSearchFields: ["username"],
      allowedFields: [
        "unit_id",
        "username",
        "first_name",
        "last_name",
        "middle_name",
        "email",
        "language",
        "password",
        "phone",
        "is_blocked",
        "user_reference",
        "blocked_reason",
        "is_reset_requested",
        "deleted_at"
      ]
    },
    role: {
      preloads: ["permission"],
      urls: {},
      id_fields: []
    },
    unit: {
      preloads: ["center", "user"],
      urls: {
        centers: "center"
      },
      partialSearchFields: ["name"],
      id_fields: ["region_id"]
    },
    center: {
      preloads: ["user", "quality_rule"],
      urls: {},
      partialSearchFields: ["name"],
      id_fields: ["region_id"]
    },
    reading_itineraries: {
      preloads: ["image"],
      urls: {},
      id_fields: ["agent_id", "capturer_id"],
      partialSearchFields: ["remote_id"]
    },
    distribution_itineraries: {
      preloads: ["image"],
      urls: {},
      id_fields: ["agent_id"],
      partialSearchFields: ["remote_id"]
    },
    device: {
      preloads: ["user"],
      urls: {
        users: "user"
      },
      partialSearchFields: [
        "device_identifier",
        "device_serial",
        "app_version"
      ],
      id_fields: ["center_id"]
    },
    readings: {
      preloads: [],
      urls: {},
      id_fields: [
        "task_id",
        "itinerary_id",
        "picture_anomaly_id",
        "reading_anomaly_id",
        "client_activity_id",
        "agent_id"
      ]
    },
    reading_tasks: {
      preloads: [
        "itinerary",
        "meter",
        "supply_point",
        "result",
        "latest_result.image"
      ],
      urls: {},
      id_fields: ["meter_id", "itinerary_id"]
    },
    distribution_tasks: {
      preloads: [
        "itinerary",
        "meter",
        "supply_point",
        "result",
        "latest_result.image"
      ],
      urls: {},
      id_fields: ["meter_id", "itinerary_id", "supply_point_id"]
    },
    registered_meter: {
      preloads: ["image", "itinerary"],
      urls: {},
      partialSearchFields: ["meter_ref"],
      id_fields: [
        "itinerary_id",
        "meter_type_id",
        "reading_type_id",
        "agent_id",
        "center_id",
        "client_activity_id",
        "anomaly_id",
        "picture_anomaly_id"
      ]
    },
    quality_run: {
      preloads: [],
      urls: {},
      id_fields: ["itinerary_id"]
    },
    itinerary: {
      preloads: ["images"],
      urls: {},
      id_fields: ["agent_id", "capturer_id"],
      partialSearchFields: ["remote_id"]
    },
    task: {
      preloads: [
        "itinerary",
        "meter",
        "supply_point",
        "result",
        "latest_result.image"
      ],
      urls: {},
      id_fields: ["meter_id", "itinerary_id", "supply_point_id"],
      partialSearchFields: ["@meter_remote_id", "@supply_point_remote_id"]
    },
    result: {
      preloads: [],
      urls: {},
      id_fields: [
        "task_id",
        "itinerary_id",
        "anomaly_id",
        "picture_anomaly_id",
        "client_activity_id",
        "agent_id"
      ]
    }
  }
};
