import React from "react";
import PropTypes from "prop-types";
import { Card, CardHeader } from "@material-ui/core";
import ActionCheck from "@material-ui/icons/CheckCircle";
import AlertError from "@material-ui/icons/ErrorOutline";
import {
  Title,
  SaveButton,
  SimpleForm,
  Toolbar,
  AutocompleteInput,
  translate
} from "react-admin";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { getRecord, getSelectedIds } from "../../redux/selectors";
import { generateItinerary } from "../../redux/actions";
import { FieldAgentInput } from "../inputs";
import { Button } from "../base/buttons";
import config from "../../config";

const styles = {
  actions: { zIndex: 2, display: "inline-block", float: "right" },
  toolbar: { clear: "both" },
  button: { margin: "10px 24px", position: "relative", padding: "8px 16px" }
};

const { ITINERARY_RESOURCE, USER_RESOURCE } = config;

const FormToolbar = ({ goBack, ...props }) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        color="primary"
        icon={<ActionCheck />}
        label={"mra.confirm.ok"}
      />
      <Button
        onClick={goBack}
        style={styles.button}
        label="ra.action.cancel"
        size={"medium"}
      >
        <AlertError />
      </Button>
    </Toolbar>
  );
};

FormToolbar.propTypes = {
  confirmButtonLabel: PropTypes.string,
  goBack: PropTypes.func
};

function Dialog(props) {
  const {
    title,
    kind,
    translate,
    record = { agent_id: 0 },
    selectedIds,
    history,
    generateItinerary
  } = props;
  return (
    <Card>
      <Title title={translate(title, { id: record.remote_id })} />
      <CardHeader title={translate(`mra.generate.${kind}`)} />
      <SimpleForm
        basePath={`/${ITINERARY_RESOURCE}`}
        record={kind === "control" ? { ...record, agent_id: 0 } : record}
        resource={ITINERARY_RESOURCE}
        save={({ agent_id }) => {
          generateItinerary(kind, record.id || selectedIds, agent_id);
        }}
        toolbar={
          <FormToolbar
            goBack={() => {
              history.goBack();
            }}
          />
        }
      >
        {selectedIds.length < 2 && (
          <FieldAgentInput
            allowEmpty
            source="agent_id"
            reference={USER_RESOURCE}
            label={"mra.generate.agent"}
            filterToQuery={username => ({ username })}
          >
            <AutocompleteInput
              options={{ name: "reader" }}
              optionText="username"
            />
          </FieldAgentInput>
        )}
      </SimpleForm>
    </Card>
  );
}

Dialog.propTypes = {
  title: PropTypes.string,
  history: PropTypes.object.isRequired,
  record: PropTypes.object,
  translate: PropTypes.func,
  selectedIds: PropTypes.array,
  kind: PropTypes.oneOf(["control", "anomaly_correction"]),
  generateItinerary: PropTypes.func
};

export const GenerateItineraryDialog = compose(
  connect(
    (state, props) => {
      const {
        match: {
          params: { id }
        }
      } = props;
      return {
        record: getRecord(ITINERARY_RESOURCE, id)(state),
        selectedIds: getSelectedIds(ITINERARY_RESOURCE)(state)
      };
    },
    { generateItinerary }
  ),
  translate
)(Dialog);
