import React from "react";
import P from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import { FieldTitle } from "ra-core";

export default function FilterButtonMenuItem(props) {
  const { filter, onShow, resource } = props;
  return (
    <MenuItem
      className="new-filter-item"
      data-key={filter.source}
      data-default-value={filter.defaultValue}
      key={filter.source}
      onClick={() => {
        onShow({ source: filter.source, defaultValue: filter.defaultValue });
      }}
    >
      <FieldTitle
        label={filter.label}
        source={filter.source}
        resource={resource}
      />
    </MenuItem>
  );
}
FilterButtonMenuItem.propTypes = {
  filter: P.object.isRequired,
  onShow: P.func.isRequired,
  resource: P.string.isRequired
};
