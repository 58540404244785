import React from "react";

import { List, TextField, Filter } from "react-admin";
import { RestrictedEditButton } from "../restricted-components";
import config from "../../config";
import { DescriptionField, TranslatedSettingsField } from "../fields";
import { Datagrid, ListActions } from "../base";

/**
 * filters for list component
 */
const DeviceSettingFilter = props => (
  <Filter {...props}>
    <TextField source="name" alwaysOn />
  </Filter>
);

/**
 * component for listing mra devices
 *
 * Use <TranslatedSettingsField source="name" type="device_settings" />
 * if translations for the settings are added.
 */
const DeviceSettingList = props => (
  <List
    {...props}
    filters={<DeviceSettingFilter />}
    actions={<ListActions />}
    perPage={config.PAGINATION}
    bulkActions={false}
  >
    <Datagrid>
      <TextField source="name" />
      <DescriptionField
        source="description"
        translationKey="mobile_settings_short_descriptions"
        sortable={false}
      />
      <TranslatedSettingsField source="value" />
      <RestrictedEditButton
        requiredPermissions={[config.PERMISSIONS.SETTINGS_UPDATE]}
      />
    </Datagrid>
  </List>
);

export default DeviceSettingList;
