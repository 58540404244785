import React from "react";
import P from "prop-types";
import { CardActions, withStyles } from "@material-ui/core";
import { ListButton, translate } from "react-admin";
import { Link } from "react-router-dom";
import {
  compose,
  branch,
  renderNothing,
  defaultProps,
  setDisplayName
} from "recompose";
import {
  RestrictedDeleteButton,
  RestrictedFlatButton
} from "../restricted-components";
import { itineraryIsComplete } from "../utils";
import config from "../../config";
import { LabeledMapButton } from "../base/buttons";

const cardActionStyle = {
  display: "flex",
  justifyContent: "flex-end"
};

const HideableFlatButton = compose(
  setDisplayName("HideableFlatButton"),
  defaultProps({ display: true }),
  branch(props => !props.display, renderNothing)
)(RestrictedFlatButton);

export const ItineraryActions = props => {
  const { basePath, data, resource, translate, classes } = props;
  const {
    id,
    completion_confirmed_at,
    is_completion_confirmation_required
  } = data;
  const hasDelete =
    resource === config.CONTROL_READING_ITINERARY_RESOURCE &&
    data &&
    data.status &&
    [
      config.ITINERARY_STATUS_TYPES.UNASSIGNED.id,
      config.ITINERARY_STATUS_TYPES.READY.id,
      config.ITINERARY_STATUS_TYPES.PAPER_READING_READY.id
    ].indexOf(data.status.toUpperCase()) !== -1;
  return (
    <div>
      <CardActions style={cardActionStyle}>
        {!completion_confirmed_at ? (
          <HideableFlatButton
            color="primary"
            component={Link}
            to={
              resource && !basePath.includes(resource)
                ? `${basePath}/${resource}/${id}/reopen`
                : `${basePath}/${id}/reopen`
            }
            style={{ overflow: "inherit" }}
            requiredPermissions={[config.PERMISSIONS.ITINERARY_REOPEN]}
            name="reopen"
            display={itineraryIsComplete(data)}
          >
            {translate("mra.itinerary_overview.reopen")}
          </HideableFlatButton>
        ) : null}
        {is_completion_confirmation_required ? (
          <RestrictedFlatButton
            color="primary"
            component={Link}
            to={`/${resource}/${id}/completion_confirm`}
            style={{ minWidth: 0, overflow: "inherit" }}
            requiredPermissions={[config.PERMISSIONS.DEVICE_UPDATE]}
          >
            {translate("mra.itinerary_overview.confirm_as_complete")}
          </RestrictedFlatButton>
        ) : null}
        <LabeledMapButton
          record={data}
          filtrationKey="itinerary_id"
          mapResource={config.LOCATION}
        />
        <ListButton basePath={basePath} />
        {hasDelete && (
          <RestrictedDeleteButton
            undoable={false}
            resource={resource}
            basePath={basePath}
            requiredPermissions={[
              config.PERMISSIONS.DELETE_CONTROL_ITINERARY_TASK
            ]}
            record={data}
          />
        )}
      </CardActions>
      {completion_confirmed_at ? (
        <div className={classes.info}>{translate("mra.confirm.info")}</div>
      ) : null}
    </div>
  );
};

ItineraryActions.propTypes = {
  translate: P.func,
  basePath: P.string,
  data: P.object,
  resource: P.string,
  classes: P.object
};

ItineraryActions.defaultProps = {
  data: {},
  classes: {}
};

export default compose(
  translate,
  withStyles({
    info: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#00bcd4",
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: "1.4em",
      textTransform: "uppercase",
      color: "#fff",
      padding: "10px 0"
    }
  })
)(ItineraryActions);
