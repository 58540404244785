import React from "react";
import { Labeled } from "react-admin";
import cn from "classnames";
import get from "lodash/get";

export const RenderChildren = ({
  children,
  classes,
  record,
  resource,
  basePath,
  childrenClasses = {},
  ...restProps
}) =>
  React.Children.map(children, field => {
    const props = get(field, "props", {});
    const labelClasses = get(props, "labelclasses", childrenClasses);
    const clearedClasses = {
      label: labelClasses.label,
      value: labelClasses.value
    };
    return field
      ? <div
          key={props.source}
          className={cn(
            `ra-field ra-field-${field.props.source}`,
            field.props.className,
            field.props.source != null && classes ? classes.field : null
          )}
        >
          {props.addLabel
            ? <Labeled
                className={labelClasses.root}
                classes={clearedClasses}
                record={record}
                resource={resource}
                basePath={basePath}
                label={field.props.label}
                source={field.props.source}
                disabled={false}
              >
                {field}
              </Labeled>
            : typeof field.type === "string"
              ? field
              : React.cloneElement(field, {
                  record,
                  resource,
                  basePath,
                  labelclasses: undefined,
                  ...restProps
                })}
        </div>
      : null;
  });
