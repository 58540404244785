/* eslint-disable react/jsx-key */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { withRouter, matchPath } from "react-router";
import cn from "classnames";
import { intersection, size, omit, compact, isArray } from "lodash";
import { compose, mapProps } from "recompose";
import { translate, setSidebarVisibility, Logout } from "react-admin";
import { connect } from "react-redux";
import { stringify } from "query-string";
import ReadingCenterIcon from "@material-ui/icons/AccountBalance";
import ReadingUnitIcon from "@material-ui/icons/Motorcycle";
import HelpIcon from "@material-ui/icons/HelpOutline";
import ItineraryIcon from "@material-ui/icons/DirectionsWalk";
import UserIcon from "@material-ui/icons/People";
import DeviceIcon from "@material-ui/icons/Devices";
import AnomaliesIcon from "@material-ui/icons/AssignmentLate";
import DistributionIcon from "@material-ui/icons/MonetizationOn";
import RegisteredMetersIcon from "@material-ui/icons/Assignment";
import SettingsIcon from "@material-ui/icons/Settings";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DeviceSettingsIcon from "@material-ui/icons/SettingsCell";
import WorkHoursIcon from "@material-ui/icons/HourglassEmpty";
import ClientActivityIcon from "@material-ui/icons/Face";
import ImageSearch from "@material-ui/icons/ImageSearch";
import ControlReadingIcon from "@material-ui/icons/Beenhere";
import AnomalyCorrectionIcon from "@material-ui/icons/LooksTwo";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  withStyles
} from "@material-ui/core";
import config from "../../config";
import { hide } from "../fields/hideableField";
import {
  getCurrentUserPermissionNames,
  getRcContext
} from "../../redux/selectors";
import reports from "../../config/reports";
import { getAnomalyRedirection } from "../utils";

const checkPermission = (state, props) => {
  const permissions = compact(getCurrentUserPermissionNames(state));
  const { requiredPermissions, disableInGlobalContext } = props;
  const rcContext = getRcContext(state);
  if (disableInGlobalContext && rcContext === config.RC_CONTEXT_ALL)
    return false;
  if (requiredPermissions)
    return size(intersection(permissions, requiredPermissions)) > 0;
  return true;
};

const getKey = value => (isArray(value) ? value.join("_") : value);

const SimpleListItem = props => {
  const {
    leftIcon,
    className,
    nestedItems,
    primaryText,
    classes,
    linkTo,
    isOpen,
    onClick,
    translate,
    location
  } = props;
  const selected = linkTo
    ? matchPath(location.pathname, {
        path: linkTo,
        exact: true,
        strict: false
      })
    : false;
  return (
    <React.Fragment>
      <ListItem
        button
        className={cn(className, classes.text, {
          [classes.selected]: selected
        })}
        onClick={onClick}
        component={linkTo ? NavLink : null}
        to={linkTo}
      >
        {leftIcon}
        {primaryText && (
          <ListItemText
            inset
            classes={{ primary: classes.text }}
            primary={
              isArray(primaryText)
                ? translate(...primaryText)
                : translate(primaryText)
            }
          />
        )}
        {nestedItems && (isOpen ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>
      {nestedItems && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div">
            {React.Children.map(nestedItems, item => (
              <item.type
                key={
                  item.key ||
                  `${getKey(primaryText)}_${getKey(item.props.primaryText)}`
                }
                {...item.props}
              />
            ))}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  );
};

SimpleListItem.propTypes = {
  leftIcon: PropTypes.node,
  name: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  translate: PropTypes.func,
  nestedItems: PropTypes.arrayOf(PropTypes.node),
  primaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  linkTo: PropTypes.string,
  location: PropTypes.object
};

SimpleListItem.defaulProps = {
  onClick: () => null
};

const ListItemWithIcon = compose(
  withRouter,
  withStyles(theme => ({
    text: {
      color: theme.palette.action.active
    },
    selected: {
      textDecoration: "none",
      backgroundColor: theme.palette.action.hover
    }
  })),
  translate
)(SimpleListItem);

const RestrictedListItem = compose(
  hide(checkPermission),
  mapProps(props =>
    omit(props, [
      "disableInGlobalContext",
      "dispatch",
      "requiredPermissions",
      "display"
    ])
  )
)(ListItemWithIcon);

const location = window.location;

const styles = theme => ({
  root: {
    width: "100%",
    maxWidth: 360
    // backgroundColor: theme.palette.background.paper
  },
  listItem: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
  },
  listItemText: {
    color: theme.palette.action.active
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4
  }
});

const changeState = (stateFunction, setSidebarVisibility, isOpen, key) => {
  stateFunction(s => {
    const nextValue = !s[key];
    if (nextValue && !isOpen) {
      setSidebarVisibility(true);
    }
    return { [key]: nextValue };
  });
};

const menuClick = (setSidebarVisibility, isOpen, onMenuClick) => () => {
  if (!isOpen) {
    setSidebarVisibility(true);
  }
  return onMenuClick(setSidebarVisibility);
};

const onMenuClick = setSidebarVisibility => {
  setSidebarVisibility(true);
};

const LogoutButton = compose(
  connect(state => ({
    disabled: state.mra.undoInProgress
  })),
  mapProps(props => omit(props, ["dispatch"]))
)(Logout);

const Menu = ({ setSidebarVisibility, classes, isOpen }) => {
  const [open, setOpen] = useState({});
  const menuClass = classes.listItem;
  const nestedClass = classes.nested;
  const setState = changeState.bind(
    null,
    setOpen,
    setSidebarVisibility,
    isOpen
  );
  const onClick = menuClick(setSidebarVisibility, isOpen, onMenuClick);
  return (
    <div className={classes.root}>
      <List value={location.pathname}>
        <RestrictedListItem
          name="mra.navigation.dashboard"
          linkTo="/"
          leftIcon={<DashboardIcon />}
          primaryText="mra.dashboard.title"
          onClick={onClick}
          className={menuClass}
          requiredPermissions={[config.PERMISSIONS.NAV_DASHBOARD]}
        />
        <RestrictedListItem
          name="mra.navigation.reports"
          primaryText="mra.reports.title"
          leftIcon={<DashboardIcon />}
          requiredPermissions={[config.PERMISSIONS.NAV_REPORTS]}
          className={menuClass}
          isOpen={open.reports}
          onClick={() => setState("reports")}
          nestedItems={Object.values(reports).map(report => (
            <RestrictedListItem
              key={report.name}
              linkTo={`/${config.REPORTS_RESOURCE}?${stringify({
                report: report.name
              })}`}
              leftIcon={<DashboardIcon />}
              className={nestedClass}
              primaryText={report.title}
              onClick={onClick}
              requiredPermissions={[report.permission]}
            />
          ))}
        />
        <RestrictedListItem
          name="mra.navigation.reading_itineraries"
          primaryText="mra.navigation.reading_itineraries"
          leftIcon={<ItineraryIcon />}
          className={menuClass}
          requiredPermissions={[
            config.PERMISSIONS.NAV_READING_ITINERARY_OVERVIEW,
            config.PERMISSIONS.NAV_READING_ITINERARY_ASSIGN
          ]}
          isOpen={open.reading_itineraries}
          onClick={() => setState("reading_itineraries")}
          nestedItems={[
            <RestrictedListItem
              linkTo={`/${config.ITINERARY_RESOURCE}`}
              leftIcon={<ItineraryIcon />}
              className={nestedClass}
              primaryText="mra.navigation.overview"
              onClick={onClick}
              requiredPermissions={[
                config.PERMISSIONS.NAV_READING_ITINERARY_OVERVIEW
              ]}
            />,
            <RestrictedListItem
              linkTo={`${config.ITINERARIES_ASSIGN_PATH}`}
              leftIcon={<ItineraryIcon />}
              className={nestedClass}
              primaryText={[`mra.navigation.assignment`, 2]}
              onClick={onClick}
              requiredPermissions={[
                config.PERMISSIONS.NAV_READING_ITINERARY_ASSIGN
              ]}
              disableInGlobalContext
            />
          ]}
        />
        <RestrictedListItem
          name="mra.navigation.distribution_itineraries"
          primaryText="mra.navigation.distribution_itineraries"
          primaryTogglesNestedList={true}
          className={menuClass}
          leftIcon={<DistributionIcon />}
          requiredPermissions={[
            config.PERMISSIONS.NAV_DISTRIBUTION_ITINERARY_OVERVIEW,
            config.PERMISSIONS.NAV_DISTRIBUTION_ITINERARY_ASSIGN
          ]}
          isOpen={open.distribution_itineraries}
          onClick={() => setState("distribution_itineraries")}
          nestedItems={[
            <RestrictedListItem
              linkTo={`/${config.DISTRIBUTION_ITINERARY_RESOURCE}`}
              leftIcon={<DistributionIcon />}
              className={nestedClass}
              primaryText={[`mra.navigation.overview`, 2]}
              onClick={onClick}
              requiredPermissions={[
                config.PERMISSIONS.NAV_DISTRIBUTION_ITINERARY_OVERVIEW
              ]}
            />,
            <RestrictedListItem
              linkTo={`${config.DISTRIBUTION_ITINERARIES_ASSIGN_PATH}`}
              leftIcon={<DistributionIcon />}
              className={nestedClass}
              primaryText="mra.navigation.assignment"
              onClick={onClick}
              requiredPermissions={[
                config.PERMISSIONS.NAV_DISTRIBUTION_ITINERARY_ASSIGN
              ]}
              disableInGlobalContext
            />
          ]}
        />
        <RestrictedListItem
          name="mra.navigation.new_meters"
          className={menuClass}
          primaryText={[
            `resources.${config.REGISTERED_METERS_RESOURCE}.name`,
            2
          ]}
          leftIcon={<RegisteredMetersIcon />}
          requiredPermissions={[
            config.PERMISSIONS.NAV_NEW_METERS_OVERVIEW,
            config.PERMISSIONS.NAV_NEW_METERS_REGISTER
          ]}
          isOpen={open.new_meters}
          onClick={() => setState("new_meters")}
          nestedItems={[
            <RestrictedListItem
              linkTo={`/${config.REGISTERED_METERS_RESOURCE}`}
              leftIcon={<RegisteredMetersIcon />}
              className={nestedClass}
              primaryText="mra.navigation.overview"
              onClick={onClick}
              requiredPermissions={[config.PERMISSIONS.NAV_NEW_METERS_OVERVIEW]}
            />,
            <RestrictedListItem
              linkTo={`/${config.REGISTERED_METERS_RESOURCE}/create`}
              leftIcon={<RegisteredMetersIcon />}
              className={nestedClass}
              primaryText="mra.navigation.register"
              onClick={onClick}
              requiredPermissions={[config.PERMISSIONS.NAV_NEW_METERS_REGISTER]}
              disableInGlobalContext
            />
          ]}
        />
        <RestrictedListItem
          name="mra.navigation.control_reading_itineraries"
          primaryText="mra.navigation.control_reading_itineraries"
          primaryTogglesNestedList={true}
          className={menuClass}
          leftIcon={<ControlReadingIcon />}
          requiredPermissions={[
            config.PERMISSIONS.NAV_CONTROL_READING_ITINERARY_OVERVIEW,
            config.PERMISSIONS.NAV_CONTROL_READING_ITINERARY_ASSIGN
          ]}
          isOpen={open.control_reading_itineraries}
          onClick={() => setState("control_reading_itineraries")}
          nestedItems={[
            <RestrictedListItem
              linkTo={`/${config.CONTROL_READING_ITINERARY_RESOURCE}`}
              leftIcon={<ControlReadingIcon />}
              className={nestedClass}
              primaryText={[`mra.navigation.overview`, 2]}
              onClick={onClick}
              requiredPermissions={[
                config.PERMISSIONS.NAV_CONTROL_READING_ITINERARY_OVERVIEW
              ]}
            />,
            <RestrictedListItem
              linkTo={`${config.CONTROL_READING_ITINERARY_ASSIGN_PATH}`}
              leftIcon={<ControlReadingIcon />}
              className={nestedClass}
              primaryText="mra.navigation.assignment"
              onClick={onClick}
              requiredPermissions={[
                config.PERMISSIONS.NAV_CONTROL_READING_ITINERARY_ASSIGN
              ]}
              disableInGlobalContext
            />
          ]}
        />
        <RestrictedListItem
          name="mra.navigation.anomaly_correction_itineraries"
          primaryText="mra.navigation.anomaly_correction_itineraries"
          primaryTogglesNestedList={true}
          className={menuClass}
          leftIcon={<AnomalyCorrectionIcon />}
          requiredPermissions={[
            config.PERMISSIONS.NAV_ANOMALY_CORRECTION_ITINERARY_OVERVIEW,
            config.PERMISSIONS.NAV_ANOMALY_CORRECTION_ITINERARY_ASSIGN
          ]}
          isOpen={open.anomaly_correction_itineraries}
          onClick={() => setState("anomaly_correction_itineraries")}
          nestedItems={[
            <RestrictedListItem
              linkTo={`/${config.ANOMALY_CORRECTION_ITINERARY_RESOURCE}`}
              leftIcon={<AnomalyCorrectionIcon />}
              className={nestedClass}
              primaryText={[`mra.navigation.overview`, 2]}
              onClick={onClick}
              requiredPermissions={[
                config.PERMISSIONS.NAV_ANOMALY_CORRECTION_ITINERARY_OVERVIEW
              ]}
            />,
            <RestrictedListItem
              linkTo={`${config.ANOMALY_CORRECTION_ITINERARY_ASSIGN_PATH}`}
              leftIcon={<AnomalyCorrectionIcon />}
              className={nestedClass}
              primaryText="mra.navigation.assignment"
              onClick={onClick}
              requiredPermissions={[
                config.PERMISSIONS.NAV_ANOMALY_CORRECTION_ITINERARY_ASSIGN
              ]}
              disableInGlobalContext
            />
          ]}
        />
        <RestrictedListItem
          name="mra.navigation.picture_viewer"
          linkTo={`/${config.RMS_RESOURCE}`}
          leftIcon={<ImageSearch />}
          className={menuClass}
          primaryText={`resources.${config.RMS_RESOURCE}.name`}
          onClick={onClick}
          requiredPermissions={[config.PERMISSIONS.NAV_PICTURE_VIEWER]}
        />
        <RestrictedListItem
          name="mra.navigation.devices"
          linkTo={`/${config.DEVICE_RESOURCE}`}
          leftIcon={<DeviceIcon />}
          className={menuClass}
          primaryText={[`resources.${config.DEVICE_RESOURCE}.name`, 2]}
          onClick={onClick}
          requiredPermissions={[config.PERMISSIONS.NAV_DEVICES_OVERVIEW]}
        />
        <RestrictedListItem
          primaryText={[`resources.${config.USER_RESOURCE}.name`, 2]}
          leftIcon={<UserIcon />}
          className={menuClass}
          requiredPermissions={[
            config.PERMISSIONS.NAV_USERS_OVERVIEW,
            config.PERMISSIONS.NAV_USERS_ADD_USER
          ]}
          isOpen={open[config.DEVICE_RESOURCE]}
          onClick={() => setState(config.DEVICE_RESOURCE)}
          nestedItems={[
            <RestrictedListItem
              linkTo={`/${config.USER_RESOURCE}`}
              leftIcon={<UserIcon />}
              className={nestedClass}
              primaryText="mra.navigation.overview"
              onClick={onClick}
              requiredPermissions={[config.PERMISSIONS.NAV_USERS_OVERVIEW]}
            />,
            <RestrictedListItem
              linkTo={`/${config.USER_RESOURCE}/create`}
              leftIcon={<UserIcon />}
              className={nestedClass}
              primaryText="mra.navigation.add_user"
              onClick={onClick}
              requiredPermissions={[config.PERMISSIONS.NAV_USERS_ADD_USER]}
            />
          ]}
        />
        <RestrictedListItem
          name="mra.navigation.administration"
          primaryText="mra.navigation.administration"
          className={menuClass}
          leftIcon={<SettingsIcon />}
          requiredPermissions={[
            config.PERMISSIONS.NAV_ADMINISTRATION_READING_UNITS,
            config.PERMISSIONS.NAV_ADMINISTRATION_READING_CENTERS,
            config.PERMISSIONS.NAV_ADMINISTRATION_READING_ANOMALY,
            config.PERMISSIONS.NAV_ADMINISTRATION_PICTURE_ANOMALY,
            config.PERMISSIONS.NAV_ADMINISTRATION_CONTROL_ANOMALY,
            config.PERMISSIONS.NAV_ADMINISTRATION_OCCUPANCY_ANOMALY,
            config.PERMISSIONS.NAV_ADMINISTRATION_DISTRIBUTION_ANOMALY,
            config.PERMISSIONS.NAV_ADMINISTRATION_WORK_HOURS,
            config.PERMISSIONS.NAV_ADMINISTRATION_CLIENT_ACTIVITIES,
            config.PERMISSIONS.NAV_ADMINISTRATION_READING_CALENDAR
          ]}
          isOpen={open.administration}
          onClick={() => setState("administration")}
          nestedItems={[
            <RestrictedListItem
              linkTo={`/${config.READINGUNIT_RESOURCE}`}
              leftIcon={<ReadingUnitIcon />}
              primaryText={[`resources.${config.READINGUNIT_RESOURCE}.name`, 2]}
              className={nestedClass}
              onClick={onClick}
              requiredPermissions={[
                config.PERMISSIONS.NAV_ADMINISTRATION_READING_UNITS
              ]}
            />,
            <RestrictedListItem
              linkTo={`/${config.READINGCENTER_RESOURCE}`}
              leftIcon={<ReadingCenterIcon />}
              primaryText={[
                `resources.${config.READINGCENTER_RESOURCE}.name`,
                2
              ]}
              onClick={onClick}
              className={nestedClass}
              requiredPermissions={[
                config.PERMISSIONS.NAV_ADMINISTRATION_READING_CENTERS
              ]}
            />,
            <RestrictedListItem
              linkTo={getAnomalyRedirection(null, null, {
                type: config.ANOMALY_TYPES.READING.id
              })}
              leftIcon={<AnomaliesIcon />}
              primaryText="mra.anomaly.reading"
              className={nestedClass}
              onClick={onClick}
              requiredPermissions={[
                config.PERMISSIONS.NAV_ADMINISTRATION_READING_ANOMALY
              ]}
            />,
            <RestrictedListItem
              linkTo={getAnomalyRedirection(null, null, {
                type: config.ANOMALY_TYPES.PICTURE.id
              })}
              leftIcon={<AnomaliesIcon />}
              primaryText="mra.anomaly.picture"
              className={nestedClass}
              onClick={onClick}
              requiredPermissions={[
                config.PERMISSIONS.NAV_ADMINISTRATION_PICTURE_ANOMALY
              ]}
            />,
            <RestrictedListItem
              linkTo={getAnomalyRedirection(null, null, {
                type: config.ANOMALY_TYPES.DISTRIBUTION.id
              })}
              leftIcon={<AnomaliesIcon />}
              primaryText="mra.anomaly.distribution"
              className={nestedClass}
              onClick={onClick}
              requiredPermissions={[
                config.PERMISSIONS.NAV_ADMINISTRATION_DISTRIBUTION_ANOMALY
              ]}
            />,
            <RestrictedListItem
              linkTo={getAnomalyRedirection(null, null, {
                type: config.ANOMALY_TYPES.CONTROL.id
              })}
              leftIcon={<AnomaliesIcon />}
              primaryText="mra.anomaly.control"
              className={nestedClass}
              onClick={onClick}
              requiredPermissions={[
                config.PERMISSIONS.NAV_ADMINISTRATION_CONTROL_ANOMALY
              ]}
            />,
            <RestrictedListItem
              linkTo={getAnomalyRedirection(null, null, {
                type: config.ANOMALY_TYPES.OCCUPANCY.id
              })}
              leftIcon={<AnomaliesIcon />}
              primaryText="mra.anomaly.occupancy"
              className={nestedClass}
              onClick={onClick}
              requiredPermissions={[
                config.PERMISSIONS.NAV_ADMINISTRATION_OCCUPANCY_ANOMALY
              ]}
            />,
            <RestrictedListItem
              linkTo={`/${config.CLIENT_ACTIVITY_RESOURCE}`}
              leftIcon={<ClientActivityIcon />}
              primaryText={[
                `resources.${config.CLIENT_ACTIVITY_RESOURCE}.name`,
                2
              ]}
              className={nestedClass}
              onClick={onClick}
              requiredPermissions={[
                config.PERMISSIONS.NAV_ADMINISTRATION_CLIENT_ACTIVITIES
              ]}
            />,
            <RestrictedListItem
              linkTo={`/${config.WORK_HOURS_RESOURCE}`}
              leftIcon={<WorkHoursIcon />}
              primaryText={[`resources.${config.WORK_HOURS_RESOURCE}.name`, 2]}
              className={nestedClass}
              onClick={onClick}
              requiredPermissions={[
                config.PERMISSIONS.NAV_ADMINISTRATION_WORK_HOURS
              ]}
            />,
            <RestrictedListItem
              linkTo={`/${config.READING_CALENDAR_RESOURCE}`}
              leftIcon={<WorkHoursIcon />}
              primaryText={`resources.${config.READING_CALENDAR_RESOURCE}.name`}
              className={nestedClass}
              onClick={onClick}
              requiredPermissions={[
                config.PERMISSIONS.NAV_ADMINISTRATION_READING_CALENDAR
              ]}
            />
          ]}
        />
        <RestrictedListItem
          primaryText="mra.navigation.settings"
          className={menuClass}
          leftIcon={<SettingsIcon />}
          requiredPermissions={[
            config.PERMISSIONS.NAV_SETTINGS_MOBILE_APP,
            config.PERMISSIONS.NAV_SETTINGS_SERVER
          ]}
          isOpen={open.settings}
          onClick={() => setState("settings")}
          nestedItems={[
            <RestrictedListItem
              linkTo={`/${config.DEVICES_SETTINGS_RESOURCE}`}
              leftIcon={<DeviceSettingsIcon />}
              primaryText={[
                `resources.${config.DEVICES_SETTINGS_RESOURCE}.name`,
                2
              ]}
              className={nestedClass}
              onClick={onClick}
              requiredPermissions={[config.PERMISSIONS.NAV_SETTINGS_MOBILE_APP]}
            />,
            <RestrictedListItem
              linkTo={`/${config.SETTINGS_RESOURCE}`}
              leftIcon={<SettingsIcon />}
              primaryText={[`resources.${config.SETTINGS_RESOURCE}.name`, 2]}
              className={nestedClass}
              onClick={onClick}
              requiredPermissions={[config.PERMISSIONS.NAV_SETTINGS_SERVER]}
            />
          ]}
        />
        <ListItemWithIcon
          primaryText="mra.navigation.documentation"
          className={menuClass}
          leftIcon={<HelpIcon />}
          isOpen={open.documentation}
          onClick={() => setState("documentation")}
          nestedItems={[
            <ListItemWithIcon
              linkTo={`${process.env.PUBLIC_URL}${config.DOCS_BOM_PATH}`}
              leftIcon={<HelpIcon />}
              primaryText={["mra.navigation.documentation_bom", 2]}
              onClick={onClick}
              className={nestedClass}
            />,
            <ListItemWithIcon
              linkTo={`${process.env.PUBLIC_URL}${config.DOCS_APP_PATH}`}
              leftIcon={<HelpIcon />}
              primaryText={[`mra.navigation.documentation_app`, 2]}
              onClick={onClick}
              className={nestedClass}
            />
          ]}
        />
        <LogoutButton className={cn(classes.listItem, classes.listItemText)} />
      </List>
    </div>
  );
};

Menu.propTypes = {
  history: PropTypes.object.isRequired,
  logout: PropTypes.node,
  onMenuClick: PropTypes.func,
  classes: PropTypes.object,
  isOpen: PropTypes.bool,
  setSidebarVisibility: PropTypes.func
};

Menu.defaultProps = {
  onMenuClick: () => {}
};

const enhance = compose(
  withRouter,
  withStyles(styles),
  connect(
    null,
    { setSidebarVisibility }
  )
);

export default enhance(Menu);
