import React from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import compose from "recompose/compose";
import {
  translate,
  getDefaultValues,
  CardContentInner,
  FormInput
} from "react-admin";
import DisabledToolbar from "./DisabledToolbar";

const noop = () => {};

/* #DisabledSimpleForm#
 * basically the same component as aor [SimpleForm](https://github.com/marmelab/admin-on-rest/blob/master/src/mui/form/SimpleForm.js)
 * differences:
 * * 1. There is no submit/save Button
 * * 2. Values inside the TextFields cant be changed. These are not input Components
 * * 3. if the prop disableCompletely is provided, everythin will be shown as disabled (disabled cursor etc)
 * * 4. The TextField can be styled with the property elStyle (will be passed to the children)
 * * 5. prop disabledToolbarTitle: pass a title to the Toolbar
 * * 6. prop disabledToolbarDisplayEmpty: show Toolbar even if empty
 */
const DisabledSimpleForm = ({
  children,
  handleSubmit,
  record,
  resource,
  submitOnEnter,
  disabled,
  disabledToolbarTitle,
  disabledToolbarDisplayEmpty
}) => {
  return (
    <form
      onSubmit={submitOnEnter ? handleSubmit : noop}
      className="simple-form"
    >
      <CardContentInner>
        {React.Children.map(
          children,
          input =>
            input &&
            input.props.source !== "quality_rules" && (
              <FormInput
                input={input}
                record={record}
                resource={resource}
                disabled={disabled}
                options={{ disabled: true }}
              />
            )
        )}
      </CardContentInner>
      <DisabledToolbar
        toolbarTitle={disabledToolbarTitle}
        displayEmpty={disabledToolbarDisplayEmpty}
      />
    </form>
  );
};

DisabledSimpleForm.propTypes = {
  children: PropTypes.node,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  record: PropTypes.object,
  resource: PropTypes.string,
  basePath: PropTypes.string,
  validate: PropTypes.func,
  submitOnEnter: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledToolbarTitle: PropTypes.string,
  disabledToolbarDisplayEmpty: PropTypes.bool
};

DisabledSimpleForm.defaultProps = {
  submitOnEnter: true
};

const enhance = compose(
  connect((state, props) => ({
    initialValues: getDefaultValues(state, props)
  })),
  translate,
  reduxForm({
    form: "record-form",
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  })
);

export default enhance(DisabledSimpleForm);
