import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR } from "react-admin";
import { getAuthToken, setAuthToken, removeAuthToken } from "./tokenStorage";
import jwtDecode from "jwt-decode";
import config from "../config";

const loginUrl = `${config.API_BASE_URL}${config.LOGIN_URL}`;
/**
 * handles the login action from admin-on-rest
 * @param {string} loginUrl - this is the login endpoint to retrieve the token from
 */
export default (type, params) => {
  switch (type) {
    case AUTH_LOGIN: {
      const { username, password } = params;
      const request = new Request(loginUrl, {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers: new Headers({ "Content-Type": "application/json" })
      });
      // request token with credentials
      return fetch(request)
        .then(response => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then(({ token }) => {
          const details = jwtDecode(token);
          if (details.access_bom) {
            setAuthToken(token);
          } else {
            return Promise.reject();
          }
        });
    }
    case AUTH_LOGOUT: {
      removeAuthToken();
      return Promise.resolve();
    }
    case AUTH_CHECK: {
      const token = getAuthToken();
      const details = token ? jwtDecode(token) : { access_bom: false };
      return details.access_bom ? Promise.resolve() : Promise.reject();
    }
    case AUTH_ERROR: {
      const { status } = params;
      return status === 401 || status === 403
        ? Promise.reject()
        : Promise.resolve();
    }
    default:
      return Promise.reject("Unknown method");
  }
};
