import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import pure from "recompose/pure";
import { BooleanField } from "react-admin";

export const NotNullField = ({ source, record = {}, ...props }) => {
  return (
    <BooleanField
      {...props}
      source={source}
      record={{
        ...record,
        [source]: Boolean(get(record, source))
      }}
    />
  );
};

NotNullField.propTypes = {
  addLabel: PropTypes.bool,
  elStyle: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired
};

const PureNotNullField = pure(NotNullField);

PureNotNullField.defaultProps = {
  addLabel: true
};

export default PureNotNullField;
