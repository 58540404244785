/* eslint-disable react/jsx-key */
import React from "react";
import { Route } from "react-router-dom";
import config from "./config";
import Reports from "./components/reports/Reports";
import {
  ForceExportItineraryDialog,
  ForceQCDialog,
  ReturnItineraryDialog,
  DeviceConfirmDialog,
  GenerateItineraryDialog,
  ConnectedConfirmResequenceDialog,
  ReopenItineraryDialog,
  CompleteCaptureDialog,
  AnomaliesActivationConfirmation,
  ClientActivityAtivationConfirmation,
  ReadingCenterActivationConfirmation,
  UserActivationConfirmation,
  UnassignConfirmation,
  CompletionConfirm
} from "./components/dialogs";
import { DataCaptureList, ResequenceList } from "./components/reading-tasks";
import { ImageUpload } from "./components/upload";
import { UserProfile, ResetPassword } from "./components/users";
import {
  ItineraryAssignList,
  ItineraryAssignment
} from "./components/reading-itineraries";
import {
  AnomalyCorrectionAssignList,
  AnomalyCorrectionAssignment,
  ControlReadingAssignList,
  ControlReadingAssignment,
  DistributionAssignList,
  DistributionAssignment
} from "./components/itineraries";
import { DeviceConfirmation } from "./components/devices";

export const customRoutes = [
  <Route exact path={`/${config.REPORTS_RESOURCE}`} component={Reports} />,
  <Route
    exact
    path={config.COMPLETE_CAPTURE_PATH}
    component={CompleteCaptureDialog}
  />,
  <Route exact path={config.DATA_CAPTURE_PATH} component={DataCaptureList} />,
  <Route
    exact
    path={config.RESEQUENCE_ITINERARY_PATH}
    component={ResequenceList}
  />,
  <Route
    exact
    path={config.RETURN_ITINERARY_PATH}
    component={ReturnItineraryDialog}
  />,
  <Route
    exact
    path={config.GENERATE_CONTROL_READING_PATH}
    render={routeProps => (
      <GenerateItineraryDialog
        {...routeProps}
        kind="control"
        title="mra.itinerary_overview.generate_control_reading"
      />
    )}
  />,
  <Route
    exact
    path={config.GENERATE_ANOMALY_CORRECTION_PATH}
    render={routeProps => (
      <GenerateItineraryDialog
        {...routeProps}
        kind="anomaly_correction"
        title="mra.itinerary_overview.generate_anomaly_correction"
      />
    )}
  />,
  <Route
    exact
    path={config.RESEQUENCE_ITINERARY_CONFIRM_PATH}
    component={ConnectedConfirmResequenceDialog}
  />,
  <Route
    exact
    path={config.ROUTESHEET_UPLOAD_PATH}
    render={routeProps => (
      <ImageUpload
        {...routeProps}
        resource={config.ITINERARY_RESOURCE}
        type="routesheet"
        title="mra.upload.routesheet.title"
        message="mra.upload.routesheet.message"
      />
    )}
  />,
  <Route
    exact
    path={config.REOPEN_ITINERARY_PATH}
    component={ReopenItineraryDialog}
  />,
  <Route
    exact
    path={config.FORCE_EXPORT_ITINERARY_PATH}
    component={ForceExportItineraryDialog}
  />,
  <Route exact path={config.PROFILE_PATH} component={UserProfile} />,
  <Route exact path={config.RESET_PATH} component={ResetPassword} />,
  <Route exact path={config.FORCE_QC_PATH} component={ForceQCDialog} />,
  <Route
    exact
    path={config.USER_ACTIVATE_PATH}
    component={UserActivationConfirmation}
  />,
  <Route
    exact
    path={config.COMPLETION_CONFIRM}
    component={CompletionConfirm}
  />,
  <Route
    exact
    path={config.DEVICE_ACTIVATE_PATH}
    component={DeviceConfirmDialog}
  />,
  <Route
    exact
    path={config.RC_ACTIVATE_PATH}
    component={ReadingCenterActivationConfirmation}
  />,
  <Route
    exact
    path={config.ANOMALIES_ACTIVATE_PATH}
    component={AnomaliesActivationConfirmation}
  />,
  <Route
    exact
    path={config.CLIENT_ACTIVITY_ACTIVATE_PATH}
    component={ClientActivityAtivationConfirmation}
  />,
  <Route exact path={config.ASSIGN_PATH} component={ItineraryAssignment} />,
  <Route
    exact
    path={config.ITINERARIES_ASSIGN_PATH}
    component={ItineraryAssignList}
  />,
  <Route
    exact
    path={config.DISTRIBUTION_ASSIGN_PATH}
    component={DistributionAssignment}
  />,
  <Route
    exact
    path={config.DISTRIBUTION_ITINERARIES_ASSIGN_PATH}
    component={DistributionAssignList}
  />,
  <Route
    exact
    path={config.CONTROL_READING_ITINERARY_ASSIGN_PATH}
    component={ControlReadingAssignList}
  />,
  <Route
    exact
    path={config.ANOMALY_CORRECTION_ITINERARY_ASSIGN_PATH}
    component={AnomalyCorrectionAssignList}
  />,
  <Route
    exact
    path={config.CONTROL_READING_ASSIGN_PATH}
    component={ControlReadingAssignment}
  />,
  <Route
    exact
    path={config.ANOMALY_CORRECTION_ASSIGN_PATH}
    component={AnomalyCorrectionAssignment}
  />,
  <Route
    exact
    path={config.DEVICES_CONFIRM_PATH}
    component={DeviceConfirmation}
  />,
  <Route
    exact
    path={config.UNASSIGN_CONFIRM_PATH}
    component={UnassignConfirmation}
  />
];
