export const NUMBER = "NUMBER";
export const CHOICE = "CHOICE";
// const TEXT = 'TEXT';

const TrueFalseType = {
  type: CHOICE,
  choices: [
    { id: "TRUE", name: "mra.settings.TRUE" },
    { id: "FALSE", name: "mra.settings.FALSE" }
  ]
};

const LangType = {
  type: CHOICE,
  choices: [
    { id: "en", name: "mra.settings.en" },
    { id: "fr", name: "mra.settings.fr" }
  ]
};

const MandatoryOptionalType = {
  type: CHOICE,
  choices: [
    { id: "Mandatory", name: "mra.settings.Mandatory" },
    { id: "Optional", name: "mra.settings.Optional" }
  ]
};

const MandatoryOptionalDoNotUseType = {
  type: CHOICE,
  choices: [
    { id: "Mandatory", name: "mra.settings.Mandatory" },
    { id: "Optional", name: "mra.settings.Optional" },
    { id: "Do not use", name: "mra.settings.Do not use" }
  ]
};

const CamSizeType = {
  type: CHOICE,
  choices: [
    { id: "SMALL_SIZE", name: "mra.settings.SMALL_SIZE" },
    { id: "MED_SIZE", name: "mra.settings.MED_SIZE" },
    { id: "LARGE_SIZE", name: "mra.settings.LARGE_SIZE" }
  ]
};

const EnforceWarnDoNotEnforce = {
  type: CHOICE,
  choices: [
    { id: "Enforce", name: "mra.settings.Enforce" },
    { id: "Warn", name: "mra.settings.Warn" },
    { id: "Do not enforce", name: "mra.settings.Do not enforce" }
  ]
};

const TrueFalseLower = {
  type: CHOICE,
  choices: [
    { id: "true", name: "mra.settings.true" },
    { id: "false", name: "mra.settings.false" }
  ]
};

const JanitorNumber = {
  type: NUMBER,
  min: 365,
  max: 1825
};

export const serverSettings = {
  CURRENT_CYCLE: {
    type: NUMBER,
    min: 1,
    max: 100
  },
  PRIMARY_LANGUAGE: LangType,
  SECOND_SUPPORTED_LANGUAGE: LangType,
  LIMIT_READING_UNIT_TO_READING_CENTRE: TrueFalseType,
  CYCLE_VIEW_LEVEL: {
    type: CHOICE,
    choices: [
      { id: "Strict", name: "mra.settings.Strict" },
      { id: "All", name: "mra.settings.All" }
    ]
  },
  SCAN_INTINERARY_SHEET: MandatoryOptionalDoNotUseType,
  MOBILE_APP_PROVISION_EXPIRATION_TIME: {
    type: NUMBER,
    min: 1,
    max: 60
  },
  BACK_OFFICE_PASSWORD_LENGTH: {
    type: NUMBER,
    min: 1,
    max: 20
  },
  FIELD_AGENT_PASSWORD_LENGTH: {
    type: NUMBER,
    min: 1,
    max: 20
  },
  FIELD_AGENT_PASSWORD: {
    type: CHOICE,
    choices: [
      { id: "Digits only", name: "mra.settings.Digits only" },
      { id: "Any character", name: "mra.settings.Any character" }
    ]
  },
  DEFAULT_METER_READING_MODE: {
    type: CHOICE,
    choices: [
      { id: "PEN&PAPER", name: "mra.settings.PEN&PAPER" },
      { id: "MOBILEAPP", name: "mra.settings.MOBILEAPP" }
    ]
  },
  MOBILE_APP_REGISTRATION_KEY: {
    type: NUMBER,
    min: 10000,
    max: 99999
  },
  ACCEPTED_READINGS_MIN: {
    type: NUMBER,
    min: 0,
    max: 1000
  },
  ACCEPTED_READINGS_MAX: {
    type: NUMBER,
    min: 0,
    max: 1000
  },
  ALLOW_DATA_SYNC: TrueFalseType,
  ALLOW_DATA_SYNC_WITH_PICTURES: TrueFalseType,
  JANITOR_TTL_QUALITY_RUN: JanitorNumber,
  JANITOR_TTL_NEW_REGISTERED_METER: JanitorNumber,
  JANITOR_TTL_DISTRIBUTION_ITINERARY: JanitorNumber,
  JANITOR_TTL_READING_ITINERARY: JanitorNumber,
  JANITOR_TTL_ANOMALY_CORRECTION_ITINERARY: JanitorNumber,
  JANITOR_TTL_CONTROL_ITINERARY: JanitorNumber,
  READING_ITINERARY_ENABLE_AUTOASSIGNMENT: TrueFalseLower,
  ANOMALY_CORRECTION_ITINERARY_ENABLE_AUTOASSIGNMENT: TrueFalseLower,
  CONTROL_ITINERARY_ENABLE_AUTOASSIGNMENT: TrueFalseLower,
  DISTRIBUTION_ITINERARY_ENABLE_AUTOASSIGNMENT: TrueFalseLower,
  READING_ITINERARY_REQUIRE_AUTOASSIGNMENT_CONFIRMATION: TrueFalseLower,
  CONTROL_ITINERARY_REQUIRE_AUTOASSIGNMENT_CONFIRMATION: TrueFalseLower,
  ANOMALY_CORRECTION_ITINERARY_REQUIRE_AUTOASSIGNMENT_CONFIRMATION: TrueFalseLower,
  DISTRIBUTION_ITINERARY_REQUIRE_AUTOASSIGNMENT_CONFIRMATION: TrueFalseLower,
  REPORTS_ACCEPTED_GPS_DISTANCE: {
    type: NUMBER,
    min: 1,
    max: 99999
  },
  CURRENT_CYCLE_YEAR: {
    type: NUMBER,
    min: 1970
  },
  DATAX_EXPORT_LOCK_TO_CURRENT_CYCLE: TrueFalseType,
  ANOMALY_CORRECTION_ITINERARY_REQUIRE_EXPORT_CONFIRMATION: TrueFalseLower,
  DISTRIBUTION_ITINERARY_REQUIRE_EXPORT_CONFIRMATION: TrueFalseLower,
  READING_ITINERARY_REQUIRE_EXPORT_CONFIRMATION: TrueFalseLower,
  CONTROL_READING_ITINERARY_REQUIRE_EXPORT_CONFIRMATION: TrueFalseLower
  // IMAGE_SERVER_URL: {
  //   type: TEXT
  // }
  // IMAGE_SERVER_USERNAME: {
  //   type: TEXT
  // },
  // IMAGE_SERVER_PASSWORD: {
  //   type: TEXT
  // },
  // REPORTING_SERVER_URL: {
  //   type: TEXT
  // },
  // REPORTING_SERVER_USERNAME: {
  //   type: TEXT
  // },
  // REPORTING_SERVER_PASSWORD: {
  //   type: TEXT
  // }
  // RMS_URL: {
  //   type: TEXT
  // }
};

export const mobileSettings = {
  GPS_READING_SUCCESS: MandatoryOptionalType,
  GPS_READING_FAILED: MandatoryOptionalType,
  GPS_METER_REGISTRATION: MandatoryOptionalType,
  PICTURE_READING_SUCCESS: MandatoryOptionalType,
  PICTURE_READING_FAILED: MandatoryOptionalType,
  PICTURE_METER_REGISTRATION: MandatoryOptionalType,
  READ_METER_NUMBER_BARCODE: MandatoryOptionalDoNotUseType,
  METER_READING_OPTICAL_SCANNER: MandatoryOptionalDoNotUseType,
  VICINITY_CHECK: EnforceWarnDoNotEnforce,
  VICINITY_RANGE: {
    type: NUMBER,
    min: 50,
    max: 10000
  },
  EXPECTED_READING_VALUE: EnforceWarnDoNotEnforce,
  METER_REGISTRATION_NUMBER_KEYPAD_TYPE: {
    type: CHOICE,
    choices: [
      { id: "Numeric", name: "mra.settings.Numeric" },
      { id: "All Characters", name: "mra.settings.All Characters" }
    ]
  },
  PICTURE_QUALITY: {
    type: CHOICE,
    choices: [
      { id: "Low", name: "mra.settings.Low" },
      { id: "Medium", name: "mra.settings.Medium" },
      { id: "High", name: "mra.settings.High" }
    ]
  },
  NUMBER_OF_PICTURES_PER_INCIDENT: {
    type: NUMBER,
    min: 0,
    max: 99
  },
  // IMAGE_SERVER_SFTP_HOST: {
  //   type: TEXT
  // },
  IMAGE_SERVER_SFTP_PORT: {
    type: NUMBER,
    min: 1,
    max: 65535
  },
  // IMAGE_SERVER_SFTP_PUBLICKEY: {
  //   type: TEXT
  // },
  // IMAGE_SERVER_SFTP_KEY: {
  //   type: TEXT
  // },
  // IMAGE_SERVER_SFTP_USER: {
  //   type: TEXT
  // },
  // IMAGE_SERVER_SFTP_PASSWORD: {
  //   type: TEXT
  // }
  // IMAGE_SERVER_SFTP_PATH: {
  //   type: TEXT
  // },
  ALLOW_DATA_SYNC_WITH_PICTURES: TrueFalseType,
  ALLOW_DATA_SYNC: TrueFalseType,
  LOCK_AGENTS_ASSIGNED_DEVICES: TrueFalseType,
  UNITS: {
    type: CHOICE,
    choices: [
      { id: "kWh", name: "mra.settings.kWh" },
      { id: "m³", name: "mra.settings.m³" }
    ]
  },
  TIME_SYNC_MODE: {
    type: CHOICE,
    choices: [
      { id: "Off", name: "mra.settings.Off" },
      { id: "Provider", name: "mra.settings.Provider" },
      { id: "Server", name: "mra.settings.Server" },
      { id: "GPS", name: "mra.settings.GPS" }
    ]
  },
  PRINT_READING_RECEIPT: TrueFalseType,
  GPS_DISTRIBUTION_SUCCESS: MandatoryOptionalType,
  GPS_DISTRIBUTION_FAILED: MandatoryOptionalType,
  PICTURE_DISTRIBUTION_SUCCESS: MandatoryOptionalDoNotUseType,
  PICTURE_DISTRIBUTION_FAILED: MandatoryOptionalDoNotUseType,
  MANUAL_IMAGE_SYNC_CONFIRM_PIN: {
    type: NUMBER,
    min: 10000,
    max: 99999
  },
  BACKGROUND_SYNC_MODE: {
    type: CHOICE,
    choices: [
      { id: "FULL", name: "mra.settings.FULL" },
      { id: "DATA", name: "mra.settings.DATA" },
      { id: "OFF", name: "mra.settings.OFF" }
    ]
  },
  BACKGROUND_SYNC_INTERVAL: {
    type: NUMBER,
    min: 1,
    max: 43200
  },
  GPS_PERSIST_PERIOD: {
    type: NUMBER,
    min: 1,
    max: 43200
  },
  GPS_TIMEOUT: {
    type: NUMBER,
    min: 1,
    max: 180
  },
  CAMSIZE_MTR_READING_OK: CamSizeType,
  CAMSIZE_MTR_READING_NOK: CamSizeType,
  CAMSIZE_DIST_READING_OK: CamSizeType,
  CAMSIZE_DIST_READING_NOK: CamSizeType,
  CAMSIZE_MTR2_READING_OK: CamSizeType,
  CAMSIZE_MTR2_READING_NOK: CamSizeType,
  CAMSIZE_CTRL_READING_OK: CamSizeType,
  CAMSIZE_CTRL_READING_NOK: CamSizeType,
  CAMSIZE_NEWMTR: CamSizeType,
  APP_NUM_CYCLES_KEEP_PIC: {
    type: NUMBER,
    min: 1
  },
  SYNC_REMINDER: {
    type: NUMBER,
    min: 1,
    max: 999
  }
};
