import React from "react";
import { Filter, TextField, TextInput } from "react-admin";
import { Datagrid, SwitchList } from "../base";
import { Grid, Button } from "@material-ui/core";
import ImageEye from "@material-ui/icons/RemoveRedEye";
import P from "prop-types";
import { Link } from "react-router-dom";
import config from "../../config";
import Images from "../layout/Images";
import { DateTimeField, AnomalyField, ClientActivityField } from "../fields";
import { getItineraryAnomalyLabel } from "../utils";
import { MapButton } from "../base/buttons";

const TaskFilter = props => (
  <Filter {...props}>
    <TextInput
      alwaysOn
      label={`resources.${config.METER_RESOURCE}.fields.meter_identifier`}
      source="@meter_remote_id"
    />
    <TextInput
      alwaysOn
      label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.remote_id`}
      source="@supply_point_remote_id"
    />
  </Filter>
);

const DetailsButton = ({ record, resource }) => (
  <Button
    color="primary"
    component={Link}
    to={`/${resource}/${record.id}/show`}
    style={{ minWidth: 0, overflow: "inherit" }}
  >
    <ImageEye />
  </Button>
);

DetailsButton.propTypes = {
  record: P.object,
  resource: P.string
};

const GridView = ({ ids, record, resource, data, Card, basePath }) => (
  <Grid container spacing={8} justify="center">
    {ids.map(id => (
      <Grid item key={id}>
        <Card
          basePath={basePath}
          resource={resource}
          record={{
            ...data[id],
            remote_id: record && record.remote_id
          }}
        />
      </Grid>
    ))}
  </Grid>
);

GridView.propTypes = {
  ids: P.array,
  data: P.object,
  basePath: P.string,
  resource: P.string,
  record: P.object,
  Card: P.func
};

const DatagridView = ({ ...props }) => (
  <Datagrid {...props}>
    <TextField
      label={`resources.${config.METER_RESOURCE}.fields.meter_identifier`}
      source="meter.meter_identifier"
      sortable={false}
    />
    <TextField
      label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.remote_id`}
      source="supply_point.remote_id"
      sortable={false}
    />
    <TextField
      label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.premise_identifier`}
      source="supply_point.access_premise"
      sortable={false}
    />
    <TextField
      label={`resources.${config.READING_RESOURCE}.fields.reading_value`}
      source="latest_result.value"
      sortable={false}
    />
    <DateTimeField
      label={`resources.${config.READING_RESOURCE}.fields.date_visited`}
      source="latest_result.date_visited_at"
      sortable={false}
    />
    <TextField
      label={`resources.${config.READING_RESOURCE}.fields.input_type`}
      source="latest_result.input_type"
      sortable={false}
    />
    <ClientActivityField
      label={`mra.client_activity.name`}
      sourceId={["latest_result.client_activity_id"]}
      source="client_activity_name"
      sortable={false}
    />
    <AnomalyField
      label={getItineraryAnomalyLabel(props.resource)}
      source="latest_result.anomaly_id"
      sortable={false}
    />
    <AnomalyField
      label="mra.anomaly.picture"
      source="latest_result.picture_anomaly_id"
      sortable={false}
    />
    <AnomalyField
      label="mra.anomaly.occupancy"
      source="latest_result.occupancy_anomaly_id"
      sortable={false}
    />
    <Images
      label="mra.itinerary_overview.images"
      source="latest_result.images"
      type="button"
      sortable={false}
    />
    <DetailsButton />
    <MapButton filtrationKey="task_id" mapResource={config.TASK_LOCATION} />
  </Datagrid>
);

DatagridView.propTypes = {
  resource: P.string
};

const DetailsList = ({ card, record, ...props }) => (
  <SwitchList
    {...props}
    title=" "
    debounce={1e3}
    hasCreate={false}
    perPage={config.PAGINATION}
    sort={{ field: "sequence_orig", order: "ASC" }}
    filter={{ itinerary_id: props.match.params.id }}
    filters={<TaskFilter />}
    gridComponent={<GridView Card={card} record={record} />}
    tableComponent={<DatagridView />}
  />
);

DetailsList.propTypes = {
  card: P.func,
  record: P.object,
  match: P.shape({
    params: P.shape({
      id: P.string
    })
  })
};

export default DetailsList;
