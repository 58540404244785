import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Typography } from "@material-ui/core";

const toFixed = (v, fractionDigits) => {
  return isNaN(v) ? 0 : parseFloat(v).toFixed(fractionDigits);
};

export const GpsCoordinateField = ({
  className,
  record = {},
  lat_source,
  lng_source
}) => {
  const lat = get(record, lat_source);
  const lng = get(record, lng_source);
  return (
    <Typography component="span" body1="body1" className={className}>
      {toFixed(lat, 4)} / {toFixed(lng, 4)}
    </Typography>
  );
};

GpsCoordinateField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  className: PropTypes.string,
  lat_source: PropTypes.string.isRequired,
  lng_source: PropTypes.string.isRequired
};
