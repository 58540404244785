import React, { cloneElement } from "react";
import P from "prop-types";
import { CardActions, ExportButton } from "react-admin";
import {
  RestrictedCreateButton,
  RestrictedDeleteButton
} from "./RestrictedButtons";

const Actions = ({
  bulkActions,
  currentSort,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  hasCreate,
  hasDelete,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  total,
  exportButton,
  requiredPermissionsDeleteButton,
  requiredPermissionsCreateButton,
  disableInGlobalContext,
  denyForCreateButton
}) => {
  return (
    <CardActions>
      {bulkActions &&
        cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems
        })}
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button"
        })}
      {hasDelete && (
        <RestrictedDeleteButton
          requiredPermissions={requiredPermissionsDeleteButton}
          basePath={basePath}
        />
      )}
      {hasCreate && (
        <RestrictedCreateButton
          requiredPermissions={requiredPermissionsCreateButton}
          basePath={basePath}
          disableInGlobalContext={disableInGlobalContext}
          denyFor={denyForCreateButton}
        />
      )}
      {!exportButton && exporter !== false && (
        <ExportButton
          maxResults={500}
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={filterValues}
          exporter={exporter}
        />
      )}
      {exportButton}
    </CardActions>
  );
};
Actions.propTypes = {
  bulkActions: P.oneOfType([P.bool, P.element]),
  currentSort: P.shape({
    field: P.string,
    order: P.string
  }),
  className: P.string,
  resource: P.string,
  filters: P.element,
  displayedFilters: P.object,
  exportButton: P.node,
  exporter: P.oneOfType([P.func, P.bool]),
  filterValues: P.object,
  hasCreate: P.bool,
  hasDelete: P.bool,
  basePath: P.string,
  selectedIds: P.array,
  onUnselectItems: P.func,
  showFilter: P.func,
  total: P.number,
  requiredPermissionsDeleteButton: P.array,
  requiredPermissionsCreateButton: P.array,
  disableInGlobalContext: P.bool,
  denyForCreateButton: P.array
};

export default Actions;
