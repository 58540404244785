import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY
} from "react-admin";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import uniqBy from "lodash/uniqBy";
import isArray from "lodash/isArray";
import moment from "moment";
import { transformRequest, transformResponse } from "./ResourceTransformer";
import config from "../config";
import { httpClient } from "../lib";

const {
  API_BASE_URL,
  RMS_RESOURCE,
  USER_RESOURCE,
  LOCATION,
  TASK_LOCATION,
  READINGTASK_RESOURCE,
  DISTRIBUTION_TASK_RESOURCE,
  ROLE_RESOURCE,
  DEVICE_RESOURCE,
  READINGUNIT_RESOURCE,
  REGISTERED_METERS_RESOURCE,
  TASK,
  READINGCENTER_RESOURCE,
  CLIENT_ACTIVITY_RESOURCE,
  RELATIONS,
  IMAGE_RESOURCE_QUALIFY,
  PAGINATION,
  SETTINGS_RESOURCE,
  EXPORT_RECON_RESOURCE,
  IMPORT_RECON_RESOURCE,
  RECON_RESOURCE,
  READING_CALENDAR_RESOURCE,
  USER_INFO
} = config;

class HttpError {
  constructor({ message, status }) {
    this.status = status;
    this.message = message;
  }
}

function buildFilters(filters, prefix = true) {
  return Object.keys(filters).reduce((query, key) => {
    const queryKey = prefix ? `q[${key}]` : key;
    if (!isNil(filters[key])) {
      if (key === "scheduled_at") {
        if (filters[key].trim().length) {
          query[`q[${key}]`] = filters[key]
            .split(" ")
            .map((v, idx) =>
              v
                ? moment(v).format("YYYY-MM-DD")
                : moment(idx ? void 0 : "2010-01-01").format("YYYY-MM-DD")
            )
            .reduce((from, to) => `>${from},<${to}`);
        }
      } else {
        query[queryKey] = filters[key];
      }

      if (isArray(query[queryKey])) {
        query[queryKey] = query[queryKey].filter(v => !isNil(v));
      }
    }
    return query;
  }, {});
}

const dateFilters = ["mra_processed", "uma_processed"];

function buildDataxFilters(filters) {
  const query = {};
  for (let key of Object.keys(filters)) {
    if (dateFilters.includes(key)) {
      if (filters[key] && filters[key].trim()) {
        const [from, to] = filters[key]
          .split(" ")
          .map(v => (v ? moment(v).format() : moment().format()));
        query[`${key}_from`] = from;
        query[`${key}_to`] = to;
      }
    } else if (!isNil(filters[key]) && filters[key] !== "") {
      query[key] = filters[key];
    }
  }
  return query;
}

function queryParameters(data) {
  return Object.entries(data)
    .map(pair => pair.map(encodeURIComponent).join("="))
    .join("&");
}

function rmsQuery(params) {
  const query = {};
  if (params.pagination) {
    const { perPage, page } = params.pagination;
    query.limit = perPage || 20;
    query.offset = (page - 1) * perPage;
  }
  if (params.sort) {
    const { field, order } = params.sort;
    if (field) {
      query.sort_by = [order === "DESC" ? field : `-${field}`];
    }
  }
  if (params.filter) {
    query.search_by = Object.entries(params.filter)
      .map(([k, v]) => `${k}:${v}`)
      .join("|");
  }
  return isEmpty(query) ? "" : btoa(JSON.stringify(query));
}

/**
 * Maps admin-on-rest queries to the MRA REST API
 *
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */

export default (client = httpClient) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The REST request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  let RMS_URL = "";
  let DATAX_URL = "";
  function convertRESTRequestToHTTP(t, r, p) {
    const { type, resource, params } = transformRequest(t, r, p);
    let url = "";
    let _resource;
    const options = { meta: { type: t, resource: r, params: p } };
    const isRMS = resource === RMS_RESOURCE;
    const isDATAX = resource === RECON_RESOURCE;
    const baseURL = isRMS
      ? new URL("api/v1", RMS_URL).href
      : isDATAX
      ? DATAX_URL
      : API_BASE_URL;
    const _buildFilters = isDATAX ? buildDataxFilters : buildFilters;
    switch (type) {
      case GET_LIST: {
        const query = {};
        // preload user stuff so we don't overwrite relation ids for RC/RU
        // preload meters for reading tasks so we can reference them in the grid
        if (
          resource === USER_RESOURCE ||
          resource === READINGTASK_RESOURCE ||
          resource === DISTRIBUTION_TASK_RESOURCE ||
          resource === ROLE_RESOURCE ||
          resource === DEVICE_RESOURCE ||
          resource === READINGUNIT_RESOURCE ||
          resource === REGISTERED_METERS_RESOURCE ||
          resource === TASK
        ) {
          query.preloads = RELATIONS[resource].preloads;
        }
        if (params.pagination) {
          query.limit = params.pagination.perPage || PAGINATION;
          query.page = params.pagination.page;
        }
        if (params.sort && params.sort.field) {
          const { field, order } = params.sort;
          query.sort = order === "DESC" ? `-${field}` : field;
        }
        if (
          resource === USER_RESOURCE ||
          resource === READINGCENTER_RESOURCE ||
          resource === DEVICE_RESOURCE ||
          resource === CLIENT_ACTIVITY_RESOURCE
        ) {
          query.showDeleted = true;
        }
        if (params.filter) {
          let { filter } = params;
          if (resource === READING_CALENDAR_RESOURCE && filter.rc_id) {
            const { rc_id } = filter;
            filter.rc_id = null;
            filter = {
              ...filter,
              rc_code: rc_id
            };
          }
          Object.assign(query, _buildFilters(filter));
        }
        _resource = isRMS
          ? `search/${rmsQuery(params)}`
          : `${resource}?${queryParameters(query)}`;
        url = `${baseURL}/${_resource}`;
        break;
      }
      case GET_ONE: {
        const preloads = RELATIONS[resource]
          ? RELATIONS[resource].preloads
          : [];

        _resource = isRMS
          ? `resource/${params.id}`
          : `${resource}/${params.id}?${queryParameters({ preloads })}`;
        switch (resource) {
          case USER_INFO:
            url = `${baseURL}/${resource}`;
            break;
          case USER_RESOURCE:
            url = `${baseURL}/${_resource}&showDeleted=true`;
            break;
          default:
            url = `${baseURL}/${_resource}`;
            break;
        }
        break;
      }
      case GET_MANY: {
        const query = {
          ..._buildFilters({ ...params.filter, id: params.ids })
        };
        if (params.pagination) {
          const { perPage: limit, page } = params.pagination;
          if (limit) {
            query.limit = limit;
          }

          if (page) {
            query.page = page;
          }
        }
        // preload user stuff so we don't overwrite relation ids for RC/RU
        // preload meters for reading tasks so we can reference them in the grid
        // preload permissions because roles are no use without permissions
        if (
          resource === USER_RESOURCE ||
          resource === READINGTASK_RESOURCE ||
          resource === READINGUNIT_RESOURCE ||
          resource === ROLE_RESOURCE
        ) {
          query.preloads = RELATIONS[resource].preloads;
        }
        _resource = isRMS
          ? `search/${rmsQuery(params)}`
          : `${resource}?${queryParameters(query)}`;
        url = `${baseURL}/${_resource}`;

        if ([LOCATION, TASK_LOCATION].indexOf(resource) !== -1) {
          const [type, location] = resource.split("_");
          url = `${baseURL}/${type}/${params.ids[0]}/${location}`;
        }
        break;
      }
      case GET_MANY_REFERENCE: {
        const query = {
          ..._buildFilters({ ...params.filter, [params.target]: params.id })
        };
        // preload meters and supply points for route sheet printing
        if (resource === READINGTASK_RESOURCE) {
          query.preloads = RELATIONS[resource].preloads;
        }
        if (params.pagination) {
          query.limit = params.pagination.perPage;
          query.page = params.pagination.page;
        }
        if (params.sort && params.sort.field) {
          const { field, order } = params.sort;
          query.sort = order === "DESC" ? `-${field}` : field;
        }
        _resource = isRMS
          ? `search/${rmsQuery(params)}`
          : `${resource}?${queryParameters(query)}`;
        url = `${baseURL}/${_resource}`;
        break;
      }
      case UPDATE:
        _resource = isRMS
          ? `resource/${params.id}`
          : `${resource}/${params.id}?showDeleted=true`;
        url = `${baseURL}/${_resource}`;
        options.method = "PATCH";

        if (resource === USER_RESOURCE) {
          url += `&preloads=${RELATIONS[resource].preloads.join(",")}`;
        }

        if (resource === IMAGE_RESOURCE_QUALIFY) {
          url = `${baseURL}/${IMAGE_RESOURCE_QUALIFY}/${params.id}`;
          if (params.data && params.data.control_anomaly_id) {
            params.data.control_anomaly_id = Number(
              params.data.control_anomaly_id
            );
          }
        }

        options.body = JSON.stringify(params.data);
        break;
      case CREATE:
        _resource = isRMS ? "resource" : `${resource}`;
        url = `${baseURL}/${_resource}`;
        options.method = "POST";
        if (resource === REGISTERED_METERS_RESOURCE) {
          params.data.registration_date = moment(
            params.data.registration_date
          ).format();
        }
        options.body = JSON.stringify(params.data);
        break;
      case DELETE:
        _resource = isRMS
          ? `resource/${params.id}`
          : `${resource}/${params.id}`;
        url = `${baseURL}/${_resource}`;
        options.method = "DELETE";
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  }

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The REST request params, depending on the type
   * @returns {Object} REST response
   */
  function convertHTTPResponseToREST(response, type, resource, params) {
    const { json } = response;
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
      case GET_MANY: {
        if (resource === SETTINGS_RESOURCE && json.items) {
          RMS_URL =
            (json.items.find(i => i.name === "RMS_URL") || {}).value || RMS_URL;
          DATAX_URL =
            (json.items.find(i => i.name === "DATAX_URL") || {}).value ||
            DATAX_URL;
        }

        let data = json.items || json.resources || json.records || [];
        let { total } = get(json, "meta", get(json, "_meta", json));

        if (resource === READING_CALENDAR_RESOURCE && Array.isArray(json)) {
          data = json.map(i => ({
            ...i,
            id: `${i.rc_code}-${i.itinerary_code}-${i.cycle}-${i.cycle_year}`
          }));
          total = json.length;
        }

        if ([LOCATION, TASK_LOCATION].indexOf(resource) !== -1) {
          if (isArray(json)) {
            data = uniqBy(
              json.map(supply_point => ({
                ...supply_point,
                id: supply_point.task_id,
                itinerary_id: params.ids[0]
              })),
              supply_point => supply_point.id
            );
          } else {
            data = [
              {
                id: json.task_id,
                ...json
              }
            ];
          }
          total = data.length;
        }

        return {
          data,
          total
        };
      }
      case CREATE:
        return { data: { ...params.data, id: json.id } };
      default:
        return { data: json };
    }
  }

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a REST response
   */
  return async (type, resource, params) => {
    if (!RMS_URL && resource === RMS_RESOURCE) {
      try {
        const res = await client(
          `${API_BASE_URL}/${SETTINGS_RESOURCE}?q%5Bname%5D=RMS_URL`
        );
        const {
          json: {
            items: [{ value }]
          }
        } = res;
        RMS_URL = value;
      } catch (e) {
        throw Error("Unable to retrieve RMS url");
      }
    }
    if (
      !DATAX_URL &&
      (resource === EXPORT_RECON_RESOURCE || resource === IMPORT_RECON_RESOURCE)
    ) {
      try {
        const res = await client(
          `${API_BASE_URL}/${SETTINGS_RESOURCE}?q%5Bname%5D=DATAX_URL`
        );
        const {
          json: {
            items: [{ value }]
          }
        } = res;
        DATAX_URL = value;
      } catch (e) {
        throw Error("Unable to retrieve DATAX url");
      }
    }
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          client(`${API_BASE_URL}/${resource}/${id}`, {
            method: "DELETE"
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }
    const { url, options } = convertRESTRequestToHTTP(type, resource, params);
    return client(url, options)
      .then(response =>
        convertHTTPResponseToREST(response, type, resource, params)
      )
      .then(restResponse =>
        transformResponse(restResponse, type, resource, params)
      )
      .catch(error => {
        const { id, code } = error.body || {};
        if (id && code) {
          throw new HttpError({
            message: id,
            status: code
          });
        }
        throw error;
      });
  };
};
