import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required
} from "react-admin";
import { validate } from "./validators";
import config from "../../config";

export const ReadingCenterCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm validate={validate}>
        <TextInput
          source="name"
          validate={required("mra.validation.required")}
        />
        <TextInput
          source="remote_id"
          validate={required("mra.validation.required")}
        />
        <TextInput source="location" />
        <ReferenceInput
          source="region_id"
          reference={config.REGION_RESOURCE}
          filterToQuery={searchText => ({ name: searchText })}
          allowEmpty
        >
          <SelectInput options={{ name: "region" }} optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default ReadingCenterCreate;
