import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-admin";
import config from "../../config";
import { CHOICE, mobileSettings, serverSettings } from "../../config/settings";

/*
 * not used any more
 * settings are no longer translated through translation keys
 */
const TranslatedSettingsField = ({ record = {}, translate: t, resource }) => {
  const { name, value } = record;
  const { type } =
    (resource === config.DEVICES_SETTINGS_RESOURCE
      ? mobileSettings
      : serverSettings)[name] || {};
  return (
    <span>
      {type === CHOICE ? t(`mra.settings.${value}`) : value}
    </span>
  );
};

TranslatedSettingsField.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string.isRequired,
  type: PropTypes.string,
  translate: PropTypes.func
};

export default translate(TranslatedSettingsField);
