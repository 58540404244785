export const validate = (values, { translate }) => {
  const errors = {};

  if (!(values.name && values.name.trim().length)) {
    errors.name = [translate("mra.reading_center.name")];
  }
  if (!(values.remote_id && values.remote_id.trim().length)) {
    errors.remote_id = [translate("mra.reading_center.remote_id")];
  }
  return errors;
};
