import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent as CardText,
  CardActions,
  Toolbar,
  Button as RaisedButton
} from "@material-ui/core";
import ActionCheck from "@material-ui/icons/CheckCircle";
import AlertError from "@material-ui/icons/ErrorOutline";
import { Title, ImageField, translate } from "react-admin";
import ImageInput from "./MRAImageInput";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import compose from "recompose/compose";
import { pushUploadFiles } from "../../redux/actions";

const ToolbarGroup = props => <div {...props} />;

const styles = {
  actions: { zIndex: 2, display: "inline-block", float: "right" },
  toolbar: { clear: "both" },
  button: { margin: "10px 24px", position: "relative" }
};

class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    const { upload } = this.props;
    const { id } = this.props.match.params;
    event.preventDefault();
    upload(id);
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const {
      resource,
      source,
      type,
      accept,
      title,
      message,
      match: {
        params: { id }
      },
      translate
    } = this.props;
    return (
      <div>
        <Card>
          <CardActions style={styles.actions} />
          <Title title={translate(title, { id })} />
          <form onSubmit={this.handleSubmit}>
            <CardText>{translate(message)}</CardText>
            <ImageInput
              resource={resource}
              type={type}
              source={source}
              id={id}
              accept={accept}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
            <Toolbar style={styles.toolbar}>
              <ToolbarGroup>
                <RaisedButton
                  type="submit"
                  color="primary"
                  variant="raised"
                  style={styles.button}
                >
                  <ActionCheck />
                  {translate("mra.confirm.ok")}
                </RaisedButton>
                <RaisedButton
                  variant="raised"
                  onClick={this.goBack}
                  style={styles.button}
                >
                  <AlertError />
                  {translate("ra.action.cancel")}
                </RaisedButton>
              </ToolbarGroup>
            </Toolbar>
          </form>
        </Card>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  title: PropTypes.string,
  history: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  match: PropTypes.object.isRequired,
  record: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  type: PropTypes.string,
  accept: PropTypes.string,
  message: PropTypes.string,
  translate: PropTypes.func,
  upload: PropTypes.func
};

ImageUpload.defaultProps = {
  accept: "image/*",
  source: "images",
  type: "image",
  title: "mra.upload.image.title",
  message: "mra.upload.image.message"
};

ImageUpload.contextTypes = {
  translate: PropTypes.func
};

export const ConnectedImageUpload = compose(
  connect(
    null,
    (dispatch, { resource, source = "images" }) => ({
      upload: id => dispatch(pushUploadFiles(resource, id, source))
    })
  ),
  reduxForm({
    form: "record-form"
  }),
  translate
)(ImageUpload);

export default ConnectedImageUpload;
