import React from "react";
import PropTypes from "prop-types";
import {
  Edit,
  TextField,
  TextInput,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  NumberInput,
  AutocompleteInput,
  translate,
  required
} from "react-admin";
import config from "../../config";
import {
  RestrictedCustomForm,
  RestrictedEditActions
} from "../restricted-components";
import Images from "../layout/Images";
import { withStyles } from "@material-ui/core";
import compose from "recompose/compose";
import { FieldAgentInput, TranslatedSelectInput, DateInput } from "../inputs";
import { PrecisionModeField, ConnectionModeField } from "../fields";
import { SubTitle } from "../base";

const styles = {
  override: {
    width: "auto",
    height: "auto"
  }
};

const EditTitle = translate(({ translate, record }) => (
  <span>
    {translate(`resources.${config.REGISTERED_METERS_RESOURCE}.name`, 2)}
    {record ? ` - ${record.meter_ref}` : ""}
  </span>
));

const validate = (values, { translate }) => {
  const errors = {};

  if (!(values.meter_ref && values.meter_ref.trim().length)) {
    errors.meter_ref = [translate("mra.register_meter.meter_ref")];
  }
  if (!(values.dials && !isNaN(values.dials))) {
    errors.dials = [translate("mra.register_meter.dials")];
  }
  if (!(values.reading_value && values.reading_value.trim().length)) {
    errors.reading_value = [translate("mra.register_meter.reading_value")];
  }
  if (values.reading_value && values.reading_value.length > values.dials) {
    errors.reading_value = [
      translate("mra.register_meter.reading_value_chars")
    ];
  }
  if (!values.registration_date) {
    errors.registration_date = [
      translate("mra.register_meter.registration_date")
    ];
  }
  if (!values.agent_id) {
    errors.agent_id = [translate("mra.register_meter.agent_id")];
  }
  /*
  //These are not required any more #MRA-643
  if (!(values.lng && !isNaN(values.lng))) {
    errors.lng = [translate("mra.register_meter.lng")];
  }
  if (!(values.lat && !isNaN(values.lat))) {
    errors.lat = [translate("mra.register_meter.lat")];
  }
  */
  if (!values.itinerary_id) {
    errors.itinerary_id = [translate("mra.register_meter.itinerary")];
  }
  if (!values.client_activity_id) {
    errors.client_activity_id = [
      translate("mra.register_meter.client_activity")
    ];
  }
  if (!values.reading_anomaly_id) {
    errors.reading_anomaly_id = [
      translate("mra.register_meter.reading_anomaly_id")
    ];
  }
  if (!values.picture_anomaly_id) {
    errors.picture_anomaly_id = [
      translate("mra.register_meter.picture_anomaly_id")
    ];
  }
  return errors;
};

/**
 * component for editing mra registered meter
 */
const RegisteredMeterEdit = ({ translate, classes, ...props }) => {
  return (
    <Edit
      {...props}
      title={<EditTitle />}
      actions={
        <RestrictedEditActions
          requiredPermissionsDeleteButton={[
            config.PERMISSIONS.REGISTERED_METER_DELETE
          ]}
        />
      }
    >
      <RestrictedCustomForm
        requiredPermissions={[config.PERMISSIONS.REGISTERED_METER_UPDATE]}
        disabledToolbarTitle={translate("mra.general.readonly_details")}
        validate={validate}
      >
        <TextInput
          source="meter_ref"
          validate={required("mra.validation.required")}
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.meter_ref`}
        />
        <TextInput source="next_meter_ref" />
        <TextInput source="prev_meter_ref" />
        <ReferenceInput
          allowEmpty
          source="itinerary_id"
          reference={config.ITINERARY_RESOURCE}
          filterToQuery={searchText => ({ remote_id: searchText })}
          validate={required("mra.validation.required")}
        >
          <AutocompleteInput optionText="remote_id" />
        </ReferenceInput>
        <FieldAgentInput
          allowEmpty
          source="agent_id"
          reference={config.USER_RESOURCE}
          filterToQuery={searchText => ({ username: searchText })}
          validate={required("mra.validation.required")}
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.agent_id`}
        >
          <AutocompleteInput
            options={{ name: "reader" }}
            optionText="username"
          />
        </FieldAgentInput>
        <NumberInput
          source="dials"
          style={{ textAlign: "left" }}
          validate={required("mra.validation.required")}
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.dials`}
        />
        <TextInput
          source="value"
          validate={required("mra.validation.required")}
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.reading_value`}
        />
        <ReferenceInput
          allowEmpty
          source="meter_type_id"
          reference={config.METER_TYPE_RESOURCE}
          filterToQuery={searchText => ({ name: searchText })}
        >
          <SelectInput options={{ name: "meter type" }} optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          allowEmpty
          source="reading_type_id"
          reference={config.READING_TYPE_RESOURCE}
          filterToQuery={searchText => ({ label_lang_one: searchText })}
        >
          <SelectInput
            options={{ name: "reading type" }}
            optionText="label_lang_one"
          />
        </ReferenceInput>
        <DateInput
          source="registration_date"
          validate={required("mra.validation.required")}
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.registration_date`}
        />
        <TextInput source="location_description" />
        <BooleanInput source="has_barcode" />
        <ConnectionModeField
          addLabel
          className={classes.inlineField}
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.conn_mode`}
          source="conn_mode"
        />
        <TextField
          addLabel
          className={classes.inlineField}
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.net_type`}
          source="net_type"
        />
        <PrecisionModeField
          addLabel
          className={classes.inlineField}
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.gps_precision_mode`}
          source="gps_precision_mode"
        />
        <NumberInput
          source="lng"
          style={{ textAlign: "left" }}
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.lng`}
        />
        <NumberInput
          source="lat"
          style={{ textAlign: "left" }}
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.lat`}
        />
        <ReferenceInput
          source="client_activity_id"
          reference={config.CLIENT_ACTIVITY_RESOURCE}
          validate={required("mra.validation.required")}
        >
          <TranslatedSelectInput />
        </ReferenceInput>
        <ReferenceInput
          source="anomaly_id"
          reference={config.ANOMALIES_RESOURCE}
          filterData={{ type: config.ANOMALY_TYPES.READING.id }}
          validate={required("mra.validation.required")}
        >
          <TranslatedSelectInput />
        </ReferenceInput>
        <ReferenceInput
          source="picture_anomaly_id"
          reference={config.ANOMALIES_RESOURCE}
          filterData={{ type: config.ANOMALY_TYPES.PICTURE.id }}
          validate={required("mra.validation.required")}
        >
          <TranslatedSelectInput />
        </ReferenceInput>
        <SubTitle source="full-width" title="mra.itinerary_overview.images" />
        <Images source="images" className={classes.override} type="grid" />
      </RestrictedCustomForm>
    </Edit>
  );
};

RegisteredMeterEdit.propTypes = {
  translate: PropTypes.func,
  classes: PropTypes.object
};

export default compose(
  translate,
  withStyles(styles)
)(RegisteredMeterEdit);
