import React from "react";
import PropTypes from "prop-types";

import { translate } from "react-admin";

import { AnomalyField } from "./AnomalyField";

import config from "../../config";

const PictureControl = props => {
  const { record, translate } = props;
  const { images: meterImages, latest_result, metadata } = record;
  const images = meterImages
    ? meterImages
    : latest_result
    ? latest_result.images || []
    : [];
  record.control_anomaly_id = record.metadata
    ? metadata.control_anomaly_id
    : images.length > 0
    ? images[0].control_anomaly_id
    : null;
  record.quality_status = record.metadata
    ? record.metadata.quality
    : images.length > 0
    ? images[0].quality_status
    : null;
  return (
    <AnomalyField
      {...props}
      record={record}
      sourceId={["control_anomaly_id"]}
      defaultValue={
        record.quality_status === config.PICTURE_QUALITY.OK.id
          ? config.PICTURE_QUALITY.OK.id
          : translate("mra.unqualified")
      }
    />
  );
};

PictureControl.propTypes = {
  record: PropTypes.object,
  translate: PropTypes.func.isRequired
};

export default translate(PictureControl);
