import PropTypes from "prop-types";
import React from "react";
import { translate } from "react-admin";
import { Divider, withStyles } from "@material-ui/core";
import compose from "recompose/compose";

const styles = {
  dividerWrapper: {
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
    "&:parent": {
      width: "100% !important"
    }
  },
  subheading: {
    marginBottom: "5px"
  }
};

const SubTitle = ({ classes, translate, title }) => (
  <div className={classes.dividerWrapper}>
    <h4 className={classes.subheading}>{translate(title)}</h4>
    <Divider />
  </div>
);

SubTitle.propTypes = {
  classes: PropTypes.object,
  translate: PropTypes.func,
  title: PropTypes.string
};

export default compose(
  translate,
  withStyles(styles)
)(SubTitle);
