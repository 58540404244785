import React from "react";
import P from "prop-types";
import { Assignment } from "../base/assigment";

const EditTitle = ({ translate, record }) => {
  return (
    <span>
      {translate("mra.itinerary_assign.title")}
      {record ? ` - ${record.remote_id}` : ""}
    </span>
  );
};

EditTitle.propTypes = {
  translate: P.func,
  record: P.object
};

export default props => <Assignment EditTitle={EditTitle} {...props} />;
