import React from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";

import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  withStyles
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { Map } from "../base";
import { closeModal } from "../../redux/actions";
import config from "../../config";
import { getModal } from "../../redux/selectors";

function Transition(props) {
  return <Slide style={{ width: "80%" }} direction="up" {...props} />;
}

class MapModal extends React.Component {
  handleClose = () => this.props.closeModal(config.MODALS.MAP_MODAL);

  render() {
    const { classes, isOpen = false, options = {} } = this.props;
    const {
      filteredItems,
      fieldsToDisplay,
      filtrationKey,
      resource,
      latField,
      lngField,
      mapCenter
    } = options;
    return (
      <div>
        <Dialog
          keepMounted
          maxWidth={false}
          open={isOpen}
          TransitionComponent={Transition}
          className={classes.dialogMapBox}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle disableTypography className={classes.dialogTitle}>
            <IconButton onClick={this.handleClose}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ padding: 0 }}>
            {resource ? (
              <Map
                center={mapCenter}
                latField={latField}
                lngField={lngField}
                dataResource={resource}
                filtrationKey={filtrationKey}
                filteredItems={filteredItems}
                fieldsToDisplay={fieldsToDisplay}
              />
            ) : null}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const styles = theme => ({
  dialogMapBox: {
    minWidth: "100%",
    width: "80%"
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "flex-end",
    margin: 0,
    padding: "8px 4px"
  }
});

MapModal.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  options: PropTypes.object,
  classes: PropTypes.object
};

MapModal.defaultProps = {
  isOpen: false
};

export default compose(
  connect(
    state => {
      return {
        ...getModal(config.MODALS.MAP_MODAL)(state)
      };
    },
    {
      closeModal
    }
  ),
  withStyles(styles)
)(MapModal);
