import { CardActions, Button as FlatButton } from "@material-ui/core";
import ActionLock from "@material-ui/icons/Lock";
import { ListButton, DeleteButton, translate } from "react-admin";
import { Link } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import { compose, mapProps } from "recompose";
import { hide } from "../fields/hideableField";
import { omit } from "lodash";
import { checkPermission } from "../restricted-components/RestrictedButtons";
import { getCurrentUserId, getCurrentUserRoles } from "../../redux/selectors";
import config from "../../config";

const cardActionStyle = {
  justifyContent: "flex-end"
};

export const checkPermissionsAndAdminUser = (state, props) => {
  const { data, requiredPermissions } = props;
  // check if stuff is loaded
  if (!data) return false;
  const userRoles = getCurrentUserRoles(state);
  if (!userRoles || userRoles.length < 2) return false;
  // admin users should only be editable if the current user has the MraUser_update_Administrators permission
  if (userRoles.map(r => r.name).includes(config.ROLES.NAMES.AD)) {
    const perms = requiredPermissions ? requiredPermissions : [];
    return checkPermission(state, {
      requiredPermissions: perms.concat(
        config.PERMISSIONS.USER_UPDATE_ADMINISTRATORS
      )
    });
  } else return checkPermission(state, props);
};

// returns true if current user is editing his own profile
export const checkNotEditSelf = (state, props) => {
  const { record } = props;
  const currentUserId = getCurrentUserId(state);
  // check if stuff is loaded
  if (!record || !currentUserId) return false;
  return record.id !== currentUserId;
};

const isAllowed = (state, props) =>
  checkNotEditSelf(state, props) && checkPermissionsAndAdminUser(state, props);

export const RestrictedFlatButton = compose(
  hide(checkPermissionsAndAdminUser),
  mapProps(props => omit(props, ["dispatch", "display", "requiredPermissions"]))
)(FlatButton);

export const RestrictedDeleteButton = compose(
  hide(isAllowed),
  mapProps(props => omit(props, ["dispatch", "display", "requiredPermissions"]))
)(DeleteButton);

const UserEditActions = props => {
  // if this is displayed in user profile, we require different permissions
  const {
    basePath,
    data,
    resource,
    listButton,
    deleteButton,
    profile,
    translate
  } = props;
  const id = data && data.id;

  return (
    <CardActions style={cardActionStyle}>
      <RestrictedFlatButton
        color="primary"
        component={Link}
        to={
          resource && !basePath.includes(resource)
            ? `${basePath}/${resource}/${id}/reset`
            : `${basePath}/${id}/reset`
        }
        style={{ overflow: "inherit" }}
        requiredPermissions={[
          profile
            ? config.PERMISSIONS.USER_OWN_PROFILE
            : config.PERMISSIONS.USER_CHANGE_PASSWORD
        ]}
        data={data}
      >
        <ActionLock />
        {translate("mra.reset.button")}
      </RestrictedFlatButton>

      {listButton ? <ListButton basePath={basePath} /> : null}
      {deleteButton ? (
        <RestrictedDeleteButton
          basePath={basePath}
          record={data}
          resource={resource}
          requiredPermissions={[config.PERMISSIONS.USER_DELETE]}
          data={data}
        />
      ) : null}
    </CardActions>
  );
};

UserEditActions.propTypes = {
  translate: PropTypes.func,
  basePath: PropTypes.string,
  data: PropTypes.object,
  resource: PropTypes.string,
  deleteButton: PropTypes.bool,
  profile: PropTypes.bool,
  listButton: PropTypes.bool
};

UserEditActions.defaultProps = {
  deleteButton: true,
  listButton: true
};

export default translate(UserEditActions);
