import React from "react";
import PropTypes from "prop-types";
import {
  Edit,
  TextInput,
  ReferenceInput,
  SelectInput,
  DisabledInput,
  translate,
  required
} from "react-admin";
import { connect } from "react-redux";
import compose from "recompose/compose";
import {
  TextField,
  Table,
  TableBody,
  TableHead as TableHeader,
  TableCell,
  TableRow,
  withStyles
} from "@material-ui/core";
import { validate } from "./validators";
import config from "../../config";
import {
  RestrictedEditActions,
  RestrictedCustomForm
} from "../restricted-components";
import { setQualityThreshold } from "../../redux/actions";
import { getQualityRules } from "../../redux/selectors";
import { SubTitle } from "../base";

const styles = {
  tableHeadFirst: {
    width: "10%"
  },
  tableHeadSecond: {
    width: "70%"
  },
  tableHeadThird: {
    width: "10%"
  },
  muiTextStyle: {
    width: "50%"
  }
};

const QualityRuleField = ({
  classes,
  record = {},
  qualityRulesList,
  thresholds,
  set,
  translate
}) => (
  <div>
    <Table className={classes.dividerWrapper}>
      <TableHeader>
        <TableRow>
          <TableCell className={classes.tableHeadFirst}>
            {translate("mra.reading_center.rule_code")}
          </TableCell>
          <TableCell className={classes.tableHeadSecond}>
            {translate("mra.reading_center.description")}
          </TableCell>
          <TableCell className={classes.tableHeadThird}>
            {translate("mra.reading_center.threshold")}
          </TableCell>
        </TableRow>
      </TableHeader>
      {qualityRulesList && record.quality_rule_assignments && (
        <TableBody>
          {record.quality_rule_assignments.map((quality_rule, index) => (
            <TableRow key={index}>
              <TableCell className={classes.tableHeadFirst}>
                {
                  (qualityRulesList[quality_rule.quality_rule_id] || {})
                    .rule_code
                }
              </TableCell>
              <TableCell className={classes.tableHeadSecond}>
                {
                  (qualityRulesList[quality_rule.quality_rule_id] || {})
                    .description
                }
              </TableCell>
              <TableCell className={classes.tableHeadThird}>
                <TextField
                  className={classes.muiTextStyle}
                  placeholder="Numbers from 0-100 Only"
                  value={
                    thresholds &&
                    thresholds[quality_rule.quality_rule_id] != null
                      ? thresholds[quality_rule.quality_rule_id]
                      : quality_rule.threshold
                  }
                  onChange={event =>
                    set(
                      record.id,
                      quality_rule.quality_rule_id,
                      event.target.value
                    )
                  }
                  type="number"
                  name={`${quality_rule.quality_rule_id}_threshold_input`}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      )}
    </Table>
  </div>
);

QualityRuleField.defaultProps = { label: "Quality Rule" };

QualityRuleField.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object,
  qualityRulesList: PropTypes.object,
  thresholds: PropTypes.object,
  set: PropTypes.func,
  translate: PropTypes.func
};

const ConnectedQualityRuleField = compose(
  connect(
    (state, props) => ({
      qualityRulesList: getQualityRules(state),
      thresholds: state.mra.thresholds[props.record.id]
    }),
    { set: setQualityThreshold }
  ),
  withStyles(styles),
  translate
)(QualityRuleField);

const EditTitle = props => (
  <span>
    {props.translate(`resources.${config.READINGCENTER_RESOURCE}.name`, 2)} -{" "}
    {props.record ? `"${props.record.name}"` : ""}
  </span>
);

EditTitle.propTypes = {
  translate: PropTypes.func,
  record: PropTypes.object
};

/**
 * component for editing reading centers
 */
export const ReadingCenterEdit = ({ translate, ...props }) => {
  return (
    <Edit
      title={<EditTitle translate={translate} />}
      actions={
        <RestrictedEditActions
          hasDelete
          requiredPermissionsDeleteButton={[
            config.PERMISSIONS.READING_CENTER_DELETE
          ]}
        />
      }
      {...props}
    >
      <RestrictedCustomForm
        validate={validate}
        requiredPermissions={[config.PERMISSIONS.READING_CENTER_UPDATE]}
        disabledToolbarTitle={translate("mra.general.readonly_details")}
      >
        <TextInput
          source="name"
          validate={required("mra.validation.required")}
        />
        <DisabledInput
          source="remote_id"
          validate={required("mra.validation.required")}
        />
        <TextInput source="location" />
        <ReferenceInput
          source="region_id"
          reference={config.REGION_RESOURCE}
          filterToQuery={searchText => ({ name: searchText })}
          allowEmpty
        >
          <SelectInput options={{ name: "region" }} optionText="name" />
        </ReferenceInput>
        <SubTitle
          source="full-width"
          title="mra.reading_center.quality_checks"
        />
        <ConnectedQualityRuleField source="quality_rule_assignments" />
      </RestrictedCustomForm>
    </Edit>
  );
};

ReadingCenterEdit.propTypes = {
  translate: PropTypes.func,
  router: PropTypes.object,
  classes: PropTypes.object
};

export default translate(ReadingCenterEdit);
