import React from "react";
import PropTypes from "prop-types";
import ReactDOMServer from "react-dom/server";
import { connect } from "react-redux";
import { translate, showNotification } from "react-admin";
import compose from "recompose/compose";

import AnomalySheet from "./AnomalySheet";
import { RestrictedFlatButton } from "../restricted-components";
import { getLocale } from "../../redux/selectors";
import config from "../../config";
import { httpClient } from "../../lib";
const { API_BASE_URL, ANOMALIES_RESOURCE } = config;

class AnomalyPrintButton extends React.Component {
  printSheet = async () => {
    let { showNotification, locale } = this.props;
    try {
      let { json } = await httpClient(
        `${API_BASE_URL}/${ANOMALIES_RESOURCE}?limit=500`
      );

      let anomalies = json.items.filter(a => a.is_active);
      let anomalysheet = <AnomalySheet anomalies={anomalies} locale={locale} />;
      let content = ReactDOMServer.renderToStaticMarkup(anomalysheet);
      let pri = this.iframe.contentWindow;
      pri.document.open();
      pri.document.write(content);
      pri.document.close();
      pri.focus();
      pri.print();
    } catch (e) {
      showNotification("Unable to get anomalies");
    }
  };

  render() {
    const { translate } = this.props;
    return (
      <div style={{ overflow: "inherit", display: "inline-block" }}>
        <RestrictedFlatButton
          color="primary"
          style={{ overflow: "inherit" }}
          onClick={() => this.printSheet()}
          requiredPermissions={[config.PERMISSIONS.READING_ITINERARY_PRINT]}
        >
          {translate("mra.itinerary_assign.anomaly_print_button")}
        </RestrictedFlatButton>
        <iframe
          ref={iframe => {
            this.iframe = iframe;
          }}
          title="print_frame"
          style={{
            height: "0px",
            width: "0px",
            position: "absolute",
            border: "0"
          }}
        />
      </div>
    );
  }
}

AnomalyPrintButton.propTypes = {
  translate: PropTypes.func,
  showNotification: PropTypes.func,
  locale: PropTypes.string
};

const mapStateToProps = state => ({
  locale: getLocale(state)
});

export default compose(
  connect(
    mapStateToProps,
    { showNotification }
  ),
  translate
)(AnomalyPrintButton);
