import { createSelector } from "reselect";
import config from "../config";
import compact from "lodash/compact";
import get from "lodash/get";
import map from "lodash/map";
import omit from "lodash/omit";
import filter from "lodash/filter";

export const adminResource = state => resourceName =>
  state.admin.resources[resourceName];
export const possibleValues = state => state.admin.references.possibleValues;
export const getAllRecords = resource => state =>
  adminResource(state)(resource).data;

export const getRecord = (resource, id) => state =>
  getAllRecords(resource)(state)[id];
export const getSelectedIds = resource => state =>
  adminResource(state)(resource).list.selectedIds;
export const getValueFromRecord = (resource, id, name) => state => {
  const record = getRecord(resource, id)(state);
  return record ? record[name] : null;
};
export const getModal = modalName => state => state.mra.modals[modalName];
export const getAllMessages = getAllRecords(config.MESSAGES_RESOURCE);
export const getJwt = state => state.mra.jwt;
export const getJwtValues = state => state.mra.decodedJwt;
export const getJwtValue = path => state => get(getJwtValues(state), path);
export const getUserPermissions = state => state.mra.userPermissions;
export const getCurrentUserRoles = state => state.mra.userRoles;
export const getAllRoles = getAllRecords(config.ROLE_RESOURCE);
export const getAllItineraries = getAllRecords(config.ITINERARY_RESOURCE);
export const getAllUsers = getAllRecords(config.USER_RESOURCE);
export const getAllAnomalies = getAllRecords(config.ANOMALIES_RESOURCE);
export const getAllClientActivities = getAllRecords(
  config.CLIENT_ACTIVITY_RESOURCE
);
export const getAllReadingCenters = getAllRecords(
  config.READINGCENTER_RESOURCE
);
export const getAllReadingUnits = getAllRecords(config.READINGUNIT_RESOURCE);
export const getAllSettings = getAllRecords(config.SETTINGS_RESOURCE);
export const getAllReadingTasks = getAllRecords(config.READINGTASK_RESOURCE);
export const getAllReadingTasksForItinerary = itineraryID => state => {
  return Object.values(getAllReadingTasks(state)).filter(
    task => task.itinerary_id === itineraryID
  );
};
export const getCurrentLocation = state => state.routing.location.pathname;
export const getParams = resource => state =>
  adminResource(state)(resource).list.params;
export const getTotalListItems = resource => state =>
  adminResource(state)(resource).list.total;
export const getQualityRunsByItineraryId = itineraryId => state => {
  const runs = getAllRecords(config.QUALITYRUN_RESOURCE)(state);
  return runs
    ? Object.values(runs).filter(r => r.itinerary_id === itineraryId)
    : [];
};
export const getQualityRules = getAllRecords(config.QUALITY_RULES_RESOURCE);

export const getQualityRuleByQualityRun = run => state => {
  const rules = getAllRecords(config.QUALITY_RULES_RESOURCE)(state);
  return rules[run.rule_id];
};
export const getReadingCenterById = (id, state) => {
  const rcs = getAllReadingCenters(state);
  return Object.values(rcs).find(rc => rc.id === id);
};
export const getRCContextId = state => {
  const id = getRcContext(state);
  return !id || id === config.RC_CONTEXT_ALL ? null : id;
};
export const getRCContextExtId = state => {
  const id = getRcContext(state);
  return !id || id === config.RC_CONTEXT_ALL
    ? null
    : get(getReadingCenterById(id, state), "remote_id");
};
export const getReadingUnitById = id => state => {
  const rus = getAllReadingUnits(state);
  return Object.values(rus).find(ru => ru.id === id);
};
export const getRCsofRU = id => state => {
  const ru = getReadingUnitById(id)(state);
  const rcs = getAllReadingCenters(state);
  return ru && ru.centers ? compact(ru.centers.map(id => rcs[id])) : null;
};
export const getCurrentUser = state => state.mra.currentUser;
export const getCurrentUserId = createSelector(
  [getCurrentUser],
  user => (user ? user.id : null)
);
// FIXME: does not get the actual selected context... remove and use getCurrentRC
export const getCurrentUserRC = createSelector(
  [getCurrentUser, getAllReadingCenters],
  (user, rcs) => {
    const ids = get(user, "centers");
    if (ids && ids[0]) return rcs[ids[0]];
    return null;
  }
);
export const getCurrentUserRCs = createSelector(
  [getCurrentUser, getAllReadingCenters],
  (user, rcs) => {
    const ids = get(user, "centers");
    if (ids) return ids.map(id => rcs[id]);
    return null;
  }
);
export const getCurrentUserRU = createSelector(
  [getCurrentUser, getAllReadingUnits],
  (user, rus) => {
    const id = get(user, "unit_id");
    if (id !== undefined) return rus[id];
    return null;
  }
);
export const getCurrentUserRUContext = createSelector(
  [getCurrentUser],
  user => {
    const id = get(user, "unit_id");
    if (id !== undefined) return id;
    return null;
  }
);

export const checkCurrentUserRole = state => roleName => {
  const roles = getCurrentUserRoles(state);
  const result = roles.find(role => role.name === roleName);
  return !!result;
};

export const getUserRoles = user => state => {
  const userRoles = getAllUsers(state)[user].roles;
  const roles = getAllRoles(state);
  if (userRoles && userRoles.length > 0)
    return compact(userRoles.map(id => roles[id]));
  return [];
};

export const getCurrentUserPermissionNames = state =>
  map(getUserPermissions(state), p => p.name);
//returns true if currentuser has permission with name "permissionName"
export const checkPermissionByName = permissionName => state => {
  const names = getCurrentUserPermissionNames(state);
  return names.includes(permissionName);
};
export const getPossibleReferences = (
  state,
  referenceSource,
  reference,
  selectedIds = []
) => {
  const possibleValues =
    state.admin.references.possibleValues[referenceSource] || [];
  if (selectedIds.length !== 0) {
    selectedIds.forEach(
      id => possibleValues.includes(id) || possibleValues.unshift(id)
    );
  }
  return possibleValues
    .map(id => getAllRecords(reference)(state)[id])
    .filter(r => typeof r !== "undefined");
};
export const getNewSequence = (itineraryId, taskId) => state =>
  get(state.mra.resequence, `${itineraryId}.${taskId}`);
export const getReadingsMRA = state => state.mra.readings;
export const getReadingValueMRA = (id, field) => state =>
  get(state.mra.readings[id], field);
export const getReadingValue = (id, field) => state => {
  return get(getAllRecords(config.READING_RESOURCE)(state)[id], field);
};
export const getOkControlAnomaly = state => {
  const anomalies = adminResource(state)(config.ANOMALIES_RESOURCE)
    ? getAllAnomalies(state)
    : null;
  if (anomalies) {
    return Object.keys(anomalies).filter(
      a =>
        anomalies[a].type === config.ANOMALY_TYPES.CONTROL.id &&
        anomalies[a].is_default
    )[0];
  }
  return null;
};

export const getSkipReadingFlag = id => state => {
  const readingAnomalyId = get(state.mra.readings[id], "reading_anomaly_id");
  const anomaly = getAllAnomalies(state)[readingAnomalyId];
  return anomaly ? anomaly.is_skip_reading : false;
};
export const getSettingValue = name => state =>
  get(
    Object.values(getAllSettings(state)).find(setting => setting.name === name),
    "value"
  );
export const getDeviceCodeSetting = getSettingValue(
  config.MOBILE_APP_REGISTRATION_KEY
);
export const getSelectedFields = state => {
  let ids = [];
  const selected = state.mra.selectedFields;
  if (selected === undefined) return undefined;
  Object.keys(selected).forEach(id => {
    if (selected[id]) ids.push(id);
  });
  return ids;
};
export const getTotalCompletedReadings = (state, itineraryId) => {
  let count = 0;
  const readings = getAllRecords(config.READINGTASK_RESOURCE)(state);
  if (readings === undefined) return count;
  Object.keys(readings).forEach(id => {
    if (readings[id].itinerary_id === itineraryId) {
      if (
        readings[id].latest_result !== null &&
        readings[id].latest_result.status === "SUCCESS"
      )
        count += 1;
    }
  });
  return count;
};
export const getLocale = state => state.i18n.locale;
export const getCurrentLang = state => config.LOCALE_TYPES[getLocale(state)];
function getRecordValue(record, key) {
  return Array.isArray(key)
    ? get(record, key.find(k => get(record, k)))
    : get(record, key);
}
function getQualityStatus(record) {
  const { metadata, latest_reading, latest_result } = record;
  const { images } = latest_reading || latest_result || record;
  const { quality, quality_status } = metadata || (images && images[0]) || {};
  return quality || quality_status;
}
export const getAnomalyName = (id, props) => state => {
  const { sourceId, record, defaultValue, type } = props;
  if (!id && !sourceId) return null;
  const isControlAnomaly = type === config.ANOMALY_TYPES.CONTROL.id;
  if (
    isControlAnomaly &&
    getQualityStatus(record) === config.PICTURE_QUALITY.OK.id
  )
    return config.PICTURE_QUALITY.OK.id;
  const anomalyId = id ? id : getRecordValue(record, sourceId);
  if (!anomalyId) {
    return isControlAnomaly ? defaultValue : null;
  }
  const anomalies = getAllAnomalies(state);
  const langLabel = getCurrentLang(state).label;
  return get(anomalies, `${anomalyId}.${langLabel}`);
};
export const getClientActivityName = (id, props) => state => {
  const { sourceId, record } = props;
  if (!id && !sourceId) return null;
  const clientAcitivityId = id
    ? id
    : Array.isArray(sourceId)
    ? get(record, sourceId[0]) || get(record, sourceId[1])
    : get(record, sourceId);
  if (!clientAcitivityId) return null;
  const clientActivities = getAllClientActivities(state);
  const langLabel = getCurrentLang(state).label;
  return get(clientActivities, `${clientAcitivityId}.${langLabel}`);
};

export const getActiveCycle = createSelector(
  [getAllSettings],
  settings => {
    return get(
      Object.values(settings).find(
        setting => setting.name === config.CURRENT_CYCLE
      ),
      "value"
    );
  }
);
export const getRcContext = state => state.mra.rcContext;
export const getCurrentRC = createSelector(
  [getRcContext, getAllReadingCenters],
  (rcContext, rcs) => {
    if (rcContext && rcContext !== config.RC_CONTEXT_ALL) return rcs[rcContext];
    return null;
  }
);
export const getCurrentRCId = createSelector(
  [getCurrentRC],
  rc => (rc ? rc.id : null)
);

export const getDefaultSelectedId = (reference, type) => state => {
  const data =
    adminResource(state)(reference) && getAllRecords(reference)(state);
  if (!data && omit(Object.keys(data), ["fetchedAt"]).length === 0) return null;
  const defaults = Object.values(data).filter(
    d => (!type || d.type === type) && d.is_default
  );
  if (defaults.length < 1) return null;
  return defaults[0].id;
};

const getTotalAnomaliesCountByType = type => (state, itineraryId) => {
  const readings = Object.values(
    getAllRecords(config.READINGTASK_RESOURCE)(state)
  );
  return readings.filter(
    r =>
      r.itinerary_id === itineraryId &&
      r.latest_result &&
      r.latest_result[`${type}_anomaly_id`] &&
      !config.IGNORE_ANOMALY_IDS.includes(r.latest_result[`${type}_anomaly_id`])
  ).length;
};
export const getTotalReadingAnomaliesCount = getTotalAnomaliesCountByType(
  "reading"
);
export const getTotalPictureAnomaliesCount = getTotalAnomaliesCountByType(
  "picture"
);
export const getTotalDistributionAnomaliesCount = getTotalAnomaliesCountByType(
  "distribution"
);
export const getTotalAnomaliesCount = (state, itineraryId) =>
  getTotalReadingAnomaliesCount(state, itineraryId) +
  getTotalPictureAnomaliesCount(state, itineraryId) +
  getTotalDistributionAnomaliesCount(state, itineraryId);

export const getPrefillSelectedDate = state => {
  if (state.mra.prefill_selected_date) {
    return state.mra.prefill_selected_date;
  }
  return null;
};

export const getRoleIdByName = (name, state) => {
  const roles = getAllRoles(state);
  const role = Object.values(roles).find(role => role.name === name);
  return role ? role.id : null;
};
export const getRoleIdsByNames = (names, state) => {
  const roles = [];
  names.forEach(name => {
    roles.push(getRoleIdByName(name, state));
  });
  return roles;
};

export const getRoleNameById = (id, state) => {
  const roles = getAllRoles(state);
  if (!id || !roles[id]) return null;
  return roles[id].name;
};

export const getRoleById = (id, state) => {
  const roles = getAllRoles(state);
  if (!id) return null;
  return Object.values(roles).find(role => role.id === id);
};

export const getRoleByName = (name, state) => {
  const roles = getAllRoles(state);
  if (!name) return null;
  return Object.values(roles).find(role => role.name === name);
};

export const getRoleNamesByIds = (ids, state) => {
  const roles = getAllRoles(state);
  const names = [];
  if (!ids || ids.length < 1) return null;
  ids.forEach(id => {
    if (roles[id]) names.push(roles[id].name);
  });
  return names;
};

export const getRecordFormValues = name => state => {
  if (
    state.form &&
    state.form["record-form"] &&
    state.form["record-form"].values
  ) {
    return state.form["record-form"].values[name];
  }
  return null;
};
export const getSetting = settingName => state => {
  const settings = getAllSettings(state);
  const setting = Object.values(settings).find(
    setting => setting.name === settingName
  );
  if (setting) return setting;
  return null;
};
export const getImageServerUrl = state => {
  const setting = getSetting(config.IMAGE_SERVER_URL)(state);
  return setting && setting.value ? setting.value : null;
};
export const getUploadFiles = (resource, id, source) => state => {
  const upload =
    state.mra.uploadFiles && state.mra.uploadFiles[resource][id][source];
  return upload ? upload : null;
};
export const getAllImages = state =>
  getAllRecords(config.IMAGE_RESOURCE)(state);
export const getImagesById = ids => state => {
  const result = [];
  if (!ids) return result;
  const images = getAllImages(state);
  if (!images) return result;
  ids.forEach(id => {
    result.push(images[id]);
  });
  return compact(result);
};
export const mraImages = state => state.mra.images;
export const getItineraryByID = (type, id) => state =>
  getRecord(type, id)(state);
export const isAssignmentWithMethodChange = id => state => {
  const { assignmentWithMethodChange } = state.mra;
  if (assignmentWithMethodChange && assignmentWithMethodChange.id === id) {
    return (
      assignmentWithMethodChange.old_method !==
      assignmentWithMethodChange.new_method
    );
  }
  return false;
};
export const getRcIdByItineraryId = itineraryId => state => {
  const itinerary = getAllItineraries(state)[itineraryId];
  return itinerary ? itinerary.reading_center_id : null;
};

export function makeFilter(map) {
  return createSelector(
    Object.values(map),
    (...results) =>
      Object.keys(map).reduce((acc, k, index) => {
        acc[k] = results[index];
        return acc;
      }, {})
  );
}
function getRecordData(_, { record, source }) {
  return get(record, source);
}
export function makeImages() {
  return createSelector(
    [getRecordData, getAllImages],
    (images, allImages) =>
      Array.isArray(images)
        ? typeof images[0] === "object"
          ? images
          : images.map(id => allImages[id] || id)
        : images
        ? [images]
        : []
  );
}

export function getFilteredResource(resourceSelector, filters) {
  return createSelector(
    [resourceSelector],
    resource => filter(resource, filters)
  );
}
