import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import get from "lodash/get";
import moment from "moment";

export const DateTimeField = ({ className, source, record = {}, format }) => {
  const value = get(record, source);
  return (
    <Typography component="span" body1="body1" className={className}>
      {value ? moment(value).format(format) : ""}
    </Typography>
  );
};

DateTimeField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  className: PropTypes.string,
  source: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired
};

DateTimeField.defaultProps = {
  format: "DD/MM/YYYY HH:mm"
};
