import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";

import "ol/ol.css";
import { Map as OlMap, View } from "ol";
import Feature from "ol/Feature";
import Tile from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import Point from "ol/geom/Point";
import XYZ from "ol/source/XYZ.js";
import OSM from "ol/source/OSM";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import Text from "ol/style/Text";
import Fill from "ol/style/Fill";
import TileImage from "ol/source/TileImage";
import VectorSource from "ol/source/Vector.js";
import { fromLonLat } from "ol/proj";
import Overlay from "ol/Overlay";

import get from "lodash/get";
import isNil from "lodash/isNil";
import isEqual from "lodash/isEqual";
import isArray from "lodash/isArray";
import { translate } from "react-admin";
import {
  List,
  ListItem,
  ListItemText,
  Card,
  IconButton,
  withStyles
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import config from "../../../config";
import MarkerIcon from "../../../assets/marker-icon.png";
import { adminResource, getSettingValue } from "../../../redux/selectors";

const ArcGisLayer = "ArcGis";
const GoogleLayer = "Google";
const OpenStreetLayer = "OpenStreet";

const MapSources = {
  [ArcGisLayer]: [
    new Tile({
      source: new XYZ({
        attributions:
          "Tiles © <a href='https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer'>ArcGIS</a>",
        url:
          "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
      })
    })
  ],
  [GoogleLayer]: [
    new Tile({
      source: new TileImage({
        maxZoom: 5,
        wrapX: true,
        url: "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga"
      })
    }),
    new Tile({
      title: "Google Road Names",
      source: new TileImage({
        url: "http://mt1.google.com/vt/lyrs=h&x={x}&y={y}&z={z}"
      })
    }),
    new Tile({
      title: "Google Road Map",
      source: new TileImage({
        url: "http://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
      })
    }),
    new Tile({
      title: "Google Road without Building",
      source: new TileImage({
        url: "http://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}"
      })
    })
  ],
  [OpenStreetLayer]: [
    new Tile({
      source: new OSM()
    })
  ]
};

const uuidv4 = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export class Map extends React.Component {
  mapRef = React.createRef();

  state = {
    currentLayer: ArcGisLayer,
    center: [10, 20],
    zoom: 12,
    showPopup: false
  };
  vectorSource = new VectorSource({ features: [] });
  vectorLayer = new VectorLayer({
    source: this.vectorSource
  });

  constructor(props) {
    super(props);
    if (props.center) {
      this.state.center = this.props.center;
    }

    this.mapUuid = uuidv4();
    switch (props.mapSource) {
      case config.MAPS.ARCGIS:
      default:
        this.mapSourceLayers = [
          new Tile({
            source: new XYZ({
              attributions: "Tiles © ArcGIS",
              url: `${props.mapSourceURL}/{z}/{y}/{x}`
            })
          })
        ];
        break;
      case config.MAPS.OSM:
        this.mapSourceLayers = [
          new Tile({
            source: new OSM({
              url: `${props.mapSourceURL}/{z}/{x}/{y}.png`
            })
          })
        ];
        break;
    }
  }

  componentDidMount() {
    const container = document.getElementById(`popup-${this.mapUuid}`);
    const closer = document.getElementById(`popup-closer-${this.mapUuid}`);
    this.updateVectorSource();
    this.overlay = new Overlay({
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });
    this.map = new OlMap({
      target: this.mapRef.current,
      layers: [...this.mapSourceLayers, this.vectorLayer],
      overlays: [this.overlay],
      view: new View({
        center: fromLonLat(this.state.center),
        zoom: this.state.zoom
      })
    });
    this.map.on("click", evt => {
      const features = this.map.getFeaturesAtPixel(evt.pixel);
      if (features !== null && features.length > 0) {
        const item = features[0].get("item");
        this.showPopup(item);
        evt.preventDefault(); // avoid bubbling
      }
    });

    closer.onclick = () => {
      this.closePopup();
      closer.blur();
      this.setState({ item: null });
      return false;
    };
  }

  updateVectorSource() {
    this.vectorSource.clear();
    this.props.items.forEach((item, idx) => {
      const feature = new Feature({
        geometry: new Point(
          fromLonLat([
            get(item, this.props.lngField),
            get(item, this.props.latField)
          ])
        ),
        item
      });
      feature.setStyle(
        new Style({
          image: new Icon({
            // anchor: [0.5, 0.96],
            crossOrigin: "anonymous",
            src: MarkerIcon,
            scale: 0.5
          }),
          text: new Text({
            text: `${idx + 1}`,
            offsetY: -8,
            overflow: true,
            fill: new Fill({ color: "#fff" }),
            backgroundFill: new Fill({ color: "#ea4335" })
          })
        })
      );
      this.vectorSource.addFeature(feature);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentLayer !== this.state.currentLayer) {
      this.map.getLayers().clear();
      MapSources[this.state.currentLayer].forEach(layer => {
        this.map.addLayer(layer);
      });
      this.map.addLayer(this.vectorLayer);
      setTimeout(() => this.map.updateSize(), 100);
    }

    if (!isEqual(prevProps.items.sort(), this.props.items.sort())) {
      this.closePopup();
      this.updateVectorSource();
      if (this.props.items.length > 0) {
        this.map.getView().fit(this.vectorSource.getExtent(), {
          maxZoom: this.state.zoom
        });
      }
      setTimeout(() => {
        this.map.updateSize();
        this.map.render();
      }, 100);
    }
  }

  changeLayouts = evt => {
    this.setState({ currentLayer: evt.target.value });
  };

  closePopup = () => {
    this.overlay.setPosition(undefined);
  };

  showPopup = item => {
    const coords = fromLonLat([
      get(item, this.props.lngField),
      get(item, this.props.latField)
    ]);
    this.setState({ showPopup: true, item });
    this.overlay.setPosition(coords);
  };

  render() {
    const { classes, fieldsToDisplay, translate, items } = this.props;
    const { item } = this.state;
    return (
      <Card>
        {// eslint-disable-next-line
        isNil(items) ||
          (items.length === 0 && (
            <div className={classes.messageContainer}>
              {translate("mra.notifications.no_map_data")}
            </div>
          ))}
        <div ref={this.mapRef} className={classes.mapContainer} />
        {/* <Select
            className={classes.layoutSelector}
            value={this.state.currentLayer}
            onChange={this.changeLayouts}
          >
            {[ArcGisLayer, GoogleLayer, OpenStreetLayer].map(layer => (
              <MenuItem key={layer} value={layer}>
                {layer}
              </MenuItem>
            ))}
          </Select> */}
        <div id={`popup-${this.mapUuid}`} className={classes.popup}>
          <div className={classes.popupHeader}>
            <span className={classes.popupHeaderText}>Supply Point</span>
            <IconButton
              id={`popup-closer-${this.mapUuid}`}
              color="primary"
              className={classes.closeButton}
            >
              <Close />
            </IconButton>
          </div>
          {item && (
            <List>
              {fieldsToDisplay.map(field => (
                <ListItem style={{ padding: 0 }} key={field.key}>
                  <ListItemText
                    primary={translate(field.label)}
                    secondary={
                      isArray(field.key)
                        ? field.key
                            .map(k => get(item, k))
                            .filter(v => !!v)
                            .join(" ")
                        : get(item, field.key)
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
        </div>
      </Card>
    );
  }
}

const styles = theme => ({
  mapContainer: {
    height: "100%",
    width: "100%",
    position: "relative"
  },
  messageContainer: {
    padding: 10,
    textAlign: "center",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.light
  },
  layoutSelector: {
    marginTop: -35,
    backgroundColor: theme.palette.common.white,
    top: 50,
    right: 25,
    float: "right",
    zIndex: 100
  },
  popup: {
    position: "absolute",
    backgroundColor: theme.palette.common.white,
    padding: 15,
    borderRadius: 10,
    border: "1px solid #cccccc",
    bottom: 12,
    left: -50,
    minWidth: 280,
    "&:before": {
      top: "100%",
      border: "solid transparent",
      content: " ",
      height: 0,
      width: 0,
      position: "absolute",
      pointerEvents: "none",
      borderTopColor: "#cccccc",
      borderWidth: 11,
      left: 48,
      marginLeft: -11
    },
    "&:after": {
      top: "100%",
      border: "solid transparent",
      content: " ",
      height: 0,
      width: 0,
      position: "absolute",
      pointerEvents: "none",
      borderTopColor: theme.palette.common.white,
      borderWidth: 10,
      left: 48,
      marginLeft: -10
    }
  },
  popupHeader: {
    padding: "0 24px"
  },
  popupHeaderText: {
    display: "inline-block",
    lineHeight: "48px"
  },
  closeButton: {
    float: "right"
  }
});

Map.propTypes = {
  classes: PropTypes.object,
  center: PropTypes.array,
  latField: PropTypes.string.isRequired,
  lngField: PropTypes.string.isRequired,
  dataResource: PropTypes.string.isRequired,
  items: PropTypes.array,
  fieldsToDisplay: PropTypes.array,
  translate: PropTypes.func.isRequired,
  mapSource: PropTypes.string,
  mapSourceURL: PropTypes.string,
  filteredItems: PropTypes.object,
  filtrationKey: PropTypes.string
};

Map.defaultProps = {
  mapSource: config.MAPS.ARCGIS,
  filtrationKey: "id",
  mapSourceURL:
    "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile"
};

export default compose(
  withStyles(styles),
  connect((state, props) => {
    const resourceState = adminResource(state)(props.dataResource);
    let items = Object.keys(resourceState.data).map(
      key => resourceState.data[key]
    );
    if (
      props.filteredItems != null &&
      Object.keys(props.filteredItems).length > 0
    ) {
      const filtrationKey = props.filtrationKey;
      items = items.filter(
        item => props.filteredItems[item[filtrationKey]] != null
      );
    }
    const mapSource = getSettingValue(config.ACTIVE_MAP_SOURCE)(state);
    return {
      items: items || [],
      isLoading: state.admin.loading > 0,
      mapSource,
      mapSourceURL:
        mapSource === config.MAPS.ARCGIS
          ? getSettingValue(config.MAP_SOURCE_ARCGIS_URL)(state)
          : getSettingValue(config.MAP_SOURCE_OSM_URL)(state)
    };
  }),
  translate
)(Map);
