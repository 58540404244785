import React from "react";
import PropTypes from "prop-types";
import { TextField, List, translate } from "react-admin";
import config from "../../config";
import {
  TextField as MuiTextField,
  Button as FlatButton,
  CardActions
} from "@material-ui/core";
import { connect } from "react-redux";
import { setNewSequence, updateNewSequence } from "../../redux/actions";
import { getNewSequence } from "../../redux/selectors";
import { Link } from "react-router-dom";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import omit from "lodash/omit";
import { Datagrid } from "../base";

const cardActionStyle = {
  zIndex: 2,
  display: "inline-block",
  float: "right"
};
const ResequenceActions = translate(({ itineraryId, history, translate }) => (
  <CardActions style={cardActionStyle}>
    <FlatButton color="primary" onClick={() => history.goBack()}>
      {translate("mra.general.back")}
    </FlatButton>
    <FlatButton
      color="primary"
      component={Link}
      to={`${config.RESEQUENCE_ITINERARY_CONFIRM_PATH.replace(
        ":id",
        itineraryId
      )}`}
      style={{ overflow: "inherit" }}
    >
      {translate("ra.action.save")}
    </FlatButton>
  </CardActions>
));

ResequenceActions.propTypes = {
  translate: PropTypes.func
};

class NewSequenceInput extends React.Component {
  constructor(props) {
    super(props);
    const { set, record, itineraryId, value } = this.props;
    if (
      set &&
      record &&
      itineraryId &&
      record.itinerary_id === itineraryId &&
      typeof value === "undefined"
    ) {
      set(itineraryId, record.id, record.sequence_new);
    }
  }

  render() {
    const { record, value, update, itineraryId } = this.props;
    return (
      <MuiTextField
        value={value ? value : record.sequence_new}
        onChange={event => update(itineraryId, record.id, event.target.value)}
        type="number"
        name={`${itineraryId}_${record.id}_sequence_input`}
      />
    );
  }
}

NewSequenceInput.propTypes = {
  set: PropTypes.func,
  update: PropTypes.func,
  record: PropTypes.object,
  itineraryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

const ConnectedNewSequenceInput = connect(
  (state, props) => ({
    value: getNewSequence(props.itineraryId, props.record.id)(state)
  }),
  {
    set: setNewSequence,
    update: updateNewSequence
  }
)(NewSequenceInput);

/**
 * component for resequencing reading tasks
 */
const ReadingTaskList = ({ translate, ...props }) => (
  <List
    {...props}
    basePath={config.READINGTASK_RESOURCE}
    resource={config.READINGTASK_RESOURCE}
    hasCreate={false}
    hasEdit={false}
    hasList={false}
    hasShow={false}
    perPage={500}
    actions={
      <ResequenceActions
        itineraryId={props.match.params.id}
        history={props.history}
      />
    }
    title={translate("mra.itinerary_overview.resequence")}
    sort={{ field: "sequence_orig", order: "ASC" }}
    filter={{ itinerary_id: props.match.params.id }}
  >
    <Datagrid>
      <TextField
        label={translate(
          `resources.${config.METER_RESOURCE}.fields.meter_identifier`
        )}
        source="meter.meter_identifier"
      />
      <TextField
        label={translate(
          `resources.${config.METER_RESOURCE}.fields.manufacturer`
        )}
        source="meter.manufacturer"
      />
      <TextField
        label={translate(
          `resources.${config.SUPPLYPOINT_RESOURCE}.fields.remote_id`
        )}
        source="supply_point.remote_id"
      />
      <TextField
        label={translate(
          `resources.${config.SUPPLYPOINT_RESOURCE}.fields.premise_identifier`
        )}
        source="supply_point.premise_identifier"
      />
      <TextField source="sequence_orig" />
      <ConnectedNewSequenceInput
        itineraryId={props.match.params.id}
        label={translate(
          `resources.${config.READINGTASK_RESOURCE}.fields.sequence_new`
        )}
      />
    </Datagrid>
  </List>
);

ReadingTaskList.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  translate: PropTypes.func
};

export default compose(
  translate,
  mapProps(props => omit(props, ["dispatch", "staticContext", "authClient"]))
)(ReadingTaskList);
