import React from "react";
import {
  Edit,
  Toolbar,
  TextInput,
  BooleanInput,
  SelectInput,
  SaveButton,
  translate,
  required
} from "react-admin";
import { withStyles } from "@material-ui/core";
import { formValueSelector } from "redux-form";
import PropTypes from "prop-types";
import config from "../../config";
import {
  RestrictedEditActions,
  RestrictedCustomForm,
  RestrictedDeleteButton
} from "../restricted-components";
import { getAnomalyRedirection } from "../utils";

const selector = formValueSelector("record-form");
export const mapStateToProps = state => ({
  display: selector(state, "is_active")
});

const EditTitle = ({ record, translate }) => {
  return (
    <span>
      {translate(`mra.anomaly.${(record.type || "").toLowerCase()}`)}
      {record ? ` - "${record.code}"` : ""}
    </span>
  );
};

const AnomalyEditToolbar = withStyles({
  toolbar: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between"
  }
})(({ classes, ...props }) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton />
    <RestrictedDeleteButton
      redirect={() => getAnomalyRedirection(null, null, props.record)}
      requiredPermissions={[
        config.PERMISSIONS.ANOMALY_UPDATE,
        config.PERMISSIONS.ANOMALY_DELETE,
        config.PERMISSIONS.HQ_MEMBER
      ]}
    />
  </Toolbar>
));

EditTitle.propTypes = {
  translate: PropTypes.func,
  record: PropTypes.object
};

/**
 * component for editing mra devices
 */
export const AnomalyEdit = ({ translate, ...props }) => {
  return (
    <Edit
      title={<EditTitle translate={translate} />}
      actions={
        <RestrictedEditActions
          requiredPermissionsDeleteButton={[
            config.PERMISSIONS.ANOMALY_UPDATE,
            config.PERMISSIONS.ANOMALY_DELETE,
            config.PERMISSIONS.HQ_MEMBER
          ]}
          redirectFunc={getAnomalyRedirection}
        />
      }
      {...props}
    >
      <RestrictedCustomForm
        requiredPermissions={[
          config.PERMISSIONS.ANOMALY_UPDATE,
          config.PERMISSIONS.HQ_MEMBER
        ]}
        toolbar={<AnomalyEditToolbar />}
        redirect={getAnomalyRedirection}
        disabledToolbarTitle={translate("mra.general.readonly_details")}
      >
        <SelectInput
          source="type"
          choices={Object.values(config.ANOMALY_TYPES)}
          validate={required("mra.anomaly.type")}
        />
        <TextInput source="code" validate={required("mra.anomaly.code")} />
        <BooleanInput source="is_default" />
        <BooleanInput source="is_active" />
        <BooleanInput source="is_skip_reading" />
        <TextInput source="label_lang_one" />
        <TextInput source="label_lang_two" />
      </RestrictedCustomForm>
    </Edit>
  );
};

AnomalyEdit.propTypes = {
  translate: PropTypes.func,
  record: PropTypes.object
};

export default translate(AnomalyEdit);
