import React from "react";

import { List, TextField, Filter } from "react-admin";
import { RestrictedEditButton } from "../restricted-components";
import config from "../../config";
import { TranslatedSettingsField, DescriptionField } from "../fields";
import { Datagrid } from "../base";
import PropTypes from "prop-types";

/**
 * filters for list component
 */
const SettingFilter = props => (
  <Filter
    {...props}
    filterValues={props.filterValues ? props.filterValues : {}}
  >
    <TextField source="name" alwaysOn />
  </Filter>
);

SettingFilter.propTypes = {
  filterValues: PropTypes.object
};

/**
 * component for listing mra devices
 *
 * Use <TranslatedSettingsField source="name" />
 * if translations for the settings are added.
 */
const SettingList = props => (
  <List
    {...props}
    filters={<SettingFilter />}
    perPage={config.PAGINATION}
    bulkActionButtons={false}
    exporter={false}
  >
    <Datagrid>
      <TextField source="name" />
      <DescriptionField
        source="description"
        translationKey="settings_short_descriptions"
        sortable={false}
      />
      <TranslatedSettingsField source="value" />
      <RestrictedEditButton
        requiredPermissions={[config.PERMISSIONS.SETTINGS_UPDATE]}
      />
    </Datagrid>
  </List>
);

export default SettingList;
