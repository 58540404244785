import { connect } from "react-redux";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import omit from "lodash/omit";

export const withSelector = Component =>
  compose(
    connect((state, props) => ({
      record: props.selector(state)
    })),
    mapProps(props => omit(props, ["dispatch", "selector"]))
  )(Component);

export const sanitizeRestProps = props =>
  omit(props, [
    "addLabel",
    "allowEmpty",
    "basePath",
    "cellClassName",
    "className",
    "formClassName",
    "headerClassName",
    "label",
    "linkType",
    "locale",
    "record",
    "resource",
    "sortable",
    "sortBy",
    "source",
    "textAlign",
    "translateChoice"
  ]);

const injectedProps = [
  "basePath",
  "currentSort",
  "data",
  "defaultTitle",
  "displayedFilters",
  "filterValues",
  "hasCreate",
  "hideFilter",
  "ids",
  "isLoading",
  "loadedOnce",
  "onSelect",
  "onToggleItem",
  "onUnselectItems",
  "page",
  "perPage",
  "refresh",
  "resource",
  "selectedIds",
  "setFilters",
  "setPage",
  "setPerPage",
  "setSort",
  "showFilter",
  "total",
  "translate",
  "version"
];

export const sanitizeListRestProps = props =>
  Object.keys(props)
    .filter(propName => !injectedProps.includes(propName))
    .reduce((acc, key) => ({ ...acc, [key]: props[key] }), {});
