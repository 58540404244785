/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import P from "prop-types";
import cn from "classnames";
import Autosuggest from "react-autosuggest";
import compose from "recompose/compose";
import { translate } from "react-admin";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles, createStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import {
  getReadingValueMRA,
  getAllRecords,
  getDefaultSelectedId,
  getFilteredResource
} from "../../redux/selectors";
import config from "../../config";
import { setReadingValue } from "../../redux/actions";
import cloneDeep from "lodash/cloneDeep";

const styles = theme =>
  createStyles({
    container: {
      flexGrow: 1,
      position: "relative"
    },
    root: {},
    suggestionsContainerOpen: {
      position: "absolute",
      marginBottom: theme.spacing.unit * 3,
      zIndex: 2
    },
    suggestionsPaper: {
      maxHeight: "50vh",
      overflowY: "auto"
    },
    suggestion: {
      display: "block",
      fontFamily: theme.typography.fontFamily
    },
    suggestionText: { fontWeight: 300 },
    highlightedSuggestionText: { fontWeight: 500 },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: "none"
    }
  });

function getSelectedItem({ options }, inputValue) {
  return options && inputValue
    ? options.find(option => getSuggestionValue(option) === inputValue)
    : null;
}

function getSuggestionValue(suggestion) {
  return `${suggestion.code} - ${suggestion.label_lang_one}`;
}

function getSuggestionText(suggestion) {
  if (!suggestion) return "";
  return getSuggestionValue(suggestion);
}

function AutocompleteInput(props) {
  const {
    id,
    classes,
    value,
    options,
    valueFromResource,
    defaultValue,
    defaultClientActivityId,
    set,
    record,
    source
  } = props;
  const anchorEl = useRef();
  const inputEl = useRef();
  const [suggestions, setSuggestions] = useState([]);

  const [selectedItem, setSelectedItem] = useState();
  const [search, setSearch] = useState(getSuggestionText(selectedItem));

  useEffect(() => {
    if (!value) {
      if (valueFromResource) {
        set(record.id, source, valueFromResource);
        const selected = options.find(o => o.id === valueFromResource);
        setSelectedItem(selected);
        setSearch(getSuggestionText(selected));
      } else if (defaultValue) {
        set(record.id, source, defaultValue);
        const selected = options.find(o => o.id === defaultValue);
        setSelectedItem(selected);
        setSearch(getSuggestionText(selected));
      }
    } else {
      const selected = options.find(o => o.id === value);
      setSelectedItem(selected);
      setSearch(getSuggestionText(selected));
    }
  }, [options, defaultValue, record.id, set, source, value, valueFromResource]);

  function onSuggestionsFetchRequested() {
    setSuggestions(
      options.filter(o => `${o.code} - ${o.label_lang_one}`.includes(search))
    );
  }

  function onSuggestionsClearRequested() {
    setSuggestions([]);
  }

  function onSuggestionSelected(event, { suggestion, method }) {
    set(record.id, source, suggestion.id);
    setSelectedItem(suggestion);
    if (method === "enter") {
      event.preventDefault();
    }
  }

  function renderInputComponent(inputProps) {
    const {
      autoFocus,
      className,
      classes = {},
      isRequired,
      label,
      meta = {},
      onChange,
      resource,
      source,
      value,
      ref,
      ...other
    } = inputProps;

    const { touched, error } = meta;

    const storeInputRef = input => {
      inputEl.current = input;
      updateAnchorEl();
      ref(input);
    };

    return (
      <TextField
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        margin="normal"
        className={cn(classes.root, className)}
        inputRef={storeInputRef}
        error={!!(touched && error)}
        InputProps={{
          classes: {
            input: classes.input
          },
          ...other
        }}
      />
    );
  }

  function updateAnchorEl() {
    if (!inputEl.current) {
      return;
    }
    const inputPosition = inputEl.current.getBoundingClientRect();
    if (!anchorEl.current) {
      anchorEl.current = { getBoundingClientRect: () => inputPosition };
    } else {
      const anchorPosition = anchorEl.current.getBoundingClientRect();

      if (
        anchorPosition.x !== inputPosition.x ||
        anchorPosition.y !== inputPosition.y
      ) {
        anchorEl.current = { getBoundingClientRect: () => inputPosition };
      }
    }
  }

  function renderSuggestionsContainer(autosuggestOptions) {
    const {
      containerProps: { className, ...containerProps },
      children
    } = autosuggestOptions;

    updateAnchorEl();

    return (
      <Popper
        className={className}
        open={Boolean(children)}
        anchorEl={anchorEl.current}
        placement="bottom-start"
      >
        <Paper square className={classes.suggestionsPaper} {...containerProps}>
          {children}
        </Paper>
      </Popper>
    );
  }

  function renderSuggestion(suggestion, { query, isHighlighted }) {
    const label = getSuggestionValue(suggestion);
    const matches = match(label, query);
    const parts = parse(label, matches);
    return (
      <MenuItem
        selected={isHighlighted}
        component={({ suggestion, query, isHighlighted, ...props }) => (
          <div {...props} />
        )}
        suggestion={suggestion}
        query={query}
        isHighlighted={isHighlighted}
      >
        <div>
          {parts.map((part, index) => (
            <strong key={index} className={classes.suggestionText}>
              {part.text}
            </strong>
          ))}
        </div>
      </MenuItem>
    );
  }

  return (
    <Autosuggest
      id={`${source}-${record.id}`}
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion
      }}
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={onSuggestionSelected}
      renderInputComponent={renderInputComponent}
      renderSuggestionsContainer={renderSuggestionsContainer}
      inputProps={{
        value: search,
        onBlur() {
          setSearch(getSuggestionText(selectedItem));
        },
        onChange(e, { newValue, method }) {
          if (["type", "click", "escape"].includes(method)) {
            setSearch(newValue);
          }
        },
        options: {}
      }}
    />
  );
}

AutocompleteInput.propTypes = {
  id: P.string,
  value: P.number,
  options: P.array,
  valueFromResource: P.number,
  defaultValue: P.string,
  defaultClientActivityId: P.string,
  set: P.func,
  record: P.object,
  source: P.string,
  classes: P.object
};

export default compose(
  translate,
  withStyles(styles),
  connect(
    (state, { record, source, reference, defaultValues, filterData }) => {
      return {
        value: getReadingValueMRA(record.id, source)(state),
        defaultValue: defaultValues && defaultValues[source],
        options: getFilteredResource(getAllRecords(reference), filterData)(
          state
        ),
        valueFromResource:
          record.latest_result && `${record.latest_result[source]}`,
        defaultClientActivityId: getDefaultSelectedId(
          config.CLIENT_ACTIVITY_RESOURCE,
          null
        )(state)
      };
    },
    {
      set: setReadingValue
    }
  )
)(AutocompleteInput);
