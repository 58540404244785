import React, { useEffect } from "react";
import P from "prop-types";
import {
  List,
  TextField,
  Filter,
  TextInput,
  ReferenceField,
  NumberField,
  SelectInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";
import config from "../../config";
import { Link } from "react-router-dom";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import omit from "lodash/omit";
import PrintButton from "../reading-itineraries/PrintButton";
import { Datagrid } from "../base";
import { getFilter } from "../layout";
import { TypeTranslationField, DateTimeField, BooleanField } from "../fields";
import { RestrictedFlatButton } from "../restricted-components";
import { getRcContext, getActiveCycle } from "../../redux/selectors";
import { connect } from "react-redux";
import Assign from "@material-ui/icons/Assignment";
import { itineraryIsComplete, itineraryIsExported } from "../utils";

/**
 * nobody has permissions to edit on global rc context MRA-367
 * result: buttons are not available if current rccontext is global
 * hide buttons if complete or exported
 */
const ConnectedRestrictedFlatButton = connect((state, props) => {
  const { data } = props;
  const permission =
    getRcContext(state) === config.RC_CONTEXT_ALL ||
    itineraryIsComplete(data) ||
    itineraryIsExported(data)
      ? config.NOPERMISSION
      : props.requiredPermissions;
  return {
    requiredPermissions: permission
  };
})(RestrictedFlatButton);

const RestrictedAssignButton = ({ resource, record, assignPath }) => (
  <ConnectedRestrictedFlatButton
    color="primary"
    component={Link}
    to={`${resource}/${record.id}/${assignPath}`}
    style={{ minWidth: 0, overflow: "inherit" }}
    requiredPermissions={[config.PERMISSIONS.ITINERARY_DATA_ASSIGNMENT]}
  >
    <Assign />
  </ConnectedRestrictedFlatButton>
);

RestrictedAssignButton.propTypes = {
  resource: P.string,
  assignPath: P.string,
  record: P.shape({
    id: P.number
  })
};

/**
 * filters for list component
 */
const ItineraryFilter = ({ types, ...props }) => {
  const { context, setFilters } = props;
  useEffect(() => {
    if (context === "form") {
      setFilters({});
    }
  }, [context, setFilters]);
  return (
    <Filter {...props} perPage={config.PAGINATION}>
      <TextInput source="remote_id" />
      <BooleanInput source="is_printed" parse={v => (v ? 1 : 0)} />
      <ReferenceInput
        source="center_id"
        reference={config.READINGCENTER_RESOURCE}
        filterToQuery={searchText => ({ remote_id: searchText })}
        allowEmpty
      >
        <AutocompleteInput
          options={{ name: "reading center" }}
          optionValue="id"
          optionText="remote_id"
        />
      </ReferenceInput>
      <ReferenceInput
        source="agent_id"
        reference={config.USER_RESOURCE}
        filterToQuery={searchText => ({ username: searchText })}
        allowEmpty
      >
        <AutocompleteInput
          options={{ name: "reader" }}
          optionValue="id"
          optionText="username"
        />
      </ReferenceInput>
      <SelectInput source="status" choices={Object.values(types)} />
      <BooleanInput source="is_confirmation_required" />
    </Filter>
  );
};

ItineraryFilter.propTypes = {
  types: P.object,
  setFilters: P.func,
  context: P.oneOf(["form", "button"])
};

/**
 * component for listing itineraries
 */
export const AssignList = ({ types, assignPath, ...props }) => (
  <List
    {...props}
    hasCreate={false}
    hasEdit={false}
    hasShow={false}
    hasList={false}
    exporter={false}
    basePath={props.resource}
    filters={<ItineraryFilter types={types} />}
    filter={
      props.active_cycle
        ? { ...props.filter, cycle: props.active_cycle }
        : props.filter
    }
    sort={{ field: "scheduled_at", order: "DESC" }}
  >
    <Datagrid>
      <TextField source="route" />
      <TextField source="remote_id" />
      <TextField source="cycle" />
      <TextField source="description" />
      <TypeTranslationField source="status" types={types} />
      <DateTimeField source="scheduled_at" />
      <NumberField source="task_count" />
      <ReferenceField
        source="agent_id"
        reference={config.USER_RESOURCE}
        linkType={false}
        allowEmpty
      >
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField
        source="center_id"
        reference={config.READINGCENTER_RESOURCE}
        linkType={false}
        allowEmpty
      >
        <TextField source="remote_id" />
      </ReferenceField>
      <BooleanField source="is_confirmation_required" />
      <RestrictedAssignButton assignPath={assignPath} />
      <PrintButton />
    </Datagrid>
  </List>
);

AssignList.propTypes = {
  types: P.object,
  assignPath: P.string,
  filter: P.object,
  active_cycle: P.string,
  resource: P.string
};

export default compose(
  connect(state => ({
    active_cycle: getActiveCycle(state),
    filter: getFilter(state)
  })),
  mapProps(props => omit(props, ["dispatch", "authClient", "staticContext"]))
)(AssignList);
