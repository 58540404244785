import React, { useEffect, useState } from "react";
import P from "prop-types";
import { translate } from "react-admin";
import { connect } from "react-redux";
import moment from "moment";
import {
  GridList,
  GridListTile,
  GridListTileBar,
  Chip,
  Button,
  CircularProgress,
  withStyles
} from "@material-ui/core";
import compose from "recompose/compose";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import noop from "lodash/noop";
import { mraImages, makeImages } from "../../redux/selectors";
import { getImages, openLightBox, downloadImage } from "../../redux/actions";
import { isPaperReading } from "../utils";

const styles = theme => ({
  countSpan: {
    margin: 4,
    float: "left"
  },
  link: {
    margin: 4,
    float: "left",
    cursor: "pointer"
  },
  chip: {
    margin: 4,
    float: "left",
    cursor: "pointer"
  },
  gridRoot: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    overflow: "hidden"
  },
  gridList: {
    width: "100%",
    height: "auto",
    overflowY: "auto",
    overflowX: "auto",
    //flexWrap: "nowrap",
    display: "flex"
  },
  gridTileRoot: {
    display: "flex",
    width: "400px !important",
    height: "400px !important"
  },
  gridTile: {
    cursor: "pointer",
    display: "flex",
    flex: 1
  },
  gridListContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  gridImg: {
    height: "100%",
    width: "100%"
  },
  cardField: {
    margin: 0,
    fontSize: theme.typography.fontSize,
    color: theme.palette.text.primary
  }
});

export function Images(props) {
  const {
    translate,
    type,
    images: baseImages = [],
    label,
    slider,
    openLightBox,
    mraImages,
    cellHeight,
    classes,
    resource,
    loadImages,
    downloadImage,
    preload,
    record
  } = props;
  const images = baseImages.filter(image =>
    isObject(image) ? !!image.id : image
  );
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    if (isEmpty(images) || isObject(images[0]) || isLoaded) return;
    // preload images if they are there as Array of ids instead as Array of objs
    loadImages(images);
    setLoaded(true);
  }, [images, loadImages, isLoaded]);

  useEffect(() => {
    if (
      isEmpty(images) ||
      !(
        (type === "grid" || preload || !slider) &&
        isObject(images[0]) &&
        images.some(image => mraImages[image.id] == null)
      )
    )
      return;

    images.forEach(image => {
      if (image.id && !mraImages[image.id]) {
        downloadImage(image);
      }
    });
  }, [images, type, preload, slider, mraImages, downloadImage]);

  let counter = 0;
  const count = images ? images.length : 0;
  const { method } = record;
  const noItemsLabel = isPaperReading(resource, method)
    ? "mra.general.not_available"
    : "mra.general.noImages";
  if (count === 0) {
    return (
      <p className={type === "count" ? classes.cardField : ""}>
        {translate(noItemsLabel)}
      </p>
    );
  }

  if (type === "count") {
    return <span className={classes.countSpan}>{count}</span>;
  }

  if (type === "links") {
    return images.map(image => {
      const url = get(mraImages, `${image.id}.url`);
      return (
        // eslint-disable-next-line
        <a
          key={image.id}
          href={slider ? null : url}
          target={slider ? null : "_blank"}
          rel="noopener noreferrer"
          className={classes.link}
          onClick={
            slider
              ? () => openLightBox({ index: counter, images: [url] })
              : noop
          }
        >
          {label && image[label] ? image[label] : (counter += 1 || counter)}
        </a>
      );
    });
  }

  if (type === "chips") {
    return images.map(image => {
      const url = get(mraImages, `${image.id}.url`);
      return (
        <Chip key={image.id} className={classes.chip}>
          {/* eslint-disable-next-line */}
          <a
            onClick={
              slider
                ? () => openLightBox({ index: counter, images: [url] })
                : noop
            }
            href={slider ? null : url}
            target={slider ? null : "_blank"}
            rel="noopener noreferrer"
          >
            {label && image[label] ? image[label] : (counter += 1 || counter)}
          </a>
        </Chip>
      );
    });
  }

  if (type === "button") {
    return (
      <Button
        onClick={() => {
          openLightBox({
            index: 0,
            images: images
          });
        }}
      >
        {`${count}`}
      </Button>
    );
  }

  if (type === "grid") {
    return (
      <div id="GridListRoot" className={classes.gridRoot}>
        <GridList cellHeight={cellHeight} className={classes.gridList}>
          {images.map(tile => {
            const image = get(mraImages, `${tile.id}`, { isLoading: true });
            return (
              <GridListTile
                key={tile.id}
                classes={{
                  root: classes.gridTileRoot,
                  tile: classes.gridTile
                }}
              >
                {/* eslint-disable-next-line */}
                <a
                  onClick={
                    slider
                      ? () =>
                          openLightBox({
                            index: counter,
                            images: [image.url]
                          })
                      : noop
                  }
                  className={classes.gridListContainer}
                  href={slider ? null : image.url}
                  target={slider ? null : "_blank"}
                  rel="noopener noreferrer"
                >
                  {image.isLoading ? (
                    <CircularProgress />
                  ) : (
                    <img
                      className={classes.gridImg}
                      src={image.url}
                      alt={tile.filename}
                    />
                  )}
                </a>
                <GridListTileBar
                  title={label && tile[label] ? tile[label] : tile.filename}
                  subtitle={
                    <span>
                      <b>{moment(tile.created_at).format("LLL")}</b>
                    </span>
                  }
                />
              </GridListTile>
            );
          })}
        </GridList>
      </div>
    );
  }

  return null;
}

Images.propTypes = {
  translate: P.func.isRequired,
  resource: P.string,
  source: P.string,
  type: P.string,
  preload: P.bool,
  slider: P.bool,
  captionObject: P.object,
  titleObject: P.object,
  additionalProps: P.object,
  record: P.object,
  loadImages: P.func,
  mraImages: P.object,
  downloadImage: P.func,
  openLightBox: P.func,
  images: P.array,
  cellHeight: P.number,
  label: P.string,
  classes: P.object
};

Images.defaultProps = {
  source: "images",
  type: "chips",
  preload: false,
  cellHeight: 200,
  slider: true,
  titleObject: { source: "filename", default: "Loading..." },
  captionObject: null,
  additionalProps: {}
};

const makeMapStateToProps = () => {
  const images = makeImages();
  return (state, props) => ({
    images: images(state, props),
    mraImages: mraImages(state)
  });
};

const ConnectedImages = compose(
  connect(
    makeMapStateToProps,
    {
      loadImages: getImages,
      downloadImage,
      openLightBox
    }
  ),
  translate,
  withStyles(styles)
)(Images);

export default ConnectedImages;
