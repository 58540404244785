/* eslint-env browser, es6 */
import React from "react";
import P from "prop-types";
import { connect } from "react-redux";
import { TextField, TextInput, SelectInput } from "react-admin";
import { List, Filter } from ".";
import ReconGrid from "./ReconGrid";
import { DateTimeField, OkNotokField } from "../fields";
import { DateRangeInput } from "../inputs";
import {
  getActiveCycle,
  getRCContextExtId,
  makeFilter
} from "../../redux/selectors";

const statusChoices = [{ id: true, name: "OK" }, { id: false, name: "NOT OK" }];

function ReconFilters(props) {
  return (
    <Filter {...props}>
      <TextInput source="cycle" />
      <TextInput source="year" />
      <TextInput source="itinerary_ext_id" />
      <DateRangeInput source="mra_processed" />
      <DateRangeInput source="uma_processed" />
      <SelectInput source="is_match" choices={statusChoices} />
      <SelectInput source="is_tasks_match" choices={statusChoices} />
    </Filter>
  );
}

ReconFilters.propTypes = {
  id: P.number,
  resource: P.string
};

// eslint-disable-next-line no-unused-vars
function ReconReport({ dispatch, ...props }) {
  return (
    <div style={{ margin: 10, flex: 1 }}>
      <List
        {...props}
        basePath={`/${props.resource}`}
        filters={<ReconFilters />}
      >
        <ReconGrid>
          <TextField source="year" />
          <TextField source="cycle" />
          <TextField source="reading_center_ext_id" />
          <TextField source="itinerary_ext_id" />
          <TextField source="task_count" />
          <DateTimeField source="mrc_processed_at" format={"DD/MM/YYYY"} />
          <TextField source="imported_task_count" />
          <DateTimeField source="uma_processed_at" format={"DD/MM/YYYY"} />
          <OkNotokField source="is_match" />
          <OkNotokField source="is_tasks_match" />
        </ReconGrid>
      </List>
    </div>
  );
}

ReconReport.propTypes = {
  dispatch: P.func,
  resource: P.string,
  filter: P.object,
  defaultFilter: P.object
};

const makeMapStateToProps = () => {
  const filterSelector = makeFilter({
    reading_center_ext_id: getRCContextExtId
  });
  const defaultFilterSelector = makeFilter({
    cycle: getActiveCycle,
    year: () => new Date().getFullYear()
  });
  return state => ({
    filter: filterSelector(state),
    defaultFilter: defaultFilterSelector(state)
  });
};

const ConnectedReconReport = connect(makeMapStateToProps)(ReconReport);

export { ConnectedReconReport as ReconReport };
