import React, { cloneElement } from "react";
import P from "prop-types";
import {
  translate,
  CardActions,
  CreateButton,
  ExportButton
} from "react-admin";
import { Button } from "@material-ui/core";
import config from "../../../config";

const { GRID_VIEW } = config;

export const Actions = ({
  bulkActions,
  currentSort,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  total
}) => {
  return (
    <CardActions>
      {bulkActions &&
        cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems
        })}
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button"
        })}
      {hasCreate && <CreateButton basePath={basePath} />}
      {exporter !== false && (
        <ExportButton
          maxResults={500}
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={filterValues}
          exporter={exporter}
        />
      )}
    </CardActions>
  );
};

Actions.propTypes = {
  bulkActions: P.element,
  currentSort: P.shape({
    field: P.string,
    order: P.string
  }),
  className: P.string,
  resource: P.string,
  filters: P.element,
  displayedFilters: P.object,
  exporter: P.oneOfType([P.func, P.bool]),
  filterValues: P.object,
  hasCreate: P.bool,
  basePath: P.string,
  selectedIds: P.array,
  onUnselectItems: P.func,
  showFilter: P.func,
  total: P.number
};

export const SwitchLayoutButton = translate(
  ({ translate: t, viewMode, toggleView }) => (
    <Button color="primary" onClick={toggleView}>
      {t(
        `mra.constants.view_modes.${viewMode === GRID_VIEW ? "list" : "grid"}`
      )}
    </Button>
  )
);

SwitchLayoutButton.propTypes = {
  viewMode: P.string,
  onToggleView: P.func
};
