export const styles = {
  override: {
    display: "flex",
    marginRight: 0
  },
  fieldGroup: {
    width: "100%",
    height: "auto !important;",
    float: "none !important;"
  }
};
