import React from "react";
import PropTypes from "prop-types";
import {
  Show,
  ReferenceField,
  TextField,
  ArrayField,
  SingleFieldList,
  SimpleShowLayout,
  ChipField,
  translate
} from "react-admin";
import { withStyles } from "@material-ui/core";
import compose from "recompose/compose";
import config from "../../config";
import { commonStyles, Actions } from "../base";
import FieldsGroup from "../base/show-group";

const ShowTitle = props => {
  return (
    <span>
      {props.translate(`resources.${config.DEVICE_RESOURCE}.name`, 1)} -{" "}
      {props.record ? `"${props.record.device_identifier}"` : ""}
    </span>
  );
};

ShowTitle.propTypes = {
  translate: PropTypes.func,
  record: PropTypes.object
};

/**
 * component for displaying device details
 */
const DeviceDetails = ({ classes, ...props }) => (
  <Show
    {...props}
    actions={<Actions />}
    title={<ShowTitle translate={props.translate} />}
  >
    <SimpleShowLayout>
      <FieldsGroup
        title={`mra.devices_overview.details`}
        className={classes.fieldGroup}
      >
        <TextField source="device_identifier" />
        <TextField source="device_serial" />
        <TextField source="model" />
        <TextField source="manufacturer" />
        <TextField source="app_version" />
        <TextField source="android_version" />
        <ReferenceField
          source="center_id"
          reference={config.READINGCENTER_RESOURCE}
          allowEmpty
          linkType={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ArrayField source="users">
          <SingleFieldList linkType={false}>
            <ChipField source="username" />
          </SingleFieldList>
        </ArrayField>
        <TextField source="onb_status" />
      </FieldsGroup>
      <FieldsGroup
        title={`mra.devices_overview.latest_info`}
        className={classes.fieldGroup}
      >
        <TextField source="last_seen_at" />
        <ReferenceField
          addLabel
          source="reading_center_id"
          reference={config.READINGCENTER_RESOURCE}
          linkType={false}
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          addLabel
          source="last_used_by_id"
          reference={config.USER_RESOURCE}
          linkType={false}
          allowEmpty
        >
          <TextField source="username" />
        </ReferenceField>
      </FieldsGroup>
    </SimpleShowLayout>
  </Show>
);

DeviceDetails.propTypes = {
  translate: PropTypes.func,
  classes: PropTypes.object
};

export default compose(
  withStyles(commonStyles),
  translate
)(DeviceDetails);
