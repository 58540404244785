import React, { Component } from "react";
import P from "prop-types";
import {
  Toolbar,
  Card,
  CardContent as CardText,
  CardActions,
  Button as RaisedButton
} from "@material-ui/core";
import ActionCheck from "@material-ui/icons/CheckCircle";
import AlertError from "@material-ui/icons/ErrorOutline";
import { Title, translate } from "react-admin";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { getValueFromRecord } from "../../redux/selectors";

const ToolbarGroup = props => <div {...props} />;

const styles = {
  actions: { zIndex: 2, display: "inline-block", float: "right" },
  toolbar: { clear: "both" },
  button: { margin: "10px 24px", position: "relative" }
};

class ConfirmDialog extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    const { handleSubmit, record, match } = this.props;
    event.preventDefault();
    const submitValue =
      typeof this.props.submitValue === "undefined"
        ? record
        : this.props.submitValue;
    handleSubmit(match.params.id, submitValue);
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const {
      confirmButtonLabel = "mra.confirm.ok",
      title,
      itemCode,
      translate
    } = this.props;
    return (
      <div>
        <Card>
          <CardActions style={styles.actions} />
          <Title title={translate(title, { id: itemCode })} />
          <form onSubmit={this.handleSubmit}>
            <CardText>{translate("ra.message.are_you_sure")}</CardText>
            <Toolbar style={styles.toolbar}>
              <ToolbarGroup>
                <RaisedButton
                  type="submit"
                  color="primary"
                  variant="raised"
                  style={styles.button}
                >
                  <ActionCheck />
                  {translate(confirmButtonLabel)}
                </RaisedButton>
                <RaisedButton
                  variant="raised"
                  onClick={this.goBack}
                  style={styles.button}
                >
                  <AlertError />
                  {translate("ra.action.cancel")}
                </RaisedButton>
              </ToolbarGroup>
            </Toolbar>
          </form>
        </Card>
      </div>
    );
  }
}

ConfirmDialog.propTypes = {
  title: P.string,
  history: P.object.isRequired,
  handleSubmit: P.func,
  match: P.object.isRequired,
  confirmButtonLabel: P.string,
  record: P.object,
  translate: P.func,
  submitValue: P.string,
  itemCode: P.string,
  resource: P.string
};

const ConnectedConfirmDialog = compose(
  connect((state, props) => {
    const {
      resource,
      source = "remote_id",
      match: {
        params: { id }
      }
    } = props;
    const itemCode = getValueFromRecord(resource, id, source)(state);
    return {
      itemCode: itemCode ? itemCode : ""
    };
  }),
  translate
)(ConfirmDialog);

export default ConnectedConfirmDialog;
