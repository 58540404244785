import React from "react";
import PropTypes from "prop-types";
import { Button, translate } from "react-admin";

const SimpleButton = ({
  onClick,
  size,
  alignIcon,
  label,
  variant,
  className,
  classes,
  style,
  disabled,
  type,
  color,
  children,
  translate
}) => (
  <Button
    alignIcon={alignIcon}
    className={className}
    classes={classes}
    translate={translate}
    variant={variant}
    disabled={disabled}
    type={type}
    style={style}
    onClick={onClick}
    color={color}
    label={label}
    size={size}
  >
    {children}
  </Button>
);

SimpleButton.propTypes = {
  alignIcon: PropTypes.string,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  translate: PropTypes.func
};

export default translate(SimpleButton);
