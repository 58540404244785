import React from "react";
import PropTypes from "prop-types";
import {
  List,
  TextField,
  Filter,
  TextInput,
  SingleFieldList,
  BooleanField,
  ChipField,
  ReferenceField,
  ReferenceInput,
  ReferenceArrayField,
  AutocompleteInput
} from "react-admin";
import { getCurrentUserRUContext, getRcContext } from "../../redux/selectors";
import {
  RestrictedFlatButton,
  RestrictedListActions
} from "../restricted-components";
import { getFilter, ToolTipChipField } from "../layout";
import config from "../../config";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import EditIcon from "@material-ui/icons/Create";
import LockOpen from "@material-ui/icons/LockOpen";
import { Datagrid } from "../base";
import { makeUserFilter } from "../utils";
/**
 * filters for list component
 */
// eslint-disable-next-line no-unused-vars
const UserFilter = ({ dispatch, ruContext, showRcFilter, ...props }) => (
  <Filter {...props}>
    <TextInput source="username" />
    {!ruContext ? (
      <ReferenceInput
        source="unit_id"
        reference={config.READINGUNIT_RESOURCE}
        filterToQuery={searchText => ({ name: searchText })}
        allowEmpty
      >
        <AutocompleteInput
          options={{ name: "reading unit" }}
          optionValue="id"
          optionText="name"
        />
      </ReferenceInput>
    ) : null}
    {showRcFilter ? (
      <ReferenceInput
        source="@center_id"
        reference={config.READINGCENTER_RESOURCE}
        filterToQuery={searchText => ({ remote_id: searchText })}
        allowEmpty
      >
        <AutocompleteInput
          options={{ name: "reading center" }}
          optionValue="id"
          optionText="remote_id"
        />
      </ReferenceInput>
    ) : null}
  </Filter>
);
UserFilter.propTypes = {
  dispatch: PropTypes.func,
  ruContext: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showRcFilter: PropTypes.bool
};

const ConnectedUserFilter = connect(
  state => ({
    ruContext: getCurrentUserRUContext(state),
    showRcFilter: config.RC_CONTEXT_ALL === getRcContext(state)
  }),
  null
)(UserFilter);

const FullNameField = ({ record = {} }) => (
  <span>
    {record.first_name}, {record.middle_name} {record.last_name}
  </span>
);

FullNameField.defaultProps = { label: "Full Name" };

FullNameField.propTypes = {
  record: PropTypes.object
};

//label={context.translate("aor.action.activate")}
//label={context.translate("aor.action.edit")}
const CustomButton = props => (
  <span>
    {props.record.deleted_at ? (
      <RestrictedFlatButton
        color="primary"
        component={Link}
        to={`/${config.USER_RESOURCE}/${props.record.id}/activate`}
        style={{ minWidth: 0, overflow: "inherit" }}
        requiredPermissions={[config.PERMISSIONS.USER_UPDATE]}
      >
        <LockOpen />
      </RestrictedFlatButton>
    ) : (
      <RestrictedFlatButton
        color="primary"
        component={Link}
        to={`/${config.USER_RESOURCE}/${props.record.id}`}
        style={{ minWidth: 0, overflow: "inherit" }}
        requiredPermissions={[config.PERMISSIONS.USER_UPDATE]}
      >
        <EditIcon />
      </RestrictedFlatButton>
    )}
  </span>
);
CustomButton.propTypes = {
  record: PropTypes.object
};

const HideableChipField = props => {
  return props.record != null ? <ChipField {...props} /> : null;
};
HideableChipField.propTypes = {
  record: PropTypes.object
};
/**
 * component for listing users
 */
// eslint-disable-next-line no-unused-vars

export const UserList = ({ dispatch, ...props }) => (
  <List
    {...props}
    actions={
      <RestrictedListActions
        requiredPermissionsCreateButton={[config.PERMISSIONS.USER_CREATE]}
      />
    }
    filters={<ConnectedUserFilter filter={props.filter} />}
    sort={{ field: "last_name", order: "ASC" }}
    perPage={config.PAGINATION}
    bulkActions={false}
  >
    <Datagrid>
      <FullNameField source="last_name" />
      <TextField source="username" />
      <TextField source="phone" />
      <TextField source="email" />
      <ReferenceArrayField
        reference={config.ROLE_RESOURCE}
        target="id"
        source="roles"
        sortable={false}
      >
        <SingleFieldList linkType={false}>
          <ChipField source="label" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField
        reference={config.READINGCENTER_RESOURCE}
        target="id"
        source="centers"
        sortable={false}
      >
        <SingleFieldList>
          <ToolTipChipField
            toolTipEval
            toolTipText="#{remote_id} - #{name}"
            source="remote_id"
          />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField
        allowEmpty
        reference={config.READINGUNIT_RESOURCE}
        target="id"
        source="unit_id"
        sortable={false}
      >
        <HideableChipField source="name" />
      </ReferenceField>
      <TextField source="user_reference" />
      <BooleanField source="is_blocked" />
      <CustomButton />
    </Datagrid>
  </List>
);

UserList.propTypes = {
  filter: PropTypes.object,
  dispatch: PropTypes.func
};
const ConnectedUserList = connect(state => ({
  filter: makeUserFilter(getFilter(state))
}))(UserList);

export default ConnectedUserList;
