import { GET_ONE, GET_MANY, UPDATE } from "react-admin";
import isArray from "lodash/isArray";
import omit from "lodash/omit";
import mapValues from "lodash/mapValues";
import has from "lodash/has";
import isNil from "lodash/isNil";
import pick from "lodash/pick";
import cloneDeep from "lodash/cloneDeep";
import config from "../config";

const {
  RELATIONS,
  USER_RESOURCE,
  DEVICE_RESOURCE,
  SETTINGS_RESOURCE,
  DEVICES_SETTINGS_RESOURCE,
  ITINERARY,
  TASK,
  ITINERARY_RESOURCE,
  DISTRIBUTION_ITINERARY_RESOURCE,
  CONTROL_READING_ITINERARY_RESOURCE,
  ANOMALY_CORRECTION_ITINERARY_RESOURCE,
  READING_TYPE,
  DISTRIBUTION_TYPE,
  CONTROL_ITINERARY_TYPE,
  ANOMALY_CORRECTION_TYPE,
  READINGTASK_RESOURCE,
  DISTRIBUTION_TASK_RESOURCE,
  CONTROL_READING_TASK_RESOURCE,
  ANOMALY_CORRECTION_TASK_RESOURCE,
  EXPORT_RECON_RESOURCE,
  IMPORT_RECON_RESOURCE,
  RECON_RESOURCE,
  EXPORT_DIRECTION_TYPE,
  IMPORT_DIRECTION_TYPE,
  USER_INFO,
  READINGUNIT_RESOURCE
} = config;

export function toNewResource(resource, _params = {}) {
  const params = cloneDeep(_params);
  switch (resource) {
    case ITINERARY_RESOURCE: {
      params.filter = {
        ...params.filter,
        kind: READING_TYPE
      };
      return {
        resource: ITINERARY,
        params
      };
    }

    case DISTRIBUTION_ITINERARY_RESOURCE: {
      params.filter = {
        ...params.filter,
        kind: DISTRIBUTION_TYPE
      };
      return {
        resource: ITINERARY,
        params
      };
    }

    case CONTROL_READING_ITINERARY_RESOURCE: {
      params.filter = {
        ...params.filter,
        kind: CONTROL_ITINERARY_TYPE
      };
      return {
        resource: ITINERARY,
        params
      };
    }

    case ANOMALY_CORRECTION_ITINERARY_RESOURCE: {
      params.filter = {
        ...params.filter,
        kind: ANOMALY_CORRECTION_TYPE
      };
      return {
        resource: ITINERARY,
        params
      };
    }

    case READINGTASK_RESOURCE:
    case DISTRIBUTION_TASK_RESOURCE:
    case CONTROL_READING_TASK_RESOURCE:
    case ANOMALY_CORRECTION_TASK_RESOURCE:
      return {
        resource: TASK,
        params
      };

    case EXPORT_RECON_RESOURCE: {
      params.filter = {
        ...params.filter,
        direction: EXPORT_DIRECTION_TYPE
      };
      return {
        resource: RECON_RESOURCE,
        params
      };
    }

    case IMPORT_RECON_RESOURCE: {
      params.filter = {
        ...params.filter,
        direction: IMPORT_DIRECTION_TYPE
      };
      return {
        resource: RECON_RESOURCE,
        params
      };
    }
    case SETTINGS_RESOURCE: {
      params.filter = {
        ...params.filter,
        scope: "server"
      };
      return {
        resource,
        params
      };
    }
    case DEVICES_SETTINGS_RESOURCE: {
      params.filter = {
        ...params.filter,
        scope: "device"
      };
      return {
        resource: SETTINGS_RESOURCE,
        params
      };
    }

    default:
      return {
        resource,
        params
      };
  }
}

export const transformRequest = (type, _resource, _params) => {
  const { resource, params } = toNewResource(_resource, _params);
  if (params.data && params.data.id) {
    // ids to int
    params.data.id = Number(params.data.id);
  }
  // also transform ids of related items

  if (type === UPDATE) {
    const toOmit = [...config.PROTECTED_FIELDS];
    if (resource !== DEVICE_RESOURCE && resource !== USER_RESOURCE) {
      toOmit.push("blocked_reason");
    }
    params.data = omit(params.data, toOmit);
  }

  if (params.data && RELATIONS[resource]) {
    const isDelete =
      type.toUpperCase() === "DELETE" || params.data.restoreResource;
    delete params.data.restoreResource;
    if (RELATIONS[resource].id_fields && !isDelete) {
      RELATIONS[resource].id_fields.forEach(field => {
        if (params.data[field]) {
          params.data[field] = Number(params.data[field]);
        } else {
          params.data[field] = null;
        }
      });
    }
    if (RELATIONS[resource].preloads) {
      params.data = omit(params.data, RELATIONS[resource].preloads);
    }

    const fieldsToOmit = Object.keys(RELATIONS[resource].urls);
    if (fieldsToOmit && fieldsToOmit.length > 0) {
      params.data = omit(params.data, fieldsToOmit);
    }

    const { allowedFields } = RELATIONS[resource];
    if (allowedFields && allowedFields.length > 0) {
      params.data = pick(params.data, allowedFields);
    }
  }

  if (
    params.filter &&
    RELATIONS[resource] &&
    RELATIONS[resource].partialSearchFields
  ) {
    const partialSearchFields = RELATIONS[resource].partialSearchFields;
    partialSearchFields.forEach(fieldName => {
      if (params.filter[fieldName]) {
        params.filter[fieldName] = `%${params.filter[fieldName]}%`;
      }
    });
  }

  if (
    resource === READINGUNIT_RESOURCE &&
    type === GET_MANY &&
    params.ids.length === 1
  ) {
    type = GET_ONE;
    params.id = params.ids[0];
    delete params.ids;
  }

  return { type, resource, params };
};

// copy password hash and empty password field of user objects
const movePasswordHash = data => {
  if (data.password) {
    data.hash = data.password;
    data.password = "";
  }
  return data;
};

const removeEmbedded = resource => (value, key) => {
  if (value === null) return null;

  if (key === "id") return `${value}`;
  if (key.endsWith("_id") && value === 0) {
    return null;
  }
  if (
    config.RELATIONS[resource] &&
    config.RELATIONS[resource].id_fields &&
    config.RELATIONS[resource].id_fields.includes(key)
  ) {
    // also transform ids of related items, but don't transform nulls to string
    return value === null ? null : `${value}`;
  }
  if (isArray(value) && key !== "images" && has(value[0], "id")) {
    // we can keep single nested object, since we use the _id field for reference inputs
    // else if (has(value, "id")) return `${value.id}`;
    return value.filter(v => !isNil(v)).map(v => `${v.id}`);
  }
  return value;
};

export const transformResponse = (response, type, resource, params) => {
  switch (resource) {
    case USER_RESOURCE:
      if (response.data) {
        response.data = isArray(response.data)
          ? response.data.map(movePasswordHash)
          : movePasswordHash(response.data);
      }
      break;
    case USER_INFO:
    case DEVICE_RESOURCE:
      return response;
    default:
      break;
  }

  response.data = !isArray(response.data)
    ? mapValues(response.data, removeEmbedded(resource))
    : response.data.map(d => mapValues(d, removeEmbedded(resource)));

  if (type === "DELETE") response.data = params;

  return response;
};
