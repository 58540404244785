import React from "react";
import P from "prop-types";
import { FunctionField } from "react-admin";

export function FieldWithUnits({ source, units, ...props }) {
  return (
    <FunctionField {...props} render={rec => `${rec[source]} ${rec[units]}`} />
  );
}

FieldWithUnits.propTypes = {
  source: P.string,
  units: P.string
};
