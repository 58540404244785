import React from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { addField, translate } from "react-admin";
import { Typography, withStyles } from "@material-ui/core";

const ReadOnlyInput = ({
  translate,
  classes,
  input: { value },
  resource,
  source
}) =>
  value ? (
    <Typography
      component="span"
      variant="body2"
      className={classes.readOnlyComponent}
    >
      {translate(`resources.${resource}.fields.${source}`)}
    </Typography>
  ) : null;

ReadOnlyInput.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  resource: PropTypes.string,
  source: PropTypes.string
};

export default compose(
  translate,
  addField,
  withStyles({
    readOnlyComponent: {
      fontWeight: 600
    }
  })
)(ReadOnlyInput);
