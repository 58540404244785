import React from "react";
import PropTypes from "prop-types";
import { Typography, withStyles } from "@material-ui/core";

const styles = {
  inlineField: {
    display: "inline-block"
  }
};

export const MultiField = ({
  className,
  classes,
  record = {},
  children,
  delimiter
}) => {
  const length = children.length - 1;
  return (
    <Typography component="span" body1="body1" className={className}>
      {React.Children.map(children, (field, idx) =>
        <React.Fragment>
          {React.cloneElement(field, {
            className: classes.inlineField,
            record: record
          })}
          {length !== idx ? delimiter : null}
        </React.Fragment>
      )}
    </Typography>
  );
};

MultiField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.object,
  children: PropTypes.node,
  delimiter: PropTypes.string
};

MultiField.defaultProps = {
  delimiter: " / "
};

export default withStyles(styles)(MultiField);
