import {
  GET_ONE,
  GET_LIST,
  GET_MANY_REFERENCE,
  GET_MANY,
  UPDATE,
  CREATE,
  CRUD_GET_LIST,
  crudUpdate
} from "react-admin";
import { change, destroy } from "redux-form";
import config from "../config";
export const JWT_RECEIVED = "JWT_RECEIVED";
export const jwtReceived = jwt => ({
  type: JWT_RECEIVED,
  payload: { jwt }
});
export const GET_CURRENTUSER = "GET_CURRENTUSER";
export const getCurrentUser = id => ({
  type: GET_CURRENTUSER,
  payload: { id },
  meta: {
    resource: config.USER_RESOURCE,
    fetch: GET_ONE,
    cancelPrevious: false
  }
});
// FIXME we run this at startup now, remove in other components
export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const GET_ALL_ROLES_SUCCESS = "GET_ALL_ROLES_SUCCESS";
export const getAllRoles = () => ({
  type: GET_ALL_ROLES,
  payload: {},
  meta: {
    resource: config.ROLE_RESOURCE,
    fetch: GET_LIST,
    cancelPrevious: false
  }
});
export const GET_USER_INFO = "GET_USER_INFO";
export const getPermissions = () => ({
  type: GET_USER_INFO,
  meta: {
    resource: config.USER_INFO,
    fetch: GET_ONE,
    cancelPrevious: true
  }
});
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_READINGCENTERS = "GET_READINGCENTERS";
export const getReadingCenters = () => ({
  type: GET_READINGCENTERS,
  payload: {
    pagination: { page: 1, perPage: 500 }
  },
  meta: {
    resource: config.READINGCENTER_RESOURCE,
    fetch: GET_LIST,
    cancelPrevious: false
  }
});
export const GET_READINGCENTERS_SUCCESS = "GET_READINGCENTERS_SUCCESS";
export const GET_READINGUNITS = "GET_READINGUNITS";
export const getReadingUnits = () => ({
  type: GET_READINGUNITS,
  payload: {},
  meta: {
    resource: config.READINGUNIT_RESOURCE,
    fetch: GET_LIST,
    cancelPrevious: false
  }
});
export const GET_CLIENT_ACTIVITIES = "GET_CLIENT_ACTIVITIES";
export const getClientActivites = () => ({
  type: GET_CLIENT_ACTIVITIES,
  payload: {},
  meta: {
    resource: config.CLIENT_ACTIVITY_RESOURCE,
    fetch: GET_LIST,
    cancelPrevious: false
  }
});
export const GET_ANOMALIES = "GET_ANOMALIES";
export const getAnomalies = (payload = {}) => ({
  type: GET_ANOMALIES,
  payload,
  meta: {
    resource: config.ANOMALIES_RESOURCE,
    fetch: GET_LIST,
    cancelPrevious: false
  }
});
export const GET_SETTINGS = "GET_SETTINGS";
export const getSettings = () => ({
  type: GET_SETTINGS,
  payload: { pagination: { page: 1, perPage: 500 } },
  meta: {
    resource: config.SETTINGS_RESOURCE,
    fetch: GET_LIST,
    cancelPrevious: false
  }
});
export const GET_CURRENTUSER_SUCCESS = "GET_CURRENTUSER_SUCCESS";
// dispatch checkforToken to check for a token in local storage and load it if available
export const CHECK_FOR_TOKEN = "CHECK_FOR_TOKEN";
export const checkForToken = () => ({
  type: CHECK_FOR_TOKEN
});
export const SWITCH_RCCONTEXT = "SWITCH_RCCONTEXT";
export const switchRcContext = rcContext => ({
  type: SWITCH_RCCONTEXT,
  payload: { rcContext }
});
export const RESET_ITINERARY = "RESET_ITINERARY";
export const resetItinerary = id => ({
  type: RESET_ITINERARY,
  payload: { id }
});
export const RESET_ITINERARY_SUCCESS = "RESET_ITINERARY_SUCCESS";
export const resetItinerarySuccess = id => ({
  type: RESET_ITINERARY_SUCCESS,
  payload: { id }
});
export const BATCH_OPERATION = "BATCH_OPERATION";
export const batchOperation = (type, optionsMap, meta) => ids => ({
  type: BATCH_OPERATION,
  payload: { ids, type, optionsMap },
  meta
});
export const FORCE_QC = "FORCE_QC";
export const forceQC = id => ({
  type: FORCE_QC,
  payload: { id }
});
export const GENERATE_ITINERARY = "GENERATE_ITINERARY";
export const generateItinerary = (kind, ids, agent_id) => ({
  type: GENERATE_ITINERARY,
  payload: { kind, ids, agent_id }
});
export const SET_SELECTED_FIELD = "SET_SELECTED_FIELD";
export const setSelectedField = (id, value) => ({
  type: SET_SELECTED_FIELD,
  payload: { id, value }
});
export const CLEAR_SELECTED_FIELD = "CLEAR_SELECTED_FIELD";
export const clearSelectedField = () => ({
  type: CLEAR_SELECTED_FIELD
});
export const REOPEN_ITINERARY = "REOPEN_ITINERARY";
export const reopenItinerary = (id, data) => ({
  type: REOPEN_ITINERARY,
  payload: { id, data }
});
export const REOPEN_DISTRIBUTION_ITINERARY = "REOPEN_DISTRIBUTION_ITINERARY";
export const reopenDistributionItinerary = (id, data) => ({
  type: REOPEN_DISTRIBUTION_ITINERARY,
  payload: { id, data }
});
export const UPDATE_ITINERARY = "UPDATE_ITINERARY";
export const updateItinerary = (
  id,
  data,
  resource = config.ITINERARY_RESOURCE,
  redirectTo
) => ({
  type: UPDATE_ITINERARY,
  payload: { id, data },
  meta: {
    resource,
    fetch: UPDATE,
    cancelPrevious: false,
    redirectTo
  }
});

export const CONFIRM_ITINERARY = "CONFIRM_ITINERARY";
export const confirmItinerary = (id, resource, redirectTo) => ({
  type: CONFIRM_ITINERARY,
  payload: { id },
  meta: {
    resource,
    fetch: UPDATE,
    cancelPrevious: false,
    redirectTo
  }
});
export const CONFIRM_DEVICE = "CONFIRM_DEVICE";
export const confirmDevice = (id, onb_code) => ({
  type: CONFIRM_DEVICE,
  payload: { id, onb_code }
});
export const confirmActivation = (
  resource,
  id,
  deleted_at = null,
  redirectTo = "edit"
) => {
  return crudUpdate(
    resource,
    id,
    { deleted_at, restoreResource: true },
    null,
    `/${resource}`,
    redirectTo
  );
};
export const PRINT_ROUTESHEET = "PRINT_ROUTESHEET";
export const printRoutesheet = id => ({
  type: PRINT_ROUTESHEET,
  payload: {
    target: "itinerary_id",
    id,
    sort: {
      field: "sequence_new",
      order: "DESC"
    },
    filters: {}
  },
  meta: {
    resource: config.READINGTASK_RESOURCE,
    relatedTo: `${config.ITINERARY_RESOURCE}_${config.READINGTASK_RESOURCE}@itinerary_id_${id}`,
    fetch: GET_MANY_REFERENCE,
    cancelPrevious: false
  }
});
export const PRINT_ROUTESHEET_SUCCESS = "PRINT_ROUTESHEET_SUCCESS";
export const PRINT_ROUTESHEET_COMPLETE = "PRINT_ROUTESHEET_COMPLETE";
export const printRoutesheetComplete = id => ({
  type: PRINT_ROUTESHEET_COMPLETE,
  payload: { id }
});
export const CLEAR_NEW_SEQUENCE = "CLEAR_NEW_SEQUENCE";
export const clearNewSequence = () => ({
  type: CLEAR_NEW_SEQUENCE
});
export const SAVE_NEW_SEQUENCE = "SAVE_NEW_SEQUENCE";
export const saveNewSequence = (id, newSequence) => ({
  type: SAVE_NEW_SEQUENCE,
  payload: { id, newSequence }
});
// use this to initialize the new sequence screen, no checks performed
export const SET_NEW_SEQUENCE = "SET_NEW_SEQUENCE";
export const setNewSequence = (itineraryId, taskId, newSequence) => ({
  type: SET_NEW_SEQUENCE,
  payload: { itineraryId, taskId, newSequence }
});
// updates a sequence, moving other tasks at and between the current and target sequence
export const UPDATE_NEW_SEQUENCE = "UPDATE_NEW_SEQUENCE";
export const updateNewSequence = (itineraryId, taskId, newSequence) => ({
  type: UPDATE_NEW_SEQUENCE,
  payload: { itineraryId, taskId, newSequence }
});
export const CREATE_READING = "CREATE_READING";
export const createReading = data => ({
  type: CREATE_READING,
  payload: { data },
  meta: {
    resource: config.READING_RESOURCE,
    fetch: CREATE,
    cancelPrevious: false
  }
});
export const SET_READING_VALUE = "SET_READING_VALUE";
export const setReadingValue = (id, field, value) => ({
  type: SET_READING_VALUE,
  payload: { id, field, value }
});
export const CREATE_READINGS = "CREATE_READINGS";
export const createReadings = (readings, itinerary) => ({
  type: CREATE_READINGS,
  payload: { readings, itinerary }
});
export const CLEAR_READINGS = "CLEAR_READINGS";
export const clearReadings = () => ({
  type: CLEAR_READINGS
});
export const COMPLETE_CAPTURE = "COMPLETE_CAPTURE";
export const completeCapture = id => ({
  type: COMPLETE_CAPTURE,
  payload: { id }
});
export const SET_PREFILL_SELECTED_DATE = "SET_PREFILL_SELECTED_DATE";
export const setPrefillSelectedDate = date => ({
  type: SET_PREFILL_SELECTED_DATE,
  payload: { date }
});
export const CLEAR_PREFILL_SELECTED_DATE = "CLEAR_PREFILL_SELECTED_DATE";
export const clearPrefillSelectedDate = date => ({
  type: CLEAR_PREFILL_SELECTED_DATE,
  payload: { date }
});
export const GET_QUALITY_RULES = "GET_QUALITY_RULES";
export const getQualityRules = () => ({
  type: GET_QUALITY_RULES,
  payload: {},
  meta: {
    resource: config.QUALITY_RULES_RESOURCE,
    fetch: GET_LIST,
    cancelPrevious: false
  }
});
export const SET_QUALITY_THRESHOLD = "SET_QUALITY_THRESHOLD";
export const setQualityThreshold = (rId, qId, threshold) => ({
  type: SET_QUALITY_THRESHOLD,
  payload: { rId, qId, threshold }
});

/**
 * changes values in state.form.record-form.values
 */
export const setRecordFormValue = (name, value) =>
  change("record-form", name, value);
export const resetForm = () => destroy("record-form");
export const GET_READING_TASKS = "GET_READING_TASKS";
export const getReadingTasks = ids => ({
  type: GET_READING_TASKS,
  payload: { ids },
  meta: {
    resource: config.READINGTASK_RESOURCE,
    fetch: GET_MANY,
    cancelPrevious: false
  }
});
export const getAllReadingTasks = (pagination, sort, filter) => ({
  type: CRUD_GET_LIST,
  payload: {
    pagination: pagination,
    sort: sort,
    filter: filter
  },
  meta: {
    resource: config.READINGTASK_RESOURCE,
    fetch: GET_LIST,
    cancelPrevious: false
  }
});
export const getAllDistributionTasks = (pagination, sort, filter) => ({
  type: CRUD_GET_LIST,
  payload: {
    pagination: pagination,
    sort: sort,
    filter: filter
  },
  meta: {
    resource: config.DISTRIBUTION_TASK_RESOURCE,
    fetch: GET_LIST,
    cancelPrevious: false
  }
});
export const SET_UPLOAD_FILES = "SET_UPLOAD_FILES";
export const setUploadFiles = (files, type, accept) => (
  resource,
  id,
  source
) => {
  return {
    type: SET_UPLOAD_FILES,
    payload: {
      data: { type, files, accept },
      meta: { resource, id, source }
    }
  };
};
export const PUSH_UPLOAD_FILES = "PUSH_UPLOAD_FILES";
export const pushUploadFiles = (resource, id, source) => {
  return {
    type: PUSH_UPLOAD_FILES,
    payload: { resource, id, source }
  };
};
export const GET_IMAGES = "GET_IMAGES";
export const GET_IMAGES_SUCCESS = "GET_IMAGES_SUCCESS";
export const getImages = ids => ({
  type: GET_IMAGES,
  payload: { ids },
  meta: {
    resource: config.IMAGE_RESOURCE,
    fetch: GET_MANY,
    cancelPrevious: false
  }
});
export const getQualityRuns = itineraryId => ({
  type: CRUD_GET_LIST,
  payload: {
    pagination: { page: 1, perPage: config.PAGINATION },
    sort: { field: "rule_code", order: "ASC" },
    filter: { itinerary_id: itineraryId }
  },
  meta: {
    resource: config.QUALITYRUN_RESOURCE,
    fetch: GET_LIST,
    cancelPrevious: false
  }
});
export const GET_ITINERARY = "GET_ITINERARY";
export const getItinerary = id => ({
  type: GET_ITINERARY,
  payload: { id },
  meta: {
    resource: config.ITINERARY_RESOURCE,
    fetch: GET_ONE,
    cancelPrevious: false
  }
});
export const ADD_DISABLED_RC_TO_TOKEN = "ADD_DISBALED_RC_TO_TOKEN";
export const addDisabledRcToToken = id => ({
  type: ADD_DISABLED_RC_TO_TOKEN,
  payload: { id }
});
export const ASSIGNMENT_WITH_METHOD_CHANGE = "ASSIGNMENT_WITH_METHOD_CHANGE";
export const assignmentWithMethodChange = (id, old_method, new_method) => ({
  type: ASSIGNMENT_WITH_METHOD_CHANGE,
  payload: { id, old_method, new_method }
});
export const ASSIGN_ITINERARY = "ASSIGN_ITINERARY";
export const assign = (
  resource,
  method,
  id,
  agent_id,
  capturer_id,
  basePath
) => ({
  type: ASSIGN_ITINERARY,
  payload: { resource, method, id, agent_id, capturer_id, basePath }
});
export const UNASSIGN_ITINERARY = "UNASSIGN_ITINERARY";
export const unassign = (id, options) => ({
  type: UNASSIGN_ITINERARY,
  payload: { id, options }
});
export const OPEN_LIGHT_BOX = "OPEN_LIGHT_BOX";
export const openLightBox = payload => ({
  type: OPEN_LIGHT_BOX,
  payload
});
export const CLOSE_LIGHT_BOX = "CLOSE_LIGHT_BOX";
export const closeLightBox = () => ({
  type: CLOSE_LIGHT_BOX
});

export const DOWNLOAD_IMAGE_SUCCESS = "DOWNLOAD_IMAGE_SUCCESS";
export const DOWNLOAD_IMAGE_ATTEMPT = "DOWNLOAD_IMAGE_ATTEMPT";
export const downloadImage = image => ({
  type: DOWNLOAD_IMAGE_ATTEMPT,
  payload: { image }
});

export const DOWNLOAD_IMAGE_START = "DOWNLOAD_IMAGE_START";
export const startDownload = image => ({
  type: DOWNLOAD_IMAGE_START,
  payload: { image }
});

export const downloadImageSuccess = (image, blob, url) => ({
  type: DOWNLOAD_IMAGE_SUCCESS,
  payload: { image, blob, url }
});

export const QUALIFY_IMAGE = "QUALIFY_IMAGE";
export const qualifyImage = payload => ({
  type: QUALIFY_IMAGE,
  payload
});

export const SET_PAGE = "SET_PAGE";
export const setPage = (id, page) => ({
  type: SET_PAGE,
  payload: { id, page }
});
export const SET_PER_PAGE = "SET_PER_PAGE";
export const setPerPage = (id, perPage) => ({
  type: SET_PER_PAGE,
  payload: { id, perPage }
});
export const SET_SORT = "SET_SORT";
export const setSort = (id, sort) => ({
  type: SET_SORT,
  payload: { id, sort }
});
export const SET_FILTERS = "SET_FILTERS";
export const setFilters = (id, filters) => ({
  type: SET_FILTERS,
  payload: { id, filters }
});

export const START_UNDOABLE = "START_UNDOABLE";
export const startUndoable = () => ({
  type: START_UNDOABLE
});
export const STOP_UNDOABLE = "STOP_UNDOABLE";
export const stopUndoable = () => ({
  type: STOP_UNDOABLE
});

export const OPEN_MODAL = "OPEN_MODAL";
export const openModal = (modalName, options) => ({
  type: OPEN_MODAL,
  payload: { modalName, options }
});

export const CLOSE_MODAL = "CLOSE_MODAL";
export const closeModal = modalName => ({
  type: CLOSE_MODAL,
  payload: { modalName }
});
