import React from "react";
import PropTypes from "prop-types";
import { CardActions } from "@material-ui/core";
import { ListButton, ShowButton, RefreshButton } from "react-admin";
import { RestrictedDeleteButton } from "./RestrictedButtons";

const cardActionStyle = {
  justifyContent: "flex-end"
};

const EditActions = (
  props /*{
  basePath,
  data,
  hasDelete,
  hasShow,
  refresh,
  requiredPermissionsDeleteButton
}*/
) => {
  const {
    basePath,
    data,
    hasDelete,
    hasShow,
    refresh,
    resource,
    redirectFunc,
    requiredPermissionsDeleteButton
  } = props;
  const redirectPath =
    redirectFunc && data ? redirectFunc(basePath, data.id, data) : basePath;
  return (
    <CardActions style={cardActionStyle}>
      {hasShow && <ShowButton basePath={redirectPath} record={data} />}
      <ListButton basePath={redirectPath} />
      {hasDelete &&
        <RestrictedDeleteButton
          undoable={false}
          resource={resource}
          requiredPermissions={requiredPermissionsDeleteButton}
          basePath={redirectPath}
          record={data}
        />}
      <RefreshButton refresh={refresh} />
    </CardActions>
  );
};

EditActions.propTypes = {
  basePath: PropTypes.string,
  resource: PropTypes.string,
  redirectFunc: PropTypes.func,
  data: PropTypes.object,
  hasDelete: PropTypes.bool,
  hasShow: PropTypes.bool,
  refresh: PropTypes.func,
  requiredPermissionsDeleteButton: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ])
};

export default EditActions;
