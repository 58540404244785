import compose from "recompose/compose";
import { connect } from "react-redux";
import mapProps from "recompose/mapProps";
import omit from "lodash/omit";
import { getHumanReadablePrecisionMode } from "../../config/filterOptions";
import TranslationField from "./TranslationField";

export default compose(
  connect(() => ({
    convertFunction: getHumanReadablePrecisionMode
  })),
  mapProps(props => omit(props, ["dispatch"]))
)(TranslationField);
