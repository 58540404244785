import { formValueSelector } from "redux-form";
import { hide } from "../../fields/hideableField";
import { Warning } from "./Warning";

const selector = formValueSelector("record-form");
export const ResetWarning = hide((state, { record, methodChange }) => {
  // MRA-874 only call reset for method change for now
  const toCheck = ["method"];
  //const toCheck = ["method", "reader_id", "capturer_id"];
  const checked = toCheck.map(item => ({
    id: item,
    current: record[item],
    selected: selector(state, item)
  }));
  let warning = false;
  checked.forEach(check => {
    if (check.current && check.current !== check.selected) {
      warning = true;
      //#FIXME this needs to be done for MRA-874 to still reset on method change but not on reassigning agents
      if (check.id === "method") methodChange(check.current, check.selected);
    }
  });
  return warning;
})(Warning);
