import React from "react";
import { Divider, withStyles } from "@material-ui/core";
import { translate } from "react-admin";
import P from "prop-types";
import cn from "classnames";
import compose from "recompose/compose";
import { Labeled } from "react-admin";

const styles = theme => ({
  groupContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  field: {
    display: "inline-block",
    marginRight: 40,
    minWidth: "20%"
  },
  groupHeader: {
    ...theme.typography.display1,
    marginBottom: "0.35em",
    marginTop: 40
  },
  details: {
    flex: 1
  }
});

const FieldsGroup = ({
  key,
  resource,
  basePath,
  record,
  children,
  title,
  translate,
  className,
  classes,
  hideIfExpanded,
  expanded
}) => {
  if (hideIfExpanded && expanded) {
    return null;
  }
  return (
    <div key={key} className={cn(className)}>
      <h4 className={classes.groupHeader}>
        {translate(
          typeof title === "function" ? title(resource, record) : title
        )}
      </h4>
      <Divider />
      <div className={classes.groupContainer}>
        {React.Children.map(
          children,
          field =>
            field
              ? <div
                  key={field.props.source}
                  className={cn(
                    `ra-field ra-field-${field.props.source}`,
                    field.props.className,
                    field.props.source != null ? classes.field : classes.details
                  )}
                >
                  {field.props.addLabel
                    ? <Labeled
                        record={record}
                        resource={resource}
                        basePath={basePath}
                        label={field.props.label}
                        source={field.props.source}
                        disabled={false}
                      >
                        {field}
                      </Labeled>
                    : typeof field.type === "string"
                      ? field
                      : React.cloneElement(field, {
                          record,
                          resource,
                          basePath
                        })}
                </div>
              : null
        )}
      </div>
    </div>
  );
};

FieldsGroup.propTypes = {
  key: P.oneOfType([P.string, P.number]),
  basePath: P.string,
  children: P.oneOfType([P.arrayOf(P.element), P.element]),
  className: P.string,
  record: P.object,
  resource: P.string,
  title: P.oneOfType([P.string, P.func]),
  version: P.number,
  translate: P.func,
  source: P.string,
  classes: P.object,
  expanded: P.bool,
  hideIfExpanded: P.bool
};

FieldsGroup.defaultProps = {
  source: "group",
  hideIfExpanded: false
};

export default compose(translate, withStyles(styles))(FieldsGroup);
