import React from "react";
import ReactTooltip from "react-tooltip";
import { ChipField } from "react-admin";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

/** This HOC uses https://github.com/wwayne/react-tooltip
  * props:
  * toolTipId: string for custom id, result: id=`tooltip-${toolTipId}`
  * toolTipText: string, if it should be filled by the values from the record use:
      "${attribute1} ${attribute2}" otherwise just use text
  * toolTipEval: true if values from the record should be used
  */

export const addToolTip = Component => {
  /**
   * @return {null | Component}
   */
  function AddToolTip(props) {
    const {
      toolTipId,
      toolTipText,
      toolTip,
      toolTipEval,
      ...componentProps
    } = props;
    const record = props.record || {};
    /**
     * use #{var} instead of ${var} to get rid of annoying es-lint warnings
     * const regex = new RegExp("\\$\{([^\}]*)\}", "g"); //eslint-disable-line
     */

    if (isEmpty(record)) return null;

    const regex = new RegExp("#{([^}]*)}", "g"); //eslint-disable-line
    return (
      <div>
        <span data-tip data-for={`tooltip-${toolTipId}-${record.id}`}>
          <Component record={record} {...componentProps} />
        </span>
        <ReactTooltip id={`tooltip-${toolTipId}-${record.id}`} {...toolTip}>
          <span>
            {toolTipEval
              ? toolTipText.replace(regex, (match, group) =>
                  record[group] ? record[group] : ""
                )
              : toolTipText}
          </span>
        </ReactTooltip>
      </div>
    );
  }
  AddToolTip.propTypes = {
    toolTipId: PropTypes.number,
    toolTipText: PropTypes.string,
    toolTip: PropTypes.string,
    toolTipEval: PropTypes.bool,
    record: PropTypes.object
  };
  return AddToolTip;
};

export const ToolTipChipField = addToolTip(ChipField);

ToolTipChipField.propTypes = {
  toolTipId: PropTypes.string,
  toolTipText: PropTypes.string.isRequired,
  toolTipEval: PropTypes.bool,
  toolTip: PropTypes.object
};

ToolTipChipField.defaultProps = {
  toolTipId: "tooltip",
  toolTipText: "",
  toolTipEval: false,
  toolTip: {
    type: "info",
    effect: "float"
  }
};
