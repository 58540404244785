import React from "react";
import { List, TextField, Filter, BooleanField } from "react-admin";
import config from "../../config";
import {
  RestrictedEditButton,
  RestrictedListActions,
  RestrictedFlatButton
} from "../restricted-components";
import { TranslatedTextField } from "../fields";
import PropTypes from "prop-types";
import LockOpen from "@material-ui/icons/LockOpen";
import { withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Datagrid } from "../base";

const CustomButton = props => {
  const { id, deleted_at } = props.record;
  if (deleted_at) {
    return (
      <RestrictedFlatButton
        color="primary"
        component={Link}
        to={config.CLIENT_ACTIVITY_ACTIVATE_PATH.replace(":id", id)}
        style={{ minWidth: 0, overflow: "inherit" }}
        requiredPermissions={[config.PERMISSIONS.CLIENT_ACTIVITY_UPDATE]}
      >
        <LockOpen />
      </RestrictedFlatButton>
    );
  } else {
    return (
      <RestrictedEditButton
        requiredPermissions={[config.PERMISSIONS.CLIENT_ACTIVITY_READ]}
        {...props}
      />
    );
  }
};

CustomButton.propTypes = {
  record: PropTypes.object
};

/**
 * filters for list component
 */
const ClientActivityFilter = props => (
  <Filter
    {...props}
    filterValues={props.filterValues ? props.filterValues : {}}
  />
);

ClientActivityFilter.propTypes = {
  filterValues: PropTypes.object
};

const styles = {
  textCenter: {
    textAlign: "center"
  }
};

/**
 * component for listing client activity
 */
export const ClientActivityList = ({ classes, ...props }) => (
  <List
    {...props}
    filters={<ClientActivityFilter />}
    perPage={config.PAGINATION}
    actions={
      <RestrictedListActions
        requiredPermissionsCreateButton={[
          config.PERMISSIONS.CLIENT_ACTIVITY_CREATE,
          config.PERMISSIONS.HQ_MEMBER
        ]}
      />
    }
  >
    <Datagrid>
      <TextField
        source="code"
        cellClassName={classes.textCenter}
        headerClassName={classes.textCenter}
      />
      <TranslatedTextField
        source="description"
        sortable={false}
        cellClassName={classes.textCenter}
        headerClassName={classes.textCenter}
      />
      <BooleanField
        source="is_active"
        cellClassName={classes.textCenter}
        headerClassName={classes.textCenter}
      />
      <BooleanField
        source="is_default"
        cellClassName={classes.textCenter}
        headerClassName={classes.textCenter}
      />
      <CustomButton />
    </Datagrid>
  </List>
);

ClientActivityList.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(ClientActivityList);
