import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Datagrid as AdminDatagrid } from "react-admin";

const styles = theme => ({
  thead: {
    background: theme.palette.primary.main
  },
  headerCell: {
    color: theme.palette.primary.contrastText,
    "& span": {
      color: `${theme.palette.primary.contrastText} !important`
    }
  }
});

const Datagrid = ({ classes, ...props }) =>
  <AdminDatagrid
    classes={{
      thead: classes.thead,
      headerCell: classes.headerCell
    }}
    {...props}
  />;

Datagrid.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(Datagrid);
