import React, { Component } from "react";
import P from "prop-types";
import { Link } from "react-router-dom";
import {
  Card as MaterialCard,
  CardHeader,
  CardContent,
  CardMedia,
  CardActions,
  Collapse,
  IconButton,
  withStyles,
  CircularProgress
} from "@material-ui/core";
import Block from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import ImageEye from "@material-ui/icons/RemoveRedEye";
import ShowImage from "@material-ui/icons/Image";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DataUsage from "@material-ui/icons/DataUsage";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { reset } from "redux-form";
import {
  SimpleForm,
  SelectInput,
  ReferenceInput,
  Toolbar,
  SaveButton
} from "react-admin";
import { RenderChildren } from "../utils";
import {
  adminResource,
  getAllRecords,
  getRecord,
  getSettingValue,
  mraImages,
  getLocale,
  getOkControlAnomaly
} from "../../../redux/selectors";
import {
  downloadImage,
  openLightBox,
  qualifyImage
} from "../../../redux/actions";
import config from "../../../config";
import { checkPermission } from "../../restricted-components/RestrictedButtons";

const styles = theme => ({
  card: {
    width: "350px",
    margin: "0.5em",
    display: "inline-block",
    verticalAlign: "top"
  },
  actions: {
    display: "flex"
  },
  media: {
    height: 0,
    paddingTop: "56.25%"
  },
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "calc(28.125% - 40px)",
    paddingBottom: "calc(28.125% - 40px)"
  },
  notOk: {
    border: `1px solid ${theme.palette.error.main}`
  },
  ok: {
    border: `1px solid ${theme.palette.primary.main}`
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    }),
    marginLeft: "auto"
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  withoutPadding: {
    padding: 0
  },
  controlForm: {
    paddingTop: 10,
    display: "flex",
    flexDirection: "column"
  },
  toolbar: {
    background: "transparent"
  },
  header: {},
  oneRowField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 0
  },
  fieldClass: {
    flex: 1,
    padding: 0
  },
  labelClass: {
    flex: 1,
    textAlign: "right"
  }
});

const validate = (values, { translate }) => {
  if (!values.control_anomaly_id) {
    return { control_anomaly_id: [translate("mra.validation.required")] };
  }
};

const getImageRecord = record => {
  const { metadata, latest_reading, latest_result } = record;
  const { images } = latest_reading || latest_result || record;
  return metadata || (images && images[0]) || {};
};

const FormToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

class Card extends Component {
  state = { expanded: false };

  componentDidMount() {
    this.preloadImage();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { images, record, mraImages } = this.props;
    const image = (images && images[0]) || record;
    return (
      !isEqual(record, nextProps.record) ||
      !isEqual(this.state, nextState) ||
      mraImages[image.id] !== nextProps.mraImages[image.id]
    );
  }

  componentDidUpdate() {
    this.preloadImage();
  }

  preloadImage() {
    const { images, mraImages, record } = this.props;
    const image = (images && images[0]) || record;
    if (image.id && !mraImages[image.id]) {
      this.props.downloadImage(image);
    }
  }

  handleExpandClick = () => {
    if (this.props.fetchOnExpand && this.props.getContent) {
      this.props.getContent(this.props.record);
    }
    this.setState(state => ({ expanded: !state.expanded }));
  };

  save = data => {
    const { control_anomaly_id } = data;
    if (control_anomaly_id) {
      this.submitQualityControl(
        config.PICTURE_QUALITY.NOK.id,
        control_anomaly_id
      );
    }
  };

  submitQualityControl = (quality_status, control_anomaly_id = 0) => {
    const { record, resource, images, basePath, qualifyImage } = this.props;
    qualifyImage({
      images,
      payload: {
        control_anomaly_id: parseInt(control_anomaly_id, 10),
        quality_status
      },
      record,
      resource,
      basePath
    });
    this.setState({ not_ok: null });
  };

  getQualityStatus() {
    const { record, images } = this.props;
    if (record.metadata) {
      return record.metadata.quality;
    }

    if (!isEmpty(images)) {
      return images[0].quality_status;
    }
    return null;
  }

  onNotOk = () => {
    this.setState({ not_ok: true });
  };

  onOk = () => {
    this.submitQualityControl(
      config.PICTURE_QUALITY.OK.id,
      this.props.ok_control_anomaly
    );
  };

  render() {
    const {
      avatar,
      record,
      basePath,
      resource,
      resourceToFetch,
      classes,
      title,
      subheader,
      hasPermission,
      images: baseImages,
      openLightBox,
      children,
      controlAnomalyLabel,
      mraImages,
      rmsUrl
    } = this.props;
    const collapsedChild = [];
    const contentChild = [];
    React.Children.forEach(children, child => {
      if (child.props && child.props.collapsed) {
        collapsedChild.push(
          React.cloneElement(child, {
            key: child.props.label || child.props.source,
            collapsed: undefined
          })
        );
      } else {
        contentChild.push(child);
      }
    });
    const childrenClasses = {
      root: classes.oneRowField,
      label: classes.labelClass,
      value: classes.fieldClass
    };
    let isOk = false;
    let isNok = false;
    let qualityStatus = this.getQualityStatus();

    if (qualityStatus) {
      isNok = qualityStatus === config.PICTURE_QUALITY.NOK.id;
      isOk = qualityStatus === config.PICTURE_QUALITY.OK.id;
    }

    const images = !isEmpty(record.metadata)
      ? [
          new URL(
            `api/v1/resource/${record.rmsImageId || record.id}/file`,
            rmsUrl
          )
        ]
      : baseImages || [];
    const image = !isEmpty(images)
      ? !isEmpty(record.metadata)
        ? { url: images[0], isLoading: false }
        : get(mraImages, images[0].id, { isLoading: true })
      : null;
    const resourceUrl = record.metadata
      ? null
      : `/${resource || resourceToFetch}/${record.id}/show`;
    const { expanded, not_ok } = this.state;
    return (
      <MaterialCard className={classes.card}>
        <CardHeader
          className={classes.header}
          classes={{
            content: classes.header,
            subheader: classes.header,
            title: classes.header
          }}
          title={title}
          subheader={subheader}
          avatar={avatar}
        />
        {image && (
          <React.Fragment>
            {image.isLoading && (
              <div className={classes.loader}>
                <CircularProgress />
              </div>
            )}
            {image.url && (
              <CardMedia
                onClick={() => {
                  openLightBox({ images });
                }}
                className={classes.media}
                image={image.url}
              />
            )}
          </React.Fragment>
        )}
        <CardContent>
          <RenderChildren
            record={record}
            basePath={basePath}
            resource={resource}
            childrenClasses={childrenClasses}
          >
            {contentChild}
          </RenderChildren>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <RenderChildren
              record={record}
              basePath={basePath}
              resource={resource}
              childrenClasses={childrenClasses}
            >
              {collapsedChild}
            </RenderChildren>
          </Collapse>
          <CardActions className={classes.actions} disableActionSpacing>
            {hasPermission &&
              (!isEmpty(baseImages) ||
                (record.metadata && record.metadata.image_id)) &&
              !not_ok && (
                <React.Fragment>
                  <IconButton
                    className={cn({ [classes.notOk]: isNok })}
                    aria-label="not ok"
                    onClick={this.onNotOk}
                  >
                    <Block color={isNok ? "error" : "inherit"} />
                  </IconButton>
                  <IconButton
                    className={cn({ [classes.ok]: isOk })}
                    aria-label="ok"
                    onClick={this.onOk}
                  >
                    <Check color={isOk ? "primary" : "inherit"} />
                  </IconButton>
                </React.Fragment>
              )}
            {not_ok && (
              <SimpleForm
                validate={validate}
                form={record.id}
                className={classes.controlForm}
                basePath={basePath}
                record={getImageRecord(record)}
                resource={resource}
                save={this.save}
                toolbar={<FormToolbar className={classes.toolbar} />}
              >
                <ReferenceInput
                  className={classes.withoutPadding}
                  label="mra.anomaly.control"
                  source="control_anomaly_id"
                  filter={{ type: config.ANOMALY_TYPES.CONTROL.id }}
                  reference={config.ANOMALIES_RESOURCE}
                  format={v => (v ? String(v) : "")}
                >
                  <SelectInput optionText={controlAnomalyLabel} />
                </ReferenceInput>
              </SimpleForm>
            )}
            {resourceUrl && (
              <IconButton aria-label="view" component={Link} to={resourceUrl}>
                <ImageEye />
              </IconButton>
            )}
            {image && (
              <IconButton
                aria-label="gallery"
                onClick={() => {
                  openLightBox({ images });
                }}
              >
                <ShowImage />
              </IconButton>
            )}
            <IconButton
              className={cn(classes.expand, {
                [classes.expandOpen]: expanded
              })}
              onClick={this.handleExpandClick}
              aria-expanded={expanded}
              aria-label="Show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
        </CardContent>
      </MaterialCard>
    );
  }
}

Card.propTypes = {
  auth: P.object,
  record: P.object,
  ok_control_anomaly: P.oneOfType([P.object, P.string]),
  basePath: P.string,
  resource: P.string,
  resourceToFetch: P.string,
  classes: P.object,
  title: P.node,
  fetchOnExpand: P.bool,
  getContent: P.func,
  dispatchCrudUpdate: P.func,
  crudGetOne: P.func,
  subheader: P.node,
  images: P.array,
  actions: P.node,
  hasPermission: P.bool,
  avatar: P.node,
  downloadImage: P.func,
  rmsUrl: P.string,
  openLightBox: P.func,
  qualifyImage: P.func,
  mraImages: P.object,
  controlAnomalyLabel: P.string,
  children: P.arrayOf(P.node)
};

Card.defaultProps = {
  avatar: <DataUsage />
};

const mapStateToProps = (state, props) => {
  const username = getSettingValue(config.IMAGE_SERVER_USERNAME)(state);
  const password = getSettingValue(config.IMAGE_SERVER_PASSWORD)(state);
  const remoteId =
    props.record && props.record.metadata && props.record.metadata.task_id
      ? props.record.metadata.task_id
      : props.record.remote_id;

  return {
    record:
      props.resourceToFetch &&
      adminResource(state)(props.resourceToFetch) &&
      getAllRecords(props.resourceToFetch)(state) &&
      remoteId
        ? {
            ...props.record,
            ...getRecord(props.resourceToFetch, remoteId)(state),
            rmsImageId: props.record.id
          }
        : props.record,
    auth: username && password ? { username, password } : null,
    hasPermission: checkPermission(state, {
      requiredPermissions: ["execute_picture_control"]
    }),
    ok_control_anomaly: getOkControlAnomaly(state),
    mraImages: mraImages(state),
    controlAnomalyLabel:
      (config.LOCALE_TYPES[getLocale(state)] || {}).label || "label_lang_one",
    rmsUrl: getSettingValue("RMS_URL")(state)
  };
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    {
      resetForm: reset,
      openLightBox,
      qualifyImage,
      downloadImage
    }
  )
)(Card);
