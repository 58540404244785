import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import AppBar from "./AppBar";
import cn from "classnames";

import {
  Sidebar,
  Notification,
  defaultTheme,
  setSidebarVisibility
} from "react-admin";
import Menu from "./Menu";
import LightBox from "./Lightbox";
import { checkForToken } from "../../redux/actions";
import { MapModal } from "../modals";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
    position: "relative",
    minWidth: "fit-content",
    width: "100%"
  },
  appFrame: {
    display: "flex",
    flexDirection: "column"
  },
  contentWithSidebar: {
    marginTop: 64,
    display: "flex",
    overflow: "hidden",
    flexGrow: 1,
    position: "relative",
    height: "calc(100vh - 64px)"
  },
  sidebar: {
    overflowY: "auto"
  },
  content: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 5
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0
    }
  },
  notification: {
    bottom: "10%"
  }
});

export class AppLayout extends Component {
  constructor(props) {
    super(props);
    props.init();
  }

  componentDidMount() {
    this.props.setSidebarVisibility(true);
  }

  render() {
    const { className, theme, classes, title, open, children } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <div className={cn("layout", classes.root, className)}>
          <div className={classes.appFrame}>
            <AppBar title={title} open={open} />
            <main className={classes.contentWithSidebar}>
              <Sidebar className={classes.sidebar}>
                <Menu isOpen={open} />
              </Sidebar>
              <div className={classes.content}>{children}</div>
            </main>
            <Notification
              classes={{ root: classes.notification }}
              autoHideDuration={10000}
            />
          </div>
        </div>
        <LightBox />
        <MapModal />
      </MuiThemeProvider>
    );
  }
}

AppLayout.propTypes = {
  init: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  classes: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.object,
  dashboard: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  isLoading: PropTypes.bool.isRequired,
  logout: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.string
  ]),
  menu: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.element
  ]),
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  setSidebarVisibility: PropTypes.func
};

AppLayout.defaultProps = {
  theme: defaultTheme
};

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
  isLoading: state.admin.loading > 0
});

export default compose(
  connect(
    mapStateToProps,
    { init: checkForToken, setSidebarVisibility }
  ),
  withStyles(styles)
)(AppLayout);
