export const downloadImage = (imageUrl, auth) =>
  fetch(imageUrl, {
    method: "GET",
    headers: {
      Authorization: "Basic " + btoa(`${auth.username}:${auth.password}`)
    }
  }).then(response => {
    if (response.ok) {
      return response.blob();
    }
    throw Error(response.statusText);
  });
