import config from "../config";
/**
 * this is the main translation file, see admin-on-rest translation conventions for components
 */
export default {
  en: {
    "Failed to fetch":
      "Couldn't connect to the API. Please check your Connection.",
    no_token: "You don't have an api token",
    wip: " (Work in Progress)",
    ra: {
      action: {
        delete: "Delete",
        activate: "Activate",
        deactivate: "Deactivate",
        edit: "Details"
      }
    },
    resources: {
      [config.READING_CALENDAR_RESOURCE]: {
        name: "Reading Calendar",
        fields: {
          cycle_year: "Year",
          cycle: "Cycle",
          itinerary_code: "ITIN ID",
          rc_code: "RC CODE",
          rc_name: "RC NAME",
          scheduled_at: "Planned Reading Date",
          scheduled: "Planned Reading Date"
        }
      },
      [config.TOP_ITINERARY_RESOURCE]: {
        name: "Top X Itineraries"
      },
      [config.EXPORT_RECON_RESOURCE]: {
        name: "Export Reconcilation Report",
        fields: {
          year: "Year",
          cycle: "Cycle",
          mra_processed: "Export date",
          mrc_processed_at: "Export date",
          uma_processed: "Process date",
          uma_processed_at: "Process date",
          sync_type: "Sync type",
          reading_center_name: "MRC Name",
          reading_center_ext_id: "MRC ID",
          itinerary_ext_id: "ITIN ID",
          imported_task_count: "# SP",
          task_count: "# SP",
          is_match: "ITIN status",
          is_tasks_match: "Compare #SP"
        }
      },
      [config.IMPORT_RECON_RESOURCE]: {
        name: "Import Reconciliation Report",
        fields: {
          year: "Year",
          cycle: "Cycle",
          mra_processed: "Import date",
          mrc_processed_at: "Import date",
          uma_processed: "Create date",
          uma_processed_at: "Create date",
          sync_type: "Sync type",
          reading_center_name: "MRC Name",
          reading_center_ext_id: "MRC ID",
          itinerary_ext_id: "ITIN ID",
          imported_task_count: "# SP",
          task_count: "# SP",
          is_match: "ITIN status",
          is_tasks_match: "Compare #SP"
        }
      },
      [config.CONTROL_READING_ITINERARY_RESOURCE]: {
        name: "Control Reading Itinerary |||| Control Reading Itineraries",
        fields: {
          route: "Route",
          remote_id: "Code",
          cycle: "Cycle",
          cycle_year: "Year",
          description: "Description",
          avg_consumption: "Consumption",
          status: "Status",
          scheduled_at: "Date",
          task_count: "# Tasks",
          agent_id: "Field A.",
          center_id: "RC",
          result_count: "# Done",
          completed_result_count: "# Done",
          total_anomaly_count: "# Anomalies",
          anomaly_count: "# Control Reading Anomalies",
          picture_anomaly_count: "# Picture Anomalies",
          is_confirmation_required: "Confirmation required",
          completion_confirmed_by_id: "Completion Confirmed By",
          completion_confirmed_at: "Completion Confirmation Date",
          is_completion_confirmation_required:
            "Requires Completion Confirmation"
        }
      },
      [config.ANOMALY_CORRECTION_ITINERARY_RESOURCE]: {
        name:
          "Anomaly Correction Itinerary |||| Anomaly Correction Itineraries",
        fields: {
          route: "Route",
          remote_id: "Code",
          cycle: "Cycle",
          cycle_year: "Year",
          description: "Description",
          avg_consumption: "Consumption",
          status: "Status",
          scheduled_at: "Date",
          task_count: "# Tasks",
          agent_id: "Field A.",
          center_id: "RC",
          result_count: "# Done",
          completed_result_count: "# Done",
          total_anomaly_count: "# Anomalies",
          anomaly_count: "# Correction Anomalies",
          picture_anomaly_count: "# Picture Anomalies",
          is_confirmation_required: "Confirmation required",
          completion_confirmed_by_id: "Completion Confirmed By",
          completion_confirmed_at: "Completion Confirmation Date",
          is_completion_confirmation_required:
            "Requires Completion Confirmation"
        }
      },
      [config.CONTROL_READING_TASK_RESOURCE]: {
        name: "Control Reading |||| Control Readings",
        type_name: "Control Reading",
        gps_precision_mode: "GPS Precision mode",
        net_type: "Network Type",
        conn_mode: "Connection Mode"
      },
      [config.ANOMALY_CORRECTION_TASK_RESOURCE]: {
        name: "Anomaly Correction |||| Anomaly Corrections",
        type_name: "Correction",
        gps_precision_mode: "GPS Precision mode",
        net_type: "Network Type",
        conn_mode: "Connection Mode"
      },
      [config.RMS_RESOURCE]: {
        name: "Picture Manager"
      },
      [config.READINGCENTER_RESOURCE]: {
        name: "Reading Center |||| Reading Centers",
        fields: {
          id: "ID",
          name: "Name",
          remote_id: "Code",
          location: "Location",
          region_id: "Region",
          reading_units: "Reading Units"
        }
      },
      [config.READINGUNIT_RESOURCE]: {
        name: "Reading Unit |||| Reading Units",
        fields: {
          id: "ID",
          name: "Name",
          type: "Type",
          contact_person: "Contact Person",
          address: "Headquarter",
          ref_number: "ID Code",
          tax_number: "Tax Number",
          phone: "Phone",
          mail: "Mail",
          region_id: "Region",
          centers: "Reading Centers"
        }
      },
      [config.USER_RESOURCE]: {
        name: "User |||| Users",
        fields: {
          id: "ID",
          username: "Username",
          first_name: "First Name",
          middle_name: "Middle Name",
          last_name: "Last Name",
          password: "Password",
          phone: "Phone",
          email: "Mail",
          language: "Language",
          user_reference: "Employee Nr.",
          is_blocked: "Block User",
          blocked_reason: "Block Reason",
          reading_centers: "Assigned Reading Centers",
          reading_center: "Assigned Reading Center",
          reset_requested: "Request password reset for this user",
          unit_id: "Reading Unit",
          roles: "Roles",
          rcs: "Reading Centers",
          deleted_at: "Deleted At",
          "@center_id": "Reading Center"
        }
      },
      [config.ITINERARY_RESOURCE]: {
        name: "Reading Itinerary |||| Reading Itineraries",
        fields: {
          id: "ID",
          route: "Route",
          remote_id: "Code",
          cycle: "Cycle",
          cycle_year: "Year",
          description: "Description",
          avg_consumption: "Consumption",
          status: "Status",
          scheduled_for: "Date",
          task_count: "#",
          agent_id: "Field A.",
          method: "Mode",
          capturer_id: "Capture A.",
          center_id: "RC",
          is_printed: "Printed",
          is_in_quality: "QC",
          result_count: "# Done Readings",
          completed_result_count: "# Done Readings",
          total_anomaly_count: "# Anomalies",
          anomaly_count: "# Reading Anomalies",
          picture_anomaly_count: "# Picture Anomalies",
          is_exported: "Exported",
          is_force_exported: "Forced",
          updated_at: "Updated at",
          created_at: "Created at",
          imported_at: "Imported at",
          is_confirmation_required: "Confirmation required",
          c_money_brutto: "Avg cons. incl. tax",
          c_money_netto: "Avg cons. excl. tax",
          c_units: "Avg cons. units",
          n_supply_points: "# of SP",
          completion_confirmed_by_id: "Completion Confirmed By",
          completion_confirmed_at: "Completion Confirmation Date",
          is_completion_confirmation_required:
            "Requires Completion Confirmation"
        }
      },
      [config.DISTRIBUTION_ITINERARY_RESOURCE]: {
        name: "Distribution Itinerary |||| Distribution Itineraries",
        fields: {
          id: "ID",
          itinerary_cycle: "Itinerary / Cycle",
          itinerary_id: "Itinerary",
          avg_consumption: "Consumption",
          route: "Route",
          images: "Images",
          remote_id: "Code",
          cycle: "Cycle",
          cycle_year: "Year",
          description: "Description",
          status: "Status",
          scheduled_at: "Date",
          reading_count: "#",
          agent_id: "Field A.",
          method: "Mode",
          capturer_id: "Capture A.",
          center_id: "RC",
          is_printed: "Printed",
          distribution_type: "Type",
          task_count: "# Distributions",
          result_count: "# Done Distributions",
          completed_result_count: "# Done Distributions",
          total_anomaly_count: "# Anomalies",
          anomaly_count: "# Distribution Anomalies",
          picture_anomaly_count: "# Picture Anomalies",
          updated_at: "Updated at",
          created_at: "Created at",
          imported_at: "Imported at",
          is_confirmation_required: "Confirmation required",
          completion_confirmed_by_id: "Completion Confirmed By",
          completion_confirmed_at: "Completion Confirmation Date",
          is_completion_confirmation_required:
            "Requires Completion Confirmation"
        }
      },
      [config.DEVICE_RESOURCE]: {
        name: "Device |||| Devices",
        fields: {
          id: "ID",
          model: "Model",
          manufacturer: "Vendor",
          device_identifier: "Device Nr.",
          app_version: "App Version",
          last_seen_at: "Time of Last Connection",
          last_used_by: "Last Logged User",
          onb_code: "App Confirmation Code",
          onb_confirmed_at: "Time of Device Provisioning",
          onb_confirmed_by: "Provisioning Confirmed By",
          onb_registered_at: "Registered On",
          onb_regitered_by: "Registered By",
          onb_expiration: "To be Confirmed Before",
          onb_status: "Status",
          is_blocked: "Block",
          blocked_reason: "Reason for Blocking",
          android_version: "Android",
          reading_center: "Reading Center",
          center_id: "RC",
          blocked_by: "Blocked By",
          users: "Users",
          device_serial: "Serial Number"
        }
      },
      [config.READING_RESOURCE]: {
        name: "Reading |||| Readings",
        fields: {
          itinerary_cycle: "Itinerary / Cycle",
          itinerary_id: "Itinerary",
          itinerary_eid: "Itinerary",
          itinerary_remote_id: "Itinerary External Id",
          avg_consumption: "Consumption",
          cycle: "Cycle",
          input_type: "Type",
          status: "Status",
          date_visited: "Date",
          reading_value: "Value",
          images: "Images",
          gps_coordinates: "GPS Lat/Long",
          gps_precision_mode: "GPS Precision mode",
          net_type: "Network Type",
          conn_mode: "Connection Mode",
          is_out_of_vicinity: "Vicinity Check",
          is_out_of_range: "Range Check"
        }
      },
      [config.SUPPLYPOINT_RESOURCE]: {
        name: "Supply Point |||| Supply Points",
        fields: {
          remote_id: "Supply Point",
          premise_identifier: "Premise ID",
          access_premise: "Premise Access",
          access: "Access",
          access_meter: "Access Meter",
          prev_reading_value: "Previous Reading Value",
          customer_name: "Customer Name"
        }
      },
      [config.METER_RESOURCE]: {
        name: "Meter |||| Meters",
        fields: {
          meter_identifier: "Meter ID",
          remote_id: "Meter Reference",
          manufacturer: "Manufacturer"
        }
      },
      [config.MAP_RESOURCE]: {
        fields: {
          id: "Supply Point Id",
          premise_identifier: "Premise ID",
          service_number: "Service Number",
          meter_identifier: "Meter Identifier",
          supply_point_number: "Supply Point Number",
          address: "Address",
          previous_reading_date: "Previous Reading Date",
          consumption_type: "Consumption Type",
          full_customer_name: "Full Customer Name"
        }
      },
      [config.ANOMALIES_RESOURCE]: {
        name: "Anomalies |||| Anomaly",
        fields: {
          type: "Anomaly Type",
          code: "Code",
          is_active: "Active",
          is_default: "Default",
          is_skip_reading: "Skip Reading",
          label_lang_one: "English",
          label_lang_two: "French",
          description: "Description"
        }
      },
      [config.REGISTERED_METERS_RESOURCE]: {
        name: "New Meters |||| New Meters",
        type_name: "New Meter",
        fields: {
          itinerary_cycle: "Itinerary / Cycle",
          meter_ref: "Meter Reference Number",
          next_meter_ref: "Next Meter Reference Number",
          prev_meter_ref: "Previous Meter Reference Number",
          itinerary_id: "Itinerary",
          remote_id: "Itinerary",
          dials: "Meter Dials",
          reading_value: "Reading Value",
          reading_type_id: "Reading Type",
          meter_type_id: "Meter Type",
          reading_date: "Reading Date",
          reported_by: "Registered By",
          lng: "GPS X",
          lat: "GPS Y",
          gps_coordinates: "GPS Lat/Long",
          gps_precision_mode: "GPS Precision mode",
          net_type: "Network Type",
          conn_mode: "Connection Mode",
          has_barcode: "Is Barcode attached",
          location_description: "Location Description",
          registration_date: "Registration Date",
          images: "Attached Images",
          client_activity_id: "Client Activity",
          agent_id: "Field Agent",
          center_id: "Reading Center",
          reading_anomaly_id: "Reading Anomaly",
          picture_anomaly_id: "Picture Anomaly",
          cycle: "Cycle",
          fieldAgent: "Field Agent",
          customerActivity: "Customer Activity",
          reading_center_id: "Reading Center"
        }
      },
      [config.SETTINGS_RESOURCE]: {
        name: "Server |||| Server",
        fields: {
          updated_by: "Updated By",
          updated_at: "Updated At",
          created_by: "Created By",
          name: "Name",
          value: "Value",
          description: "Description"
        }
      },
      [config.DEVICES_SETTINGS_RESOURCE]: {
        name: "Mobile App |||| Mobile App",
        fields: {
          updated_by_id: "Updated By",
          updated_at: "Updated At",
          created_by: "Created By",
          name: "Name",
          value: "Value",
          description: "Description"
        }
      },
      [config.WORK_HOURS_RESOURCE]: {
        name: "Work Hours |||| Agents Work Hours",
        fields: {
          day: "Day of Week",
          start: "Start Time",
          end: "End Time",
          is_access: "Is Working Day?",
          updated_at: "Updated At",
          updated_by: "Updated By"
        }
      },
      [config.METER_TYPE_RESOURCE]: {
        name: "Meter Type",
        fields: {
          name: "Name"
        }
      },
      [config.READING_TYPE_RESOURCE]: {
        name: "Reading Type",
        fields: {
          name: "Name"
        }
      },
      [config.DISTRIBUTION_TASK_RESOURCE]: {
        type_name: "Distribution",
        name: "Distribution Task |||| Distribution Tasks",
        fields: {
          status: "Status",
          sequence_orig: "Original Sequence",
          sequence_new: "New Sequence",
          date_visited: "Date",
          reading_value: "Value",
          meter_id: "Meter ID",
          input_type: "Type",
          gps_precision_mode: "GPS Precision mode",
          net_type: "Network Type",
          conn_mode: "Connection Mode",
          comment: "Comment",
          reading_center_id: "Reading Center"
        }
      },
      [config.READINGTASK_RESOURCE]: {
        name: "Reading Task |||| Reading Tasks",
        type_name: "Reading",
        fields: {
          status: "Status",
          sequence_orig: "Original Sequence",
          sequence_new: "New Sequence",
          date_visited: "Date",
          reading_value: "Value",
          meter_identifier: "Meter ID",
          supply_point_remote_id: "Supply Point",
          comment: "Comment",
          gps_precision_mode: "GPS Precision mode",
          net_type: "Network Type",
          conn_mode: "Connection Mode",
          reading_center_id: "Reading Center"
        }
      },
      [config.CLIENT_ACTIVITY_RESOURCE]: {
        name: "Client Activity |||| Client Activity",
        fields: {
          code: "Code",
          is_active: "Is Active",
          is_default: "Is Default",
          label_lang_one: config.LOCALE_TYPES.en.name,
          label_lang_two: config.LOCALE_TYPES.fr.name,
          description: "Description"
        }
      },
      [config.QUALITY_RULES_RESOURCE]: {
        name: "Quality Rules  |||| Quality Rules",
        fields: {
          rule_code: "Code",
          description: "Description",
          is_active: "Active?",
          short_description: "Short Description"
        }
      }
    },
    mra: {
      constants: {
        itinerary_status: {
          unassigned: "Unassigned",
          mobile_reading_ready: "Mobile Reading Ready",
          mobile_reading_in_progress: "Mobile Reading in Progress",
          mobile_reading_completed: "Mobile Reading Completed",
          mobile_reading_fully_received: "Mobile Reading Synchronized",
          paper_reading_ready: "Paper Reading Ready",
          paper_reading_fagent_selected: "Paper Reading Field Agent Selected",
          return_for_estimation: "Returned for Estimation",
          paper_reading_data_being_captured: "Data Capture in Progress",
          paper_reading_fully_entered: "Data Capture Completed"
        },
        distribution_status: {
          unassigned: "Unassigned",
          mobile_distribution_ready: "Mobile Reading Ready",
          mobile_distribution_in_progress: "Mobile Reading in Progress",
          mobile_distribution_completed: "Mobile Reading Completed",
          mobile_distribution_fully_received: "Mobile Reading Synchronized"
        },
        gps_precision_mode: {
          high: "High",
          low: "Low",
          cached: "Cached",
          unknown: "Unknown",
          unrecognised: "-"
        },
        connection_mode: {
          online: "Online",
          offline: "Offline",
          degraded: "Degraded",
          unrecognised: "-"
        },
        control_correction_status: {
          unassigned: "Unassigned",
          ready: "Ready",
          mobile_reading_ready: "Mobile Reading Ready",
          in_progress: "in Progress",
          completed: "Completed",
          fully_received: "Synchronized"
        },
        device_status: {
          registered: "Registered",
          expired: "Expired",
          ok: "OK",
          lost: "Lost"
        },
        ru_types: {
          internal: "Internal",
          external: "External"
        },
        method_types: {
          mobile: "Mobile app",
          paper: "Paper reading"
        },
        input_types: {
          manual: "Manual",
          optical: "Optical"
        },
        view_modes: {
          list: "List",
          grid: "Grid"
        },
        days: {
          Monday: "Monday",
          Tuesday: "Tuesday",
          Wednesday: "Wednesday",
          Thursday: "Thursday",
          Friday: "Friday",
          Saturday: "Saturday",
          Sunday: "Sunday"
        }
      },
      unqualified: "Not qualified",
      unassign: "Unassign",
      assign: "Assign",
      roles: {
        selectRole: "Role",
        selectRolesNoPermission: "Roles",
        selectRoles: "Roles",
        HQM_AD: "HQ Administrator",
        HQM_SU: "HQ Supervisor",
        HQM_RU: "HQ Report User",
        HQM_PV: "HQ Picture Viewer",
        HQM_PC: "HQ Picture Controller",
        HQM_SOA: "HQ Service Order Agent",
        HQM_RSU: "HQ Region Supervisor",
        RUM_SU: "RU Unit Supervisor",
        RUM_FA: "RU Field Agent",
        RUM_RU: "RU Report User",
        RCM_AG: "RC Agent",
        RCM_RU: "RC Report User",
        RCM_SU: "RC Supervisor",
        RCM_IT: "RC IT Support",
        accordingToRole: "Selected based on your Role"
      },
      general: {
        readonly_details:
          "These Details are read-only, due to insufficient Permissions.",
        loading: "Loading",
        noImages: "No Images",
        not_available: "Not available",
        map: "Map",
        back: "Back"
      },
      assign_itinerary: {
        confirm_req_title: "Itinerary Confirmation Required",
        assign_req_title: "Assign Itinerary"
      },
      messages: {
        mark_as_read: "Mark as read",
        read_at: "Read at %{read_at}.",
        title: "Messages"
      },
      upload: {
        image: {
          title: "Upload Images",
          message: "Select the Images and click confirm to upload them."
        },
        routesheet: {
          title: "Upload Routesheet for Itinerary %{id}",
          message: "Select the Scans and click confirm to upload them.",
          success: "Successfully uploaded Routesheet",
          fail:
            "Couldn't upload the Routesheet. Check the connection to the Image Server!"
        },
        invalidFile: "There were no proper Files to upload.",
        unknownUploadType: "The type of the upload is unknown."
      },
      dashboard: {
        title: "Dashboard",
        text_welcome:
          " Welcome to the Meter Reading Application Management Backend!",
        text_hello: "Hello ",
        text_active_cycle: "The current active cycle is ",
        text_rc: "Your Reading Center is ",
        text_rc_disabled_message:
          ", which is deactivated! Therefore you cannot perform any actions.",
        contact_supervisor:
          "If you believe that this is an error, please contact your Supervisor.",
        text_rcs: "You are assigned to the following Reading Centers: ",
        text_rcs_disabled_message:
          ", please note that you are also assigned to these deactivated Reading Centers: ",
        text_global_context:
          "You are able to access any Reading Centers because of your ",
        global_context: "Global Context.",
        text_rcs_diabled_message_global_context:
          "Please note that these Reading Centers are deactivated: "
      },
      reports: {
        title: "Reports",
        itinerary: {
          title: "Reading Itinerary Report"
        },
        supply_points: {
          title: "Supply Point Report"
        },
        distribution_gps_comparison: {
          title: "Reading/Distribution index/GPS Comparison Report"
        },
        distribution_index_comparison: {
          title: "Distribution Index Comparison Report"
        },
        reading_gps_comparison: {
          title: "Current Cycle/Previous Cycle Index/GPS Comparison Report"
        },
        device: {
          title: "Device Report"
        },
        user_connection: {
          title: "User Connection Report"
        },
        new_meters: {
          title: "New Meters Report"
        },
        picture_control_rc: {
          title: "Picture Control Report RC"
        },
        picture_control_ru: {
          title: "Picture Control Report RU"
        },
        anomaly_correction: {
          title: "Anomaly Correction Report"
        },
        control_reading: {
          title: "Control Reading Report"
        },
        import_recon: {
          title: "Reconciliation Report"
        },
        zero_consumption: {
          title: "Meter Reading Zero Consumption"
        }
      },
      devices: {
        confirm: "Confirm",
        confirm_title: "Confirm Device",
        confirm_text:
          "Please enter the confirmation code displayed on the device.",
        code_setting: "Device Registration Token",
        confirm_activate_title: "Reactivate Device %{id}"
      },
      devices_overview: {
        latest_info: "Last Reported Information",
        details: "Details"
      },
      quality_runs: {
        actual_value: "Current Value",
        passed: "Status",
        no_checks: "No quality checks have run yet.",
        last_run: "Last run",
        overall: "Overall status",
        check_failed: "Failed",
        check_passed: "Passed",
        run_at: "Ran at"
      },
      users: {
        activate: "ACTIVATE",
        confirm_activate_title: "Reactivate User %{id}"
      },
      notifications: {
        api_error: "api server error",
        no_map_data: "No data to display",
        username_taken: "Username is already in use",
        password_too_long: "Password is too long",
        password_too_short: "Password is too short",
        password_unsafe: "Password does not meet security guidelines. Please increase password complexity",
        cannot_reopen: {
          reading:
            "This itinerary could not be reopened as it is not marked as completed or already exported.",
          distribution:
            "This itinerary could not be reopened as it is not marked as fully received."
        },
        context_switch_success: "Successfully changed RC context",
        reading_center: "Reading Center",
        global_context: "Global Context",
        error: "Unspecified Error.",
        access_denied: "Access Denied",
        user_exists: "User already exists. Choose another username.",
        force_qc: "Quality Check performed.",
        password_invalid:
          "The password is invalid. Most likely it's too short.",
        invalid_confirmation_code: "The confirmation Code is invalid. Retry!",
        expired_confirmation_code: "The confirmation Code is expired.",
        usernotassignedtomember: "User not assigned to this reading center.",
        notassignedtocenter: "User not assigned to device.",
        return_for_estimation_error:
          "Return for estimation is unsuccessful, please connect to admin for log details",
        force_qc_error:
          'The operation "FORCED EXPORT" was only partially successful. Kindly contact administrator for log details',
        generation_error:
          "Generation of itinerary is unsuccessful, kindly connect to admin for log details.",
        no_active_rc_one_disabled:
          "Your RC is deactivated, therefore you cannot perform any action.",
        no_active_rc_all_disabled:
          "All of Your RCs are deactivated, therefore you cannot perform any action.",
        rcs_disabled:
          "Some of your RCs are deactivated, you cannot perform any actions in these RC."
      },
      confirm: {
        ok: "Confirm",
        confirm: "Confirm",
        info: "Completion confirmed"
      },
      profile: {
        title: "User Profile",
        reset: "Enter New Password",
        confirm: "Confirm New Password"
      },
      gps_precision_mode: {
        high: "High",
        low: "Low",
        cached: "Cached",
        unknown: "Unknown",
        unrecognised: "Undefined"
      },
      connection_mode: {
        online: "Online",
        offline: "Offline",
        degraded: "Degraded",
        unrecognised: "-"
      },
      reset: {
        title: "Reset Password",
        match: "The entered passwords must match.",
        reset: "Enter New Password",
        confirm: "Confirm New Password",
        button: "Reset Password",
        msg: "You have to reset your password.",
        username: "Username must not be empty",
        first_name: "First name must not be empty",
        last_name: "Last name must not be empty",
        language: "Language must not be empty",
        password: "Give in the password",
        activate: "Activate User",
        reading_centers: "You have to assign a RC",
        reading_centers_valid: "You have to assign a valid RC",
        reading_unit_id: "You have to assign a RU",
        roles: "You have to assign a Role",
        rolesNoRU: "You have to assign a RU and RC for this Role"
      },
      activate: {
        title: "Record Activation",
        activate: "Activate",
        confirm_text: "Do you want to Reactivate this Item?"
      },
      itinerary_assign: {
        title: "Itineraries Assignment",
        assign_button: "Assign",
        reassign_button: "Reassign",
        print_button: "Print",
        anomaly_print_button: "Print Anomalies",
        method_not_null: "You must select a reading mode.",
        reader_not_null: "You must select a field agent.",
        capturer_not_null: "You must select a data capture agent.",
        warn_reset:
          "Changing the reading mode or assigned agents of an itinerary will reset all readings!",
        unassign_confirm: "Unassign itinerary %{id}"
      },
      distribution_itinerary_assign: {
        title: "Distribution Itineraries Assignment",
        assign_button: "Assign",
        reassign_button: "Reassign",
        print_button: "Print"
      },
      itinerary_overview: {
        title: "Itineraries Overview",
        reset: "Reset",
        confirm_reset: "Reset itinerary %{id}",
        forceexport: "Force Export",
        confirm_forceexport: "Force Export of itinerary %{id}",
        force_qc: "Quality Check",
        confirm_force_qc: "Force Quality Check of itinerary %{id}",
        reopen: "Reopen",
        newmeter: "Register new Meter",
        confirm_reopen: "Reopen itinerary %{id}",
        confirm_return: "Return itinerary %{id}",
        confirm_return_bulk: "Return Itineraries %{id}",
        confirm_capture: "Complete Data Capture for itinerary %{id}",
        confirm_capture_button: "Mark as complete",
        return: "Return for Estimation",
        return_selected: "Return selected Itineraries",
        generate_control_reading: "Generate Control Reading",
        generate_anomaly_correction: "Generate Anomaly Correction",
        readings: "Readings",
        resequence_button: "Resequence",
        resequence: "Resequence Itinerary",
        confirm_resequence: "Resequence Itinerary %{id}",
        capture_button: "DataCapture",
        capture: "Data Capture",
        itinerary_data: "Itinerary Data",
        quality_checks: "Quality Checks",
        summary: "Summary",
        reading_summary: "Reading Summary",
        distribution_summary: "Distribution Summary",
        correction_summary: "Correction Summary",
        control_summary: "Control Summary",
        supply_points_map: "Supply Points Map",
        details: "Details",
        images: "Images",
        route_sheet: "Route Sheet",
        current_active_cycle: "Current active Cycle",
        image_upload_button: "Upload attachment",
        client_activity_invalid: "Please change the activity type.",
        itinerary_code: "Itinerary Code",
        confirm_as_complete: "Confirm as complete"
      },
      navigation: {
        distribution_itineraries: "Distribution Itineraries",
        reading_itineraries: "Reading Itineraries",
        anomaly_correction_itineraries: "Anomaly Correction Itineraries",
        control_reading_itineraries: "Control Reading Itineraries",
        overview: "Overview",
        assignment: "Assign",
        register: "Register",
        administration: "Administration",
        itineraries: "Itineraries",
        anomalies: "Anomalies",
        settings: "Settings",
        add_device: "Add Device",
        add_user: "Add User",
        activate_user: "Reactivate Users",
        documentation: "Documentation",
        documentation_app: "Mobile App",
        documentation_bom: "BOM",
        picture_viewer: "Picture Viewer"
      },
      reading_center: {
        details: "Reading Center Details",
        name: "The reading center name must not be empty",
        remote_id: "The reading center external identifier is needed",
        quality_checks: "Quality Check",
        rule_code: "Rule Code",
        description: "Description",
        threshold: "Threshold",
        confirm_activate_title: "Reactivate Reading Center %{id}"
      },
      reading_unit: {
        name: "The reading unit name must not be empty",
        type: "The reading unit type is needed",
        region_id: "The reading unit region has to be selected"
      },
      anomaly: {
        type: "Type is needed",
        code: "Code is needed",
        picture: "Picture Anomaly",
        reading: "Reading Anomaly",
        control: "PIC Control Anomaly",
        control_reading: "Control Reading Anomaly",
        distribution: "Distribution Anomaly",
        correction: "Correction Anomaly",
        occupancy: "Occupancy Anomaly",
        pictures: "Picture Anomalies",
        readings: "Reading Anomalies",
        controls: "PIC Control Anomalies",
        control_readings: "Control Reading Anomalies",
        distributions: "Distribution Anomalies",
        corrections: "Correction Anomalies",
        occupancys: "Occupancy Anomalies",
        confirm_activate_title: "Reactivate Anomaly %{id}",
        picture_control: "Picture control"
      },
      client_activity: {
        name: "Client Activity",
        confirm_activate_title: "Reactivate Client Activity %{id}"
      },
      register_meter: {
        meter_ref: "Meter reference number needed",
        dials: "Meter Dials needed",
        reading_value: "Meter reading rate needed",
        reading_value_chars: "Number of characters must match the dials value",
        reading_date: "Meter reading date needed",
        lng: "Longitude this reading was taken is needed",
        lat: "Latitude this reading was taken is needed",
        registration_date: "Date when the meter was registered is needed",
        client_activity: "Client Activity is required.",
        itinerary: "itinerary is required.",
        agent_id: "Field Agent is required.",
        reading_anomaly_id: "Reading Anomaly is required.",
        picture_anomaly_id: "Picture Anomaly is required."
      },
      settings: {
        name: "Name needed",
        value: "Value needed",
        TRUE: "True",
        FALSE: "False",
        en: "En",
        fr: "Fr",
        de: "De",
        Mandatory: "Mandatory",
        Optional: "Optional",
        "Do not use": "Do not use",
        SMALL_SIZE: "Small size",
        MED_SIZE: "Medium size",
        LARGE_SIZE: "Large size",
        Strict: "Strict",
        All: "All",
        "Digits only": "Digits only",
        "Any character": "Any character",
        "PEN&PAPER": "Pen & Paper",
        MOBILEAPP: "Mobile App",
        Enforce: "Enforce",
        Warn: "Warn",
        "Do not enforce": "Do not enforce",
        Numeric: "Numeric",
        "All Characters": "All Characters",
        Low: "Low",
        Medium: "Medium",
        High: "High",
        kWh: "kWh",
        "m³": "m³",
        Provider: "Provider",
        Server: "Server",
        GPS: "GPS",
        Off: "Off",
        OFF: "Off",
        FULL: "Full",
        DATA: "Data",
        true: "True",
        false: "False"
      },
      validation: {
        required: "Required Field"
      },
      picture_filter: {
        itinerary_id: "Itinerary",
        supply_point_eid: "Supply point",
        meter_identifier: "Meter ID",
        agent_id: "Field agent",
        client_activity_id: "Client activity",
        namespace: "Itinerary type",
        cycle: "Cycle",
        year: "Year",
        quality: "Picture quality"
      },
      generate: {
        control: "Generate control reading itinerary ?",
        anomaly_correction: "Generate anomaly correction itinerary ?",
        agent: "Field agent to assign"
      },
      task_types: {
        reading: "Reading",
        distribution: "Distribution",
        registered_meter: "New meter",
        control: "Control",
        anomaly_correction: "Correction"
      },
      settings_descriptions: {
        CURRENT_CYCLE:
          "This setting defines the current cycle of the meter reading for the utility company",
        PRIMARY_LANGUAGE: "Primary language supported by the application",
        SECOND_SUPPORTED_LANGUAGE:
          "Secondary language supported by the application",
        LIMIT_READING_UNIT_TO_READING_CENTRE:
          "Setting determines if a reading unit can only be assigned to one single reading center",
        CYCLE_VIEW_LEVEL: "Limits cycle view to the current one",
        SCAN_INTINERARY_SHEET:
          "Setting determines if the RC agents MUST scan Itinerary Sheets during data capture",
        MOBILE_APP_PROVISION_EXPIRATION_TIME:
          "Defines the maximum time (in minutes) before a Device Confirmation Code generated by the server expires. If not used within this interval, the server shall reject the code and the user must redo the process",
        BACK_OFFICE_PASSWORD_LENGTH:
          "Defines the password length for back office user passwords",
        FIELD_AGENT_PASSWORD_LENGTH:
          "Defines the password length for mobile field agent passwords",
        FIELD_AGENT_PASSWORD:
          "Defines the type of characters allowed as password for Mobile App users (field agents.)",
        DEFAULT_METER_READING_MODE:
          "Defines the most common mode of operation of the utility company when taking meter readings. The application assigns per default this mode of reading to new itineraries. It can however, be changed by a reading center Supervisor",
        MOBILE_APP_REGISTRATION_KEY:
          "Provisioning code for devices connecting to this system for the first time. Must be a 5 digit value",
        ACCEPTED_READINGS_MIN:
          "Accepted min reading range based on estimated reading value in percent (%). A value of e.g. 85 means accepted min value is 85% of estimated min",
        ACCEPTED_READINGS_MAX:
          "Accepted min reading range based on estimated reading value in percent (%). A value of e.g. 185 means accepted max value is 185% of estimated max",
        IMAGE_SERVER_URL:
          "HTTP URL that points to the  BASE URL of the image server",
        IMAGE_SERVER_USERNAME:
          "Username to authorize the BOM application at the image server",
        IMAGE_SERVER_PASSWORD:
          "Password to authorize the BOM application at the image server",
        REPORTING_SERVER_URL:
          "Host under which the reporting server is running. Must be a fully qualified HTTP or HTTPS address",
        REPORTING_SERVER_USERNAME:
          "Username to authorize the BOM application at the reporting server",
        REPORTING_SERVER_PASSWORD:
          "Password to authorize the BOM application at the reporting server",
        RMS_URL: "Service indexing and serving resources to BOM",
        DATAX_URL: "Data Exchange Service Host",
        JANITOR_TTL_READING_ITINERARY:
          "Months after which to remove reading itinerary data",
        JANITOR_TTL_DISTRIBUTION_ITINERARY:
          "Months after which to remove distribution itinerary data",
        JANITOR_TTL_NEW_REGISTERED_METER:
          "Months after which to remove new registered meter data",
        JANITOR_TTL_QUALITY_RUN:
          "Months after which to remove quality run data",
        IMAGE_SERVER_URL_SECONDARY:
          "HTTP URL that points to the  BASE URL of thesecondary image server",
        JANITOR_TTL_ANOMALY_CORRECTION_ITINERARY:
          "Months after which to remove Anomaly Correction itinerary data",
        JANITOR_TTL_CONTROL_ITINERARY:
          "Months after which to remove Control itinerary data",
        READING_ITINERARY_ENABLE_AUTOASSIGNMENT:
          "Enable Autoassignment to assign a new itinerary to the same agent who read the previous itinerary",
        ANOMALY_CORRECTION_ITINERARY_ENABLE_AUTOASSIGNMENT:
          "Enable Autoassignment to assign a new itinerary to the same agent who read the previous itinerary",
        CONTROL_ITINERARY_ENABLE_AUTOASSIGNMENT:
          "Enable Autoassignment to assign a new itinerary to the same agent who read the previous itinerary",
        DISTRIBUTION_ITINERARY_ENABLE_AUTOASSIGNMENT:
          "Enable Autoassignment to assign a new itinerary to the same agent who read the previous itinerary",
        READING_ITINERARY_REQUIRE_AUTOASSIGNMENT_CONFIRMATION:
          "Requires the confirmation of a system autoassignment before the itinerary will be available on the auto assigned agents' device",
        CONTROL_ITINERARY_REQUIRE_AUTOASSIGNMENT_CONFIRMATION:
          "Requires the confirmation of a system autoassignment before the itinerary will be available on the auto assigned agents' device",
        ANOMALY_CORRECTION_ITINERARY_REQUIRE_AUTOASSIGNMENT_CONFIRMATION:
          "Requires the confirmation of a system autoassignment before the itinerary will be available on the auto assigned agents' device",
        DISTRIBUTION_ITINERARY_REQUIRE_AUTOASSIGNMENT_CONFIRMATION:
          "Requires the confirmation of a system autoassignment before the itinerary will be available on the auto assigned agents' device",
        APP_MINIMUM_SUPPORTED_VERSION:
          "Only allow mobile app running at least this version to connect to the server",
        REPORTS_ACCEPTED_GPS_DISTANCE:
          "Maximum distance (in meters) allowed for a reading to be taken in respect to the GPS value of the supply point (Reports only)",
        CURRENT_CYCLE_YEAR: "The current cycle year",
        DATAX_EXPORT_LOCK_TO_CURRENT_CYCLE:
          "Set to true if only reading itinerary results for the current cycle are to be exported to UMA",
        ANOMALY_CORRECTION_ITINERARY_REQUIRE_EXPORT_CONFIRMATION:
          "Requires Confirmation of export of Anomaly correction Itinerary",
        DISTRIBUTION_ITINERARY_REQUIRE_EXPORT_CONFIRMATION:
          "Requires Confirmation of export of Distribution Itinerary",
        READING_ITINERARY_REQUIRE_EXPORT_CONFIRMATION:
          "Requires Confirmation of export of Reading Itinerary",
        CONTROL_READING_ITINERARY_REQUIRE_EXPORT_CONFIRMATION:
          "Requires Confirmation of export of Control Itinerary",
        ACTIVE_MAP_SOURCE: "Set Active Map Source",
        MAP_SOURCE_ARCGIS_URL: "Set ARCGIS URL",
        MAP_SOURCE_OSM_URL: "Set OSM URL"
      },
      settings_short_descriptions: {
        CURRENT_CYCLE: "Current Cycle",
        PRIMARY_LANGUAGE: "Primary language",
        SECOND_SUPPORTED_LANGUAGE: "Secondary language",
        LIMIT_READING_UNIT_TO_READING_CENTRE:
          "Limit Reading Units to a single Reading Center",
        CYCLE_VIEW_LEVEL: "Limit view only to current cycle",
        SCAN_INTINERARY_SHEET: "Require a scanned itinerary sheet",
        MOBILE_APP_PROVISION_EXPIRATION_TIME:
          "Mobile App Provisioning Time (mins)",
        BACK_OFFICE_PASSWORD_LENGTH: "Password Length - Back-office Users",
        FIELD_AGENT_PASSWORD_LENGTH: "Password Length - Mobile App Users",
        FIELD_AGENT_PASSWORD: "Password Allowed Characters - Mobile App Users",
        DEFAULT_METER_READING_MODE: "Default Meter Reading Mode",
        MOBILE_APP_REGISTRATION_KEY: "Device provisioning code",
        ACCEPTED_READINGS_MIN: "Lower Limit of Meter Reading (% and < 100)",
        ACCEPTED_READINGS_MAX: "Upper Limit of Meter Reading (%) and > 100)",
        IMAGE_SERVER_URL: "Image Server URL",
        IMAGE_SERVER_USERNAME: "Image Server Username",
        IMAGE_SERVER_PASSWORD: "Image Server Password",
        REPORTING_SERVER_URL: "Reporting Server URL",
        REPORTING_SERVER_USERNAME: "Reporting Server Username",
        REPORTING_SERVER_PASSWORD: "Reporting Server Password",
        RMS_URL: "Resource Management Service URL",
        DATAX_URL: "Data Exchange Service URL",
        JANITOR_TTL_READING_ITINERARY: "Reading Itinerary Cleanup Threshold",
        JANITOR_TTL_DISTRIBUTION_ITINERARY:
          "Distribution Itinerary Cleanup Threshold",
        JANITOR_TTL_NEW_REGISTERED_METER: "Registered Meter Cleanup Threshold",
        JANITOR_TTL_QUALITY_RUN: "Quality Run Cleanup Threshold",
        IMAGE_SERVER_URL_SECONDARY: "Secondary Image Server URL",
        JANITOR_TTL_ANOMALY_CORRECTION_ITINERARY:
          "Anomaly Correction Itinerary Cleanup Threshold",
        JANITOR_TTL_CONTROL_ITINERARY:
          "Control Reading Itinerary Cleanup Threshold",
        READING_ITINERARY_ENABLE_AUTOASSIGNMENT:
          "Enable Autoassignment of Reading Itineraries",
        ANOMALY_CORRECTION_ITINERARY_ENABLE_AUTOASSIGNMENT:
          "Enable Autoassignment of Anomlay Correction Itineraries",
        CONTROL_ITINERARY_ENABLE_AUTOASSIGNMENT:
          "Enable Autoassignment of Control Reading Itineraries",
        DISTRIBUTION_ITINERARY_ENABLE_AUTOASSIGNMENT:
          "Enable Autoassignment of Distribution Itineraries",
        READING_ITINERARY_REQUIRE_AUTOASSIGNMENT_CONFIRMATION:
          "Require Confirmation of Autoassignment of Reading Itineraries",
        CONTROL_ITINERARY_REQUIRE_AUTOASSIGNMENT_CONFIRMATION:
          "Require Confirmation of Autoassignment of Control Itineraries",
        ANOMALY_CORRECTION_ITINERARY_REQUIRE_AUTOASSIGNMENT_CONFIRMATION:
          "Require Confirmation of Autoassignment of Anomaly Correction Itineraries",
        DISTRIBUTION_ITINERARY_REQUIRE_AUTOASSIGNMENT_CONFIRMATION:
          "Require Confirmation of Autoassignment of Distribution Itineraries",
        APP_MINIMUM_SUPPORTED_VERSION: "Minimum Mobile App Version",
        REPORTS_ACCEPTED_GPS_DISTANCE:
          "GPS Range Acceptance Distance (Reports)",
        CURRENT_CYCLE_YEAR: "Current Year",
        DATAX_EXPORT_LOCK_TO_CURRENT_CYCLE: "Lock Export to current cycle",
        ANOMALY_CORRECTION_ITINERARY_REQUIRE_EXPORT_CONFIRMATION:
          "Require Confirmation of export of Anomaly correction Itinerary",
        DISTRIBUTION_ITINERARY_REQUIRE_EXPORT_CONFIRMATION:
          "Require Confirmation of export of Distribution Itinerary",
        READING_ITINERARY_REQUIRE_EXPORT_CONFIRMATION:
          "Require Confirmation of export of Reading Itinerary",
        CONTROL_READING_ITINERARY_REQUIRE_EXPORT_CONFIRMATION:
          "Require Confirmation of export of Control Itinerary",
        ACTIVE_MAP_SOURCE: "MAP Source",
        MAP_SOURCE_ARCGIS_URL: "ARCGIS URL",
        MAP_SOURCE_OSM_URL: "OSM URL"
      },
      mobile_settings_descriptions: {
        GPS_READING_SUCCESS:
          "This setting defines whether the Mobile App shall force  the field agent to record a GPS position for each SUCCESSFUL reading",
        GPS_READING_FAILED:
          "This setting defines whether the Mobile App shall force  the field agent to record a GPS position for each FAILED reading",
        GPS_METER_REGISTRATION:
          "This setting defines whether the Mobile App shall force  the field agent to record a GPS position for each newly registered meter",
        PICTURE_READING_SUCCESS:
          "This setting defines whether the Mobile App shall force  the field agent to take pictures for a GPS position for each SUCCESSFUL reading",
        PICTURE_READING_FAILED:
          "This setting defines whether the Mobile App shall force  the field agent to take pictures for a GPS position for each FAILED reading",
        PICTURE_METER_REGISTRATION:
          "This setting defines whether the Mobile App shall force  the field agent to take pictures for a GPS position for each newly recorded meter",
        READ_METER_NUMBER_BARCODE:
          "This setting determines whether the field agent using the Mobile App shall read the meter number via a barcode or QR-code",
        METER_READING_OPTICAL_SCANNER:
          "This setting determines whether the field agent using the Mobile App shall take the meter reading using the optical OCR scanner",
        VICINITY_CHECK:
          "This setting defines the maximum deviation of in meters (m) between the currently recorded GPS position and the GPS position stored on the server",
        VICINITY_RANGE:
          "Setting determines if the Mobile App compares the GPS position to that of the known meter position before saving meter readings (only if coordinates are available)",
        EXPECTED_READING_VALUE:
          "This setting determines if the Mobile App shall compare the meter reading recorded by the field agent with the expected value estimated",
        METER_REGISTRATION_NUMBER_KEYPAD_TYPE:
          "This setting defines the keypad type that the Mobile App shall display to the field agent when he is recording a new meter",
        PICTURE_QUALITY:
          "This setting defines the image quality that shall be used by the Mobile App. The higher the quality the larger the image sizes (data) and the higher their transmission and storage costs",
        NUMBER_OF_PICTURES_PER_INCIDENT:
          "This setting defines the maximum number of images that can be taken per incident",
        IMAGE_SERVER_SFTP_HOST: "Image server SFTP host address",
        IMAGE_SERVER_SFTP_PORT: "Image server SFTP port",
        IMAGE_SERVER_SFTP_PUBLICKEY:
          "Public key for devices to validate the host when connecting to image server (takes precedence over password) - base64 encoded",
        IMAGE_SERVER_SFTP_KEY:
          "Shared access key for devices to connect to image server (takes precedence over password) - base64 encoded",
        IMAGE_SERVER_SFTP_USER:
          "Shared SFTP username for devices to connect to image server",
        IMAGE_SERVER_SFTP_PASSWORD:
          "Shared SFTP password for devices to connect to image server",
        IMAGE_SERVER_SFTP_PATH:
          "This setting defines the directory location on the FTP server where data shall be stored",
        LOCK_AGENTS_ASSIGNED_DEVICES:
          "This setting (when set to TRUE) determines if the application shall restrict agents to use only the devices assigned to them",
        UNITS: "Unit of consumption",
        TIME_SYNC_MODE:
          "This setting defines how the Mobile App shall synchronize its internal clock to that of the server. It provides the different options (reliable clocks) possible",
        PRINT_READING_RECEIPT:
          "This setting defines if the Mobile App shall allow field agents to print receipts",
        GPS_DISTRIBUTION_SUCCESS:
          "Setting determines if the field agent using the Mobile App takes GPS position for each successful distribution task",
        GPS_DISTRIBUTION_FAILED:
          "Setting determines if the field agent using the Mobile App takes GPS position for each failed distribution task",
        PICTURE_DISTRIBUTION_SUCCESS:
          "Setting determines if the field agent using the Mobile App is required to take a picture for each successful distribution task",
        PICTURE_DISTRIBUTION_FAILED:
          "Setting determines if the field agent using the Mobile App is required to take a picture for each failed distribution task",
        MANUAL_IMAGE_SYNC_CONFIRM_PIN:
          "Confirmation code to be entered by user on device in order to authorize marking all images on the device as synced (and delete them on the device)",
        BACKGROUND_SYNC_MODE:
          "This setting determines whether the application shall synchronize the agents collected data (and pictures) with the server in the background",
        BACKGROUND_SYNC_INTERVAL:
          "This setting determines the time interval in minutes during which a background synchronization shall be performed. If set to e.g. 60, the mobile app will synchronize every 60 minutes",
        GPS_PERSIST_PERIOD:
          "This setting determines the time (in minutes) for which the current GPS position shall be stored for reuse. During this time, the stored position will be used in all readings in the event that the device cannot acquire a valid GPS position",
        GPS_TIMEOUT:
          "This setting determines the maximum time the device has to acquire a GPS position before aborting",
        CAMSIZE_MTR_READING_OK:
          "The default Camera crop sizes for pictures taken in this context",
        CAMSIZE_MTR_READING_NOK:
          "The default Camera crop sizes for pictures taken in this context",
        CAMSIZE_DIST_READING_OK:
          "The default Camera crop sizes for pictures taken in this context",
        CAMSIZE_DIST_READING_NOK:
          "The default Camera crop sizes for pictures taken in this context",
        CAMSIZE_MTR2_READING_OK:
          "The default Camera crop sizes for pictures taken in this context",
        CAMSIZE_MTR2_READING_NOK:
          "The default Camera crop sizes for pictures taken in this context",
        CAMSIZE_CTRL_READING_OK:
          "The default Camera crop sizes for pictures taken in this context",
        CAMSIZE_CTRL_READING_NOK:
          "The default Camera crop sizes for pictures taken in this context",
        CAMSIZE_NEWMTR:
          "The default Camera crop sizes for pictures taken in this context",
        APP_NUM_CYCLES_KEEP_PIC:
          "Defines the periode of time (past cycles) for which images are removed from the app",
        ALLOW_DATA_SYNC_WITH_PICTURES:
          "Enable button to allow app user to choose to sync with pictures",
        ALLOW_DATA_SYNC:
          "Enable button to allow app user to choose to sync data",
        SYNC_REMINDER:
          "Defines the reading count after which app should altert pop up"
      },
      mobile_settings_short_descriptions: {
        GPS_READING_SUCCESS: "Take GPS for Successful Reading",
        GPS_READING_FAILED: "Take GPS for Failed Reading",
        GPS_METER_REGISTRATION: "Take GPS for new meters",
        PICTURE_READING_SUCCESS: "Take Picture for Successful Reading",
        PICTURE_READING_FAILED: "Take Picture for Failed Reading",
        PICTURE_METER_REGISTRATION: "Take Picture for New Meters",
        READ_METER_NUMBER_BARCODE: "Use Barcode Reader",
        METER_READING_OPTICAL_SCANNER: "Use Optical Scanner",
        VICINITY_CHECK: "Validate GPS position range",
        VICINITY_RANGE: "Max GPS Deviation (m)",
        EXPECTED_READING_VALUE: "Validate Reading",
        METER_REGISTRATION_NUMBER_KEYPAD_TYPE: "Keypad - New Meter -> Meter #",
        PICTURE_QUALITY: "Picture quality",
        NUMBER_OF_PICTURES_PER_INCIDENT: "Max Num Pictures",
        IMAGE_SERVER_SFTP_HOST: "SFTP Hostname",
        IMAGE_SERVER_SFTP_PORT: "SFTP Port",
        IMAGE_SERVER_SFTP_PUBLICKEY: "SFTP Server Public Key",
        IMAGE_SERVER_SFTP_KEY: "SFTP Server Private Key",
        IMAGE_SERVER_SFTP_USER: "SFTP Server Username",
        IMAGE_SERVER_SFTP_PASSWORD: "SFTP Server Password",
        IMAGE_SERVER_SFTP_PATH: "SFTP Server Directory",
        LOCK_AGENTS_ASSIGNED_DEVICES: "Restrict agents to devices",
        UNITS: "Units of Consumption",
        TIME_SYNC_MODE: "Device Time Synch Mode",
        PRINT_READING_RECEIPT: "Allow Receipt Printing",
        GPS_DISTRIBUTION_SUCCESS: "Take GPS for Successful Distribution",
        GPS_DISTRIBUTION_FAILED: "Take GPS for Failed Distribution",
        PICTURE_DISTRIBUTION_SUCCESS:
          "Take Picture for Successful Distribution",
        PICTURE_DISTRIBUTION_FAILED: "Take Picture for Failed Distribution",
        MANUAL_IMAGE_SYNC_CONFIRM_PIN: "Manual Sync Confirmation CODE",
        BACKGROUND_SYNC_MODE: "Background synchronization",
        BACKGROUND_SYNC_INTERVAL: "Background synchronization interval",
        GPS_PERSIST_PERIOD: "Duration of reuse stored GPS coordinates",
        GPS_TIMEOUT: "GPS Timeout Duration",
        CAMSIZE_MTR_READING_OK:
          "Camsize for Meter Reading Task -> Reading Possible",
        CAMSIZE_MTR_READING_NOK:
          "Camsize for Meter Reading Task -> Reading Not Possible",
        CAMSIZE_DIST_READING_OK:
          "Camsize for Distribution Task -> Distribution Possible",
        CAMSIZE_DIST_READING_NOK:
          "Camsize for Distribution Task -> Distribution Not Possible",
        CAMSIZE_MTR2_READING_OK:
          "Camsize for Anomaly Correction Task -> Correction Possible",
        CAMSIZE_MTR2_READING_NOK:
          "Camsize for Anomaly Correction Task -> Correction Not Possible",
        CAMSIZE_CTRL_READING_OK:
          "Camsize for Control Reading Task -> Reading Possible",
        CAMSIZE_CTRL_READING_NOK:
          "Camsize for Control Reading Task -> Reading Not Possible",
        CAMSIZE_NEWMTR: "Camsize for New Meter Registration",
        APP_NUM_CYCLES_KEEP_PIC: "Image retention period (in cycles)",
        ALLOW_DATA_SYNC_WITH_PICTURES: "Allow sync data with images",
        ALLOW_DATA_SYNC: "Allow sync only data",
        SYNC_REMINDER: "Reminder for app user to sync data"
      }
    }
  },
  fr: {
    "Failed to fetch":
      "Impossible de se connecter à l'API. S'il vous plaît vérifier votre connexion.",
    wip: " (Travaux en Cours)",
    ra: {
      action: {
        delete: "Supprimer",
        activate: "Activer",
        deactivate: "Désactiver",
        edit: "Détails"
      }
    },
    resources: {
      [config.READING_CALENDAR_RESOURCE]: {
        name: "Calendrier de Relève",
        fields: {
          cycle_year: "Année",
          cycle: "Cycle",
          itinerary_code: "ITIN ID",
          rc_code: "RC CODE",
          mrc_name: "RC NAME",
          "scheduled_at.T": "Date de Relève Prévue",
          scheduled: "Date de Relève Prévue"
        }
      },
      [config.TOP_ITINERARY_RESOURCE]: {
        name: "Top X Itinéraires"
      },
      [config.EXPORT_RECON_RESOURCE]: {
        name: "Ètats de rapprochement pour l'exportation",
        fields: {
          year: "Année",
          cycle: "Cycle",
          mra_processed: "Date d'exportation",
          mrc_processed_at: "Date d'exportation",
          uma_processed: "Date de traitement",
          uma_processed_at: "Date de traitement",
          sync_type: "Type de sync",
          reading_center_name: "Nom MRC",
          reading_center_ext_id: "ID MRC",
          itinerary_ext_id: "ID ITIN",
          imported_task_count: "# PL",
          result_count: "# PL",
          is_match: "Statut ITIN",
          is_tasks_match: "# Statut PL"
        }
      },
      [config.IMPORT_RECON_RESOURCE]: {
        name: "Ètats de rapprochement pour l'importation",
        fields: {
          year: "Année",
          cycle: "Cycle",
          mra_processed: "Date d'importation",
          mrc_processed_at: "Date d'importation",
          uma_processed: "Date création",
          uma_processed_at: "Date création",
          sync_type: "Type de sync",
          reading_center_name: "Nom MRC",
          reading_center_ext_id: "ID MRC",
          itinerary_ext_id: "ID ITIN",
          imported_task_count: "# PL",
          result_count: "# PL",
          is_match: "Statut ITIN",
          is_tasks_match: "# Statut PL"
        }
      },
      [config.CONTROL_READING_ITINERARY_RESOURCE]: {
        name:
          "Contrôle Itinéraire de relève |||| Contrôle des itinéraires de relève",
        fields: {
          route: "Route",
          remote_id: "Code",
          cycle: "Cycle",
          cycle_year: "Année",
          description: "Description",
          avg_consumption: "Consommation",
          status: "Statut",
          scheduled_at: "Date",
          task_count: "# Tâches",
          agent_id: "Releveur",
          center_id: "CR",
          result_count: "# Effectuées",
          completed_result_count: "# Effectuées",
          total_anomaly_count: "# Anomalies",
          anomaly_count: "# Anomalies de contrôle de relève",
          picture_anomaly_count: "# Anomalies photo",
          updated_at: "Mis à jour le",
          created_at: "Créé le",
          imported_at: "Importé le",
          is_confirmation_required: "Confirmation requise",
          is_completion_confirmation_required:
            "Confirmation d'achèvement requise"
        }
      },
      [config.ANOMALY_CORRECTION_ITINERARY_RESOURCE]: {
        name:
          "Itinéraire de Correction d'Anomalies |||| Itinéraires de Correction d'Anomalies",
        fields: {
          route: "Route",
          remote_id: "Code",
          cycle: "Cycle",
          cycle_year: "Année",
          description: "Description",
          avg_consumption: "Consommation",
          status: "Statut",
          scheduled_at: "Date",
          task_count: "#",
          agent_id: "Releveur",
          center_id: "CR",
          result_count: "# Effectuées",
          completed_result_count: "# Effectuées",
          total_anomaly_count: "# Anomalies",
          anomaly_count: "# Anomalies de correction",
          picture_anomaly_count: "# Anomalies photo",
          updated_at: "Mis à jour le",
          created_at: "Créé le",
          imported_at: "Importé le",
          is_confirmation_required: "Confirmation requise",
          is_completion_confirmation_required:
            "Confirmation d'achèvement requise"
        }
      },
      [config.CONTROL_READING_TASK_RESOURCE]: {
        name: "Contrôle de relève |||| Contrôle des relèves",
        type_name: "Contrôle de relève"
      },
      [config.ANOMALY_CORRECTION_TASK_RESOURCE]: {
        name: "Correction d'Anomalie |||| Correction d'Anomalies",
        type_name: "Correction"
      },
      [config.RMS_RESOURCE]: {
        name: "Gestionnaire d'Images"
      },
      [config.READINGCENTER_RESOURCE]: {
        name: "Centre de Relève |||| Centre de Relèves",
        fields: {
          id: "ID",
          name: "Nom",
          remote_id: "Code",
          location: "Localisation",
          region_id: "Région",
          reading_units: "Sous-Traitant"
        }
      },
      [config.READINGUNIT_RESOURCE]: {
        name: "Sous-Traitant |||| Sous-Traitants",
        fields: {
          id: "ID",
          name: "Nom",
          type: "Type",
          contact_person: "Contact",
          address: "Siège social",
          ref_number: "Ref Code",
          tax_number: "Numéro fiscale",
          phone: "Téléphone",
          mail: "Email",
          region_id: "Région",
          centers: "Centre de Relèves"
        }
      },
      [config.USER_RESOURCE]: {
        name: "Utilisateur |||| Utilisateurs",
        fields: {
          id: "ID",
          username: "Nom d'utilisateur",
          first_name: "Prénom",
          middle_name: "Deuxième nom",
          last_name: "Nom de famille",
          password: "Mot de passe",
          phone: "Téléphone",
          email: "Email",
          language: "Langue",
          user_reference: "Employé N°",
          is_blocked: "Utilisateur Bloquer",
          blocked_reason: "Raison du blocage",
          reading_centers: "Centres de relèves assignés",
          reading_center: "Centre de relève assigné",
          reset_requested:
            "Réinitialisation du mot de passe de cet utilisateur",
          unit_id: "Sous-Traitants",
          roles: "Rôles",
          rcs: "Centres de relèves",
          deleted_at: "Supprimé",
          "@center_id": "Centre de relève assigné"
        }
      },
      [config.ITINERARY_RESOURCE]: {
        name: "Itinéraire |||| Itinéraires",
        fields: {
          id: "ID",
          route: "Route",
          remote_id: "Code",
          cycle: "Cycle",
          cycle_year: "Année",
          description: "Description",
          avg_consumption: "Consommation",
          status: "Statut",
          scheduled_for: "Date",
          task_count: "#",
          agent_id: "Releveur",
          method: "Mode",
          capturer_id: "Data Imputer",
          center_id: "CR",
          is_printed: "Imprimé",
          is_in_quality: "CQ",
          result_count: "# Relèves effectuées",
          completed_result_count: "# Relèves effectuées",
          total_anomaly_count: "# Anomalies",
          anomaly_count: "# Anomalies de relève",
          picture_anomaly_count: "# Anomalies photo",
          is_exported: "Exporté",
          is_force_exported: "Forcé",
          updated_at: "Mis à jour le",
          created_at: "Créé le",
          imported_at: "Importé le",
          is_confirmation_required: "Confirmation requise",
          is_completion_confirmation_required:
            "Confirmation d'achèvement requise",
          c_money_brutto: "Cons moy. incl. impôt",
          c_money_netto: "Cons moy. excl. impôt",
          c_units: "Unité cons moy",
          n_supply_points: "# PL"
        }
      },
      [config.DISTRIBUTION_ITINERARY_RESOURCE]: {
        name: "Distribution |||| Distributions",
        fields: {
          id: "ID",
          itinerary_cycle: "Itinéraire / Cycle",
          itinerary_id: "Itinéraire",
          route: "Route",
          remote_id: "Code",
          cycle: "Cycle",
          cycle_year: "Année",
          description: "Description",
          avg_consumption: "Consommation",
          status: "Statut",
          scheduled_at: "Date",
          reading_count: "#",
          agent_id: "Releveur",
          method: "Mode",
          capturer_id: "Data Imputer",
          center_id: "CR",
          is_printed: "Imprimé",
          images: "Images",
          distribution_type: "Type",
          task_count: "# Tâches",
          result_count: "# Effectuées",
          completed_result_count: "# Effectuées",
          total_anomaly_count: "# Anomalies",
          anomaly_count: "# Anomalies distributions",
          picture_anomaly_count: "# Anomalies photo",
          updated_at: "Mis à jour le",
          created_at: "Créé le",
          imported_at: "Importé le",
          is_confirmation_required: "Confirmation requise",
          is_completion_confirmation_required:
            "Confirmation d'achèvement requise"
        }
      },
      [config.DEVICE_RESOURCE]: {
        name: "Appareil |||| Appareils",
        fields: {
          id: "ID",
          model: "Model",
          manufacturer: "Vendor",
          device_identifier: "Appareil N°",
          app_version: "Version App",
          last_seen_at: "Heure de la dernière connexion",
          last_used_by: "Dernier utilisateur connecté",
          onb_code: "Code de confirmation",
          onb_confirmed_at: "Période du paramétrage",
          onb_confirmed_by: "Paramétrage Confirmé par",
          onb_registered_at: "Enregistré sur",
          onb_regitered_by: "Enregistré par",
          onb_expiration: "À confirmer avant",
          onb_status: "Statut",
          is_blocked: "Bloquer",
          blocked_reason: "Raison du blocage",
          android_version: "Android",
          reading_center: "Centre de relèves",
          center_id: "CR",
          blocked_by: "Bloquer par",
          users: "Utilisateur",
          device_serial: "Numéro de série"
        }
      },
      [config.READING_RESOURCE]: {
        name: "Relève |||| Relèves",
        fields: {
          itinerary_cycle: "Itinéraire / Cycle",
          itinerary_id: "Itinéraire",
          itinerary_eid: "Itinéraire",
          images: "Images",
          cycle: "Cycle",
          input_type: "Type",
          status: "Statut",
          date_visited: "Date",
          reading_value: "Valeur",
          is_out_of_vicinity: "Vérification de l'environement",
          is_out_of_range: "Portée de Contrôle"
        }
      },
      [config.SUPPLYPOINT_RESOURCE]: {
        name: "Point de livraison |||| Points de livraisons",
        fields: {
          remote_id: "Point de livraison",
          premise_identifier: "Premise ID",
          access_premise: "Accès aux premise",
          access: "Accès",
          access_meter: "Compteur d'accès",
          customer_name: "Nom du client"
        }
      },
      [config.METER_RESOURCE]: {
        name: "Compteur |||| Compteurs",
        fields: {
          meter_identifier: "ID Compteur",
          remote_id: "Référence du compteur",
          manufacturer: "Fabricant"
        }
      },
      [config.ANOMALIES_RESOURCE]: {
        name: "Anomalies |||| Anomalie",
        fields: {
          type: "Type Anomalie ",
          code: "Code",
          is_active: "Actif",
          is_default: "Défaut",
          is_skip_reading: "Sauter la relève",
          label_lang_one: "Description du langage 1",
          label_lang_two: "Description du langage 2",
          description: "Description"
        }
      },
      [config.REGISTERED_METERS_RESOURCE]: {
        name: "Nouveau Compteur |||| Nouveaux Compteurs",
        type_name: "New Meter",
        fields: {
          itinerary_cycle: "Itinéraire / Cycle",
          meter_ref: "Numéro de référence du compteur",
          next_meter_ref: "Numéro de référence du prochain compteur",
          prev_meter_ref: "Numéro de référence du compteur précédent",
          itinerary_id: "Itinéraire",
          remote_id: "Itinéraire",
          dials: "Cadrans du compteur",
          reading_value: "Valeur de la relève",
          reading_type_id: "Type de la relève",
          meter_type_id: "Type de compteur",
          reading_date: "Date de la relève",
          reported_by: "Enregistré par",
          lng: "GPS Long",
          lat: "GPS Lat",
          gps_coordinates: "GPS Lat/Long",
          gps_precision_mode: "GPS Precision mode",
          net_type: "Network Type",
          conn_mode: "Connection Mode",
          has_barcode: "Le code-barre est-il attaché",
          location_description: "Description de la localisation",
          registration_date: "Date d'enregistrement",
          images: "Photos attachées",
          client_activity_id: "Activité du client",
          agent_id: "Releveur",
          center_id: "Centre de relève",
          reading_anomaly_id: "Anomalie de relève",
          picture_anomaly_id: "Anomalie de l'image",
          cycle: "Cycle",
          fieldAgent: "Releveur",
          customerActivity: "Activité client",
          reading_center_id: "Centre de relèves"
        }
      },
      [config.SETTINGS_RESOURCE]: {
        name: "Serveur |||| Serveur",
        fields: {
          updated_by: "Mise à jour par",
          updated_at: "Mise à jour à",
          created_by: "Créé par",
          name: "Nom",
          value: "Valeur",
          description: "Description"
        }
      },
      [config.DEVICES_SETTINGS_RESOURCE]: {
        name: "App Mobile |||| App Mobile",
        fields: {
          updated_by_id: "Mise à jour par",
          updated_at: "Mise à jour à",
          created_by: "Créé par",
          name: "Nom",
          value: "Valeur",
          description: "Description"
        }
      },
      [config.WORK_HOURS_RESOURCE]: {
        name: "Heures de travail |||| Heures de travail des agents",
        fields: {
          day: "Jour de la semaine",
          start: "Heure de début",
          end: "Heure de fin",
          is_access: "Journée de travail?",
          updated_at: "Mise à jour à",
          updated_by: "Mise à jour par"
        }
      },
      [config.METER_TYPE_RESOURCE]: {
        name: "Type de Compteur",
        fields: {
          name: "Nom"
        }
      },
      [config.READING_TYPE_RESOURCE]: {
        name: "Type de relève ",
        fields: {
          name: "Nom"
        }
      },
      [config.DISTRIBUTION_TASK_RESOURCE]: {
        type_name: "Distribution",
        name: "Tâche de distribution |||| Tâches de distributions",
        fields: {
          status: "Statut",
          sequence_orig: "Séquence d'origine",
          sequence_new: "Nouvelle séquence",
          date_visited: "Date",
          reading_value: "Valeur",
          meter_id: "ID du compteur",
          comment: "Comment",
          reading_center_id: "Centre de relèves",
          input_type: "Type"
        }
      },
      [config.READINGTASK_RESOURCE]: {
        name: "Tâche de relève |||| Tâches de relève",
        type_name: "Reading",
        fields: {
          status: "Statut",
          sequence_orig: "Sequence initial",
          sequence_new: "Nouvelle Sequence",
          date_visited: "Date",
          reading_value: "Valeur",
          meter_identifier: "ID du compteur",
          supply_point_remote_id: "Point de livraison",
          comment: "Comment",
          reading_center_id: "Centre de relèves"
        }
      },
      [config.CLIENT_ACTIVITY_RESOURCE]: {
        name: "Activité du client |||| Activité du client",
        fields: {
          code: "Code",
          is_active: "Actif",
          is_default: "Par défaut",
          label_lang_one: "Langue 1",
          label_lang_two: "Langue 2",
          description: "Description"
        }
      },
      [config.QUALITY_RULES_RESOURCE]: {
        name: "Règles de qualité  |||| Règles de qualité",
        fields: {
          rule_code: "Code",
          description: "Description",
          is_active: "Actif?",
          short_description: "Brève description"
        }
      }
    },
    mra: {
      constants: {
        itinerary_status: {
          unassigned: "Non assigné",
          mobile_reading_ready: "Relève mobile prêt",
          mobile_reading_in_progress: "Relève mobile en cours",
          mobile_reading_completed: "Relève mobile terminée",
          mobile_reading_fully_received: "Relève mobile synchronisée",
          paper_reading_ready: "Relève sur papier prêt",
          paper_reading_fagent_selected: "Agent de relève sur papier choisi",
          return_for_estimation: "Retour pour estimation",
          paper_reading_data_being_captured: "Data Capture en cours",
          paper_reading_fully_entered: "Data Capture terminée"
        },
        distribution_status: {
          unassigned: "Non assigné",
          mobile_distribution_ready: "Relève mobile prêt",
          mobile_distribution_in_progress: "Relève mobile en cours",
          mobile_distribution_completed: "Relève mobile terminée",
          mobile_distribution_fully_received: "Relève mobile synchronisée"
        },
        device_status: {
          registered: "Enregistré",
          expired: "Expiré",
          ok: "OK",
          lost: "Perdu"
        },
        ru_types: {
          internal: "Interne",
          external: "Externe"
        },
        method_types: {
          mobile: "Application mobile",
          paper: "Relève de papier"
        },
        input_types: {
          manual: "Manuel",
          optical: "Optique"
        },
        view_modes: {
          list: "Liste",
          grid: "Grille"
        },
        days: {
          Monday: "Lundi",
          Tuesday: "Mardi",
          Wednesday: "Mercredi",
          Thursday: "Jeudi",
          Friday: "Vendredi",
          Saturday: "Samedi",
          Sunday: "Dimanche"
        }
      },
      unqualified: "Non contrôlé",
      unassign: "Retirer",
      roles: {
        selectRole: "Rôle",
        selectRolesNoPermission: "Rôles",
        selectRoles: "Rôles",
        HQM_AD: "Administrateur de QG",
        HQM_SU: "Superviseur de QG",
        HQM_RU: "Rapport Utilisateur de QG",
        HQM_PV: "Visionneuse d'images de QG",
        HQM_PC: "Contrôleur d'image de QG",
        HQM_SOA: "Agent d'order de service de QG",
        HQM_IT: "Support Informatique de QG",
        HQM_RSU: "Superviseur de région",
        RUM_SU: "Superviseur Unité de UR",
        RUM_FA: "Releveur de UR",
        RUM_RU: "Rapport Utilisateur de UR",
        RCM_AG: "Agent de CR",
        RCM_RU: "Rapport Utilisateur de CR",
        RCM_SU: "Superviseur de CR",
        accordingToRole: "Sélectionné en fonction de votre rôle"
      },
      general: {
        readonly_details:
          "Ces détails sont en lecture seule, en raison des droits insuffisants.",
        loading: "Chargement",
        noImages: "Pas d'images",
        not_available: "Pas disponible",
        back: "Annuler"
      },
      messages: {
        mark_as_read: "Marquer comme relèvé",
        read_at: "Relèvé au %{read_at}",
        title: "Messages"
      },
      upload: {
        image: {
          title: "Importer des images",
          message:
            "Sélectionnez les images et cliquez sur Confirmer pour télécharger."
        },
        routesheet: {
          title: "Télécharger la feuille d'itinéraire pour l'itinéraire% {id}",
          message:
            "Sélectionnez les analyses et cliquez sur Confirmer pour les télécharger.",
          success: "Rootsheet télécharger avec succès",
          fail:
            "TImpossible de télécharger la feuille de routes. Vérifiez la connexion au serveur d'images!"
        },
        invalidFile: "Il n'y avait pas de fichiers appropriés à télécharger.",
        unknownUploadType: "Type de téléchargement inconnu."
      },
      dashboard: {
        title: "Tableau de bord",
        text_welcome:
          "Bienvenue dans la plateforme de gestion des relèves pour compteurs!",
        text_hello: "Salut ",
        text_active_cycle: "Le cycle actif actuellement est: ",
        text_rc: "Votre centre de relève est: ",
        text_rc_disabled_message:
          ", qui est désactivé! Par conséquent, vous ne pouvez effectuer aucune action.",
        contact_supervisor:
          "Si vous pensez que c'est une erreur, veuillez contacter votre superviseur.",
        text_rcs: "Vous êtes affecté aux centres de relève suivants: ",
        text_rcs_disabled_message:
          ", veuillez noter que vous êtes également affecté à ces centres de relève désactivés: ",
        text_global_context:
          "Vous pouvez accéder à n’importe quel centre de lecture grâce à votre ",
        global_context: "Contexte global.",
        text_rcs_diabled_message_global_context:
          "Veuillez noter que ces centres de relève sont désactivés: "
      },
      reports: {
        title: "Rapports",
        itinerary: {
          title: "Rapport d'itinéraires"
        },
        supply_points: {
          title: "Rapport sur le point de livraison"
        },
        distribution_gps_comparison: {
          title: "Rapport de comparaison GPS de distribution"
        },
        distribution_index_comparison: {
          title: "Rapport de comparaison des indices de distribution"
        },
        reading_gps_comparison: {
          title: "Rapport de comparaison de relève GPS"
        },
        device: {
          title: "Rapport Appareil"
        },
        user_connection: {
          title: "Rapport de connexion utilisateur"
        },
        new_meters: {
          title: "Rapport Nouveau Compteur"
        },
        picture_control_rc: {
          title: "Rapport de contrôle d'image CR"
        },
        picture_control_ru: {
          title: "Rapport de contrôle d'image UR"
        },
        anomaly_correction: {
          title: "Rapport de correction d'anomalie"
        },
        control_reading: {
          title: "Rapport de Contrôle de relève"
        },
        import_recon: {
          title: "Rapport de Réconciliation des importations"
        },
        export_recon: {
          title: "Rapport de Réconciliation des exportations"
        },
        zero_consumption: {
          title: "Relève Compteur Consommation Zero"
        }
      },
      devices: {
        confirm: "Confirmer",
        confirm_title: "Confirmer l'appareil",
        confirm_text: "Entrez le code de confirmation affiché sur l'appareil.",
        code_setting: "Code d'enregistrement de l'appareil",
        activate: "ACTIVER"
      },
      devices_overview: {
        latest_info: "Dernière information rapportée",
        details: "Détails"
      },
      quality_runs: {
        actual_value: "Valeur actuelle",
        passed: "Statut",
        no_checks: "Aucun contrôle de qualité n'a encore été exécuté",
        last_run: "Dernière exécution",
        overall: "état général",
        check_failed: "Echec",
        check_passed: "Passé",
        run_at: "Temps"
      },
      users: {
        activate: "ACTIVER",
        confirm_activate_title: "Réactiver l'utilisateur %{id}"
      },
      notifications: {
        api_error: "api server error",
        no_map_data: "No data to display",
        username_taken: "Username is already in use",
        password_too_long: "Mot de passe trop long",
        password_too_short: "Mot de passe trop court",
        password_unsafe: "Le mot de passe ne respecte pas les consignes de sécurité. Veuillez augmenter la complexité du mot de passe",
        cannot_reopen: {
          reading:
            "Cet itinéraire n'a pas pu être rouvert car il n'est pas marqué comme achevé ni déjà exporté.",
          distribution:
            "Cet itinéraire n'a pas pu être rouvert car il n'a pas été marqué comme étant entièrement reçu."
        },
        context_switch_success: "Contexte du CR changé avec succès",
        reading_center: "Reading Center",
        global_context: "Global Context",
        error: "Erreur",
        access_denied: "Accès refusé",
        user_exists:
          "L'utilisateur existe déjà. Veuillez Choisir un autre nom d'utilisateur.",
        force_qc: "Contrôle de qualité effectué.",
        return_for_estimation_error:
          "Le retour pour estimation n'a pas réussi, veuillez contacter l'administrateur pour plus de détails",
        force_qc_error:
          "L'opération \" FORCED EXPORT \" n'a été que partiellement réussie. Veuillez contacter l'administrateur pour obtenir les détails du protocole",
        generation_error:
          "La génération de l'itinéraire a échoué, veuillez contacter l'administrateur pour plus de détails.",
        password_invalid:
          "Le mot de passe est invalide. C'est probablement trop court.",
        invalid_confirmation_code:
          "Le code de confirmation est invalide. Réessayez!",
        expired_confirmation_code: "The confirmation Code is expired.",
        usernotassignedtomember:
          "Utilisateur non affecté à ce centre de releve.",
        notassignedtocenter: "Utilisateur non assigné à l'appareil.",
        no_active_rc_one_disabled:
          "Votre CR est désactivée, vous ne pouvez donc effectuer aucune action.",
        no_active_rc_all_disabled:
          "Tous vos CR sont désactivés, vous ne pouvez donc effectuer aucune action.",
        rcs_disabled:
          "Certains de vos CR sont désactivés, vous ne pouvez effectuer aucune action dans ces CR."
      },
      confirm: {
        ok: "Confirmer"
      },
      profile: {
        title: "Profil de l'utilisateur",
        reset: "Entrez un nouveau mot de passe",
        confirm: "Confirmer le nouveau mot de passe"
      },
      reset: {
        title: "Réinitialiser le mot de passe",
        match: "Les mots de passe saisis doivent correspondre.",
        reset: "Entrez un nouveau mot de passe",
        confirm: "Confirmer le nouveau mot de passe",
        button: "Réinitialiser le mot de passe",
        msg: "Vous devez réinitialiser votre mot de passe.",
        username: "Le nom d'utilisateur ne doit pas être vide",
        first_name: "Le prénom ne doit pas être vide",
        last_name: "Le nom de famille ne doit pas être vide",
        language: "La langue ne doit pas être vide",
        password: "Donnez le mot de passe",
        activate: "Activer l'utilisateur",
        reading_centers: "Vous devez assigner à un CR",
        reading_centers_valid: "Vous devez l'assigner à un CR valide",
        reading_unit_id: "Vous devez l'assigner à un Sous-Traitant",
        roles: "Vous devez l'assigner un rôle",
        rolesNoRU: "Vous devez assigner un Sous-Traitant et un CR pour ce Rôle"
      },
      activate: {
        title: "Activation de l'enregistrement",
        activate: "Activer",
        confirm_text: "Voulez-vous réactiver cet utilisateur?"
      },
      itinerary_assign: {
        title: "Affectation des itinéraires",
        assign_button: "Assigner",
        reassign_button: "Réassigner",
        print_button: "Imprimer",
        anomaly_print_button: "Imprimer anomalies",
        method_not_null: "Vous devez sélectionner un mode de relève.",
        reader_not_null: "Vous devez sélectionner un relèveur.",
        capturer_not_null: "Vous devez sélectionner un agent Data Capture.",
        warn_reset:
          "Modifier le mode de relève ou l'agent assigné d'un itinéraire réinitialise toutes les lectures!"
      },
      distribution_itinerary_assign: {
        title: "Affectation des itinéraires de distribution",
        assign_button: "Assigner",
        reassign_button: "Réassigner",
        print_button: "Imprimer"
      },
      itinerary_overview: {
        title: "Vue d'ensemble des itinéraires",
        reset: "Réinitialiser",
        confirm_reset: "Réinitialiser l'itinéraire %{id}",
        forceexport: "Forcer l'exportation",
        confirm_forceexport: "Forcer l'exportation de l'itineraire %{id}",
        force_qc: "Contrôle Qualité",
        reopen: "Ouvrir à nouveau",
        newmeter: "Enregistrer un nouveau compteur",
        confirm_reopen: "Ouvrir l'itinéraire à nouveau %{id}",
        confirm_return: " l'itinéraire %{id}",
        confirm_return_bulk: "Retourner les itinéraires %{id}",
        confirm_capture: "Capture complète de données pour l'itinéraire%{id}",
        confirm_capture_button: "Marquer comme complet",
        return: "Retourner pour estimation",
        return_selected: "Retourner  les itinéraires sélectionnées",
        generate_control_reading: "Générer une relève de contrôle",
        generate_anomaly_correction: "Générer une correction d'anomalie",
        readings: "Relèves",
        resequence_button: "Resequence",
        resequence: "Resequence d'itinéraire",
        confirm_resequence: "Resequence de l'itinéraire %{id}",
        capture_button: "DataCapture",
        capture: "Data Capture",
        itinerary_data: "Données de l'itinéraire",
        quality_checks: "Vérification de la qualité",
        summary: "Résumé",
        reading_summary: "Résumé des relèves ",
        distribution_summary: "Résumé de la distribution",
        details: "Détails",
        images: "Images",
        route_sheet: "Bordereau de relève",
        current_active_cycle: "Cycle actif actuel",
        image_upload_button: "Télécharger l'image",
        client_activity_invalid: "Modifiez le type d'activité.",
        itinerary_code: "Code d'itinéraire"
      },
      navigation: {
        distribution_itineraries: "Itinéraires de distribution",
        reading_itineraries: "Itinéraires de relèves",
        anomaly_correction_itineraries: "Itinéraires de Correction d'Anomalies",
        control_reading_itineraries: "Contrôle des Itinéraires de relève",
        overview: "Vue d'ensemble",
        assignment: "Assigner",
        register: "Enregistrer",
        administration: "Administration",
        itineraries: "Itinéraires",
        anomalies: "Anomalies",
        settings: "Paramètres",
        add_device: "Ajouter un appareil",
        add_user: "Ajouter un utilisateur",
        activate_user: "Réactiver utilisateur",
        documentation: "Documentation",
        documentation_app: "BOM",
        documentation_bom: "Application mobile"
      },
      reading_center: {
        details: "Détails du centre de relèves",
        name: "Le nom du centre de relèves ne doit pas être vide",
        remote_id: "Un centre de relèves externe est requis",
        quality_checks: "Contrôle qualité",
        rule_code: "Code Règle",
        description: "Description",
        threshold: "Seuil",
        confirm_activate_title: "Réactiver le centre de relève %{id}"
      },
      reading_unit: {
        name: "Le nom du Sous-Traitant ne doit pas être vide ",
        type: "Le type de Sous-Traitant est requis ",
        region_id: "La région de l'unité de relève doit être sélectionnée"
      },
      anomaly: {
        type: "Type est requis",
        code: "Code est requis",
        picture: "Anomalie de l'image",
        reading: "Anomalie de relève",
        control: "Anomalie de contrôle",
        distribution: "Anomalie de distribution",
        correction: "Correction Anomaly",
        occupancy: "Anomalie d'occupation",
        pictures: "Anomalies de l'image",
        readings: "Anomalies de relève",
        controls: "Anomalies de contrôle",
        distributions: "Anomalies de distribution",
        corrections: "Correction Anomalies",
        occupancys: "Anomalies d'occupation",
        confirm_activate_title: "Réactiver l'anomalie %{id}",
        picture_control: "Contrôle de l'image"
      },
      client_activity: {
        name: "Activité du client",
        confirm_activate_title: "Réactiver l'activité du client %{id}"
      },
      register_meter: {
        meter_ref: "Numéro de référence du compteur requis",
        dials: "Le nbre de cadrans du compteur est requis",
        reading_value: "Le taux de relève du compteur est requis",
        reading_value_chars:
          "Le nombre de caractères doit correspondre à la valeur de numérotation",
        reading_date: "La date de relève du compteur est requise",
        lng: "Longitude est nécessaire pour la prise de cette relève",
        lat: "Latitude est nécessaire pour la prise de cette relève",
        registration_date:
          "La date à laquelle le compteur a été enregistré est requise",
        client_activity: "Activité du client",
        agent_id: "Releveur",
        itinerary: "Itinéraire",
        reading_anomaly_id: "Anomalie de relève est nécessaire.",
        picture_anomaly_id: "Une anomalie d'image est requise."
      },
      settings: {
        name: "Nom requis",
        value: "Valeur requise",
        TRUE: "Vrai",
        FALSE: "Faux",
        en: "An",
        fr: "Fr",
        de: "Al",
        Mandatory: "Obligatoire",
        Optional: "Optionnel",
        "Do not use": "Ne pas utiliser",
        SMALL_SIZE: "Petite taille",
        MED_SIZE: "Taille moyenne",
        LARGE_SIZE: "Grande taille",
        Strict: "Strict",
        All: "Tout",
        "Digits only": "Chiffres seulement",
        "Any character": "N'importe quel caractère",
        "PEN&PAPER": "Stylo et papier",
        MOBILEAPP: "App mobile",
        Enforce: "Appliquer",
        Warn: "Alerte",
        "Do not enforce": "Ne pas appliquer",
        Numeric: "Numeric",
        "All Characters": "Tous les caractères",
        Low: "Bas",
        Medium: "Moyen",
        High: "Haute",
        kWh: "kWh",
        "m³": "m³",
        Provider: "Fournisseur",
        Server: "Serveur",
        GPS: "GPS",
        Off: "Éteint",
        OFF: "Éteint",
        FULL: "Plein",
        DATA: "Données",
        true: "Vrai",
        false: "Faux"
      },
      validation: {
        required: "Champs requis"
      },
      picture_filter: {
        itinerary_id: "Itinéraire",
        supply_point_eid: "Point de livraison",
        meter_identifier: "ID du compteur",
        agent_id: "Releveur",
        client_activity_id: "Activité du client",
        namespace: "Type d'itinéraire",
        cycle: "Cycle",
        year: "Année",
        quality: "Qualité de l'image"
      },
      generate: {
        control: "Générer un itinéraire de relève de contrôle ?",
        anomaly_correction: "Générer un itinéraire de correction d'anomalie ?",
        agent: "Agent de terrain à assigner"
      },
      task_types: {
        reading: "Relève",
        distribution: "Distribution",
        registered_meter: "Nouveau compteur",
        control: "Contrôle",
        anomaly_correction: "Correction"
      },
      settings_descriptions: {
        CURRENT_CYCLE:
          "Ce paramètre définit le cycle actuel de la relève du compteur pour l'entreprise",
        PRIMARY_LANGUAGE: "Langue principale supportée par l'application",
        SECOND_SUPPORTED_LANGUAGE:
          "Langage secondaire supportée par l'application",
        LIMIT_READING_UNIT_TO_READING_CENTRE:
          "Le paramètre détermine si une unité de relève ne peut être affectée qu'à un seul centre de relève",
        CYCLE_VIEW_LEVEL: "Limite la vue de cycle à celle actuelle",
        SCAN_INTINERARY_SHEET:
          "Le paramètre détermine si les agents RC DOIVENT scanner les feuilles d'itinéraire pendant la capture de données",
        MOBILE_APP_PROVISION_EXPIRATION_TIME:
          "Définit le temps maximum (en minutes) avant expiration du code de confirmation de l´appareil généré par le serveur. Si ce temps n'est pas utilisé dans cet intervalle, le serveur doit rejeter le code et l'utilisateur doit recommencer le processus",
        BACK_OFFICE_PASSWORD_LENGTH:
          "Définit la longueur du mot de passe des utilisateurs de back office",
        FIELD_AGENT_PASSWORD_LENGTH:
          "Définit la longueur du mot de passe de l'agent de terrain mobile",
        FIELD_AGENT_PASSWORD:
          "Définit le type de caractères autorisé comme mot de passe pour les utilisateurs de l'application mobile (agents de terrain)",
        DEFAULT_METER_READING_MODE:
          "Définit le mode de fonctionnement le plus courant de l'entreprise lors de la relève des compteurs. L'application attribue par défaut ce mode de lecture à de nouveaux itinéraires. Il peut cependant être modifié par un superviseur de centre de relève",
        MOBILE_APP_REGISTRATION_KEY:
          "Code d´approvisionnement des appareils se connectants à ce système pour la première fois. Il doit comporter 5 chiffres",
        ACCEPTED_READINGS_MIN:
          "L´intervalle de la relève minimale acceptée en fonction de la valeur de relève estimée en pourcentage (%). Une valeur de 85 par exemple, signifie que la valeur minimale acceptée est de 85% de la valeur minimale estimée",
        ACCEPTED_READINGS_MAX:
          "L´intervalle de relève minimale acceptée en fonction de la valeur de relève estimée en pourcentage (%). Une valeur de 185 par exemple,  signifie que la valeur maximale acceptée est de 185% du maximum estimé",
        IMAGE_SERVER_URL:
          "URL HTTP qui pointe vers l'URL BASE du serveur d'images",
        IMAGE_SERVER_USERNAME:
          "Nom d'utilisateur pour autoriser l´application BOM au serveur d'images",
        IMAGE_SERVER_PASSWORD:
          "Mot de passe pour autoriser l´application BOM au serveur d'images",
        REPORTING_SERVER_URL:
          "Hôte sous lequel le serveur de rapports fonctionne. Doit être conforme à une adresse HTTP ou HTTPS",
        REPORTING_SERVER_USERNAME:
          "Nom d'utilisateur pour autoriser l´application BOM au serveur de rapports",
        REPORTING_SERVER_PASSWORD:
          "Mot de passe  pour autoriser l´application BOM au serveur de rapports",
        RMS_URL: "Indexation de service et affectation de ressources à BOM",
        DATAX_URL: "Hôte du service d'échange de données",
        JANITOR_TTL_READING_ITINERARY:
          "Mois après lesquels supprimer les données de l'itinéraire de relève",
        JANITOR_TTL_DISTRIBUTION_ITINERARY:
          "Mois après lesquels supprimer les données de l'itinéraire de distribution",
        JANITOR_TTL_NEW_REGISTERED_METER:
          "Mois après lesquels supprimer les nouvelles données de compteur enregistrées",
        JANITOR_TTL_QUALITY_RUN:
          "Mois après lesquels supprimer les données de qualité",
        IMAGE_SERVER_URL_SECONDARY:
          "URL HTTP qui pointe vers l'URL BASE du serveur d'images secondaire",
        JANITOR_TTL_ANOMALY_CORRECTION_ITINERARY:
          "Mois après lesquels supprimer les données de l'itinéraire de Correction d'Anomalie",
        JANITOR_TTL_CONTROL_ITINERARY:
          "Mois après lesquels supprimer les données d'itinéraire de contrôle",
        READING_ITINERARY_ENABLE_AUTOASSIGNMENT:
          "Permettre l'affectation automatique pour attribuer un nouvel itinéraire au même agent qui a relèvée l'itinéraire précédent",
        ANOMALY_CORRECTION_ITINERARY_ENABLE_AUTOASSIGNMENT:
          "Permettre l'affectation automatique pour attribuer un nouvel itinéraire au même agent qui a relèvée l'itinéraire précédent",
        CONTROL_ITINERARY_ENABLE_AUTOASSIGNMENT:
          "Permettre l'affectation automatique pour attribuer un nouvel itinéraire au même agent qui a relèvée l'itinéraire précédent",
        DISTRIBUTION_ITINERARY_ENABLE_AUTOASSIGNMENT:
          "Permettre l'affectation automatique pour attribuer un nouvel itinéraire au même agent qui a relèvée l'itinéraire précédent",
        READING_ITINERARY_REQUIRE_AUTOASSIGNMENT_CONFIRMATION:
          "Nécessite la confirmation d'une affectation automatique du système avant que l'itinéraire ne soit disponible sur le périphérique des agents attribués automatiquement",
        CONTROL_ITINERARY_REQUIRE_AUTOASSIGNMENT_CONFIRMATION:
          "Nécessite la confirmation d'une affectation automatique du système avant que l'itinéraire ne soit disponible sur le périphérique des agents attribués automatiquement",
        ANOMALY_CORRECTION_ITINERARY_REQUIRE_AUTOASSIGNMENT_CONFIRMATION:
          "Nécessite la confirmation d'une affectation automatique du système avant que l'itinéraire ne soit disponible sur le périphérique des agents attribués automatiquement",
        DISTRIBUTION_ITINERARY_REQUIRE_AUTOASSIGNMENT_CONFIRMATION:
          "Nécessite la confirmation d'une affectation automatique du système avant que l'itinéraire ne soit disponible sur le périphérique des agents attribués automatiquement",
        APP_MINIMUM_SUPPORTED_VERSION:
          "N'autorisez que les applications mobiles exécutant au moins cette version à se connecter au serveur",
        REPORTS_ACCEPTED_GPS_DISTANCE:
          "Distance maximale (en mètres) autorisée pour une relève par rapport à la valeur GPS du point de livraison (rapports uniquement)",
        CURRENT_CYCLE_YEAR: "L'année du cycle en cours",
        DATAX_EXPORT_LOCK_TO_CURRENT_CYCLE:
          "Défini a vrai si seuls les résultats de l'itinéraire de relève pour le cycle en cours doivent être exportés vers UMA",
        ANOMALY_CORRECTION_ITINERARY_REQUIRE_EXPORT_CONFIRMATION:
          "Nécessite la confirmation de l'exportation de l'itinéraire de correction d'anomalie",
        DISTRIBUTION_ITINERARY_REQUIRE_EXPORT_CONFIRMATION:
          "Nécessite la confirmation de l'exportation de l'itinéraire de distribution",
        READING_ITINERARY_REQUIRE_EXPORT_CONFIRMATION:
          "Nécessite la confirmation de l'exportation de l'itinéraire de relève",
        CONTROL_READING_ITINERARY_REQUIRE_EXPORT_CONFIRMATION:
          "Nécessite la confirmation de l'exportation de l'itinéraire de contrôle",
        ACTIVE_MAP_SOURCE: "Définir la source de CARTE active",
        MAP_SOURCE_ARCGIS_URL: "Définir l'URL ARCGISL",
        MAP_SOURCE_OSM_URL: "Définir l'URL OSM"
      },
      settings_short_descriptions: {
        CURRENT_CYCLE: "Cycle actuel",
        PRIMARY_LANGUAGE: "Langue principale",
        SECOND_SUPPORTED_LANGUAGE: "Langue secondaire",
        LIMIT_READING_UNIT_TO_READING_CENTRE:
          "Limiter les unités de relève à un seul centre de relève",
        CYCLE_VIEW_LEVEL: "Limiter la vue uniquement au cycle actuel",
        SCAN_INTINERARY_SHEET: "Exiger une feuille d'itinéraire scannée",
        MOBILE_APP_PROVISION_EXPIRATION_TIME:
          "Temps d'approvisionnement de l'application mobile (min.)",
        BACK_OFFICE_PASSWORD_LENGTH:
          "Longueur du mot de passe - Utilisateurs de back-office",
        FIELD_AGENT_PASSWORD_LENGTH:
          "Longueur du mot de passe - Utilisateurs de l'application mobile",
        FIELD_AGENT_PASSWORD:
          "Caractères autorisés par mot de passe - Utilisateurs de l'application mobile",
        DEFAULT_METER_READING_MODE: "Mode de relève du compteur par défaut",
        MOBILE_APP_REGISTRATION_KEY: "Code d´approvisionnement de l´appareil",
        ACCEPTED_READINGS_MIN:
          "Limite inférieure de la relève du compteur (% et <100)",
        ACCEPTED_READINGS_MAX:
          "Limite supérieure de la relève du compteur (%) et> 100)",
        IMAGE_SERVER_URL: "URL du serveur d'images",
        IMAGE_SERVER_USERNAME: "Nom d'utilisateur du serveur d'images",
        IMAGE_SERVER_PASSWORD: "Mot de passe du serveur d'images",
        REPORTING_SERVER_URL: "URL du serveur de rapports",
        REPORTING_SERVER_USERNAME: "Nom d'utilisateur du serveur de rapports",
        REPORTING_SERVER_PASSWORD: "Mot de passe du serveur de rapports",
        RMS_URL: "URL du Service de Gestion des Ressources",
        DATAX_URL: "URL du Service d'échange de données",
        JANITOR_TTL_READING_ITINERARY:
          "Seuil de nettoyage de l'itinéraire de Relève",
        JANITOR_TTL_DISTRIBUTION_ITINERARY:
          "Seuil de nettoyage de l'itinéraire de Distribution",
        JANITOR_TTL_NEW_REGISTERED_METER:
          "Seuil de nettoyage de compteur enregistré",
        JANITOR_TTL_QUALITY_RUN: "Seuil de nettoyage de la qualité d'exécution",
        IMAGE_SERVER_URL_SECONDARY: "URL du serveur d'images secondaire",
        JANITOR_TTL_ANOMALY_CORRECTION_ITINERARY:
          "Seuil de nettoyage d'itinéraire de Correction d'Anomalie",
        JANITOR_TTL_CONTROL_ITINERARY:
          "Seuil de nettoyage de l'itinéraire de Contrôle",
        READING_ITINERARY_ENABLE_AUTOASSIGNMENT:
          "Permettre l'affectation automatique d'itinéraires de relève",
        ANOMALY_CORRECTION_ITINERARY_ENABLE_AUTOASSIGNMENT:
          "Permettre l'attribution automatique d'itinéraires de Correction d'Anomaly",
        CONTROL_ITINERARY_ENABLE_AUTOASSIGNMENT:
          "Permettre l’attribution automatique des itinéraires de Contrôle",
        DISTRIBUTION_ITINERARY_ENABLE_AUTOASSIGNMENT:
          "Permettre l'attribution automatique d'itinéraires de distribution",
        READING_ITINERARY_REQUIRE_AUTOASSIGNMENT_CONFIRMATION:
          "Nécessite la confirmation de l'attribution automatique d'itinéraires de relève",
        CONTROL_ITINERARY_REQUIRE_AUTOASSIGNMENT_CONFIRMATION:
          "Nécessite la confirmation de l'affectation automatique des itinéraires de contrôle",
        ANOMALY_CORRECTION_ITINERARY_REQUIRE_AUTOASSIGNMENT_CONFIRMATION:
          "Nécessite la confirmation de l'affectation automatique des itinéraires de correction d'anomalies",
        DISTRIBUTION_ITINERARY_REQUIRE_AUTOASSIGNMENT_CONFIRMATION:
          "Nécessite la confirmation de l'affectation automatique des itinéraires de distribution",
        APP_MINIMUM_SUPPORTED_VERSION:
          "Version minimale de l'application mobile",
        REPORTS_ACCEPTED_GPS_DISTANCE:
          "Distance d'Acceptation de la Portée GPS (rapports)",
        CURRENT_CYCLE_YEAR: "Année en cours",
        DATAX_EXPORT_LOCK_TO_CURRENT_CYCLE:
          "Verrouiller l'exportation vers le cycle en cours",
        ANOMALY_CORRECTION_ITINERARY_REQUIRE_EXPORT_CONFIRMATION:
          "Exiger la confirmation de l'exportation de l'itinéraire de correction d'anomalie",
        DISTRIBUTION_ITINERARY_REQUIRE_EXPORT_CONFIRMATION:
          "Exiger la confirmation de l'exportation de l'itinéraire de distribution",
        READING_ITINERARY_REQUIRE_EXPORT_CONFIRMATION:
          "Exiger la confirmation de l'exportation de l'itinéraire de lecture",
        CONTROL_READING_ITINERARY_REQUIRE_EXPORT_CONFIRMATION:
          "Exiger la confirmation de l'exportation de l'itinéraire de contrôle",
        ACTIVE_MAP_SOURCE: "Source de la CARTE",
        MAP_SOURCE_ARCGIS_URL: "URL ARCGIS",
        MAP_SOURCE_OSM_URL: "URL OSM"
      },
      mobile_settings_descriptions: {
        GPS_READING_SUCCESS:
          "Ce paramètre définit si l'application mobile oblige l'agent de terrain à enregistrer les coordonnées GPS pour chaque relève réussie",
        GPS_READING_FAILED:
          "Ce paramètre définit si l'application mobile doit forcer l'agent de terrain à enregistrer les coordonnées GPS pour chaque relève infructueuse",
        GPS_METER_REGISTRATION:
          "Ce paramètre définit si l'application mobile doit obliger l'agent de terrain à enregistrer les coordonnées  GPS pour chaque compteur nouvellement enregistré",
        PICTURE_READING_SUCCESS:
          "Ce paramètre définit si l'application mobile doit obliger l'agent de terrain à prendre les photos et les coordonnées GPS pour chaque relève réussie",
        PICTURE_READING_FAILED:
          "Ce paramètre définit si l'application mobile doit obliger l'agent de terrain à prendre les photos et les coordonnées GPS pour chaque relève infructueuse",
        PICTURE_METER_REGISTRATION:
          "Ce paramètre définit si l'application mobile doit obliger l'agent de terrain à prendre les photos et les coordonnées GPS pour chaque compteur nouvellement enregistré",
        READ_METER_NUMBER_BARCODE:
          "Ce paramètre détermine si l'agent de terrain utilisant l'application mobile doit lire le numéro du compteur via un barcode ou un QR-code",
        METER_READING_OPTICAL_SCANNER:
          "Ce paramètre détermine si l'agent de terrain utilisant l'application mobile doit prendre la relève du compteur à l'aide du scanner optique OCR",
        VICINITY_CHECK:
          "Le paramètre détermine si l'application mobile compare la position GPS à celle de la position connue du compteur avant d'enregistrer les lectures du compteur (uniquement si les coordonnées sont disponibles)",
        VICINITY_RANGE:
          "Ce paramètre définit l'écart maximal en mètres (m) entre la position GPS actuellement enregistrée et la position GPS enregistrée sur le serveur",
        EXPECTED_READING_VALUE:
          "Ce paramètre détermine si l'application mobile doit comparer la lecture du compteur enregistrée par l'agent de terrain avec la valeur attendue estimée",
        METER_REGISTRATION_NUMBER_KEYPAD_TYPE:
          "Ce paramètre définit le type de clavier que l'application mobile doit afficher à l'agent de terrain lorsqu'il enregistre un nouveau compteur",
        PICTURE_QUALITY:
          "Ce paramètre définit la qualité de l'image qui doit être utilisée par l'application mobile. Plus la qualité est élevée, plus les tailles d'image (données) sont importantes et plus leurs coûts de transmission et de stockage sont élevés",
        NUMBER_OF_PICTURES_PER_INCIDENT:
          "Ce paramètre définit le nombre maximum d'images pouvant être prises par incident",
        IMAGE_SERVER_SFTP_HOST: "Adresse hôte du serveur d'images",
        IMAGE_SERVER_SFTP_PORT: "Serveur sftp du serveur d'images",
        IMAGE_SERVER_SFTP_PUBLICKEY:
          "Clé publique des périphériques pour valider l'hôte lors de la connexion au serveur d'image (a priorité sur le mot de passe) - codé base64",
        IMAGE_SERVER_SFTP_KEY:
          "Clé d'accès partagé pour les périphériques pour se connecter au serveur d'image (a priorité sur le mot de passe) - codé base64",
        IMAGE_SERVER_SFTP_USER:
          "Nom d'utilisateur SFTP partagé pour les périphériques connectés au serveur d'images",
        IMAGE_SERVER_SFTP_PASSWORD:
          "Mot de passe SFTP partagé pour les périphériques connectés au serveur d'images",
        IMAGE_SERVER_SFTP_PATH:
          "Ce paramètre définit l'emplacement du répertoire sur le serveur FTP où les données doivent être stockées",
        LOCK_AGENTS_ASSIGNED_DEVICES:
          "Ce paramètre (lorsqu'il est défini sur TRUE) détermine si l'application doit restreindre les agents à utiliser uniquement les périphériques qui leurs sont affectés",
        UNITS: "Unité de consommation",
        TIME_SYNC_MODE:
          "Ce paramètre définit comment l'application mobile doit synchroniser son horloge avec celle du serveur. Il offre les différentes options (horloges fiables) possibles",
        PRINT_READING_RECEIPT:
          "Ce paramètre définit si l'application mobile autorise les agents de terrain à imprimer les reçus",
        GPS_DISTRIBUTION_SUCCESS:
          "Le paramètre détermine si l'agent de terrain utilisant l'application mobile prend les coordonnées GPS pour chaque tâche de distribution réussie",
        GPS_DISTRIBUTION_FAILED:
          "Le paramètre détermine si l'agent de terrain utilisant l'application mobile prend les coordonnées GPS pour chaque tâche de distribution infructueuse",
        PICTURE_DISTRIBUTION_SUCCESS:
          "Le paramètre détermine si l'agent de terrain utilisant l'application mobile est requis pour prendre une photo pour chaque tâche de distribution réussie",
        PICTURE_DISTRIBUTION_FAILED:
          "Le paramètre détermine si l'agent de terrain utilisant l'application mobile est requis pour prendre une photo pour chaque tâche de distribution infructueuse",
        MANUAL_IMAGE_SYNC_CONFIRM_PIN:
          "Le code de confirmation doit être entré par l'utilisateur sur le périphérique afin d'autoriser le marquage de toutes les images sur l'appareil comme synchronisé (et de les supprimer sur le périphérique)",
        BACKGROUND_SYNC_MODE:
          "Ce paramètre détermine si l'application doit synchroniser les données collectées (et les images) des agents avec le serveur en arrière-plan",
        BACKGROUND_SYNC_INTERVAL:
          "Ce paramètre détermine l'intervalle de temps en minutes pendant lequel une synchronisation d'arrière-plan doit être effectuée. Si l'on définit, par exemple, 60, l'application mobile se synchronise toutes les 60 minutes",
        GPS_PERSIST_PERIOD:
          "Ce paramètre détermine la durée (en minutes) pendant laquelle la position GPS actuelle doit être stockée pour une réutilisation éventuel. Dans le cas où le Smartphone ne peut pas acquérir une position GPS valide, ces coordonnées GPS seront utilisé dans toutes les relèves pendant cette intervalle",
        GPS_TIMEOUT:
          "Ce paramètre détermine la durée maximale pendant laquelle le dispositif essaie de récupérer les cordonnées GPS d'une position avant de terminer",
        CAMSIZE_MTR_READING_OK:
          "Les tailles de recadrage par défaut de l'appareil photo pour les photos prises dans ce contexte",
        CAMSIZE_MTR_READING_NOK:
          "Les tailles de recadrage par défaut de l'appareil photo pour les photos prises dans ce contexte",
        CAMSIZE_DIST_READING_OK:
          "Les tailles de recadrage par défaut de l'appareil photo pour les photos prises dans ce contexte",
        CAMSIZE_DIST_READING_NOK:
          "Les tailles de recadrage par défaut de l'appareil photo pour les photos prises dans ce contexte",
        CAMSIZE_MTR2_READING_OK:
          "Les tailles de recadrage par défaut de l'appareil photo pour les photos prises dans ce contexte",
        CAMSIZE_MTR2_READING_NOK:
          "Les tailles de recadrage par défaut de l'appareil photo pour les photos prises dans ce contexte",
        CAMSIZE_CTRL_READING_OK:
          "Les tailles de recadrage par défaut de l'appareil photo pour les photos prises dans ce contexte",
        CAMSIZE_CTRL_READING_NOK:
          "Les tailles de recadrage par défaut de l'appareil photo pour les photos prises dans ce contexte",
        CAMSIZE_NEWMTR:
          "Les tailles de recadrage par défaut de l'appareil photo pour les photos prises dans ce contexte",
        APP_NUM_CYCLES_KEEP_PIC:
          "Définit la période (cycles passés) pour laquelle les images sont supprimées de l'app",
        ALLOW_DATA_SYNC_WITH_PICTURES:
          "Activer le bouton pour permettre à l'utilisateur de l'application de choisir de synchroniser ses images",
        ALLOW_DATA_SYNC:
          "Activer le bouton pour permettre à l'utilisateur de choisir de synchroniser les données",
        SYNC_REMINDER:
          "Définit le nombre de relèves après lequel l'application doit faire apparaittre l'alerter."
      },
      mobile_settings_short_descriptions: {
        GPS_READING_SUCCESS:
          "Prenez les coordonnées GPS pour une relève réussie",
        GPS_READING_FAILED:
          "Prenez les coordonnées GPS pour une relève infructueuse",
        GPS_METER_REGISTRATION:
          "Prenez les coordonnées GPS pour de nouveaux compteurs",
        PICTURE_READING_SUCCESS: "Prenez l'image pour une relève réussie",
        PICTURE_READING_FAILED: "Prenez l'image pour une relève infructueuse",
        PICTURE_METER_REGISTRATION: "Prenez l'image pour de nouveaux compteurs",
        READ_METER_NUMBER_BARCODE: "Utiliser le lecteur de Barcode",
        METER_READING_OPTICAL_SCANNER: "Utilisation du scanner optique",
        VICINITY_CHECK: "Valider la plage du GPS",
        VICINITY_RANGE: "Déviation GPS maximale (m)",
        EXPECTED_READING_VALUE: "Valider la relève",
        METER_REGISTRATION_NUMBER_KEYPAD_TYPE:
          "Clavier numérique - Nouveau compteur -> Compteur #",
        PICTURE_QUALITY: "Qualité de l'image",
        NUMBER_OF_PICTURES_PER_INCIDENT: "Num Max de Photos",
        IMAGE_SERVER_SFTP_HOST: "Nom d'hôte SFTP",
        IMAGE_SERVER_SFTP_PORT: "Port SFTP",
        IMAGE_SERVER_SFTP_PUBLICKEY: "Clé Publique du serveur SFTP",
        IMAGE_SERVER_SFTP_KEY: "Clé privée du serveur SFTP",
        IMAGE_SERVER_SFTP_USER: "Nom d'utilisateur du serveur SFTP",
        IMAGE_SERVER_SFTP_PASSWORD: "Mot de passe du serveur SFTP",
        IMAGE_SERVER_SFTP_PATH: "Répertoire du serveur SFTP",
        LOCK_AGENTS_ASSIGNED_DEVICES: "Limiter les agents aux périphériques",
        UNITS: "Unités de consommation",
        TIME_SYNC_MODE: "Mode de synchronisation du temps de l'appareil",
        PRINT_READING_RECEIPT: "Autoriser l'impression des reçus",
        GPS_DISTRIBUTION_SUCCESS:
          "Prenez les coordonnées GPS pour une distribution réussie",
        GPS_DISTRIBUTION_FAILED:
          "Prenez les coordonnées GPS pour une distribution infructueuse",
        PICTURE_DISTRIBUTION_SUCCESS:
          "Prenez l'image pour une distribution réussie",
        PICTURE_DISTRIBUTION_FAILED:
          "Prenez l'image pour la distribution infructueuse",
        MANUAL_IMAGE_SYNC_CONFIRM_PIN:
          "Synchronisation Manuelle CODE de Confirmation",
        BACKGROUND_SYNC_MODE: "Synchronisation en arrière-plan",
        BACKGROUND_SYNC_INTERVAL:
          "Intervalle de synchronisation en arrière-plan",
        GPS_PERSIST_PERIOD: "Durée de réutilisation des coordonnées GPS stocké",
        GPS_TIMEOUT: "Durée maximale pour prendre le GPS",
        CAMSIZE_MTR_READING_OK:
          "Taille de la caméra pour la tâche de relève de compteur -> Relève Possible",
        CAMSIZE_MTR_READING_NOK:
          "Taille de la caméra pour la tâche de relève de compteur -> Relève Pas Possible",
        CAMSIZE_DIST_READING_OK:
          "Taille de la caméra pour la tâche de distribution -> Distribution Possible",
        CAMSIZE_DIST_READING_NOK:
          "Taille de la caméra pour la tâche de Distribution -> Distribution Pas Possible",
        CAMSIZE_MTR2_READING_OK:
          "Taille de la caméra pour la tâche de Correction d'Anomalie -> Correction Possible",
        CAMSIZE_MTR2_READING_NOK:
          "Taille de la caméra pour la tâche de Correction d'Anomalie -> Correction Pas Possible",
        CAMSIZE_CTRL_READING_OK:
          "Taille de la caméra pour la tâche de Contrôle de Relève -> Relève Possible",
        CAMSIZE_CTRL_READING_NOK:
          "Taille de la caméra pour la tâche de Contrôle de Relève -> Relève Pas Possible",
        CAMSIZE_NEWMTR:
          "Taille de la caméra pour l'Enregistrement du Nouveau Compteur",
        APP_NUM_CYCLES_KEEP_PIC: "Période de rétention d'image (en cycles)",
        ALLOW_DATA_SYNC_WITH_PICTURES:
          "Autoriser la synchronisation des données avec des images",
        ALLOW_DATA_SYNC: "Autoriser uniquement la synchronisation des données",
        SYNC_REMINDER:
          "Rappel pour l'utilisateur de l'application de synchroniser les données"
      }
    }
  }
};
