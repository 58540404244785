import { translate, TextField } from "react-admin";
import { compose, withProps, mapProps } from "recompose";
import omit from "lodash/omit";

export const DescriptionField = compose(
  translate,
  withProps(({ record, translationKey, translate }) => ({
    record: {
      ...record,
      description: translate(`mra.${translationKey}.${record.name}`)
    }
  })),
  mapProps(props => omit(props, ["translate", "translationKey"]))
)(TextField);
