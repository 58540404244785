import React from "react";
import PropTypes from "prop-types";
import { CardActions, Button as FlatButton } from "@material-ui/core";
import { ListButton, RefreshButton, translate } from "react-admin";
import config from "../../config";
import { LabeledMapButton } from "../base/buttons";

const cardActionStyle = {
  display: "flex",
  justifyContent: "flex-end"
};

const ReadingTaskShowActions = props => {
  const { translate, data, refresh, history } = props;
  return (
    <CardActions style={cardActionStyle}>
      <FlatButton color="primary" onClick={() => history.goBack()}>
        {translate("mra.general.back")}
      </FlatButton>
      {data ? (
        <ListButton
          basePath={`/${config.ITINERARY_RESOURCE}/${data.itinerary_id}/show`}
        />
      ) : null}
      <LabeledMapButton
        filtrationKey="task_id"
        record={data}
        mapResource={config.TASK_LOCATION}
      />
      <RefreshButton refresh={refresh} />
    </CardActions>
  );
};

ReadingTaskShowActions.propTypes = {
  translate: PropTypes.func,
  basePath: PropTypes.string,
  data: PropTypes.object,
  resource: PropTypes.string,
  refresh: PropTypes.func,
  history: PropTypes.object
};

export default translate(ReadingTaskShowActions);
