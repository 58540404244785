import React from "react";
import P from "prop-types";
import {
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteInput,
  translate
} from "react-admin";
import config from "../../config";
import {
  getRoleIdByName,
  getRecordFormValues,
  getCurrentUserRUContext,
  getCurrentUserRU
} from "../../redux/selectors";
import hideableField from "../fields/hideableField";
import { compose } from "recompose";
import { connect } from "react-redux";
import { TextField as MUITextField } from "@material-ui/core";
import RCSelectInput from "./RCSelectInput";
import { getFilter } from "../layout";

function makeUnitFilter(filter) {
  if (filter.center_id) {
    filter["@center_id"] = filter.center_id;
    delete filter.center_id;
  }
  return filter;
}

export const checkRoleContext = role => state => {
  const roleContext = getRecordFormValues("roles")(state);
  if (roleContext && roleContext.includes(getRoleIdByName(role, state))) {
    return true;
  }
  return false;
};

export const ReadingCenterInput = hideableField(
  checkRoleContext(config.ROLES.NAMES.RC)
)(ReferenceArrayInput);

export const ReadingUnitDisplay = translate(({ translate, ...props }) => {
  const { ru } = props;
  if (props.display) {
    return (
      <div>
        <MUITextField
          disabled={true}
          defaultValue={ru ? ru.name : null}
          hintText={translate(`mra.roles.accordingToRole`)}
          label={translate(`resources.${config.USER_RESOURCE}.fields.unit_id`)}
        />
      </div>
    );
  }
  return null;
});

export const ConnectedReadingUnitDisplay = connect(state => ({
  display: checkRoleContext(config.ROLES.NAMES.RU)(state)
}))(ReadingUnitDisplay);

export const ReadingUnitInput = hideableField(
  checkRoleContext(config.ROLES.NAMES.RU)
)(ReferenceInput);

export const ConnectedReadingUnitInput = connect(state => ({
  filter: makeUnitFilter(getFilter(state))
}))(ReadingUnitInput);

export const HideableRCSelectInput = compose(
  hideableField(
    checkRoleContext(config.ROLES.NAMES.RU) &&
      checkRoleContext(config.ROLES.NAMES.FA)
  )
)(RCSelectInput);

/** components below are not used anymore.
 * there is an issue with aor simpleforms and connected referenceinputs,
 * because of that the components are directly in UserEdit and UserCreate.
 */

const ReadingUnit = props => {
  /**
   * If there is a ruContext, the RU cant be changed!
   * users are locked in there contexts
   */
  if (props.ruContext) {
    return <ConnectedReadingUnitDisplay ru={props.ru} />;
  }
  return (
    <ReadingUnitInput
      {...props}
      source="reading_unit_id"
      reference={config.READINGUNIT_RESOURCE}
      filterToQuery={searchText => ({ name: searchText })}
      allowEmpty
    >
      <AutocompleteInput options={{ name: "reading unit" }} optionText="name" />
    </ReadingUnitInput>
  );
};

ReadingUnit.propTypes = {
  ruContext: P.string,
  ru: P.object
};

const ConnectedReadingUnit = connect(state => ({
  ruContext: getCurrentUserRUContext(state),
  ru: getCurrentUserRU(state)
}))(ReadingUnit);

export default ConnectedReadingUnit;
