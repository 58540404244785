import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import config from "../../config";
import {
  forceQC,
  confirmActivation,
  saveNewSequence,
  reopenItinerary,
  reopenDistributionItinerary,
  completeCapture,
  batchOperation,
  confirmItinerary,
  unassign
} from "../../redux/actions";
import ConfirmDialog from "./ConfirmDialog";
import { getRecord } from "../../redux/selectors";
export { GenerateItineraryDialog } from "./GenerateItineraryDialog";

const {
  ITINERARY_RESOURCE,
  DEVICE_RESOURCE,
  DISTRIBUTION_ITINERARY_RESOURCE,
  ANOMALIES_RESOURCE,
  CLIENT_ACTIVITY_RESOURCE,
  READINGCENTER_RESOURCE,
  USER_RESOURCE,
  CONTROL_READING_ITINERARY_RESOURCE,
  ANOMALY_CORRECTION_ITINERARY_RESOURCE,
  ITINERARIES_ASSIGN_PATH,
  DISTRIBUTION_ITINERARIES_ASSIGN_PATH,
  CONTROL_READING_ITINERARY_ASSIGN_PATH,
  ANOMALY_CORRECTION_ITINERARY_ASSIGN_PATH
} = config;

export const ForceExportItineraryDialog = compose(
  withProps({
    title: "mra.itinerary_overview.confirm_forceexport",
    resource: ITINERARY_RESOURCE
  }),
  connect(
    null,
    {
      handleSubmit: batchOperation(
        "force_export",
        {},
        { redirectTo: `/${ITINERARY_RESOURCE}` }
      )
    }
  )
)(ConfirmDialog);

export const ForceQCDialog = compose(
  withProps({
    title: "mra.itinerary_overview.confirm_force_qc",
    resource: ITINERARY_RESOURCE
  }),
  connect(
    null,
    { handleSubmit: forceQC }
  )
)(ConfirmDialog);

export const ReturnItineraryDialog = compose(
  withProps({
    title: "mra.itinerary_overview.confirm_return",
    resource: ITINERARY_RESOURCE
  }),
  connect(
    null,
    {
      handleSubmit: batchOperation(
        "return_for_estimation",
        {},
        { redirectTo: ITINERARY_RESOURCE }
      )
    }
  )
)(ConfirmDialog);

export const DeviceConfirmDialog = compose(
  withProps({
    title: "mra.devices.confirm_activate_title",
    resource: DEVICE_RESOURCE,
    source: "device_identifier",
    confirmButtonLabel: "mra.activate.activate",
    submitValue: null
  }),
  connect(
    null,
    {
      handleSubmit: (id, deleted_at) =>
        confirmActivation(DEVICE_RESOURCE, id, deleted_at)
    }
  )
)(ConfirmDialog);

/** Inject the props first in ConnectedConfirmResequenceDialog
 * after that inject the actions
 * this is necessary due to the use of the new sequence which has to be send in
 * the action, and comes from the props.
 */
export const ConfirmResequenceDialog = compose(
  withProps({
    title: "mra.itinerary_overview.confirm_resequence",
    resource: ITINERARY_RESOURCE
  }),
  connect(
    null,
    (dispatch, props) => {
      const {
        resequence,
        match: {
          params: { id }
        }
      } = props;
      return { handleSubmit: () => dispatch(saveNewSequence(id, resequence)) };
    }
  )
)(ConfirmDialog);

export const ConnectedConfirmResequenceDialog = compose(
  withProps({ title: "mra.itinerary_overview.confirm_resequence" }),
  connect(
    (state, props) => {
      const {
        match: {
          params: { id }
        }
      } = props;
      return {
        resequence: state.mra.resequence[id]
      };
    },
    null
  )
)(ConfirmResequenceDialog);

export const ReopenItineraryDialog = compose(
  withProps({
    title: "mra.itinerary_overview.confirm_reopen",
    resource: ITINERARY_RESOURCE
  }),
  connect(
    (state, props) => ({
      record: getRecord(ITINERARY_RESOURCE, props.match.params.id)(state)
    }),
    { handleSubmit: reopenItinerary }
  )
)(ConfirmDialog);

export const ReopenDistributionItineraryDialog = compose(
  withProps({
    title: "mra.itinerary_overview.confirm_reopen",
    resource: DISTRIBUTION_ITINERARY_RESOURCE
  }),
  connect(
    (state, props) => ({
      record: getRecord(DISTRIBUTION_ITINERARY_RESOURCE, props.match.params.id)(
        state
      )
    }),
    { handleSubmit: reopenDistributionItinerary }
  )
)(ConfirmDialog);

export const CompleteCaptureDialog = compose(
  withProps({
    title: "mra.itinerary_overview.confirm_capture",
    resource: ITINERARY_RESOURCE
  }),
  connect(
    (state, props) => ({
      record: getRecord(ITINERARY_RESOURCE, props.match.params.id)(state)
    }),
    { handleSubmit: completeCapture }
  )
)(ConfirmDialog);

export const AnomaliesActivationConfirmation = compose(
  withProps({
    title: "mra.anomaly.confirm_activate_title",
    resource: ANOMALIES_RESOURCE,
    confirmButtonLabel: "mra.activate.activate",
    source: "code",
    submitValue: null
  }),
  connect(
    null,
    {
      handleSubmit: (id, deleted_at) =>
        confirmActivation(ANOMALIES_RESOURCE, id, deleted_at)
    }
  )
)(ConfirmDialog);

export const ClientActivityAtivationConfirmation = compose(
  withProps({
    title: "mra.client_activity.confirm_activate_title",
    resource: CLIENT_ACTIVITY_RESOURCE,
    confirmButtonLabel: "mra.activate.activate",
    source: "code",
    submitValue: null
  }),
  connect(
    null,
    {
      handleSubmit: (id, deleted_at) =>
        confirmActivation(CLIENT_ACTIVITY_RESOURCE, id, deleted_at)
    }
  )
)(ConfirmDialog);

export const ReadingCenterActivationConfirmation = compose(
  withProps({
    title: "mra.reading_center.confirm_activate_title",
    resource: READINGCENTER_RESOURCE,
    confirmButtonLabel: "mra.activate.activate",
    submitValue: null
  }),
  connect(
    null,
    {
      handleSubmit: (id, deleted_at) =>
        confirmActivation(READINGCENTER_RESOURCE, id, deleted_at)
    }
  )
)(ConfirmDialog);

export const CompletionConfirm = compose(
  withProps({
    title: "mra.confirm.confirm",
    resource: ITINERARY_RESOURCE,
    confirmButtonLabel: "mra.confirm.confirm",
    source: "is_completion_confirmation_required",
    submitValue: null
  }),
  connect(
    null,
    (
      dispatch,
      {
        match: {
          params: { resource, id }
        }
      }
    ) => ({
      handleSubmit: () => {
        return dispatch(
          confirmItinerary(id, resource, `/${resource}/${id}/show`)
        );
      }
    })
  )
)(ConfirmDialog);

export const UserActivationConfirmation = compose(
  withProps({
    title: "mra.users.confirm_activate_title",
    resource: USER_RESOURCE,
    confirmButtonLabel: "mra.activate.activate",
    source: "username",
    submitValue: null
  }),
  connect(
    null,
    {
      handleSubmit: (id, deleted_at) =>
        confirmActivation(USER_RESOURCE, id, deleted_at, "list")
    }
  )
)(ConfirmDialog);

const resourceToAssignList = {
  [ITINERARY_RESOURCE]: ITINERARIES_ASSIGN_PATH,
  [DISTRIBUTION_ITINERARY_RESOURCE]: DISTRIBUTION_ITINERARIES_ASSIGN_PATH,
  [CONTROL_READING_ITINERARY_RESOURCE]: CONTROL_READING_ITINERARY_ASSIGN_PATH,
  [ANOMALY_CORRECTION_ITINERARY_RESOURCE]: ANOMALY_CORRECTION_ITINERARY_ASSIGN_PATH
};

export const UnassignConfirmation = compose(
  withProps(props => ({
    title: "mra.itinerary_assign.unassign_confirm",
    resource: props.match.params.type
  })),
  connect(
    null,
    (
      dispatch,
      {
        resource,
        match: {
          params: { id }
        }
      }
    ) => ({
      handleSubmit: () =>
        dispatch(unassign(id, { redirectTo: resourceToAssignList[resource] }))
    })
  )
)(ConfirmDialog);
