import React from "react";
import {
  Create,
  TextInput,
  BooleanInput,
  translate,
  required
} from "react-admin";
import { SimpleForm } from "../base";
import PropTypes from "prop-types";

/**
 * component for creating client activity
 code: "Code",
 is_active: "Active?",
 is_default: "Default?",
 label_lang_one: "Language 1",
 label_lang_two: "Language 2"
 */
export const ClientActivityCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          source="code"
          validate={required("mra.validation.required")}
        />
        <BooleanInput
          source="is_active"
          defaultValue={true}
          style={{ display: "none" }}
        />
        <BooleanInput source="is_default" defaultValue={false} />
        <TextInput source="label_lang_one" />
        <TextInput source="label_lang_two" />
      </SimpleForm>
    </Create>
  );
};

ClientActivityCreate.propTypes = {
  translate: PropTypes.func
};

export default translate(ClientActivityCreate);
