/*
 * Register a new meter
 */
import React from "react";
import PropTypes from "prop-types";
import {
  Create,
  TextInput,
  BooleanInput,
  SelectInput,
  DateInput,
  ReferenceInput,
  NumberInput,
  AutocompleteInput,
  required
} from "react-admin";
import compose from "recompose/compose";
import { SimpleForm } from "../base";
import config from "../../config";
import { getCurrentRCId } from "../../redux/selectors";
import { connect } from "react-redux";
import { FieldAgentInput, TranslatedSelectInput } from "../inputs";
import * as queryString from "query-string";
import { omit } from "lodash";
import mapProps from "recompose/mapProps";
import { safeValue } from "../utils";

const validate = (values, { translate }) => {
  const errors = {};

  if (!(values.meter_ref && values.meter_ref.trim().length)) {
    errors.meter_ref = [translate("mra.register_meter.meter_ref")];
  }
  if (!(values.dials && !isNaN(values.dials))) {
    errors.dials = [translate("mra.register_meter.dials")];
  }
  if (!(values.reading_value && values.reading_value.trim().length)) {
    errors.reading_value = [translate("mra.register_meter.reading_value")];
  }
  if (values.reading_value && values.reading_value.length > values.dials) {
    errors.reading_value = [
      translate("mra.register_meter.reading_value_chars")
    ];
  }
  if (!values.registration_date) {
    errors.registration_date = [
      translate("mra.register_meter.registration_date")
    ];
  }
  if (!values.agent_id) {
    errors.agent_id = [translate("mra.register_meter.agent_id")];
  }
  /*
  //These are not required any more #MRA-643
  if (!(values.lng && !isNaN(values.lng))) {
    errors.lng = [translate("mra.register_meter.lng")];
  }
  if (!(values.lat && !isNaN(values.lat))) {
    errors.lat = [translate("mra.register_meter.lat")];
  }*/
  if (!values.itinerary_id) {
    errors.itinerary_id = [translate("mra.register_meter.itinerary")];
  }
  if (!values.client_activity_id) {
    errors.client_activity_id = [
      translate("mra.register_meter.client_activity")
    ];
  }
  if (!values.reading_anomaly_id) {
    errors.reading_anomaly_id = [
      translate("mra.register_meter.reading_anomaly_id")
    ];
  }
  if (!values.picture_anomaly_id) {
    errors.picture_anomaly_id = [
      translate("mra.register_meter.picture_anomaly_id")
    ];
  }
  return errors;
};

/**
 * component for creating a new meter
 */
// eslint-disable-next-line no-unused-vars
export const RegisteredMeterCreate = ({ currentRCId, ...props }) => {
  const { itinerary } = queryString.parse(props.location.search);
  return (
    <Create {...props}>
      <SimpleForm validate={validate} defaultValue={{ center_id: currentRCId }}>
        <TextInput
          source="meter_ref"
          validate={required("mra.validation.required")}
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.meter_ref`}
        />
        <TextInput source="next_meter_ref" />
        <TextInput source="prev_meter_ref" />
        <ReferenceInput
          allowEmpty
          source="itinerary_id"
          defaultValue={itinerary ? itinerary : null}
          reference={config.ITINERARY_RESOURCE}
          filter={{
            center_id: safeValue(currentRCId)
          }}
          validate={required("mra.validation.required")}
          filterToQuery={searchText => ({ remote_id: searchText })}
        >
          <AutocompleteInput
            options={{ name: "itinerary" }}
            optionText="remote_id"
          />
        </ReferenceInput>
        <FieldAgentInput
          allowEmpty
          source="agent_id"
          reference={config.USER_RESOURCE}
          filterToQuery={searchText => ({ username: searchText })}
          validate={required("mra.validation.required")}
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.agent_id`}
        >
          <AutocompleteInput
            options={{ name: "reader" }}
            optionText="username"
          />
        </FieldAgentInput>
        <NumberInput
          source="dials"
          style={{ textAlign: "left" }}
          validate={required("mra.validation.required")}
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.dials`}
        />
        <TextInput
          source="value"
          validate={required("mra.validation.required")}
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.reading_value`}
        />
        <ReferenceInput
          allowEmpty
          source="meter_type_id"
          reference={config.METER_TYPE_RESOURCE}
          filterToQuery={searchText => ({ name: searchText })}
        >
          <SelectInput options={{ name: "meter type" }} optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          allowEmpty
          source="reading_type_id"
          reference={config.READING_TYPE_RESOURCE}
          filterToQuery={searchText => ({ label_lang_one: searchText })}
        >
          <SelectInput
            options={{ name: "reading type" }}
            optionText="label_lang_one"
          />
        </ReferenceInput>
        <DateInput
          source="registration_date"
          validate={required("mra.validation.required")}
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.registration_date`}
        />
        <TextInput source="location_description" />
        <BooleanInput source="has_barcode" defaultValue={false} />
        <NumberInput
          source="lng"
          style={{ textAlign: "left" }}
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.lng`}
        />
        <NumberInput
          source="lat"
          style={{ textAlign: "left" }}
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.lat`}
        />
        <ReferenceInput
          allowEmpty
          source="client_activity_id"
          reference={config.CLIENT_ACTIVITY_RESOURCE}
          validate={required("mra.validation.required")}
        >
          <TranslatedSelectInput />
        </ReferenceInput>
        <ReferenceInput
          allowEmpty
          source="anomaly_id"
          reference={config.ANOMALIES_RESOURCE}
          filter={{ type: config.ANOMALY_TYPES.READING.id }}
          validate={required("mra.validation.required")}
        >
          <TranslatedSelectInput />
        </ReferenceInput>
        <ReferenceInput
          allowEmpty
          source="picture_anomaly_id"
          reference={config.ANOMALIES_RESOURCE}
          filter={{ type: config.ANOMALY_TYPES.PICTURE.id }}
          validate={required("mra.validation.required")}
        >
          <TranslatedSelectInput />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

RegisteredMeterCreate.propTypes = {
  location: PropTypes.object,
  currentRCId: PropTypes.string,
  translate: PropTypes.func
};

export default compose(
  connect(
    state => ({
      currentRCId: getCurrentRCId(state)
    }),
    null
  ),
  mapProps(props => omit(props, ["dispatch"]))
)(RegisteredMeterCreate);
