import React from "react";
import P from "prop-types";
import { ReferenceField } from "react-admin";

export function RefField(props) {
  const { record, source } = props;
  if (record[source] === 0) {
    record[source] = null;
  }
  return <ReferenceField {...props} record={record} allowEmpty />;
}

RefField.propTypes = {
  record: P.object,
  source: P.string
};
