import config from "../../config";
import {
  getCurrentUserRUContext,
  checkCurrentUserRole,
  getCurrentUserId,
  getRcContext,
  getCurrentUserRC,
  checkPermissionByName,
  getReadingCenterById
} from "../../redux/selectors";
import filter from "lodash/filter";

export const getFilter = (state, props) => {
  //const { rcFilter } = props; //there are components which cant use addFilter function coz it overrides userfilters
  const rcFilter = getRcContext(state);
  const readingCenter = getReadingCenterById(rcFilter, state);
  const ruContext = getCurrentUserRUContext(state);
  const currentUserRole = checkCurrentUserRole(state);
  let filter = {};
  const isRMS = props && props.resource === config.RMS_RESOURCE;
  const isCalendar =
    props && props.resource === config.READING_CALENDAR_RESOURCE;
  //dont filter on rcContext too if there is a ruContext (rucontext is smaller)
  if (ruContext && !currentUserRole(config.ROLES.NAMES.HQ)) {
    filter = {
      ...filter,
      [isRMS ? "ru_id" : "unit_id"]: ruContext,
      ...(!isRMS && { "@units": ruContext })
    };
  } else if (rcFilter && rcFilter !== config.RC_CONTEXT_ALL) {
    //only filter on rcContext if they are hq member
    const shouldUseDiffNames = isRMS || isCalendar;
    filter = {
      ...filter,
      ...(shouldUseDiffNames
        ? { rc_code: readingCenter.remote_id }
        : { center_id: rcFilter }),
      ...(!shouldUseDiffNames && { center_id: rcFilter })
    };
  }
  // filter by capturer or ru depending on permissions
  if (
    !isRMS &&
    checkPermissionByName(
      config.PERMISSIONS.READING_ITINERARY_LIMIT_OWN_CAPTURER
    )(state)
  ) {
    filter = {
      ...filter,
      capturer_id: getCurrentUserId(state)
    };
  }

  if (
    isRMS &&
    checkPermissionByName(config.PERMISSIONS.EXECUTE_PICTURE_CONTROL_MY_RC)(
      state
    )
  ) {
    const { id } = getCurrentUserRC(state) || {};
    if (id) {
      filter = {
        ...filter,
        rc_id: getReadingCenterById(id, state).remote_id
      };
    }
  }
  // if(checkPermissionByName(config.READING_ITINERARY_LIMIT_OWN_RU)(state) || checkPermissionByName(config.DISTRIBUTION_ITINERARY_LIMIT_OWN_RU)(state))
  return filter;
};

export const defaultReferenceFilter = { deleted_at: null };

export const filterChoices = (choices, filterForChoices) => {
  if (filterForChoices) {
    if (typeof filterForChoices === "object") {
      return filter(choices, filterForChoices);
    }
  }
  return filter(choices, defaultReferenceFilter);
};
