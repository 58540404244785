import { connect } from "react-redux";
import compose from "recompose/compose";
import { getHumanReadableConnectionMode } from "../../config/filterOptions";
import mapProps from "recompose/mapProps";
import omit from "lodash/omit";
import TranslationField from "./TranslationField";

export default compose(
  connect(() => ({
    convertFunction: getHumanReadableConnectionMode
  })),
  mapProps(props => omit(props, ["dispatch"]))
)(TranslationField);
