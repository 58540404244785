import React from "react";
import P from "prop-types";
import { Show, TextField, SimpleShowLayout, translate } from "react-admin";
import { withStyles } from "@material-ui/core";
import config from "../../config";
import Images from "../layout/Images";
import TaskActions from "./TaskActions";
import {
  AnomalyField,
  ClientActivityField,
  CustomerNameField,
  DateTimeField,
  PrecisionModeField
} from "../fields";
import ConnectionModeField from "../fields/ConnectionModeField";
import SubTitle from "../base/sub-title";

const styles = {
  layoutGridWrapper: {
    display: "flex",
    flexWrap: "wrap"
  },
  dividerWrapper: {
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px"
  },
  subheading: {
    marginBottom: "5px"
  },
  inlineField: {
    display: "inline-flex",
    flexBasis: "calc(25% - 40px)",
    marginRight: 40
  },
  override: {
    width: "auto",
    height: "auto"
  }
};

const getAnomalyLabel = resource => {
  switch (resource) {
    case config.ANOMALY_CORRECTION_TASK_RESOURCE:
      return "correction";
    case config.CONTROL_READING_TASK_RESOURCE:
      return "control_reading";
    case config.DISTRIBUTION_TASK_RESOURCE:
      return "distribution";
    default:
      return "";
  }
};

const EditTitle = translate(props => {
  const { record, translate, resource } = props;
  return (
    <span>
      {translate(`resources.${resource}.name`, 1)}
      {props.record && props.record.meter
        ? ` - ${translate(
            `resources.${config.METER_RESOURCE}.fields.meter_identifier`
          )}: ${record.meter.meter_identifier}`
        : " "}
      {props.record && props.record.supply_point
        ? ` ${translate(
            `resources.${config.SUPPLYPOINT_RESOURCE}.fields.remote_id`
          )}: ${record.supply_point.remote_id}`
        : " "}
      {props.record && props.record.supply_point
        ? ` ${translate(
            `resources.${config.SUPPLYPOINT_RESOURCE}.fields.premise_identifier`
          )}: ${record.supply_point.premise_identifier}`
        : " "}
    </span>
  );
});

EditTitle.propTypes = {
  record: P.object
};

/**
 * component for displaying readingtask details
 */
const TaskDetails = ({ classes, ...props }) => (
  <Show
    {...props}
    actions={<TaskActions history={props.history} />}
    title={<EditTitle resource={props.resource} />}
  >
    <SimpleShowLayout className={classes.layoutGridWrapper}>
      <TextField
        className={classes.inlineField}
        label={`resources.${config.METER_RESOURCE}.fields.meter_identifier`}
        source="meter.meter_identifier"
      />
      <TextField
        className={classes.inlineField}
        label={`resources.${config.METER_RESOURCE}.fields.manufacturer`}
        source="meter.manufacturer"
      />
      <TextField
        className={classes.inlineField}
        label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.remote_id`}
        source="supply_point.remote_id"
      />
      <TextField
        className={classes.inlineField}
        label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.premise_identifier`}
        source="supply_point.premise_identifier"
      />
      <TextField
        className={classes.inlineField}
        label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.access`}
        source="supply_point.access"
      />
      <TextField
        className={classes.inlineField}
        label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.access_premise`}
        source="supply_point.access_premise"
      />
      <CustomerNameField
        addLabel
        className={classes.inlineField}
        label={`resources.${config.SUPPLYPOINT_RESOURCE}.fields.customer_name`}
        source="supply_point.customer_name"
      />
      <TextField
        className={classes.inlineField}
        label={`resources.${config.READING_RESOURCE}.fields.reading_value`}
        source="latest_result.value"
      />
      <DateTimeField
        className={classes.inlineField}
        label={`resources.${config.READING_RESOURCE}.fields.date_visited`}
        source="latest_result.date_visited_at"
        addLabel
      />
      <TextField
        className={classes.inlineField}
        label={`resources.${config.READING_RESOURCE}.fields.input_type`}
        source="latest_result.input_type"
      />
      <AnomalyField
        addLabel
        className={classes.inlineField}
        label={`mra.anomaly.${getAnomalyLabel(props.resource)}`}
        source="latest_result.anomaly_id"
      />
      <AnomalyField
        addLabel
        className={classes.inlineField}
        label={`mra.anomaly.picture`}
        source="latest_result.picture_anomaly_id"
      />
      <AnomalyField
        addLabel
        className={classes.inlineField}
        label={`mra.anomaly.occupancy`}
        source="latest_result.occupancy_anomaly_id"
      />
      <ClientActivityField
        addLabel
        className={classes.inlineField}
        label="mra.client_activity.name"
        source="latest_result.client_activity_id"
      />
      <ConnectionModeField
        addLabel
        className={classes.inlineField}
        label={`resources.${config.READINGTASK_RESOURCE}.fields.conn_mode`}
        source="latest_result.conn_mode"
      />
      <TextField
        addLabel
        className={classes.inlineField}
        label={`resources.${config.READINGTASK_RESOURCE}.fields.net_type`}
        source="latest_result.net_type"
      />
      <PrecisionModeField
        addLabel
        className={classes.inlineField}
        label={`resources.${config.READINGTASK_RESOURCE}.fields.gps_precision_mode`}
        source="latest_result.gps_precision_mode"
      />
      <TextField
        className={classes.inlineField}
        label={`resources.${config.READINGTASK_RESOURCE}.fields.comment`}
        source="latest_result.comment"
      />
      <SubTitle source="full-width" title="mra.itinerary_overview.images" />
      <Images
        source="latest_result.images"
        className={classes.override}
        type="grid"
      />
    </SimpleShowLayout>
  </Show>
);

TaskDetails.propTypes = {
  history: P.object,
  translate: P.func,
  classes: P.object,
  resource: P.string
};

export default withStyles(styles)(TaskDetails);
