import React from "react";
import PropTypes from "prop-types";
import { Card } from "@material-ui/core";
import { Title, translate } from "react-admin";
import compose from "recompose/compose";
import { connect } from "react-redux";
import queryString from "query-string";
import moment from "moment";
import {
  getCurrentRC,
  getActiveCycle,
  getCurrentUserRCs,
  getCurrentUserRU,
  getCurrentUser,
  getSettingValue
} from "../../redux/selectors";
import config from "../../config";
import reports from "../../config/reports";

export class Reports extends React.Component {
  renderReport = (props, nextProps) => {
    //gather the information for the json request
    const {
      reading_center,
      reading_centers,
      reading_unit,
      cycle,
      user,
      auth: { username, password },
      server_url
    } = props;
    if (!server_url) return;
    const { params } = nextProps;
    fetch(server_url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa(`${username}:${password}`)
      },
      body: JSON.stringify({
        template: {
          shortid: reports[params.report].shortid
        },
        data: {
          date: moment().format("YYYY-MM-DD"),
          cycle,
          user,
          reading_center,
          reading_centers,
          reading_unit
        }
      })
    })
      .then(res => {
        if (!res.ok) {
          throw Error(`${res.status} ${res.statusText}`);
        }
        return res.text();
      })
      .then(html => {
        this.setState({ html });
      })
      .catch(e => {
        this.setState({
          html: `
          <div style="display:flex;flex-direction:column;align-items:center;opacity:.5">
            <h1>Failed to get report</h1>
            <div>${e.message}</div>
          </div>
        `
        });
      });
  };

  constructor(props) {
    super(props);
    const { translate } = props;
    this.state = {
      html: `<div style="margin-left:10px;">${translate(
        "mra.general.loading"
      )}... <progress></progress></div>`
    };
    this.renderReport(this.props, this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.params.report !== prevProps.params.report) {
      this.renderReport(prevProps, this.props);
    }
  }

  render() {
    const { translate, params } = this.props;
    return (
      <Card>
        <Title title={translate(reports[params.report].title)} />
        <iframe
          title={"iframe"}
          frameBorder={"0"}
          srcDoc={this.state.html}
          target={"_parent"}
          allowFullScreen={false}
          style={{ position: "relative" }}
          height={"10000px"}
          width={"100%"}
        />
      </Card>
    );
  }
}

Reports.propTypes = {
  translate: PropTypes.func,
  params: PropTypes.object
};

const ConnectedReports = compose(
  connect((state, props) => {
    const username = getSettingValue(config.REPORTING_SERVER_USERNAME)(state);
    const password = getSettingValue(config.REPORTING_SERVER_PASSWORD)(state);
    const server_url = getSettingValue(config.REPORTING_SERVER_URL)(state);
    return {
      reading_center: getCurrentRC(state),
      reading_unit: getCurrentUserRU(state),
      reading_centers: getCurrentUserRCs(state),
      user: getCurrentUser(state),
      cycle: getActiveCycle(state),
      params: queryString.parse(props.location.search),
      auth: { username, password },
      server_url: server_url ? `${server_url}/api/report` : ""
    };
  }),
  translate
)(Reports);

export default ConnectedReports;
