import React from "react";
import PropTypes from "prop-types";
import config from "../../config";

const style = `
html,body,route-sheet{
    width: 99%;
    height: 100%;
}
@media print {
    thead {display: table-header-group;}

    thead th {
        border: 1px solid;
        font-size: 12px;
        font-weight: bold;
        padding: 2px;
    }

    tbody{display: table-row-group;}

    tbody td{
        border: 1px solid;
        font-size: 11px;
        font-style: normal;
        padding: 2px;
     }
}

@page{
    size: A4 portrait;
    margin: 0;
}
`;

const AnomalySheet = ({ anomalies, locale }) =>
  <div className="anomaly-sheet">
    <style type="text/css">
      {style}
    </style>
    <table style={{ width: "100%" }}>
      <thead style={{ pageBreakAfter: "avoid" }}>
        <tr>
          <th colSpan="3">ENEO-Anomaly Sheet</th>
        </tr>
        <tr>
          <th>Code</th>
          <th>Type</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {anomalies.map(a =>
          <tr key={a.id}>
            <td>
              {a.code}
            </td>
            <td>
              {a.type}
            </td>
            <td>
              {locale === config.LOCALES.ENGLISH
                ? a.label_lang_one
                : a.label_lang_two}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>;

AnomalySheet.propTypes = {
  locale: PropTypes.string,
  anomalies: PropTypes.array
};

export default AnomalySheet;
