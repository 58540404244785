import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import compose from "recompose/compose";
import Typography from "@material-ui/core/Typography";
import NavigateNext from "@material-ui/icons/NavigateNext";
import { toggleSidebar as toggleSidebarAction } from "react-admin";
import {
  getCurrentUser,
  getCurrentUserRU,
  getAllReadingCenters,
  getCurrentUserPermissionNames,
  getLocale
} from "../../redux/selectors";
import config from "../../config";
import { get, size, filter } from "lodash";
import {
  AppBar as MuiAppBar,
  IconButton,
  withStyles,
  Button,
  Select,
  MenuItem,
  Toolbar,
  withWidth
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import UserIcon from "@material-ui/icons/Person";

import { switchRcContext } from "../../redux/actions";
import MraLogo from "../../assets/MRA_Logo.png";
import { pull } from "lodash";

const styles = theme => ({
  toolbar: {
    paddingRight: 24
  },
  menuButton: {
    marginLeft: "0.5em",
    marginRight: "0.5em",
    width: 48,
    height: 48
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(0deg)"
  },
  title: {
    flex: 1,
    height: 64,
    display: "flex",
    alignItems: "center"
  },
  actionRightBar: {
    display: "flex",
    flexDirection: "row",
    paddingRight: 20
  },
  selectRoot: {
    width: 335,
    margin: 2,
    color: theme.palette.primary.contrastText,
    "&:before": {
      borderBottom: `1px solid ${theme.palette.primary.contrastText}`
    },
    "&:after": {
      borderBottom: `2px solid ${theme.palette.primary.contrastText}`
    },
    "&:hover:before": {
      borderBottom: `2px solid ${theme.palette.primary.contrastText} !important`
    }
  },
  select: {
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText
  }
});

export const AppBar = ({
  user,
  userPermissions,
  readingUnit,
  readingCenters,
  rcContext,
  gotoProfile,
  selectRC,
  toggleSidebar,
  className,
  classes,
  open,
  width,
  ...props
}) => {
  const userName = get(user, "username");
  const rcIds = get(user, "centers");
  const rcList =
    rcIds && size(readingCenters) > 0
      ? rcIds.map(id => ({
          id: readingCenters[id].id,
          name: `${readingCenters[id].remote_id} - ${readingCenters[id].name}`,
          deleted_at: readingCenters[id].deleted_at
        }))
      : [];
  if (userPermissions && userPermissions.includes("AccessGlobalContext")) {
    rcList.push({
      id: config.RC_CONTEXT_ALL,
      name: "Global Context"
    });
    Object.values(readingCenters).forEach(rc => {
      rcList.push({
        id: rc.id,
        name: `${rc.remote_id} - ${rc.name}`,
        deleted_at: rc.deleted_at
      });
    });
  }
  pull(rcList, undefined);
  const activeRcList = filter(rcList, rc => !rc.deleted_at);
  return (
    <MuiAppBar
      className={className}
      color="primary"
      position="fixed"
      {...props}
    >
      <Toolbar
        disableGutters
        variant={width === "xs" ? "regular" : "dense"}
        className={classes.toolbar}
      >
        <IconButton
          aria-label="open drawer"
          color="inherit"
          onClick={toggleSidebar}
          className={classes.menuButton}
        >
          <MenuIcon
            classes={{
              root: open
                ? classes.menuButtonIconOpen
                : classes.menuButtonIconClosed
            }}
          />
        </IconButton>
        <div className={classes.title}>
          <img src={MraLogo} alt="logo" style={{ height: "64px" }} />
          <NavigateNext />
          <Typography
            variant="title"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
          />
        </div>
        <div className={classes.actionRightBar}>
          <Button color="inherit" onClick={gotoProfile}>
            <UserIcon />
            {`${userName} ${readingUnit ? " RU: " + readingUnit.name : ""} RC:`}
          </Button>
          {(rcContext || activeRcList.length > 0) && (
            <Select
              value={rcContext ? rcContext : activeRcList[0].id}
              className={classes.selectRoot}
              classes={{
                root: classes.selectRoot,
                select: classes.select,
                selectMenu: classes.select,
                icon: classes.select
              }}
              onChange={event => selectRC(event.target.value)}
              disabled={activeRcList && activeRcList.length === 1}
            >
              {activeRcList
                ? activeRcList.map(rc => (
                    <MenuItem color="inherit" key={rc.id} value={rc.id}>
                      {rc.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
          )}
        </div>
      </Toolbar>
    </MuiAppBar>
  );
};

AppBar.propTypes = {
  user: PropTypes.object,
  userPermissions: PropTypes.array,
  readingUnit: PropTypes.string,
  readingCenters: PropTypes.object,
  rcContext: PropTypes.string,
  gotoProfile: PropTypes.func,
  selectRC: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object,
  open: PropTypes.bool,
  width: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  userLabel: PropTypes.string
};

const mapStateToProps = state => ({
  user: getCurrentUser(state),
  userPermissions: getCurrentUserPermissionNames(state),
  readingUnit: getCurrentUserRU(state),
  readingCenters: getAllReadingCenters(state),
  rcContext: state.mra.rcContext,
  locale: getLocale(state)
});

const enhance = compose(
  connect(
    mapStateToProps,
    {
      toggleSidebar: toggleSidebarAction,
      gotoProfile: () => replace(`${config.PROFILE_PATH}`),
      selectRC: switchRcContext
    }
  ),
  withStyles(styles),
  withWidth()
);

export default enhance(AppBar);
