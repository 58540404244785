import React from "react";
import PropTypes from "prop-types";
import { ReferenceField as RField, TextField } from "react-admin";

import { connect } from "react-redux";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";

import get from "lodash/get";
import clone from "lodash/clone";
import set from "lodash/set";
import isNil from "lodash/isNil";
import omit from "lodash/omit";

import { getCurrentLang } from "../../redux/selectors";

const ReferenceField = ({
  textSourceField,
  defaultValue = "",
  sourceId,
  ...props
}) => {
  const { record = {}, source = "" } = props;
  const value = get(record, source, defaultValue);
  return !!value && value !== defaultValue ? (
    <RField {...props} linkType={false} allowEmpty>
      <TextField source={textSourceField} />
    </RField>
  ) : (
    <TextField {...props} record={set(record, source, defaultValue)} />
  );
};

ReferenceField.propTypes = {
  textSourceField: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sourceId: PropTypes.array,
  record: PropTypes.object,
  source: PropTypes.string
};

export const ConfiguredReferenceField = compose(
  connect((state, { sourceId = [], ...props }) => {
    let { record = {}, source, defaultValue = 0 } = props;
    let cloneRecord = clone(record);
    if (sourceId.length > 0) {
      // update record value if we have sourceId
      // and sourceId key is not null or undefined
      const values = sourceId.map(key => ({
        field: key,
        value: get(cloneRecord, key, defaultValue)
      }));
      let swapItem = values.find(
        item => item.value !== defaultValue && !!item.value
      );
      if (isNil(swapItem)) {
        swapItem = values[0];
      }
      cloneRecord = set(cloneRecord, swapItem.field, swapItem.value);
      source = swapItem.field;
    }
    return {
      textSourceField: getCurrentLang(state).label,
      ...props,
      source,
      record: cloneRecord
    };
  }),
  mapProps(props => omit(props, ["dispatch"]))
)(ReferenceField);
