import React from "react";
import PropTypes from "prop-types";
import {
  Show,
  ReferenceField,
  TextField,
  NumberField,
  BooleanField,
  SimpleShowLayout,
  translate
} from "react-admin";
import {
  FieldGroup,
  // ExpansionPanel,
  // ExpansionPanelSummary,
  // ExpansionPanelDetails,
  commonStyles
} from "../base";
import { withStyles } from "@material-ui/core";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import compose from "recompose/compose";
import config from "../../config";
import DetailsList from "./DetailList";
import ItineraryShowActions from "./ItineraryShowActions";
import { TypeTranslationField, DateTimeField } from "../fields";
import { connect } from "react-redux";
import Images from "../layout/Images";
import QualityCheckTable from "./QualityCheckTable";
import { RenderChildren } from "../base/utils";
import { isPaperReading } from "../utils/index";
import { useItineraryStats } from "../hooks";
import { AvgConsumptionField } from "../fields/AvgConsumptionField";

const ConnectedShowLayout = connect((state, props) => ({
  record: {
    ...props.record,
    ...props.stats
  }
}))(SimpleShowLayout);

const EditTitle = translate(props => (
  <span>
    {props.translate(`resources.${config.ITINERARY_RESOURCE}.name`, 1)} -{" "}
    {props.record ? `"${props.record.remote_id}"` : ""}
  </span>
));

EditTitle.propTypes = {
  record: PropTypes.object
};

const ItineraryDetailsExtended = ({ className, ...props }) => (
  <div className={className}>
    <RenderChildren {...props} />
  </div>
);

ItineraryDetailsExtended.propTypes = {
  className: PropTypes.string
};

/**
 * component for displaying itinerary details
 */

function getSectionTitle(resource, record) {
  return isPaperReading(resource, record.method)
    ? "mra.itinerary_overview.route_sheet"
    : "mra.itinerary_overview.images";
}
function ItineraryDetails({ classes, ...props }) {
  const { id, resource } = props;
  const stats = useItineraryStats(id, resource);
  return (
    <Show {...props} actions={<ItineraryShowActions />} title={<EditTitle />}>
      <ConnectedShowLayout stats={stats}>
        <FieldGroup
          title="mra.itinerary_overview.itinerary_data"
          className={classes.fieldGroup}
        >
          <TextField source="route" />
          <TextField source="remote_id" />
          <TextField source="cycle" />
          <TextField source="cycle_year" />
          <TextField source="description" />
          <TypeTranslationField
            source="status"
            types={config.ITINERARY_STATUS_TYPES}
          />
          <TextField source="method" />
          <DateTimeField source="scheduled_at" addLabel />
          <AvgConsumptionField
            addLabel
            label={`resources.${config.READING_RESOURCE}.fields.avg_consumption`}
            source="avg_consumption"
            avg_source="c_money_brutto"
            cur_source="c_money_brutto_currency"
          />
          <BooleanField source="is_in_quality" />
          <DateTimeField source="exported_at" addLabel />
          <ReferenceField
            addLabel={true}
            source="center_id"
            reference={config.READINGCENTER_RESOURCE}
            linkType={false}
            allowEmpty
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            addLabel={true}
            source="agent_id"
            reference={config.USER_RESOURCE}
            linkType={false}
            allowEmpty
          >
            <TextField source="username" />
          </ReferenceField>
          <ReferenceField
            addLabel={true}
            source="capturer_id"
            reference={config.USER_RESOURCE}
            linkType={false}
            allowEmpty
          >
            <TextField source="username" />
          </ReferenceField>
          <DateTimeField addLabel source="completion_confirmed_at" />
          <ReferenceField
            addLabel
            source="completion_confirmed_by_id"
            reference={config.USER_RESOURCE}
            linkType={false}
            allowEmpty
          >
            <TextField source="username" />
          </ReferenceField>
        </FieldGroup>
        <FieldGroup
          title="mra.itinerary_overview.quality_checks"
          className={classes.fieldGroup}
        >
          <QualityCheckTable style={{ height: "auto" }} />
        </FieldGroup>
        <FieldGroup
          title="mra.itinerary_overview.reading_summary"
          className={classes.fieldGroup}
        >
          <NumberField source="task_count" style={{ textAlign: "left" }} />
          <NumberField source="result_count" style={{ textAlign: "left" }} />
          <NumberField
            source="total_anomaly_count"
            style={{ textAlign: "left" }}
          />
          <NumberField source="anomaly_count" style={{ textAlign: "left" }} />
          <NumberField
            source="picture_anomaly_count"
            style={{ textAlign: "left" }}
          />
          <DateTimeField source="updated_at" addLabel />
          <DateTimeField source="created_at" addLabel />
          <DateTimeField source="imported_at" addLabel />
        </FieldGroup>
        <FieldGroup title={getSectionTitle} className={classes.fieldGroup}>
          <Images source="images" className={classes.override} type="grid" />
        </FieldGroup>
        <FieldGroup
          title="mra.itinerary_overview.details"
          className={classes.fieldGroup}
        >
          <DetailsList {...props} bulkActionButtons={false} />
        </FieldGroup>
      </ConnectedShowLayout>
    </Show>
  );
}

ItineraryDetails.propTypes = {
  id: PropTypes.string,
  resource: PropTypes.string,
  translate: PropTypes.func,
  classes: PropTypes.object
};

export default compose(
  translate,
  withStyles({
    ...commonStyles,
    noPaper: {
      boxShadow: "none"
    },
    expanded: {
      marginBottom: "0 !important"
    },
    summaryRoot: {
      position: "relative",
      width: "100%",
      flexDirection: "column",
      marginBottom: "0 !important"
    },
    expandIcon: {
      top: 73,
      right: "5%",
      "&$expanded": {
        top: 80
      }
    },
    extendedData: {
      display: "flex",
      flexDirection: "row"
    },
    field: {
      display: "inline-block",
      marginRight: 40
    }
  })
)(ItineraryDetails);
