import React from "react";
import PropTypes from "prop-types";
import { translate, TextField, ReferenceField } from "react-admin";
import { withStyles } from "@material-ui/core";
import RegisteredMetersIcon from "@material-ui/icons/Assignment";
import config from "../../config";
import moment from "moment";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Card } from "../base";
import { getCurrentLang } from "../../redux/selectors";
import { safeValue } from "../utils";
import Images from "../layout/Images";
import {
  AnomalyField,
  ClientActivityField,
  GpsCoordinateField,
  MultiField,
  PrecisionModeField,
  ReferenceManyField
} from "../fields";
import PictureControl from "../fields/PictureControl";

const styles = () => ({
  label: {
    flex: 1
  },
  header: {
    backgroundColor: "#04B486",
    color: "#fff"
  }
});

function MeterCard(props) {
  const {
    classes,
    record,
    lang: { dateTimeFormat },
    translate,
    ...rest
  } = props;
  const { meter_ref, metadata, images, registration_date, itinerary } = record;
  const { visited_at, itinerary_eid, meter_identifier } = metadata || {};
  const { remote_id } = itinerary || {};
  const title = (
    <span className={classes.title}>
      {`
        MTR: ${safeValue(meter_identifier || meter_ref)} -
        ITIN: ${safeValue(itinerary_eid || remote_id)}
      `}
    </span>
  );
  const subheader = `${translate(
    "resources." + config.REGISTERED_METERS_RESOURCE + ".type_name"
  )} - ${moment(visited_at || registration_date).format(dateTimeFormat)}`;

  return (
    <Card
      title={title}
      subheader={subheader}
      images={images}
      record={record}
      avatar={<RegisteredMetersIcon />}
      cardType={config.REGISTERED_METERS_RESOURCE}
      classes={{ header: classes.header }}
      {...rest}
    >
      <MultiField
        addLabel
        label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.itinerary_cycle`}
      >
        <TextField
          addLabel
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.itinerary_id`}
          source={record.metadata ? "metadata.itinerary_eid" : "itinerary_id"}
        />
        <TextField
          addLabel
          source={record.metadata ? "metadata.cycle" : "cycle"}
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.cycle`}
        />
      </MultiField>
      <TextField
        addLabel
        label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.reading_value`}
        source={record.metadata ? "metadata.reading_value" : "value"}
      />
      <ClientActivityField
        addLabel
        label="mra.client_activity.name"
        sourceId={[
          record.metadata ? "metadata.client_activity_id" : "client_activity_id"
        ]}
      />
      <Images
        addLabel
        label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.images`}
        source={record.metadata ? "id" : "images"}
        type="count"
      />
      <PictureControl addLabel label="mra.anomaly.picture_control" />
      <TextField addLabel collapsed source="dials" />
      <AnomalyField
        addLabel
        collapsed
        label="mra.anomaly.picture"
        source="picture_anomaly_id"
      />
      <AnomalyField
        addLabel
        collapsed
        label="mra.anomaly.reading"
        source="anomaly_id"
      />
      {record.metadata ? (
        <ReferenceField
          addLabel
          collapsed
          linkType={false}
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.reading_center_id`}
          source="metadata.rc_id"
          reference={config.READINGCENTER_RESOURCE}
        >
          <TextField source="name" />
        </ReferenceField>
      ) : (
        <ReferenceManyField
          addLabel
          collapsed
          linkType={false}
          label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.reading_center_id`}
          source="center_id"
          reference={config.READINGCENTER_RESOURCE}
        >
          <TextField source="remote_id" />
          <span className={classes.whiteSpace}> - </span>
          <TextField source="name" />
        </ReferenceManyField>
      )}
      <GpsCoordinateField
        addLabel
        collapsed
        label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.gps_coordinates`}
        lng_source="lng"
        lat_source="lat"
      />
      <PrecisionModeField
        addLabel
        collapsed
        label={`resources.${config.REGISTERED_METERS_RESOURCE}.fields.gps_precision_mode`}
        className={classes.inlineField}
        source="gps_precision_mode"
      />
    </Card>
  );
}

MeterCard.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object,
  basePath: PropTypes.string,
  resource: PropTypes.string,
  lang: PropTypes.object,
  translate: PropTypes.func,
  rc: PropTypes.object
};

MeterCard.defaultProps = {
  rc: {}
};

export default compose(
  connect(state => ({
    lang: getCurrentLang(state)
  })),
  withStyles(styles),
  translate
)(MeterCard);
