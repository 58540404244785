import React, { Fragment } from "react";
import P from "prop-types";
import {
  List,
  TextField,
  Filter,
  BooleanField,
  ReferenceField,
  ArrayField,
  SingleFieldList,
  ChipField,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  TextInput,
  ShowButton,
  translate
} from "react-admin";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import omit from "lodash/omit";
import { Datagrid } from "../base";
import { CardActions, Button } from "@material-ui/core";
import Check from "@material-ui/icons/CheckCircle";
import LockOpen from "@material-ui/icons/LockOpen";
import NavigationRefresh from "@material-ui/icons/Refresh";

import config from "../../config";
import { getFilter } from "../layout";
import { TypeTranslationField, DateTimeField } from "../fields";
import {
  getDeviceCodeSetting,
  getRcContext,
  getRoleIdByName
} from "../../redux/selectors";
import {
  RestrictedFlatButton,
  RestrictedEditButton
} from "../restricted-components";

// eslint-disable-next-line no-unused-vars
const DeviceButtons = ({ dispatch, ...props }) => (
  <span>
    <ShowButton label="" {...props} />
    {props.record.deleted_at ? (
      <RestrictedFlatButton
        color="primary"
        component={Link}
        to={`/${props.resource}/${props.record.id}/activate`}
        style={{ minWidth: 0, overflow: "inherit" }}
        requiredPermissions={[config.PERMISSIONS.DEVICE_UPDATE]}
      >
        <LockOpen />
      </RestrictedFlatButton>
    ) : (
      <Fragment>
        {props.record.onb_status === "REGISTERED" ? (
          <RestrictedFlatButton
            color="primary"
            component={Link}
            to={`${props.resource}/${props.record.id}/confirm`}
            style={{ minWidth: 0, overflow: "inherit" }}
            requiredPermissions={[config.PERMISSIONS.DEVICE_CONFIRM]}
          >
            <Check />
          </RestrictedFlatButton>
        ) : null}
        <ConnectedRestrictedEditButton
          requiredPermissions={[config.PERMISSIONS.DEVICE_READ]}
          style={{ minWidth: 0, overflow: "inherit" }}
          {...props}
        />
      </Fragment>
    )}
  </span>
);
DeviceButtons.propTypes = {
  dispatch: P.func,
  record: P.shape({
    id: P.string,
    deleted_at: P.string,
    onb_status: P.string
  }),
  resource: P.string
};

const ConnectedRestrictedEditButton = connect((state, props) => {
  /**
   * nobody has permissions to edit devices on global rc context MRA-370
   * result: edit button is not available if current rccontext is global
   */
  const permission =
    getRcContext(state) === config.RC_CONTEXT_ALL
      ? config.NOPERMISSION
      : props.requiredPermissions;
  return {
    requiredPermissions: permission
  };
})(RestrictedEditButton);

DeviceButtons.contextTypes = {
  translate: P.func
};

const cardActionStyle = {
  zIndex: 2,
  display: "inline-block",
  float: "right",
  width: "60%"
};

const actionStyle = {
  float: "right"
};

const DeviceActions = ({
  resource,
  filters,
  displayedFilters,
  filterValues,
  showFilter,
  refresh,
  code,
  translate
}) => (
  <CardActions style={cardActionStyle}>
    <Button disabled>
      {`${translate("mra.devices.code_setting")}: ${code}`}
    </Button>
    {filters && (
      <div style={actionStyle}>
        {React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button"
        })}
        <Button color="primary" onClick={refresh}>
          <NavigationRefresh />
          refresh
        </Button>
      </div>
    )}
  </CardActions>
);

DeviceActions.propTypes = {
  filters: P.node,
  translate: P.func,
  resource: P.string,
  displayedFilters: P.object,
  filterValues: P.object,
  showFilter: P.func,
  basePath: P.string,
  refresh: P.func,
  code: P.string
};

const ConnectedDeviceActions = compose(
  connect(state => ({
    code: getDeviceCodeSetting(state)
  })),
  translate
)(DeviceActions);

/**
 * filters for list component
 */
const DeviceFilter = ({ showRcFilter, ...props }) => (
  <Filter
    {...props}
    filterValues={props.filterValues ? props.filterValues : {}}
  >
    {showRcFilter ? (
      <ReferenceInput
        source="center_id"
        reference={config.READINGCENTER_RESOURCE}
        filterToQuery={searchText => ({ remote_id: searchText })}
        allowEmpty
      >
        <AutocompleteInput
          options={{ name: "reading center" }}
          optionValue="id"
          optionText="remote_id"
        />
      </ReferenceInput>
    ) : null}
    <ReferenceInput
      source="@user_id"
      label={`resources.${config.DEVICE_RESOURCE}.fields.users`}
      reference={config.USER_RESOURCE}
      filterToQuery={searchText => ({ username: searchText })}
      allowEmpty
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <TextInput source="device_identifier" />
    <TextInput source="device_serial" />
    <TextInput source="app_version" />
    <SelectInput
      source="onb_status"
      choices={Object.values(config.DEVICE_STATUS_TYPES)}
    />
  </Filter>
);

DeviceFilter.propTypes = {
  showRcFilter: P.bool,
  filterValues: P.object
};

const ConnectedDeviceFilter = compose(
  connect(
    (state, props) => ({
      showRcFilter: config.RC_CONTEXT_ALL === getRcContext(state),
      filter: {
        ...props.filter,
        roles: [getRoleIdByName(config.ROLES.NAMES.FA, state)]
      }
    }),
    null
  ),
  mapProps(props => omit(props, "dispatch"))
)(DeviceFilter);

/**
 * component for listing mra devices
 */
export const DeviceList = props => (
  <List
    {...props}
    perPage={config.PAGINATION}
    filters={<ConnectedDeviceFilter filter={props.filter} />}
    sort={{ field: "onb_registered_at", order: "ASC" }}
    actions={<ConnectedDeviceActions />}
    filter={props.filter}
    bulkActions={false}
  >
    <Datagrid>
      <TextField source="device_identifier" />
      <TextField source="device_serial" />
      <TextField source="app_version" />
      <TypeTranslationField
        source="onb_status"
        types={config.DEVICE_STATUS_TYPES}
      />
      <TextField source="model" />
      <ReferenceField
        source="center_id"
        reference={config.READINGCENTER_RESOURCE}
        linkType={false}
        allowEmpty
      >
        <TextField source="remote_id" />
      </ReferenceField>
      <BooleanField source="is_blocked" />
      <ArrayField source="users" sortable={false}>
        <SingleFieldList linkType={false}>
          <ChipField source="username" />
        </SingleFieldList>
      </ArrayField>
      <DateTimeField source="onb_registered_at" />
      <DeviceButtons />
    </Datagrid>
  </List>
);

DeviceList.propTypes = {
  filter: P.object
};

export const ConnectedDeviceList = compose(
  connect(
    state => ({
      filter: getFilter(state)
    }),
    null
  ),
  mapProps(props => omit(props, "dispatch"))
)(DeviceList);

export default ConnectedDeviceList;
