import {
  JWT_RECEIVED,
  SWITCH_RCCONTEXT,
  PRINT_ROUTESHEET,
  PRINT_ROUTESHEET_SUCCESS,
  PRINT_ROUTESHEET_COMPLETE,
  SET_NEW_SEQUENCE,
  CLEAR_NEW_SEQUENCE,
  SET_READING_VALUE,
  CLEAR_READINGS,
  GET_CURRENTUSER_SUCCESS,
  GET_USER_INFO_SUCCESS,
  UPDATE_NEW_SEQUENCE,
  SET_SELECTED_FIELD,
  CLEAR_SELECTED_FIELD,
  SET_PREFILL_SELECTED_DATE,
  CLEAR_PREFILL_SELECTED_DATE,
  SET_QUALITY_THRESHOLD,
  SET_UPLOAD_FILES,
  ADD_DISABLED_RC_TO_TOKEN,
  ASSIGNMENT_WITH_METHOD_CHANGE,
  OPEN_LIGHT_BOX,
  CLOSE_LIGHT_BOX,
  DOWNLOAD_IMAGE_START,
  DOWNLOAD_IMAGE_SUCCESS,
  SET_PAGE,
  SET_PER_PAGE,
  SET_SORT,
  SET_FILTERS,
  START_UNDOABLE,
  STOP_UNDOABLE,
  CLOSE_MODAL,
  OPEN_MODAL
} from "./actions";
import { USER_LOGOUT } from "react-admin";
import config from "../config";
import findKey from "lodash/findKey";
import get from "lodash/get";
import mapValues from "lodash/mapValues";
import max from "lodash/max";
import jwtDecode from "jwt-decode";

const initialState = {
  jwt: null,
  rcContext: null,
  routesheetLoading: {},
  resequence: {},
  readings: {},
  currentUser: {},
  userPermissions: {},
  userRoles: [],
  thresholds: {},
  lightbox: null,
  images: {},
  dashboard: {},
  modals: {},
  undoInProgress: false
};

const SORT_ASC = "ASC";
const SORT_DESC = "DESC";
const initDashboardState = {
  page: 1,
  perPage: config.PAGINATION,
  sort: null,
  order: null,
  filters: {}
};

export default (state = initialState, { type, payload, requestPayload }) => {
  switch (type) {
    case OPEN_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.modalName]: {
            isOpen: true,
            options: payload.options
          }
        }
      };
    }
    case CLOSE_MODAL: {
      const modal = get(state.modals, payload.modalName);
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.modalName]: {
            ...modal,
            isOpen: false
          }
        }
      };
    }
    case JWT_RECEIVED:
      return {
        ...state,
        jwt: payload.jwt,
        decodedJwt: jwtDecode(payload.jwt)
      };
    case USER_LOGOUT:
      return { ...state, jwt: null };
    case GET_CURRENTUSER_SUCCESS:
      return { ...state, currentUser: payload.data };
    case GET_USER_INFO_SUCCESS: {
      const {
        data: { permissions, roles }
      } = payload;
      return {
        ...state,
        userPermissions: permissions.reduce((m, p) => {
          m[p.id] = p;
          return m;
        }, {}),
        userRoles: roles
      };
    }
    case SWITCH_RCCONTEXT:
      return { ...state, rcContext: `${payload.rcContext}` };
    case PRINT_ROUTESHEET:
      return {
        ...state,
        routesheetLoading: {
          ...state.routesheetLoading,
          [payload.id]: config.PRINT_STATE.LOADING
        }
      };
    case PRINT_ROUTESHEET_SUCCESS:
      return {
        ...state,
        routesheetLoading: {
          ...state.routesheetLoading,
          [requestPayload.id]: config.PRINT_STATE.READY
        }
      };
    case PRINT_ROUTESHEET_COMPLETE:
      return {
        ...state,
        routesheetLoading: {
          ...state.routesheetLoading,
          [payload.id]: config.PRINT_STATE.DEFAULT
        }
      };
    case CLEAR_NEW_SEQUENCE:
      return {
        ...state,
        resequence: {}
      };
    case SET_NEW_SEQUENCE: {
      const { itineraryId } = payload;
      const resequence = state.resequence[itineraryId]
        ? Object.assign({}, state.resequence[itineraryId])
        : {};
      const taskId = payload.taskId;
      resequence[taskId] = Number(payload.newSequence);
      return {
        ...state,
        resequence: { ...state.resequence, [itineraryId]: resequence }
      };
    }
    case UPDATE_NEW_SEQUENCE: {
      const { itineraryId } = payload;
      if (payload.newSequence === "") return state;
      const taskId = `${payload.taskId}`;
      let newSequence = Number(payload.newSequence);
      let resequence = state.resequence[itineraryId]
        ? Object.assign({}, state.resequence[itineraryId])
        : {};
      const currentSequence = resequence[taskId];
      const maxSeq = max(Object.values(resequence));
      if (currentSequence && newSequence > maxSeq) newSequence = maxSeq;
      const oldTaskAtSequence = findKey(resequence, seq => seq === newSequence);
      if (oldTaskAtSequence) {
        resequence = mapValues(resequence, (seq, task_id) => {
          if (task_id === taskId) return newSequence;
          else if (seq <= newSequence && seq >= currentSequence) return seq - 1;
          else if (seq >= newSequence && seq <= currentSequence) return seq + 1;
          else return seq;
        });
      } else {
        // console.warn(`No task found at new sequence number ${newSequence}`);
      }
      return {
        ...state,
        resequence: { ...state.resequence, [itineraryId]: resequence }
      };
    }
    case SET_READING_VALUE: {
      const { id, value, field } = payload;
      return {
        ...state,
        readings: {
          ...state.readings,
          [id]: { ...state.readings[id], [field]: value }
        }
      };
    }
    case CLEAR_READINGS:
      return {
        ...state,
        readings: {}
      };
    case SET_SELECTED_FIELD: {
      const { id, value } = payload;
      return {
        ...state,
        selectedFields: {
          ...state.selectedFields,
          [id]: value
        }
      };
    }
    case CLEAR_SELECTED_FIELD:
      return {
        ...state,
        selectedFields: {}
      };
    case SET_PREFILL_SELECTED_DATE:
      return {
        ...state,
        prefill_selected_date: payload.date
      };
    case CLEAR_PREFILL_SELECTED_DATE:
      return {
        ...state,
        prefill_selected_date: null
      };
    case SET_QUALITY_THRESHOLD: {
      const { rId, qId, threshold } = payload;
      return {
        ...state,
        thresholds: {
          ...state.thresholds,
          [rId]: { ...state.thresholds[rId], [qId]: threshold }
        }
      };
    }
    case SET_UPLOAD_FILES: {
      const { resource, id, source } = payload.meta;
      return {
        ...state,
        uploadFiles: {
          [resource]: {
            [id]: {
              [source]: payload.data
            }
          }
        }
      };
    }
    case ADD_DISABLED_RC_TO_TOKEN: {
      const { id } = payload;
      const regex = new RegExp(`,?${id}`);
      const enabled_rcs = state.decodedJwt.enabled_rcs.replace(regex, "");
      let disabled_rcs = state.decodedJwt.disabled_rcs;
      disabled_rcs =
        disabled_rcs === ""
          ? disabled_rcs.concat(`${id}`)
          : disabled_rcs.concat(`,${id}`);
      return {
        ...state,
        decodedJwt: {
          ...state.decodedJwt,
          enabled_rcs,
          disabled_rcs
        }
      };
    }
    case ASSIGNMENT_WITH_METHOD_CHANGE: {
      const { id, old_method, new_method } = payload;
      if (
        state.assignmentWithMethodChange &&
        state.assignmentWithMethodChange.id === id
      )
        return state;
      return {
        ...state,
        assignmentWithMethodChange: {
          id,
          old_method,
          new_method
        }
      };
    }
    case OPEN_LIGHT_BOX:
      return { ...state, lightbox: payload };
    case CLOSE_LIGHT_BOX:
      return { ...state, lightbox: null };
    case DOWNLOAD_IMAGE_START: {
      const { image } = payload;
      return {
        ...state,
        images: {
          ...state.images,
          [image.id]: { isLoading: true }
        }
      };
    }
    case DOWNLOAD_IMAGE_SUCCESS: {
      const { url, blob, image } = payload;
      return {
        ...state,
        images: {
          ...state.images,
          [image.id]: { url, blob, isLoading: false }
        }
      };
    }
    case SET_PAGE: {
      const { id, page } = payload;
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          [id]: {
            ...(state.dashboard[id] || initDashboardState),
            page
          }
        }
      };
    }
    case SET_PER_PAGE: {
      const { id, perPage } = payload;
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          [id]: {
            ...(state.dashboard[id] || initDashboardState),
            page: 1,
            perPage
          }
        }
      };
    }
    case SET_SORT: {
      const { id, sort } = payload;
      const { dashboard } = state;
      const { sort: prevSort, order: prevOrder } = dashboard[id] || {};
      return {
        ...state,
        dashboard: {
          ...dashboard,
          [id]: {
            ...(dashboard[id] || initDashboardState),
            page: 1,
            sort,
            order:
              sort === prevSort
                ? prevOrder === SORT_DESC
                  ? SORT_ASC
                  : SORT_DESC
                : SORT_ASC
          }
        }
      };
    }
    case SET_FILTERS: {
      const { id, filters } = payload;
      const { dashboard } = state;
      return {
        ...state,
        dashboard: {
          ...dashboard,
          [id]: {
            ...(dashboard[id] || initDashboardState),
            filters
          }
        }
      };
    }
    case START_UNDOABLE:
      return { ...state, undoInProgress: true };
    case STOP_UNDOABLE:
      return { ...state, undoInProgress: false };
    default:
      return state;
  }
};
