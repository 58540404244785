import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, crudGetMany } from "react-admin";
import get from "lodash/get";
import PinDrop from "@material-ui/icons/PinDrop";
import { openModal } from "../../../redux/actions";
import config from "../../../config";

const isValid = (data, latKey, lngKey) => {
  if (data) {
    const lat = get(data, latKey);
    const lng = get(data, lngKey);
    if (lat === undefined && lng === undefined) return true;
    return (
      lat && lng && (lat >= -90 && lat <= 90) && (lng >= -180 && lng <= 180)
    );
  }
  return false;
};

export const MapButton = props => {
  const {
    record,
    mapResource,
    fieldsToDisplay,
    latField,
    lngField,
    label,
    filtrationKey,
    fetchResource,
    crudGetMany,
    openModal
  } = props;
  return (
    <Button
      color="primary"
      label={label}
      disabled={!isValid(record, latField, lngField)}
      onClick={() => {
        if (fetchResource) {
          crudGetMany(mapResource, [record.id]);
        }
        openModal(config.MODALS.MAP_MODAL, {
          record,
          resource: mapResource,
          fieldsToDisplay,
          latField,
          lngField,
          filtrationKey,
          filteredItems: { [record.id]: true },
          mapCenter: [get(record, lngField), get(record, latField)]
        });
      }}
    >
      <PinDrop />
    </Button>
  );
};

MapButton.propTypes = {
  openModal: PropTypes.func,
  record: PropTypes.object,
  label: PropTypes.string,
  mapResource: PropTypes.string,
  fetchResource: PropTypes.bool,
  latField: PropTypes.string.isRequired,
  lngField: PropTypes.string.isRequired,
  crudGetMany: PropTypes.func.isRequired,
  fieldsToDisplay: PropTypes.array,
  filtrationKey: PropTypes.string
};

MapButton.defaultProps = {
  latField: "supply_point.lat",
  lngField: "supply_point.lng",
  filtrationKey: "id",
  fetchResource: true,
  fieldsToDisplay: config.MAP_FIELDS
};

export default connect(
  null,
  { openModal, crudGetMany }
)(MapButton);
