import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

/**
 * this is the main entrypoint for the app, it renders the app component to the div with id root
 */
ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.register();
