/* eslint-disable no-unused-vars */
import React, { Children, cloneElement } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import TextField from "@material-ui/core/TextField";
import { addField, translate, LinearProgress, Labeled } from "react-admin";
import ReferenceInputController from "./ReferenceInputController";

const sanitizeRestProps = ({
  allowEmpty,
  basePath,
  choices,
  className,
  component,
  crudGetMatching,
  crudGetOne,
  defaultValue,
  filter,
  filterToQuery,
  formClassName,
  initializeForm,
  input,
  isRequired,
  label,
  locale,
  meta,
  onChange,
  optionValue,
  optionText,
  perPage,
  record,
  reference,
  referenceSource,
  resource,
  setFilter,
  setPagination,
  setSort,
  sort,
  source,
  textAlign,
  translate,
  translateChoice,
  validation,
  ...rest
}) => rest;

const ReferenceInputView = ({
  allowEmpty,
  basePath,
  children,
  choices,
  classes,
  className,
  error,
  input,
  isRequired,
  isLoading,
  label,
  meta,
  onChange,
  resource,
  setFilter,
  setPagination,
  setSort,
  source,
  translate,
  warning,
  ...rest
}) => {
  if (isLoading) {
    return (
      <Labeled
        label={label}
        source={source}
        resource={resource}
        className={className}
        isRequired={isRequired}
      >
        <LinearProgress />
      </Labeled>
    );
  }

  if (error) {
    return (
      <TextField error disabled label={label} value={error} margin="normal" />
    );
  }

  return cloneElement(children, {
    allowEmpty,
    classes,
    className,
    input,
    isRequired,
    label,
    resource,
    meta: {
      ...meta,
      helperText: warning || false
    },
    source,
    choices,
    basePath,
    onChange,
    setFilter,
    setPagination,
    setSort,
    translateChoice: false,
    ...sanitizeRestProps(rest)
  });
};

ReferenceInputView.propTypes = {
  allowEmpty: PropTypes.bool,
  basePath: PropTypes.string,
  children: PropTypes.element,
  choices: PropTypes.array,
  classes: PropTypes.object,
  className: PropTypes.string,
  error: PropTypes.string,
  input: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  onChange: PropTypes.func,
  resource: PropTypes.string.isRequired,
  setFilter: PropTypes.func,
  setPagination: PropTypes.func,
  setSort: PropTypes.func,
  source: PropTypes.string,
  translate: PropTypes.func.isRequired,
  warning: PropTypes.string
};

export const ReferenceInput = ({ children, ...props }) => {
  if (Children.count(children) !== 1) {
    throw new Error("<ReferenceInput> only accepts a single child");
  }
  const { optionValue = "id" } = children.props;
  return (
    <ReferenceInputController {...props} optionValue={optionValue}>
      {controllerProps => (
        <ReferenceInputView {...props} {...{ children, ...controllerProps }} />
      )}
    </ReferenceInputController>
  );
};

ReferenceInput.propTypes = {
  allowEmpty: PropTypes.bool.isRequired,
  basePath: PropTypes.string,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object,
  filter: PropTypes.object,
  filterToQuery: PropTypes.func.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object,
  onChange: PropTypes.func,
  perPage: PropTypes.number,
  record: PropTypes.object,
  reference: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.oneOf(["ASC", "DESC"])
  }),
  source: PropTypes.string,
  translate: PropTypes.func.isRequired
};

ReferenceInput.defaultProps = {
  allowEmpty: false,
  filter: {},
  filterToQuery: searchText => ({ q: searchText }),
  perPage: 25,
  sort: { field: "id", order: "DESC" }
};

const EnhancedReferenceInput = compose(
  addField,
  translate
)(ReferenceInput);

export default EnhancedReferenceInput;
