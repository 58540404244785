import React from "react";
import P from "prop-types";
import { Edit, DisabledInput, TextInput, translate } from "react-admin";
import { SimpleForm } from "../base";
import config from "../../config";
import { Button as FlatButton, CardActions } from "@material-ui/core";

const cardActionStyle = {
  zIndex: 2,
  display: "inline-block",
  float: "right"
};

const Actions = translate(({ translate, history }) =>
  <CardActions style={cardActionStyle}>
    <div>
      <FlatButton color="primary" onClick={() => history.goBack()}>
        {translate("mra.general.back")}
      </FlatButton>
    </div>
  </CardActions>
);

const validate = (values, { translate }) => {
  const errors = {};
  if (
    values.password &&
    values.confirmPassword &&
    values.password !== values.confirmPassword
  ) {
    errors.confirmPassword = [translate("mra.reset.match")];
  }
  return errors;
};

/**
 * component for resetting the password of a user
 */
const ResetPassword = ({ translate, match, location, history }) => {
  return (
    <Edit
      id={match.params.id}
      hasShow={false}
      title={translate("mra.reset.title")}
      basePath={`/${config.USER_RESOURCE}`}
      resource={config.USER_RESOURCE}
      match={match}
      location={location}
      actions={<Actions history={history} />}
    >
      <SimpleForm validate={validate}>
        <DisabledInput source="username" />
        <TextInput
          label="mra.reset.reset"
          source="password"
          type="password"
          defaultValue=""
        />
        <TextInput
          label="mra.reset.confirm"
          source="confirmPassword"
          type="password"
          defaultValue=""
        />
      </SimpleForm>
    </Edit>
  );
};

ResetPassword.propTypes = {
  user: P.object,
  translate: P.func,
  match: P.object,
  history: P.object,
  location: P.object
};

export default translate(ResetPassword);
