import React from "react";
import { Edit, ReferenceField, TextField, required } from "react-admin";
import P from "prop-types";
import { withStyles } from "@material-ui/core";
import { SimpleForm, Actions } from "../base";
import config from "../../config";
import { DescriptionField, DateTimeField } from "../fields";
import { SettingInput } from "../inputs";

const EditTitle = props => {
  return (
    <span>
      {props.translate(
        `resources.${config.DEVICES_SETTINGS_RESOURCE}.name`,
        2
      )}{" "}
      - {props.record ? `"${props.record.name}"` : ""}
    </span>
  );
};

EditTitle.propTypes = {
  translate: P.func,
  record: P.object
};

export const DeviceSettingEdit = ({ classes, ...props }) => {
  return (
    <Edit {...props} actions={<Actions />}>
      <SimpleForm className={classes.form}>
        <TextField addLabel source="name" />
        <DescriptionField
          addLabel
          fullWidth
          source="description"
          translationKey="mobile_settings_descriptions"
        />
        <ReferenceField
          addLabel={true}
          source="updated_by_id"
          reference={config.USER_RESOURCE}
          linkType={false}
          allowEmpty={true}
        >
          <TextField source="username" />
        </ReferenceField>
        <DateTimeField source="updated_at" addLabel />
        <SettingInput
          source="value"
          parse={v => (v !== null ? String(v) : null)}
          validate={required("mra.settings.value")}
        />
      </SimpleForm>
    </Edit>
  );
};

DeviceSettingEdit.propTypes = {
  classes: P.object,
  translate: P.func
};

export default withStyles({
  form: {
    "& > div > .ra-input": {
      display: "flex"
    }
  }
})(DeviceSettingEdit);
