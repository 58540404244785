import React from "react";
import P from "prop-types";
import { CardActions } from "@material-ui/core";
import { ListButton, RefreshButton, translate } from "react-admin";
import { Button as FlatButton } from "@material-ui/core";
import config from "../../config";
import { RestrictedDeleteButton } from "../restricted-components";
import { LabeledMapButton } from "../base/buttons";

const {
  ANOMALY_CORRECTION_TASK_RESOURCE,
  CONTROL_READING_TASK_RESOURCE,
  DISTRIBUTION_TASK_RESOURCE,
  ANOMALY_CORRECTION_ITINERARY_RESOURCE,
  CONTROL_READING_ITINERARY_RESOURCE,
  DISTRIBUTION_ITINERARY_RESOURCE
} = config;

const cardActionStyle = {
  display: "flex",
  justifyContent: "flex-end"
};

const taskToItineraryMap = {
  [DISTRIBUTION_TASK_RESOURCE]: DISTRIBUTION_ITINERARY_RESOURCE,
  [CONTROL_READING_TASK_RESOURCE]: CONTROL_READING_ITINERARY_RESOURCE,
  [ANOMALY_CORRECTION_TASK_RESOURCE]: ANOMALY_CORRECTION_ITINERARY_RESOURCE
};

const TaskActions = props => {
  const { data, refresh, history, translate, resource } = props;
  const hasDelete =
    resource === config.CONTROL_READING_TASK_RESOURCE &&
    data &&
    data.itinerary &&
    [
      config.ITINERARY_STATUS_TYPES.UNASSIGNED.id,
      config.ITINERARY_STATUS_TYPES.READY.id,
      config.ITINERARY_STATUS_TYPES.PAPER_READING_READY.id
    ].indexOf(data.itinerary.status.toUpperCase()) !== -1;
  let redirectPath;
  if (hasDelete) {
    redirectPath = `/${resource}/${data.itinerary_id}/show`;
  }
  return (
    <CardActions style={cardActionStyle}>
      <FlatButton color="primary" onClick={() => history.goBack()}>
        {translate("mra.general.back")}
      </FlatButton>
      {data ? (
        <ListButton
          basePath={`/${taskToItineraryMap[resource]}/${data.itinerary_id}/show`}
        />
      ) : null}
      {hasDelete && (
        <RestrictedDeleteButton
          undoable={false}
          resource={resource}
          requiredPermissions={[
            config.PERMISSIONS.DELETE_CONTROL_ITINERARY_TASK
          ]}
          basePath={redirectPath}
          record={data}
        />
      )}
      <LabeledMapButton
        record={data}
        filtrationKey="task_id"
        mapResource={config.TASK_LOCATION}
      />
      <RefreshButton refresh={refresh} />
    </CardActions>
  );
};

TaskActions.propTypes = {
  basePath: P.string,
  data: P.object,
  resource: P.string,
  history: P.object,
  translate: P.func,
  refresh: P.func
};

export default translate(TaskActions);
