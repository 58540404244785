import React from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import {
  ExpansionPanel as MaterialExpansionPanel,
  ExpansionPanelDetails as MaterialExpansionPanelDetails,
  withStyles
} from "@material-ui/core";
import MaterialExpansionPanelSummary from "./ExpansionPanelSummary";
import compose from "recompose/compose";
import { RenderChildren } from "../utils";

export const withRenderChildren = Component => {
  return class WrappedComponent extends React.Component {
    static BaseComponent = Component;
    render() {
      return (
        <Component
          {...omit(this.props, ["children", "record", "resource", "basePath"])}
        >
          <RenderChildren {...this.props} />
        </Component>
      );
    }
  };
};

export const ExpansionPanel = ({
  record,
  resource,
  basePath,
  children,
  ...props
}) =>
  <MaterialExpansionPanel {...props}>
    {React.Children.map(children, child =>
      React.cloneElement(child, {
        record,
        resource,
        basePath
      })
    )}
  </MaterialExpansionPanel>;

ExpansionPanel.propTypes = {
  record: PropTypes.object,
  basePath: PropTypes.string,
  resource: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node)
};

export const ExpansionPanelSummary = compose(withRenderChildren)(
  MaterialExpansionPanelSummary
);

ExpansionPanelSummary.BaseComponent.muiName = "ExpansionPanelSummary";
ExpansionPanelSummary.muiName = "ExpansionPanelSummary";

export const ExpansionPanelDetails = compose(
  withStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      padding: "0"
    }
  }),
  withRenderChildren
)(MaterialExpansionPanelDetails);
