/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import {
  Create,
  TextInput,
  ReferenceInput,
  SelectInput,
  translate,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  required
} from "react-admin";
import { SimpleForm } from "../base";
import config from "../../config";
import { addRcFilter } from "../layout";

function arrayValidation(value, _, { translate }) {
  return value && value.length
    ? undefined
    : translate("mra.validation.required");
}

export const ReadingUnitCreate = ({
  dispatch,
  translate,
  rcFilter,
  ...props
}) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          source="name"
          validate={required("mra.reading_unit.name")}
          label={`resources.${config.READINGUNIT_RESOURCE}.fields.name`}
        />
        <SelectInput
          source="type"
          choices={config.RU_TYPES}
          validate={required("mra.reading_unit.type")}
          label={`resources.${config.READINGUNIT_RESOURCE}.fields.type`}
        />
        <TextInput source="contact_person" />
        <TextInput source="phone" />
        <TextInput source="email" type="email" />
        <TextInput source="registration" />
        <TextInput source="tax_number" />
        <TextInput source="ref_number" />
        <TextInput source="address" />
        <ReferenceInput
          allowEmpty
          source="region_id"
          reference={config.REGION_RESOURCE}
          validate={required("mra.reading_unit.region_id")}
          label={`resources.${config.READINGUNIT_RESOURCE}.fields.region_id`}
        >
          <SelectInput options={{ name: "region" }} optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput
          allowEmpty
          source="centers"
          reference={config.READINGCENTER_RESOURCE}
          filter={
            props.rcFilter && props.rcFilter !== config.RC_CONTEXT_ALL
              ? { id: props.rcFilter }
              : null
          }
          filterToQuery={searchText => ({ remote_id: searchText })}
          validate={[required(), arrayValidation]}
        >
          <AutocompleteArrayInput optionText="remote_id" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};

ReadingUnitCreate.propTypes = {
  dispatch: PropTypes.func,
  rcFilter: PropTypes.string,
  translate: PropTypes.func
};

export default addRcFilter(translate(ReadingUnitCreate));
