import React from "react";
import { translate, List, TextField, BooleanField } from "react-admin";
import { RestrictedEditButton } from "../restricted-components";
import config from "../../config";
import { Datagrid, ListActions } from "../base";

/**
 * component for listing mra devices
 */
const TranslatedDayField = translate(({ translate, record, source }) => (
  <span>{translate(`mra.constants.days.${record[source]}`)}</span>
));
const WorkHoursList = props => (
  <List
    {...props}
    actions={<ListActions />}
    sort={{ field: "id", order: "ASC" }}
    perPage={config.PAGINATION}
  >
    <Datagrid>
      <TranslatedDayField source="day" />
      <TextField source="start" />
      <TextField source="end" />
      <BooleanField source="is_access" />
      <RestrictedEditButton
        requiredPermissions={[config.PERMISSIONS.WORK_HOUR_READ]}
      />
    </Datagrid>
  </List>
);

export default WorkHoursList;
