import React, { Children } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { LinearProgress } from "react-admin";
import { Link } from "react-router-dom";
import ReferenceFieldController from "./ReferenceFieldController";
import { sanitizeRestProps } from "./utils";
const stopPropagation = e => e.stopPropagation();

export const ReferenceFieldView = ({
  allowEmpty,
  basePath,
  children,
  className,
  classes = {},
  isLoading,
  record,
  reference,
  referenceRecord,
  resource,
  resourceLinkPath,
  source,
  translateChoice = false,
  ...rest
}) => {
  if (isLoading) {
    return <LinearProgress />;
  }

  if (resourceLinkPath) {
    return (
      <Link
        to={resourceLinkPath}
        className={className}
        onClick={stopPropagation}
      >
        {React.cloneElement(Children.only(children), {
          className: classnames(children.props.className),
          record: referenceRecord,
          resource: reference,
          allowEmpty,
          basePath,
          translateChoice,
          ...sanitizeRestProps(rest)
        })}
      </Link>
    );
  }

  return React.cloneElement(Children.only(children), {
    record: referenceRecord,
    resource: reference,
    allowEmpty,
    basePath,
    translateChoice,
    ...sanitizeRestProps(rest)
  });
};

ReferenceFieldView.propTypes = {
  allowEmpty: PropTypes.bool,
  basePath: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
  record: PropTypes.object,
  reference: PropTypes.string,
  referenceRecord: PropTypes.object,
  resource: PropTypes.string,
  resourceLinkPath: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  source: PropTypes.string,
  translateChoice: PropTypes.bool
};

const ReferenceField = ({ children, ...props }) => {
  if (React.Children.count(children) !== 1) {
    throw new Error("<ReferenceField> only accepts a single child");
  }

  return (
    <ReferenceFieldController {...props}>
      {controllerProps => (
        <ReferenceFieldView {...props} {...{ children, ...controllerProps }} />
      )}
    </ReferenceFieldController>
  );
};

ReferenceField.propTypes = {
  addLabel: PropTypes.bool,
  allowEmpty: PropTypes.bool.isRequired,
  basePath: PropTypes.string,
  children: PropTypes.element.isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  reference: PropTypes.string.isRequired,
  resource: PropTypes.string,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired,
  translateChoice: PropTypes.func,
  linkType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

ReferenceField.defaultProps = {
  allowEmpty: false,
  classes: {},
  linkType: "edit",
  record: {}
};

const EnhancedReferenceField = ReferenceField;

EnhancedReferenceField.defaultProps = {
  addLabel: true
};

EnhancedReferenceField.displayName = "EnhancedReferenceField";

export default EnhancedReferenceField;
