export const cycleChoices = [...Array(12).keys()].map(i => ({
  id: i + 1,
  name: `${i + 1}`
}));

export const pictureQtyChoices = [
  { id: 0, name: "ALL" },
  { id: 1, name: "OK" },
  { id: 2, name: "NOK" }
];

export const itineraryTypeChoices = [
  { id: "reading", name: "mra.task_types.reading" },
  { id: "distribution", name: "mra.task_types.distribution" },
  { id: "registered_meter", name: "mra.task_types.registered_meter" },
  { id: "control", name: "mra.task_types.control" },
  { id: "anomaly_correction", name: "mra.task_types.anomaly_correction" }
];

export const getHumanReadablePrecisionMode = (precisionMode /* 1..4 */) => {
  switch (parseInt(precisionMode)) {
    case 1:
      return "mra.constants.gps_precision_mode.high";
    case 2:
      return "mra.constants.gps_precision_mode.low";
    case 3:
      return "mra.constants.gps_precision_mode.cached";
    case 4:
      return "mra.constants.gps_precision_mode.unknown";
    default:
      return "mra.constants.gps_precision_mode.unrecognised";
  }
};

export const getHumanReadableConnectionMode = (connMode /* 1..4 */) => {
  switch (parseInt(connMode)) {
    case 1:
      return "mra.constants.connection_mode.online";
    case 2:
      return "mra.constants.connection_mode.offline";
    case 3:
      return "mra.constants.connection_mode.degraded";
    default:
      return "mra.constants.connection_mode.unrecognised";
  }
};
