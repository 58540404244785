import { Component } from "react";
import P from "prop-types";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import compose from "recompose/compose";
import { createSelector } from "reselect";
import { isEqual, uniqBy } from "lodash";
import {
  crudGetManyAccumulate as crudGetManyAccumulateAction,
  crudGetMatchingAccumulate as crudGetMatchingAccumulateAction,
  getPossibleReferences,
  getPossibleReferenceValues,
  getReferenceResource,
  translate
} from "react-admin";

const isMatchingReferencesError = matchingReferences =>
  matchingReferences && matchingReferences.error !== undefined;

const defaultReferenceSource = (resource, source) => `${resource}@${source}`;

const getStatusForInput = ({
  input,
  matchingReferences,
  referenceRecord,
  translate = x => x
}) => {
  const matchingReferencesError = isMatchingReferencesError(matchingReferences)
    ? translate(matchingReferences.error, {
        _: matchingReferences.error
      })
    : null;
  const selectedReferenceError =
    input.value && !referenceRecord
      ? translate("ra.input.references.single_missing", {
          _: "ra.input.references.single_missing"
        })
      : null;

  return {
    waiting:
      (input.value && selectedReferenceError && !matchingReferences) ||
      (!input.value && !matchingReferences),
    error:
      (input.value && selectedReferenceError && matchingReferencesError) ||
      (!input.value && matchingReferencesError)
        ? input.value
          ? selectedReferenceError
          : matchingReferencesError
        : null,
    warning: selectedReferenceError || matchingReferencesError,
    choices: Array.isArray(matchingReferences)
      ? matchingReferences
      : [referenceRecord].filter(choice => choice)
  };
};

export class UnconnectedReferenceInputController extends Component {
  static defaultProps = {
    allowEmpty: false,
    filter: {},
    filterToQuery: searchText => ({ q: searchText }),
    matchingReferences: null,
    perPage: 25,
    sort: { field: "id", order: "DESC" },
    referenceRecord: null,
    referenceSource: defaultReferenceSource
  };

  constructor(props) {
    super(props);
    const { perPage, sort, filter } = props;
    this.state = { pagination: { page: 1, perPage }, sort, filter };
    this.debouncedSetFilter = debounce(this.setFilter.bind(this), 500);
  }

  componentDidMount() {
    this.fetchReferenceAndOptions(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      (this.props.record || { id: undefined }).id !==
      (nextProps.record || { id: undefined }).id
    ) {
      this.fetchReferenceAndOptions(nextProps);
    } else if (this.props.input.value !== nextProps.input.value) {
      this.fetchReference(nextProps);
    } else if (
      !isEqual(nextProps.filter, this.props.filter) ||
      !isEqual(nextProps.sort, this.props.sort) ||
      nextProps.perPage !== this.props.perPage
    ) {
      this.setState(
        state => ({
          filter: nextProps.filter,
          pagination: {
            ...state.pagination,
            perPage: nextProps.perPage
          },
          sort: nextProps.sort
        }),
        this.fetchOptions
      );
    }
  }

  setFilter = filter => {
    if (filter !== this.state.filter) {
      this.setState(
        { filter: this.props.filterToQuery(filter) },
        this.fetchOptions
      );
    }
  };

  setPagination = pagination => {
    if (pagination !== this.state.pagination) {
      this.setState({ pagination }, this.fetchOptions);
    }
  };

  setSort = sort => {
    if (sort !== this.state.sort) {
      this.setState({ sort }, this.fetchOptions);
    }
  };

  fetchReference = (props = this.props) => {
    const { crudGetManyAccumulate, input, reference } = props;
    const id = input.value;
    if (id) {
      crudGetManyAccumulate(reference, [id]);
    }
  };

  fetchOptions = (props = this.props) => {
    const {
      crudGetMatchingAccumulate,
      filter: filterFromProps,
      reference,
      referenceSource,
      resource,
      source
    } = props;
    const { pagination, sort, filter } = this.state;

    crudGetMatchingAccumulate(
      reference,
      referenceSource(resource, source),
      pagination,
      sort,
      { ...filterFromProps, ...filter }
    );
  };

  fetchReferenceAndOptions(props) {
    this.fetchReference(props);
    this.fetchOptions(props);
  }

  render() {
    const {
      input,
      referenceRecord,
      matchingReferences,
      onChange,
      children,
      translate,
      optionValue
    } = this.props;
    const { pagination, sort, filter } = this.state;

    const dataStatus = getStatusForInput({
      input,
      matchingReferences,
      referenceRecord,
      translate
    });

    return children({
      choices: uniqBy(dataStatus.choices, optionValue),
      error: dataStatus.error,
      isLoading: dataStatus.waiting,
      onChange,
      filter,
      setFilter: this.debouncedSetFilter,
      pagination,
      setPagination: this.setPagination,
      sort,
      setSort: this.setSort,
      warning: dataStatus.warning
    });
  }
}

const makeMapStateToProps = () =>
  createSelector(
    [
      getReferenceResource,
      getPossibleReferenceValues,
      (_, props) => props.input.value,
      (_, props) => props.optionValue
    ],
    (referenceState, possibleValues, inputId, optionValue) => ({
      matchingReferences: getPossibleReferences(
        referenceState,
        possibleValues,
        [inputId]
      ),
      referenceRecord:
        referenceState &&
        Object.values(referenceState.data).find(v => v[optionValue] === inputId)
    })
  );

UnconnectedReferenceInputController.propTypes = {
  record: P.object,
  filter: P.object,
  sort: P.object,
  perPage: P.number,
  input: P.object,
  referenceRecord: P.object,
  matchingReferences: P.array,
  onChange: P.func,
  children: P.oneOfType([P.element, P.func]),
  translate: P.func,
  filterToQuery: P.func,
  optionValue: P.string
};

const ReferenceInputController = compose(
  translate,
  connect(
    makeMapStateToProps(),
    {
      crudGetManyAccumulate: crudGetManyAccumulateAction,
      crudGetMatchingAccumulate: crudGetMatchingAccumulateAction
    }
  )
)(UnconnectedReferenceInputController);

ReferenceInputController.defaultProps = {
  referenceSource: defaultReferenceSource
};

export default ReferenceInputController;
