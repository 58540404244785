import React from "react";
import PropTypes from "prop-types";
import { flatMap } from "lodash";

const style = `
html,body,route-sheet{
    width: 99%;
    height: 100%;
}
@media print {
    thead {display: table-header-group;}

    thead th {
        border-top-style: solid;
        border-top-width: 2px;
        border-right-width: 1px;
        border-right-style: solid;
        border-left: none;
        font-size: 12px;
        font-weight: bold;
        border-spacing: 0px;
        padding:0px;
    }

    thead th.MR{
        align-items: center;
        border-left: none;
        }

    thead tr.MR{
        border-left: none;
        }

    tbody{display: table-row-group;}


    tbody td{
        border-style: solid;
        border-width: 1px;
        border-right: none;
        border-top: none;
        font-size: 11px;
        font-style: normal;
        border-spacing: 0px;
        padding:0px;
        page-break-inside: avoid;
        page-break-after: auto;
     }

    .DB{page-break-after: avoid;}

    .DB1{
    page-break-after: auto;
    page-break-before: avoid;
    }

    .head{
    text-align: left;
    }

    td.No{
        text-align: right;
    }

}

@page{
    size: A4 landscape;
    margin: 0;
}
`;
const customerLength = 20;

const styleObject = {
  th: {
    borderLeft: {
      borderLeftStyle: "solid",
      borderLeftWidth: "1px"
    },
    textAlign: {
      textAlign: "center"
    },
    borderLeftBottom: {
      borderLeftStyle: "solid",
      borderLeftWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomWidth: "2px"
    },
    borderBottom: {
      borderBottomStyle: "solid",
      borderBottomWidth: "2px"
    },
    borderRightBottom: {
      borderRightStyle: "solid",
      borderRightWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomWidth: "2px"
    }
  },
  td: {
    borderRight: {
      borderRightStyle: "solid",
      borderRightWidth: "1px"
    }
  }
};

const RouteSheet = ({ itinerary, readingTasks, readingCenter, reader }) => (
  <div className="route-sheet">
    <style type="text/css">{style}</style>
    <table className="head" style={{ width: "100%" }}>
      <thead style={{ pageBreakAfter: "avoid" }}>
        <tr className="P1">
          <th style={styleObject.th.borderLeft} colSpan="10">
            ENEO-Route Sheet
          </th>
          <th style={styleObject.th.textAlign}>Issued:</th>
          <th style={styleObject.th.textAlign} colSpan="2">
            {new Date().toString()}
          </th>
        </tr>
        <tr className="P2">
          <th style={styleObject.th.borderLeft}>
            MRSP: <br />
            <br /> Route:
          </th>
          <th colSpan="3">
            {readingCenter.remote_id}
            <br />
            <br />
            {itinerary.route}
          </th>
          <th>
            Itinerary: <br />
            <br />
            Cycle:
          </th>
          <th colSpan="3">
            {itinerary.remote_id}
            <br />
            <br />
            {itinerary.cycle}
          </th>
          <th>
            M/Reader: <br />
            <br /> HHT
          </th>
          <th colSpan="3">
            {reader.user_reference}
            <br />
            <br />
            NOT LOADED TO HHT
          </th>
          <th className="Page">
            <br />
          </th>
        </tr>
        <tr
          className="MR"
          style={{
            textAlign: "center",
            borderBottomStyle: "solid",
            borderBottomWidth: "2px"
          }}
        >
          <th style={styleObject.th.borderLeft}> </th>
          <th style={styleObject.th.borderBottom}>Meter No.</th>
          <th style={styleObject.th.borderBottom}>Make</th>
          <th style={styleObject.th.borderBottom}>Usage</th>
          <th style={styleObject.th.borderBottom}>Premise access</th>
          <th style={styleObject.th.borderBottom} width={"auto"}>
            Register{" "}
          </th>
          <th style={styleObject.th.borderBottom} width={"auto"}>
            Dials{" "}
          </th>
          <th style={styleObject.th.borderBottom}>Reading </th>
          <th style={styleObject.th.borderBottom}>Anomaly </th>
          <th style={styleObject.th.borderBottom} width={"9%"}>
            Date{" "}
          </th>
          <th style={styleObject.th.borderRightBottom} colSpan="3">
            Landmark
          </th>
        </tr>
      </thead>
      {flatMap(readingTasks, (rt, idx) => [
        <tbody className="body1" key={rt.id}>
          <tr className="DB" height={"23px"}>
            <td
              className="No"
              width={"3%"}
              style={{ borderTopStyle: "solid", borderTopWidth: "2px" }}
            >
              {idx + 1}
            </td>
            <td>{rt.meter.meter_identifier}</td>
            <td>{rt.meter.manufacturer}</td>
            <td>ACTIVE</td>
            <td width={"25%"}>{rt.supply_point.access}</td>
            <td>REGISTER</td>
            <td style={styleObject.th.textAlign}>{rt.meter.dials}</td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td style={styleObject.td.borderRight} colSpan="3">
              {" "}
              <b>Reference:</b>{" "}
            </td>
          </tr>
        </tbody>,
        <tbody style={{ pageBreakBefore: "avoid" }} key={`${rt.id}_1`}>
          <tr className="DB1">
            <td className="NB" style={{ width: "3%", border: "none" }}>
              {" "}
            </td>
            <td colSpan="3" width={"20%"}>
              <b>Customer Name: </b> <br />{" "}
              {`${rt.supply_point.first_name} ${rt.supply_point.last_name}`.substring(
                0,
                customerLength
              )}
            </td>
            <td>
              <b>Tariff:</b> {rt.tarif_code}
              <br /> <b>Desc:</b> {rt.tarif_description}
            </td>
            <td colSpan="8" style={styleObject.td.borderRight}>
              <b>Location:</b> {rt.supply_point.address}
              <br /> <b>Mt. Pt. Acc.: </b> {rt.supply_point.access_meter}
            </td>
          </tr>
        </tbody>
      ])}
    </table>
  </div>
);

RouteSheet.propTypes = {
  itinerary: PropTypes.object,
  readingTasks: PropTypes.array,
  readingCenter: PropTypes.object,
  reader: PropTypes.object
};

export default RouteSheet;
